import React from "react";
import Box from "@material-ui/core/Box";
import { VegaLite } from "react-vega";

export default function PieChart(props) {
  const { data } = props;
  // console.log(data);
  return (
    <VegaLite
      actions={false}
      
      spec={{
        $schema: "https://vega.github.io/schema/vega-lite/v4.json",
        width: 100,
        height: 100,
        mark: "arc",
        data: { values: data },
        encoding: {
          theta: {
            field: "count",
            type: "quantitative",
          },
          color: {
            field: "text",
            type: "nominal",
            title: "",
          },
          view: { stroke: null },
        },
        "config": {
          "view": {
             "stroke": "transparent"
          }
        }
      }}
    />
  );
}
