import { sort } from "d3";
import _ from "underscore";

export function randomSplit(
  array,
  numOfTargetImages,
  numOfTargetsAndVigilance
) {
  // console.log(array);
  let newArray = [];
  var currentIndex = array.length,
    randomIndex;
  // console.log(currentIndex);
  let stopCondition =
    array.length - numOfTargetsAndVigilance + numOfTargetImages;
  while (currentIndex > stopCondition) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    newArray.push(array[randomIndex]);
    array.splice(randomIndex, 1);
  }
  return [array, newArray]; // [filler, vigilance]
}

export function randomImageSequence(sequenceParams, combinedImgSet, images) {
  const { min, max, min2, max2 } = sequenceParams;

  for (var i = 0; i < images.length; i++) {
    var temp1 = combinedImgSet.slice();

    var pool = temp1.splice(min, max);
    // console.log(pool);
    var c = pool.map((p, index) => {
      if (p === "" && p !== "adjacent") {
        pool[index + min + 2] = "adjacent";
        return index + min;
      } else {
        pool[index + min + 2] = "";
      }
    });
    // console.log("pool", pool, c);
    c = c.filter(Number);
    var len = c.length;
    var num = c[Math.floor(Math.random() * len)];
    // console.log(len, num);
    combinedImgSet[num] = images[i];

    var temp2 = combinedImgSet.slice();
    // var pool2 = temp2.splice(num + min2, num + max2);
    let lowerBound = num + min2;
    let upperBound =
      num + max2 < combinedImgSet.length ? num + max2 : combinedImgSet.length;
    var pool2 = temp2.splice(lowerBound, upperBound);

    var c2 = pool2.map((p, index) => {
      if (p === "" && p !== "adjacent") {
        pool2[index + num + min2 + 2] = "adjacent";
        return index + num + min2;
      }
    });
    // console.log("pool2", pool2);
    c2 = c2.filter(Number);
    var len2 = c2.length;
    var num2 = c2[Math.floor(Math.random() * len2)];
    combinedImgSet[num2] = images[i];

    c.splice(c.indexOf(num), 1);
    c2.splice(c2.indexOf(num2), 1);
  }
  // console.log(combinedImgSet);
}

export function randomizeImageSequence(
  [targetSpacingStart, targetSpacingEnd],
  [vigilanceSpacingStart, vigilanceSpacingEnd],
  targets,
  fillerImages,
  imgCount,
  NUMBER_OF_VIGILANCE
) {
  let slots = _.range(0, imgCount);
  let filledSlots = [];
  let vigilances = [];
  let fillers = [];
  console.log(fillerImages);

  function getSecondIndex(firstIndex, spacingStart, spacingEnd) {
    let remainedSlots = slots.filter((slot) => !filledSlots.includes(slot));

    // get possible range
    let secondIndexStart = firstIndex + spacingStart;
    let secondIndexEnd =
      firstIndex + spacingEnd < remainedSlots[remainedSlots.length - 1]
        ? firstIndex + spacingEnd
        : remainedSlots[remainedSlots.length - 1];
    let secondRange = _.range(secondIndexStart, secondIndexEnd + 1);
    // let secondRange = _.range(118, 119);
    // console.log(secondRange);
    // 117,119 [117,118]
    // filledSlots = [119];
    // remove already filled slots
    let netSecondRange = secondRange.filter((r) => !filledSlots.includes(r));
    // console.log(netSecondRange);

    if (netSecondRange.length > 0) {
      let secondIndex = _.sample(netSecondRange);
      filledSlots.push(secondIndex);
      return secondIndex;
    } else {
      return -1;
    }

    // []
    // undefined
    // if netSecondRange is empty, skip vigilance
    ////////////
    // if (netSecondRange)
    // let secondIndex = _.sample(netSecondRange);
    // console.log(secondIndex);
    // if (secondIndex === undefined) {
    //   // if intersection is empty,
    //   secondIndex = netSecondRange[netSecondRange.length - 1];
    //   console.log(netSecondRange, secondIndex);
    // }

    // store second index
    // filledSlots.push(secondIndex);
    ////////
    // return secondIndex;
  }

  // getSecondIndex(28, 91, 108);

  // initialize image set for experiment
  let combinedImgSet = [];
  for (var i = 0; i < imgCount; i++) {
    combinedImgSet[i] = { type: "e" };
  }

  ///------------
  //// targets
  ///------------
  // randomly set first index of targets
  let targetFirstIndexes = _.sample(
    _.range(0, imgCount - targetSpacingEnd),
    targets.length
  );

  // store first indexes
  filledSlots.push.apply(filledSlots, targetFirstIndexes);

  // get second index and assign to combinedImgSet
  targetFirstIndexes.map((firstIndex, i) => {
    let secondIndex = getSecondIndex(
      firstIndex,
      targetSpacingStart,
      targetSpacingEnd
    );
    console.log(firstIndex, secondIndex);
    if (secondIndex !== -1) {
      combinedImgSet[firstIndex] = targets[i];
      combinedImgSet[secondIndex] = targets[i];
    } else {
      filledSlots = _.without(filledSlots, firstIndex);
    }
  });
  // console.log(combinedImgSet.map((d) => d.type).join(""));

  ///------------
  /// vigilances
  ///------------
  // get vigilances' possible range for its first appearance
  let vigilanceRange = _.range(0, imgCount - vigilanceSpacingEnd);
  let netVigilanceRange = vigilanceRange.filter(
    (r) => !filledSlots.includes(r)
  );

  // randomly set first index of vigilances
  let vigilanceFirstIndexes = _.sample(netVigilanceRange, NUMBER_OF_VIGILANCE);

  // store first indexes
  filledSlots.push.apply(filledSlots, vigilanceFirstIndexes);

  // fillerImages are used for both vigilances and fillers.
  let fillerImageIndex = 0;
  // get second index and assing to combinedImgSet
  vigilanceFirstIndexes.map((vFirstIndex) => {
    let fn = fillerImages[fillerImageIndex];
    let fnObj = {
      type: "v" + fillerImageIndex,
      id: fn.split(".")[0],
      source: process.env.PUBLIC_URL + "/fillers/" + fn,
    };

    let vSecondIndex = getSecondIndex(
      vFirstIndex,
      vigilanceSpacingStart,
      vigilanceSpacingEnd
    );
    console.log(vFirstIndex, vSecondIndex);

    if (vSecondIndex !== -1) {
      combinedImgSet[vFirstIndex] = fnObj; //fillerImages[fillerImageIndex];
      combinedImgSet[vSecondIndex] = fnObj; //fillerImages[fillerImageIndex];

      // store vigilances
      vigilances.push(fnObj); //fillerImages[fillerImageIndex]);

      fillerImageIndex += 1;
    } else {
      console.log("vigi delete -----");
      filledSlots = _.without(filledSlots, vFirstIndex);
    }
  });
  // console.log(combinedImgSet.map((d) => d.type).join(""));

  ///------------
  ///  fillers
  ///------------
  // filler
  let remainedSlots = slots.filter((r) => !filledSlots.includes(r));
  remainedSlots.map((idx) => {
    if (combinedImgSet[idx].type === "e") {
      let fn = fillerImages[fillerImageIndex];
      let fnObj = {
        type: "f" + fillerImageIndex,
        id: fn.split(".")[0],
        source: process.env.PUBLIC_URL + "/fillers/" + fn,
      };

      combinedImgSet[idx] = fnObj; // fillerImages[fillerImageIndex];

      // store fillers
      fillers.push(fnObj); //fillerImages[fillerImageIndex]);

      // increase fillerImages index
      fillerImageIndex += 1;
    } else {
      console.log("ddddd:", idx, i);
    }
  });
  // console.log(combinedImgSet.map((d) => d.type).join(""));

  // previous codes
  // let vigilances = [];
  // let fillers = [];
  // const [min, max, min2, max2] = targetSequenceParam;
  // let targetIndex = _.sample(_.range(min, max), targets.length);
  // let max2Bound = max2;
  // //   1,2 first + [91, 109]
  // //   3,2 spacing
  // // 4,4 second
  // targets.map((target, index) => {
  //   combinedImgSet[targetIndex[index]] = target;
  //   let repeat = 1;
  //   while (repeat < 2) {
  //     if (targetIndex[index] + max2 < imgCount) {
  //       max2Bound = max2;
  //     } else {
  //       max2Bound = imgCount - targetIndex[index] - 1;
  //     }

  //     const index2 = targetIndex[index] + _.random(min2, max2Bound);
  //     if (combinedImgSet[index2].type === "e") {
  //       combinedImgSet[index2] = target;
  //       repeat += 1;
  //     }
  //   }
  //   console.log(combinedImgSet.map((d) => d.type).join(""));
  // });

  // /// vigilances
  // // 0-116 random, lodash, _.sample
  // // 전체에서 채워진 것 뺀 숫자 중에서
  // const [vmin, vmax, vmin2, vmax2] = vigilanceSequenceParam;
  // let vmax2Bound = vmax2;
  // let fillersImgNum = 0;
  // for (let i = 0; i < combinedImgSet.length; i++) {
  //   if (combinedImgSet[i].type === "e") {
  //     // image file path
  //     let fn = fillerImages[fillersImgNum];
  //     // console.log(fillerImages, fillersImgNum, fillerImages[fillersImgNum]);
  //     let meta = {
  //       id: fn.split(".")[0],
  //       source: process.env.PUBLIC_URL + "/fillers/" + fn,
  //     };

  //     if (i >= vmin && i < vmax) {
  //       if (_.random(0, 2)) {
  //         // 1: vigilance, 0: filler
  //         combinedImgSet[i] = {
  //           ...meta,
  //           type: "v",
  //         };
  //         // combinedImgSet[i + _.random(vmin2, vmax2)] = meta;
  //         let repeat = 1;
  //         while (repeat < 2) {
  //           console.log("while");
  //           if (i + vmax2 < imgCount) {
  //             vmax2Bound = vmax2;
  //           } else {
  //             vmax2Bound = imgCount - i - 1;
  //           }

  //           const i2 = i + _.random(vmin2, vmax2Bound);
  //           if (combinedImgSet[i2].type === "e") {
  //             combinedImgSet[i2] = {
  //               ...meta,
  //               type: "v",
  //             };
  //             repeat += 1;
  //           }
  //         }
  //         vigilances.push(meta);
  //       } else {
  //         combinedImgSet[i] = {
  //           ...meta,
  //           type: "f",
  //         };
  //         fillers.push(meta);
  //       }
  //     } else {
  //       combinedImgSet[i] = {
  //         ...meta,
  //         type: "f",
  //       };
  //       fillers.push(meta);
  //     }
  //     fillersImgNum += 1;
  //   }
  //   // else: combinedImgSet[i] !== "", pass

  //   console.log(combinedImgSet.map((d) => d.type).join(""));
  // }
  return [combinedImgSet, vigilances, fillers];
}

/*
 * Lower tail quantile for standard normal distribution function.
 *
 * This function returns an approximation of the inverse cumulative
 * standard normal distribution function.  I.e., given P, it returns
 * an approximation to the X satisfying P = Pr{Z <= X} where Z is a
 * random variable from the standard normal distribution.
 *
 * The algorithm uses a minimax approximation by rational functions
 * and the result has a relative error whose absolute value is less
 * than 1.15e-9.
 *
 * Author:      Peter John Acklam
 * E-mail:      jacklam@math.uio.no
 * WWW URL:     http://home.online.no/~pjacklam/notes/invnorm/
 *
 * Javascript implementation by Liorzou Etienne
 * - Adapted from Dr. Thomas Ziegler's C implementation itself adapted from Peter's Perl version
 *
 * Q: What about copyright?
 * A: You can use the algorithm for whatever purpose you want, but
 * please show common courtesy and give credit where credit is due.
 *
 * If you have any reclamation about this file (ie: normal.inverse.js file),
 * please contact me.
 *
 */

/* Coefficients in rational approximations. */
var a = [
  -3.969683028665376e1, 2.209460984245205e2, -2.759285104469687e2,
  1.38357751867269e2, -3.066479806614716e1, 2.506628277459239,
];

var b = [
  -5.447609879822406e1, 1.615858368580409e2, -1.556989798598866e2,
  6.680131188771972e1, -1.328068155288572e1,
];

var c = [
  -7.784894002430293e-3, -3.223964580411365e-1, -2.400758277161838,
  -2.549732539343734, 4.374664141464968, 2.938163982698783,
];

var d = [
  7.784695709041462e-3, 3.224671290700398e-1, 2.445134137142996,
  3.754408661907416,
];

var LOW = 0.02425;
var HIGH = 0.97575;
var MAX_LOW = 0.001;
var MAX_HIGH = 0.999;

export var ltqnorm = function (p) {
  var q, r;

  // errno = 0;

  if (p < 0 || p > 1) {
    // errno = EDOM;
    return 0.0;
    // } else if (p == 0) {
    //   // errno = ERANGE;
    //   // return Number.NEGATIVE_INFINITY; /* minus "infinity" */;
    //   return -3;
    // } else if (p == 1) {
    //   // errno = ERANGE;
    //   // return Number.POSITIVE_INFINITY; /* "infinity" */;
    //   return 3;
  } else if (p < MAX_LOW) {
    q = Math.sqrt(-2 * Math.log(MAX_LOW));
    return (
      (((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
      ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
    );
  } else if (p > MAX_HIGH) {
    q = Math.sqrt(-2 * Math.log(1 - MAX_HIGH));
    return (
      -(((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
      ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
    );
  } else if (p < LOW) {
    /* Rational approximation for lower region */
    q = Math.sqrt(-2 * Math.log(p));
    return (
      (((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
      ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
    );
  } else if (p > HIGH) {
    /* Rational approximation for upper region */
    q = Math.sqrt(-2 * Math.log(1 - p));
    return (
      -(((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
      ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
    );
  } else {
    /* Rational approximation for central region */
    q = p - 0.5;
    r = q * q;
    return (
      ((((((a[0] * r + a[1]) * r + a[2]) * r + a[3]) * r + a[4]) * r + a[5]) *
        q) /
      (((((b[0] * r + b[1]) * r + b[2]) * r + b[3]) * r + b[4]) * r + 1)
    );
  }
};

// export function ltqnorm(to) {
//   var mean = 0;
//   var sigma = 1;
//   var z = (to - mean) / Math.sqrt(2 * sigma * sigma);
//   var t = 1 / (1 + 0.3275911 * Math.abs(z));
//   var a1 = 0.254829592;
//   var a2 = -0.284496736;
//   var a3 = 1.421413741;
//   var a4 = -1.453152027;
//   var a5 = 1.061405429;
//   var erf =
//     1 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-z * z);
//   var sign = 1;
//   if (z < 0) {
//     sign = -1;
//   }
//   return (1 / 2) * (1 + sign * erf);
// }
