import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ExperimentStatus from "components/ExperimentStatus";
import ExperimentDashboard from "components/ExperimentDashboard";
import ExperimentIndividualDashboard from "components/ExperimentIndividualDashboard";

import { Tabs, Tab, makeStyles, Grid, Box, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
  },
  content: {
    marginTop: "25px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function DashboardView(props) {
  const classes = useStyles();
  const { experimentId } = useParams();
  const { readOnly } = props;
  const [value, setValue] = useState(0);

  const changeDashboardMode = (e, newValue) => {
    e.preventDefault();
    setValue(newValue);
  };

  const renderMode = () => {
    if (value === 0) {
      return <ExperimentDashboard experimentId={experimentId} />;
    } else if (value === 1) {
      return <ExperimentIndividualDashboard experimentId={experimentId} />;
    }
  };

  const [refresh, setRefresh] = useState();
  const handleRefreshIcon = () => {
    setRefresh({});
  };

  return (
    <Grid container className={classes.content}>
      <Grid item xs={12}>
        <ExperimentStatus experimentId={experimentId} />
      </Grid>

      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={changeDashboardMode}
          >
            <Tab label="Summary" id={0} style={{ minWidth: "25%" }} />

            {!readOnly && (
              <Tab label="Individuals" id={1} style={{ minWidth: "25%" }} />
            )}
          </Tabs>
        </Box>
      </Grid>
      {/* <Grid item xs={6}>
              <Grid container alignItems="flex-start" justify="flex-end">
                <Tooltip title="Refresh">
                  <IconButton onPointerUp={handleRefreshIcon} component="span">
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid> */}

      <Grid item xs={12}>
        {renderMode()}
      </Grid>
    </Grid>
  );
}
