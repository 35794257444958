import React from "react";
import BubbleViewInterface from "components/BubbleView/Interface";

import { default as PerceptionPractice } from "components/Perception/Practice";
import { default as MemorabilityPractice } from "components/Memorability/Practice";
import { DEFAULT_BUBBLEVIEW_PARAMS } from "utils/textResources";

import { Grid, Box, Typography, Divider } from "@material-ui/core";

function PracticeLive(props) {
  const { type, setIsValidSubmit } = props;
  // console.log(type);

  const recordResponse = (charCount, ans) => {};

  function renderPractice(type) {
    if (type === "Graphical Perception") {
      return <PerceptionPractice setIsValidSubmit={setIsValidSubmit} />;
    } else if (type === "Memorability") {
      return <MemorabilityPractice setIsValidSubmit={setIsValidSubmit} />;
    } else if (type === "Attention Tracking") {
      return (
        <BubbleViewInterface
          isDesign={false}
          imgSrc={process.env.PUBLIC_URL + "/img/Practices/bar.png"}
          {...DEFAULT_BUBBLEVIEW_PARAMS}
          recordResponse={recordResponse}
          isPractice={true}
          setIsValidSubmit={setIsValidSubmit}
        />
      );
    }
  }

  return (
    <>
      {/* <Grid container align="left">
        <Grid item xs>
          <Typography
            variant="h5"
            display="block"
            gutterBottom
            // style={{ marginTop: "20px" }}
          >
            Practice Trial
          </Typography>
        </Grid>
      </Grid> */}
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        // style={{ marginTop: "20px" }}
      >
        Practice Trial
      </Typography>
      <Divider variant="fullWidth" />
      <Box mt={3}>
        <Grid container align="center">
          <Grid item xs>
            {renderPractice(type)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PracticeLive;
