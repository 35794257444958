import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import DesignView from "components/DesignView";
import LiveView from "components/LiveView";
import DeployView from "components/DeployView";
import DashboardView from "components/DashboardView";
import RemixesView from "components/RemixesView";
// import AccountMenu from "components/AccountMenu";

import {
  requestExperimentContent,
  updateExperiment,
  duplicateExperiment,
} from "actions/experiments";
import { selectExperiment } from "actions/ui";
import { makeGetTasks } from "actions/tasks";

import {
  makeStyles,
  Tabs,
  Tab,
  Grid,
  Typography,
  Button,
  Box,
  Fab,
  Dialog,
} from "@material-ui/core";
import { TabList, TabContext, TabPanel } from "@material-ui/lab";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router-dom";
// import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  menu: {
    display: "flex",
  },
  content: {
    width: "100%",
    margin: "5px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  remixIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ExperimentReadOnly = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { experimentId } = useParams();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [remixConfirmMenu, showRemixConfirmMenu] = useState(false);

  const { experiment, username, displayName, states } = useSelector(
    (state) => ({
      experiment: state.experiments[experimentId],
      username: state.auth.user.uid,
      displayName: state.auth.user.email,
      states: state,
    })
  );

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, {
      experimentId: experimentId,
    }),
  }));

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (experiment) {
      setValue(experiment.shareState === "public-result-only" ? 3 : 0);
    }
  }, [experiment]);

  const [isLoading, setIsLoading] = useState(!tasks.length);

  useEffect(() => {
    async function fetchData() {
      if (isLoading) {
        await dispatch(requestExperimentContent(username, experimentId, true)); //retrieve form content from server
        await dispatch(selectExperiment(experimentId)); // when acceced directly from the url
      }
      if (tasks.length === 0) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const changeMode = (e, newValue) => {
    e.preventDefault();
    setValue(newValue);
  };

  const renderMode = () => {
    if (value === 0) {
      return <DesignView experimentId={experimentId} readOnly={true} />;
    }
    // else if (value === 2) {
    //   return <DeployView experimentId={experimentId} />;
    // }
    else if (value === 3) {
      return <DashboardView experimentId={experimentId} readOnly={true} />;
    } else if (value === 1) {
      return (
        <LiveView
          experimentId={experimentId}
          username={username}
          previewMode={true}
          liveMode={false}
        />
      );
    } else if (value === 4) {
      return <RemixesView experimentId={experimentId} />;
    }
  };

  const confirmRemix = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(duplicateExperiment(experimentId, username, states, history));
  };
  const handleRemix = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // closeMenu();
    showRemixConfirmMenu(true);
  };
  const cancelRemix = (event) => {
    event.stopPropagation();
    event.preventDefault();
    showRemixConfirmMenu(false);
  };

  // const handleClickRemix = () => {
  //   // copy experiment - key, id, username to parentRemix, username, _id
  //   //
  //   dispatch(duplicateExperiment(experimentId, username, states, history));
  // };

  return (
    <>
      {tasks.length > 0 && (
        <>
          <Grid
            container
            className={classes.root}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={9}>
              <Typography variant="h5" display="block" gutterBottom>
                {experiment.title}
              </Typography>
            </Grid>
            {(experiment.shareState === "public-design-result" ||
              experiment.shareState === "public-design-only") && (
              <Grid item xs={3}>
                <Box
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Fab
                    variant="extended"
                    color="secondary"
                    size="medium"
                    aria-label="remix"
                    onPointerUp={handleRemix}
                  >
                    <CreateIcon className={classes.remixIcon} />
                    Remix
                  </Fab>
                </Box>
              </Grid>
            )}
            {remixConfirmMenu && (
              <Dialog
                className={classes.dialog}
                open={remixConfirmMenu}
                onClose={cancelRemix}
              >
                <Box p={3} onClick={(e) => e.stopPropagation()}>
                  <Typography variant="subtitle1">
                    When you click Remix, this {experiment.title} experiment
                    clones to your account and you are no longer able to
                    participate in the experiment.
                  </Typography>
                  <Box
                    mt={3}
                    boxSizing="border-box"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      className={classes.margin}
                      color="secondary"
                      variant="outlined"
                      onClick={confirmRemix}
                    >
                      Remix
                    </Button>{" "}
                    <Button
                      className={classes.margin}
                      onClick={cancelRemix}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            )}
            <Grid item xs={12}>
              <Grid container>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={changeMode}
                  centered
                >
                  {(experiment.shareState === "public-design-result" ||
                    experiment.shareState === "public-design-only") && (
                    <Tab label="Design" value={0} style={{ minWidth: "25%" }} />
                  )}

                  {(experiment.shareState === "public-design-result" ||
                    experiment.shareState === "public-design-only") && (
                    <Tab
                      label="Preview"
                      value={1}
                      style={{ minWidth: "25%" }}
                    />
                  )}

                  {(experiment.shareState === "public-design-result" ||
                    experiment.shareState === "public-result-only") && (
                    <Tab label="Result" value={3} style={{ minWidth: "25%" }} />
                  )}

                  <Tab label="Remixes" value={4} style={{ minWidth: "25%" }} />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.content}>
            {tasks.length > 0 && renderMode()}
          </div>
        </>
      )}
    </>
  );
};

export default ExperimentReadOnly;
