import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { signOut } from "actions";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";

export default function AccountMenu(props) {
  const { displayName, isAuthenticated } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(signOut(history));
    handleClose();
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <Button aria-label="account" onClick={handleClick}>
            <AccountCircleIcon />
            &nbsp;<Typography variant="button">{`${displayName}`}</Typography>
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleLogout}>
              <Typography variant="button">Sign Out</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}
