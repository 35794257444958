import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";

import ChartStimuli from "components/ChartStimuli";
import { ErrorBar1D } from "utils/Charts";

import { makeGetCharts } from "actions/charts";
import { makeGetSurveys } from "actions/surveys";
import { makeGetTasks } from "actions/tasks";

import { average, standardDeviation, median, min, max } from "utils/stats";
import { parsePerceptionData } from "utils/dataProcessing";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { BoxPlot } from "utils/Charts";
import { makeStyles } from "@material-ui/core/styles";
import { createdDateAscending } from "utils/date";
const useStyles = makeStyles((theme) => ({
  tablerow: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.48)",
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const { experimentId, responses, demographicData, demographicQuestions } =
    props;

  const [data, setData] = useState([]);
  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getSurveys = makeGetSurveys();
  const { surveys } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: tasks[0].id,
      type: "perceptionSurvey",
    }),
  }));

  const getCharts = makeGetCharts();
  const { questions, options, charts } = useSelector((state) => ({
    questions: state.questions,
    options: state.options,
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  const { qIds, chartIds } = useSelector((state) => ({
    qIds: Object.values(questions)
      .filter((q) => q.surveyId === surveys[0].id)
      .map((q) => q.id)
      .sort(createdDateAscending),
    chartIds: Object.values(charts).map((c) => c.id),
  }));

  useEffect(() => {
    async function fetchData() {
      if (responses.length > 0) {
        const result = await parsePerceptionData(responses, questions, options);
        console.log(result);
        const newResult = result.map((r) => {
          if (r.accuracy < 0) {
            return {
              ...r,
              accuracy: 0,
            };
          } else {
            return r;
          }
        });

        console.log(newResult);
        // setData(result);
        setData(newResult);
      }
    }
    fetchData();
  }, [responses.length]);

  let questionListFunc = function (qId, index) {
    return { text: `Q${index + 1}. ${questions[qId].text}`, id: qId };
  };

  const questionList = qIds.map((qId, index) => questionListFunc(qId, index));
  // console.log(questionList);

  const [questionId, setQuestionId] = useState(questionList[0].id);

  const handleQuestionUpdate = (e, value) => {
    if (value === null) {
      setQuestionId(null);
    } else {
      setQuestionId(value.id);
    }
  };

  const [demographicColumn, setDemographicColumn] = useState({});
  const handleDemographicFilter = (e, value) => {
    if (value === null) value = {};
    setDemographicColumn(value);
  };

  const initialColumnNames = [
    "Visualization",
    "Result",
    "Mean",
    "Median",
    "SD",
  ];
  const [columnNames, setColumnNames] = useState(initialColumnNames);

  // console.log(data, responses);
  useEffect(() => {
    if (Object.keys(demographicColumn).length > 0) {
      // add demographic questions into TableHead after 'Condition'
      let cNames = [
        ...initialColumnNames.slice(0, 1),
        demographicColumn.text,
        ...initialColumnNames.slice(1),
      ];
      setColumnNames(cNames);
    } else {
      setColumnNames(initialColumnNames);
    }
  }, [demographicColumn]);

  const renderTable = (
    columnNames,
    chartIds,
    demographicColumn,
    dependentVar
  ) => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnNames.map((el, i) => (
                <TableCell
                  align={columnNames.length - i < 4 ? "right" : "left"}
                  key={el}
                >
                  {el}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chartIds.map((chartId) =>
              Object.keys(demographicColumn).length > 0 ? (
                <>
                  {demographicColumn.options.map((o, j) => (
                    <Fragment key={chartId + o.id}>
                      <TableRow
                        className={
                          j === demographicColumn.options.length - 1
                            ? classes.tablerow
                            : null
                        }
                      >
                        <TableCell align="right">
                          <ChartStimuli type="tiny" chartId={chartId} />
                        </TableCell>
                        <TableCell align="left">{o.text}</TableCell>
                        <TableCell align="left">
                          <ErrorBar1D
                            name={null}
                            data={data
                              .filter(
                                (d) =>
                                  d.demographicSurvey[demographicColumn.id] ===
                                  o.id
                              )
                              .filter((d) => d.questionId === questionId)
                              .filter((d) => d.chartId === chartId)}
                            myData={null}
                            text={false}
                            size={null}
                            dependentVar={dependentVar}
                            // domainMin={min(data.map((d) => d[dependentVar]))}
                            // domainMax={max(data.map((d) => d[dependentVar]))}
                            domainMin="0"
                            domainMax={
                              dependentVar === "accuracy"
                                ? "100"
                                : max(data.map((d) => d[dependentVar]))
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          {average(
                            data
                              .filter(
                                (d) =>
                                  d.demographicSurvey[demographicColumn.id] ===
                                  o.id
                              )
                              .filter((d) => d.questionId === questionId)
                              .filter((d) => d.chartId === chartId)
                              .map((d) => d[dependentVar])
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {median(
                            data
                              .filter(
                                (d) =>
                                  d.demographicSurvey[demographicColumn.id] ===
                                  o.id
                              )
                              .filter((d) => d.questionId === questionId)
                              .filter((dd) => dd.chartId === chartId)
                              .map((d) => d[dependentVar])
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {standardDeviation(
                            data
                              .filter(
                                (d) =>
                                  d.demographicSurvey[demographicColumn.id] ===
                                  o.id
                              )
                              .filter((d) => d.questionId === questionId)
                              .filter((dd) => dd.chartId === chartId)
                              .map((d) => d[dependentVar])
                          )}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </>
              ) : (
                <Fragment key={chartId}>
                  <TableRow>
                    <TableCell align="center">
                      <ChartStimuli type="tiny" chartId={chartId} />
                    </TableCell>
                    <TableCell align="left">
                      <ErrorBar1D
                        data={data
                          .filter((d) => d.questionId === questionId)
                          .filter((d) => d.chartId === chartId)}
                        dependentVar={dependentVar}
                        // domainMin={min(data.map((d) => d[dependentVar]))}
                        // domainMax={max(data.map((d) => d[dependentVar]))}
                        domainMin="0"
                        domainMax={
                          dependentVar === "accuracy"
                            ? "100"
                            : max(data.map((d) => d[dependentVar]))
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      {average(
                        data
                          .filter((d) => d.questionId === questionId)
                          .filter((d) => d.chartId === chartId)
                          .map((d) => d[dependentVar])
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {median(
                        data
                          .filter((d) => d.questionId === questionId)
                          .filter((dd) => dd.chartId === chartId)
                          .map((d) => d[dependentVar])
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {standardDeviation(
                        data
                          .filter((d) => d.questionId === questionId)
                          .filter((dd) => dd.chartId === chartId)
                          .map((d) => d[dependentVar])
                      )}
                    </TableCell>
                  </TableRow>
                </Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  // console.log(data);
  return (
    <>
      {data.length > 0 ? (
        <>
          <Box mt={1}>
            <Alert severity="warning" style={{ marginTop: "10px" }}>
              This result is not a scientific fact but could be used as a design
              feedback.
            </Alert>
          </Box>
          <Box mt={3}>
            <Typography variant="h5" display="block" gutterBottom>
              Overview
            </Typography>
          </Box>
          <Box mt={1}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Visualization</TableCell>
                    <TableCell align="left">
                      <Box display="flex" alignItems="center">
                        Accuracy (%) &nbsp;
                        <Tooltip title="Accuracy indicates how many questions are answered correctly by all participants. One means all questions correctly answered, while a zero score means none of the questions answered correctly.">
                          <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box display="flex" alignItems="center">
                        Task Completion Time (sec)&nbsp;
                        <Tooltip title="Task completion time indicates how fast participants answered questions. A higher number suggests that it took a longer time.">
                          <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chartIds.map((chartId, i) => (
                    <Fragment key={chartId + i}>
                      <TableRow>
                        <TableCell align="left">
                          <ChartStimuli type="tiny" chartId={chartId} />
                        </TableCell>
                        <TableCell align="left">
                          <ErrorBar1D
                            name={null}
                            data={data.filter((d) => d.chartId === chartId)}
                            myData={null}
                            text={false}
                            size={null}
                            dependentVar="accuracy"
                            // domainMin={min(data.map((d) => d["correct"]))}
                            // domainMax={max(data.map((d) => d["correct"]))}
                            domainMin="0"
                            domainMax="100"
                          />
                        </TableCell>
                        <TableCell align="left">
                          <ErrorBar1D
                            name={null}
                            data={data.filter((d) => d.chartId === chartId)}
                            myData={null}
                            text={false}
                            size={null}
                            dependentVar="time"
                            // domainMin={min(data.map((d) => d["time"]))}
                            domainMin="0"
                            domainMax={max(data.map((d) => d["time"]))}
                          />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Grid container item spacing={2} direction="row">
              <Grid item xs={6}>
                <BoxPlot data={data} dependentVar="correct" />
              </Grid>
              <Grid item x={6}>
                <BoxPlot data={data} dependentVar="time" />
              </Grid>
            </Grid> */}
          </Box>

          <Box mt={6}>
            <Typography variant="h5" display="block" gutterBottom>
              Breakdown Report
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
              Choose a question and demographic factor to analyze perceptual
              accuracy and task completion time.
            </Typography>
          </Box>
          <Box mt={2}>
            <Grid item container spacing={2}>
              <Grid item xs>
                <Autocomplete
                  size="small"
                  options={questionList}
                  getOptionLabel={(option) => option.text}
                  defaultValue={questionList[0]}
                  onChange={handleQuestionUpdate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Questions"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs>
                <Autocomplete
                  disabled={Object.keys(demographicData).length === 0}
                  // multiple
                  freeSolo
                  // defaultValue={demographicQuestions[0]}
                  options={demographicQuestions}
                  getOptionLabel={(option) => option.text}
                  filterSelectedOptions
                  onChange={handleDemographicFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Demographic Factor"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          {questionId && (
            <Fragment key={questionId}>
              <Box mt={4} mb={1}>
                <Chip
                  color="primary"
                  variant="outlined"
                  label="Accuracy"
                  icon={<CheckCircleOutlineIcon />}
                />
              </Box>
              <Box mt={2}>
                {renderTable(
                  columnNames,
                  chartIds,
                  demographicColumn,
                  "accuracy"
                )}
              </Box>
              <Box mt={4} mb={1}>
                <Chip
                  color="primary"
                  variant="outlined"
                  label="Task Completion Time"
                  icon={<AccessTimeIcon />}
                />
              </Box>
              <Box mt={2}>
                {renderTable(columnNames, chartIds, demographicColumn, "time")}
              </Box>
            </Fragment>
          )}
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
