import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectExperiment, openExperiment } from "actions/ui";
import { deleteExperiment } from "actions/experiments";
import { makeGetResponses } from "actions/responses";

import ExperimentCard from "components/ExperimentCard";
// import css from "components/ExperimentThumbnail.module.css";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "200px",
    cursor: "pointer",
    height: "200px",
  },
  cardDesc: {
    height: "50px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function ExperimentThumbnail(props) {
  const classes = useStyles();
  const { experimentId } = props;
  const [deleteConfirmMenu, showDeleteConfirmMenu] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const { experiment, selected } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
    selected: state.ui.selectedExperiment === experimentId,
  }));

  const getResponses = makeGetResponses();
  const { responses } = useSelector((state) => ({
    responses: getResponses(state, {
      experimentId: experimentId,
      // type: experiment ? experiment.type : "",
    }),
  }));
  // const { title, desc, coverImage, selected } = useSelector((state) => ({
  //   title: state.experiments[id].title,
  //   desc: state.experiments[id].desc,
  //   coverImage: state.experiments[id].coverImage,
  //   // updatedAt: state.experime============
  //   selected: state.ui.selectedExperiment === id,
  // }));

  const handleOpen = () => {
    dispatch(openExperiment(experimentId));
    history.push(`/experiment/${experimentId}/edit`);
  };
  const confirmDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(deleteExperiment(experimentId));
  };
  // const handleSelect = (e) => {
  //   e.stopPropagation();
  //   dispatch(selectExperiment(experimentId));
  // };
  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    closeMenu();
    showDeleteConfirmMenu(true);
  };
  const cancelDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    showDeleteConfirmMenu(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    setAnchorEl(null);
  };

  return (
    <Card className={classes.card} variant="outlined" onClick={handleOpen}>
      <CardContent>
        <Typography variant="button" gutterBottom display="block">
          {experiment.title.length < 20
            ? experiment.title
            : experiment.title.slice(0, 17).concat("...")}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.cardDesc}
          display="block"
        >
          {experiment.desc.length < 45
            ? experiment.desc
            : experiment.desc.slice(0, 42).concat("...")}
        </Typography>
        <Chip
          label={
            experiment.type === undefined
              ? experiment.template
              : experiment.type
          }
          size="small"
          className={classes.chip}
        />
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        {experiment.isFeatured && (
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box component="span">
              <Chip
                label={"Featured"}
                size="small"
                className={classes.chip}
                color="secondary"
              />
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box component="span">
            <Typography variant="caption" color="textSecondary">
              {new Date(experiment.updatedAt).toDateString().slice(-11)}
            </Typography>
          </Box>
          {/* <IconButton aria-label="open" onClick={handleOpen}>
          <OpenInNew />
        </IconButton> */}
          <IconButton aria-label="delete" onClick={openMenu}>
            {/* <DeleteOutlinedIcon /> */}

            <MoreVertIcon />
          </IconButton>
        </Box>

        {/* <Button size="small" endIcon={<OpenInNew />}>
          Open
        </Button>
        <Button size="small" endIcon={<DeleteOutlinedIcon />}>
          Delete
        </Button> */}
        {deleteConfirmMenu && (
          <Dialog
            className={classes.dialog}
            open={deleteConfirmMenu}
            onClose={cancelDelete}
          >
            <Box p={3} onClick={(e) => e.stopPropagation()}>
              <Typography variant="subtitle1">
                Are you sure you want to delete this experiment?
              </Typography>
              <Box
                mt={3}
                boxSizing="border-box"
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={classes.margin}
                  color="secondary"
                  variant="outlined"
                  onClick={confirmDelete}
                >
                  Delete
                </Button>{" "}
                <Button
                  className={classes.margin}
                  onClick={cancelDelete}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Dialog>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </CardActions>
    </Card>
    // <Paper
    //   className={[
    //     classes.experimentThumbnail,
    //     selected ? classes.selected : null,
    //   ].join(" ")}
    //   style={{
    //     width: "200px",
    //     height: "200px",
    //   }}
    //   onPointerUp={handleSelect}
    // >
    //   {/* <div className={style.title}>{title}</div> */}

    //   <ExperimentCard
    //     title={experiment.title}
    //     desc={experiment.desc}
    //     image={experiment.coverImage}
    //     numOfResponses={Object.keys(responses).length}
    //     type={
    //       experiment.type === undefined ? experiment.template : experiment.type
    //     }
    //     updatedAt={experiment.updatedAt}
    //   />
    //   {selected && (
    //     <div className={classes.overlay} style={{ opacity: 0.9 }}>
    //       {confirmDelete ? (
    //         <div className={classes.confirmPopup}>
    //           <Button onPointerUp={confirmDelete}>Delete</Button>
    //           <Button onPointerUp={cancelDelete}>Cancel</Button>
    //         </div>
    //       ) : (
    //         <>
    //           <Button onPointerUp={handleOpen}>Open</Button>
    //           <Button onPointerUp={handleDelete}>
    //             <DeleteOutlinedIcon />
    //           </Button>
    //         </>
    //       )}
    //     </div>
    //   )}
    // </Paper>
  );
}

export default ExperimentThumbnail;
