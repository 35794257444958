import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import vegaEmbed from "vega-embed";

import FileLoader from "components/FileLoader";
import ChartStimuli from "components/ChartStimuli";
import BubbleViewInterface from "components/BubbleView/Interface";

import {
  createTask,
  updateTask,
  deleteTask,
  makeGetTasks,
} from "actions/tasks";
import {
  makeGetCharts,
  createChart,
  deleteChart,
  updateChart,
} from "actions/charts";
import { selectTask, selectChart } from "actions/ui";

import { DEFAULT_BUBBLEVIEW_PARAMS } from "utils/textResources";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { EXPERIMENTAL_FEATURE } from "index";
export default function Design(props) {
  const { experimentId, readOnly } = props;
  const dispatch = useDispatch();

  const blurEl = useRef(null);
  const [task, setTask] = useState("describe");
  const [blur, setBlur] = useState("medium");
  const [bubble, setBubble] = useState("small");
  const [time, setTime] = useState("10");
  const [mouse, setMouse] = useState("click");

  const getTasks = makeGetTasks();
  const { tasks, selectedTask } = useSelector((state) => ({
    tasks: getTasks(state, props),
    selectedTask: state.ui.selectedTask,
  }));

  const getCharts = makeGetCharts();
  const { charts, selectedChart } = useSelector((state) => ({
    // charts: state.charts,
    charts: getCharts(state, {
      taskId: selectedTask,
    }),
    selectedChart: state.ui.selectedChart,
  }));

  // console.log(charts);
  // useEffect(() => {
  //   if (Object.values(charts).length > 0 && selectedChart) {
  //     const taskId = charts[selectedChart].taskId;
  //     dispatch(selectTask(taskId));
  //   }
  // }, [selectedChart]);

  // useEffect(() => {
  //   if (tasks.length > 0 && selectedTask === null) {
  //     dispatch(selectTask(tasks[0].id));
  //   }
  // }, [tasks.length]);

  useEffect(() => {
    if (tasks.length > 0) {
      dispatch(selectTask(tasks[0].id));
    }
  }, [tasks.length]);

  useEffect(() => {
    if (charts.length > 0 && selectedChart === null) {
      dispatch(selectChart(charts[0].id));
    }
  }, [charts.length]);

  useEffect(() => {
    if (selectedTask !== null) {
      let index = tasks.findIndex((q) => q.id === selectedTask);
      // console.log(selectedTask, charts[selectedChart].taskId);

      dispatch(
        updateTask(selectedTask, {
          params: {
            ...tasks[index].params,
            task: task,
            blur: blur,
            bubble: bubble,
            time: time,
            mouse: mouse,
          },
        })
      );
    }
  }, [task, blur, bubble, time, mouse]);

  useEffect(() => {
    let curTaskParams;

    if (tasks.length > 0 && selectedTask) {
      let index = tasks.findIndex((task) => task.id === selectedTask);
      curTaskParams = tasks[index].params;

      setTask(curTaskParams.task);
      setBlur(curTaskParams.blur);
      setBubble(curTaskParams.bubble);
      setTime(curTaskParams.time);
      setMouse(curTaskParams.mouse);
    }
  }, [selectedTask]);

  const handleAddChart = () => {
    let index = tasks.findIndex((task) => task.id === selectedTask);
    dispatch(createChart(tasks[index].id, {}));
  };

  const handleDeleteChart = () => {
    if (selectedChart) {
      let deleted = selectedChart;
      let index = charts.findIndex((b) => b.id === selectedChart);
      index -= 1;

      if (index >= 0) {
        dispatch(selectChart(charts[index].id));
      } else {
        dispatch(selectChart(null));
      }
      dispatch(deleteChart(deleted));
    }
  };

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };
  const handleBlurChange = (event) => {
    setBlur(event.target.value);
  };
  const handleBubbleChange = (event) => {
    setBubble(event.target.value);
  };
  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleMouseChange = (event) => {
    setMouse(event.target.value);
  };

  // const render = () => {
  //   // console.log(bubbleViewParams);
  //   let index = tasks.findIndex((q) => q.id === selectedTask);
  //   console.log(tasks[index], charts[selectedChart]);
  //   return (

  // };
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleReleaseError = () => {
    setShowError(false);
  };

  function handleDrop(e, chartId) {
    let file = e.dataTransfer.files[0];
    if (!file) {
      return;
    }

    if (file.type.match("application/json")) {
      // vega-lite
      const reader = new FileReader();

      // TODO: check
      reader.onload = async (e) => {
        const spec = JSON.parse(e.target.result);

        let div = document.createElement("div");
        div.style.width = "512px";
        div.style.height = "100%";
        document.body.appendChild(div);
        let result = await vegaEmbed(div, spec, {
          actions: false,
        });
        // console.log(result);
        result.view
          .toImageURL("png")
          .then(function (url) {
            // console.log("png:       ", url);
            dispatch(
              updateChart(chartId, {
                spec: JSON.stringify(spec),
                source: url === "data:," ? null : url,
                type: "vega",
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(div);
        document.body.removeChild(div);
      };

      reader.readAsText(file);
    } else if (file.type.match(/image.*/)) {
      if (file.size > 1000000) {
        setShowError(true);
        setErrorMsg("Size too big (>1MB)!");
        return;
      } else {
        let reader = new FileReader();
        reader.onloadend = (e) => {
          dispatch(
            updateChart(chartId, { type: "image", source: e.target.result })
          );
        };
        reader.readAsDataURL(file);
      }
    } else {
      setShowError(true);
      setErrorMsg("Wrong format!");
    }
  }

  return (
    <>
      <Box mb={2}>
        Provide your visualization design alternatives as experiment stimuli.
        Participants will be presented with blurred versions of the
        visualizations and asked to click or drag a moving window revealing
        small, circular areas of the image at original resolution. The positions
        of the moving window will be recorded for tracking attention.
      </Box>
      {tasks.length > 0 && selectedTask !== null ? (
        <>
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            // style={{ marginTop: "20px" }}
          >
            Visualizations
          </Typography>
          <Grid container spacing={3}>
            {Object.values(charts).map((chart) => (
              <Grid item key={chart.id}>
                <FileLoader
                  chartId={chart.id}
                  onDrop={!readOnly && ((e) => handleDrop(e, chart.id))}
                >
                  <ChartStimuli type="small" chartId={chart.id} />
                </FileLoader>
              </Grid>
            ))}
          </Grid>
          {!readOnly && (
            <div style={{ marginTop: "20px" }}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                onPointerUp={() => handleAddChart()}
                disabled={charts.length > 4 ? true : false}
              >
                Add Visualization
              </Button>
              {charts.length > 0 && (
                <Button onPointerUp={() => handleDeleteChart()}>
                  <DeleteOutlinedIcon />
                </Button>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {/* <Typography variant="h6" display="block" gutterBottom>
            Tasks
          </Typography>
          <div style={{ marginTop: "10px" }}>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onPointerUp={() => handleAddTask()}
            >
              Add Taks
            </Button>
          </div> */}
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            style={{ marginTop: "20px" }}
          >
            Visualizations
          </Typography>
          {!readOnly && (
            <div style={{ marginTop: "10px" }}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                onPointerUp={() => handleAddChart()}
              >
                Add Visualization
              </Button>
            </div>
          )}
        </>
      )}

      {selectedTask !== null && (
        <Grid container spacing={2}>
          {selectedChart && (
            <Grid item xs>
              <Typography
                variant="h6"
                display="block"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Visualization Preview
              </Typography>
              {charts.find((c) => c.id === selectedChart).source ? (
                <BubbleViewInterface
                  isDesign={true}
                  imgSrc={charts.find((c) => c.id === selectedChart).source}
                  {...tasks[0].params}
                />
              ) : (
                <ChartStimuli type="live" chartId={selectedChart} />
              )}
            </Grid>
          )}

          <Grid item xs>
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Parameters
            </Typography>
            <Box mt={2}>
              <FormControl component="fieldset" disabled={readOnly}>
                <FormLabel component="legend">
                  <Box display="flex" alignItems="center">
                    Task Type&nbsp;
                    <Tooltip title="In contrast to a free-viewing task, a describing task will ask participants to describe the given visualization to make sure they are attentive to the task.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="task"
                  value={task}
                  onChange={handleTaskChange}
                >
                  <FormControlLabel
                    value={"describe"}
                    control={<Radio />}
                    label={"Describe"}
                  />
                  {EXPERIMENTAL_FEATURE && (
                    <FormControlLabel
                      value={"free"}
                      control={<Radio />}
                      label={"Free-view"}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
            {task === "free" && (
              <Box mt={2}>
                <FormControl component="fieldset" disabled={readOnly}>
                  <FormLabel component="legend">
                    <Box display="flex" alignItems="center">
                      Time Limit&nbsp;
                      <Tooltip title="Time limit determines how long each visualization will be presented during free-viewing.">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                  </FormLabel>
                  <RadioGroup
                    row
                    name="time"
                    value={time}
                    onChange={handleTimeChange}
                  >
                    <FormControlLabel
                      value={"5"}
                      control={
                        <Radio
                        // checked={
                        //   task === "free" && time !== "10" && time !== "30"
                        // }
                        />
                      }
                      label={"5s"}
                      // disabled={task === "describe"}
                    />
                    <FormControlLabel
                      value={"10"}
                      control={<Radio />}
                      label={"10s"}
                      // disabled={task === "describe"}
                    />
                    <FormControlLabel
                      value={"30"}
                      control={<Radio />}
                      label={"30s"}
                      // disabled={task === "describe"}
                    />
                    {/* <FormControlLabel
                      value={"60"}
                      control={<Radio checked={task === "describe"} />}
                      label={"60s"}
                      // disabled={task === "free"}
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <Box mt={2}>
              <FormControl component="fieldset" disabled={readOnly}>
                <FormLabel component="legend">
                  <Box display="flex" alignItems="center">
                    Mouse Mode&nbsp;
                    <Tooltip title="Mouse mode determines how a participant will active a moving window revealing a clear area of focus in a blurred visualization.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="mouse"
                  value={mouse}
                  onChange={handleMouseChange}
                >
                  <FormControlLabel
                    value={"click"}
                    control={<Radio />}
                    label={"Click"}
                  />
                  {EXPERIMENTAL_FEATURE && (
                    <FormControlLabel
                      value={"move"}
                      control={<Radio />}
                      label={"Move"}
                      // disabled={true}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box mt={2}>
              <FormControl component="fieldset" disabled={readOnly}>
                <FormLabel component="legend">
                  <Box display="flex" alignItems="center">
                    Blur Size&nbsp;
                    <Tooltip title="Blur size determines the amount of blur effect applied to the visualization.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="blur"
                  value={blur}
                  onChange={handleBlurChange}
                >
                  <FormControlLabel
                    value={"small"}
                    control={<Radio />}
                    label={"Small"}
                  />
                  <FormControlLabel
                    value={"medium"}
                    control={<Radio />}
                    label={"Medium"}
                  />
                  <FormControlLabel
                    value={"large"}
                    control={<Radio />}
                    label={"Large"}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl component="fieldset" disabled={readOnly}>
                <FormLabel component="legend">
                  {" "}
                  <Box display="flex" alignItems="center">
                    Bubble Size&nbsp;
                    <Tooltip title="Bubble size determines the size of the moving window revealing a clear area of focus in the blurred visualization.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="bubble"
                  value={bubble}
                  onChange={handleBubbleChange}
                >
                  <FormControlLabel
                    value={"small"}
                    control={<Radio />}
                    label={"Small"}
                  />
                  <FormControlLabel
                    value={"medium"}
                    control={<Radio />}
                    label={"Medium"}
                  />
                  <FormControlLabel
                    value={"large"}
                    control={<Radio />}
                    label={"Large"}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      )}

      {showError && (
        <Snackbar open={errorMsg !== null} autoHideDuration={500}>
          <Alert
            onClose={() => handleReleaseError()}
            variant="filled"
            severity="error"
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

// function TaskChartStimuli(props) {
//   const { taskId } = props;
//   const dispatch = useDispatch();

//   const getCharts = makeGetCharts();
//   const { charts } = useSelector((state) => ({
//     charts: getCharts(state, {
//       taskId: taskId,
//     }),
//   }));

//   useEffect(() => {
//     if (charts.length > 0) {
//       dispatch(selectChart(charts[0].id));
//     }
//   }, [charts.length]);

//   const [showError, setShowError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState(null);

//   const handleReleaseError = () => {
//     setShowError(false);
//   };

//   function handleDrop(e, chartId) {
//     let file = e.dataTransfer.files[0];
//     if (!file) {
//       return;
//     }

//     if (file.type.match("application/json")) {
//       // vega-lite
//       const reader = new FileReader();

//       // TODO: check
//       reader.onload = async (e) => {
//         const spec = JSON.parse(e.target.result);

//         let div = document.createElement("div");
//         div.style.width = "512px";
//         div.style.height = "100%";
//         document.body.appendChild(div);
//         let result = await vegaEmbed(div, spec, {
//           actions: false,
//         });
//         // console.log(result);
//         result.view
//           .toImageURL("png")
//           .then(function (url) {
//             // console.log("png:       ", url);
//             dispatch(
//               updateChart(chartId, {
//                 spec: JSON.stringify(spec),
//                 source: url === "data:," ? null : url,
//                 type: "vega",
//               })
//             );
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//         // console.log(div);
//         document.body.removeChild(div);
//       };

//       reader.readAsText(file);
//     } else if (file.type.match(/image.*/)) {
//       if (file.size > 1000000) {
//         setShowError(true);
//         setErrorMsg("Size too big (>1MB)!");
//         return;
//       } else {
//         let reader = new FileReader();
//         reader.onloadend = (e) => {
//           dispatch(
//             updateChart(chartId, { type: "image", source: e.target.result })
//           );
//         };
//         reader.readAsDataURL(file);
//       }
//     } else {
//       setShowError(true);
//       setErrorMsg("Wrong format!");
//     }
//   }

//   return (
//     <>
//       {charts.length > 0 && (
//         <FileLoader
//           chartId={charts[0].id}
//           onDrop={(e) => handleDrop(e, charts[0].id)}
//         >
//           <ChartStimuli type="small" chartId={charts[0].id} />
//         </FileLoader>
//       )}

//       {showError && (
//         <Snackbar open={errorMsg !== null} autoHideDuration={500}>
//           <Alert
//             onClose={() => handleReleaseError()}
//             variant="filled"
//             severity="error"
//           >
//             {errorMsg}
//           </Alert>
//         </Snackbar>
//       )}
//     </>
//   );
// }
