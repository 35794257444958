import { RECEIVE_EXPERIMENT_CONTENT, CREATE_OPTION, UPDATE_OPTION, DELETE_OPTION } from 'actions';

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.options.reduce((acc, option) => {
        return {
          ...acc,
          [option.id]: option,
        };
      }, state);
    case CREATE_OPTION:
    case UPDATE_OPTION:
      return {
        ...state,
        [action.optionId]: {
          ...(state[action.optionId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_OPTION: {
      let newState = {
        ...state,
      };
      delete newState[action.optionId];
      return newState;
    }
    default:
      return state;
  }
};
