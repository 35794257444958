import React from "react";
import ExperimentTemplateLive from "components/ExperimentTemplateLive";
import Live from "components/Perception/Live";

function TemplateLive(props) {
  const { experimentId, previewMode, handleAnswers, handleSubmit } = props;

  return (
    <ExperimentTemplateLive
      experimentId={experimentId}
      previewMode={previewMode}
      handleAnswers={handleAnswers}
      handleSubmit={handleSubmit}
      type="Graphical Perception"
      experimentComponent={Live}
    />
  );
}

export default TemplateLive;
