import { combineReducers } from "redux";
import auth from "reducers/auth";
import experiments from "reducers/experiments";
import questions from "reducers/questions";
import options from "reducers/options";
import charts from "reducers/charts";
import ui from "reducers/ui";
import responses from "reducers/responses";
import consent from "reducers/consent";
import variables from "reducers/variables";
import tutorials from "reducers/tutorials";
import tasks from "reducers/tasks";

import surveys from "./surveys";
import { connectRouter } from "connected-react-router";
import history from "configureStore";

const createRootReducer = (history) =>
  combineReducers({
    auth,
    experiments,
    surveys,
    questions,
    options,
    ui,
    charts,
    responses,
    consent,
    tutorials,
    variables,
    tasks,
    router: connectRouter(history),
  });

export default createRootReducer;
