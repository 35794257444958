import { RECEIVE_EXPERIMENT_CONTENT, CREATE_CONSENT, UPDATE_CONSENT } from 'actions';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.consent.reduce((acc, consent) => {
        return {
          ...acc,
          [consent.id]: consent,
        };
      }, state);
    case CREATE_CONSENT:
    case UPDATE_CONSENT:
      return {
        ...state,
        [action.consentId]: {
          ...(state[action.consentId] || {}),
          ...action.attrs,
        },
      };
    default:
      return state;
  }
};
