import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Survey from "components/Survey";

import { createSurvey, makeGetSurveys } from "actions/surveys";
import { updateExperiment } from "actions/experiments";

import { FormControlLabel, Switch, Grid, Box } from "@material-ui/core";

function Demographic(props) {
  const dispatch = useDispatch();
  const { experimentId, readOnly } = props;

  const getSurveys = makeGetSurveys();
  const { surveys, showDemographicSurvey } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: experimentId,
      type: "demographicSurvey",
    }),
    showDemographicSurvey:
      state.experiments[experimentId].showDemographicSurvey,
  }));

  const handleDemographicChange = (e) => {
    if (surveys.length === 0 && showDemographicSurvey === false) {
      dispatch(createSurvey(experimentId, { type: "demographicSurvey" }));
    }
    dispatch(
      updateExperiment(experimentId, {
        showDemographicSurvey: e.target.checked,
      })
    );
  };

  return (
    <>
      <Box mb={2}>
        You can add demographic questions to analyze the experiment result based
        on the characteristics of different populations. For your convenience,
        we provide template questions for age, sex, and race.
      </Box>

      <Grid container direction="column">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showDemographicSurvey || false}
                onChange={(e) => handleDemographicChange(e)}
                disabled={readOnly}
              />
            }
            label="Add Demographic Survey"
          />
          {/* 
          {!showDemographicSurvey && (
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
              // color={!showDemographicSurvey ? "disabled" : "default"}
              // color="text.disabled"
              // style={{ marginTop: "20px" }}
            >
              If you add a demographic survey, participants are asked to answer
              about age, gender and ethinicity. You can edit the default
              questions or add another.
            </Typography>
          )} */}
        </Grid>
        <Grid item xs={8}>
          {showDemographicSurvey && (
            <>
              {surveys.length > 0 && (
                <Survey
                  surveyId={surveys[0].id}
                  type="demographicSurvey"
                  readOnly={readOnly}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Demographic;
