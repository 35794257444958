import React, { useState, useEffect, Fragment } from "react";
import { Grid, Typography, Chip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import Choice from "./Choice";

// import React, { useEffect, useState, Fragment } from "react";
import clsx from "clsx";

// import { makeStyles } from "@material-ui/core/styles";
// import { Grid, Chip, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  chips: {
    width: 100,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    paddingLeft: 0,
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Filtering = (props) => {
  const {
    filteringType,
    // text,
    answersCallback,
    filteringConditions,
    allOfTags,
    countResults,
  } = props;
  //   console.log(props);
  const classes = useStyles();

  const [selected, setSelected] = useState(filteringConditions);
  const [sortedCountResults, setSortedCountResults] = useState();
  //   console.log(sortedCountResults);

  function sortJsObject(arr) {
    // console.log(arr);
    let res = arr;
    Object.keys(arr).map((k) => {
      let dict = arr[k];
      const sortable = Object.entries(dict)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      res[k] = sortable;
    });
    setSortedCountResults(res);
  }

  useEffect(() => {
    if (countResults) {
      sortJsObject(countResults);
    }
  }, [countResults]);

  const handleSelected = (type, choice) => {
    setSelected({
      ...selected,
      [choice]: !selected[choice],
    });
  };
  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      answersCallback(filteringType, selected);
    }
  }, [selected]);

  const isChipSelected = (filteringType, choice) => {
    try {
      return filteringConditions[filteringType][choice];
    } catch {
      return false;
    }
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const minNum = 3;

  return (
    <>
      <Grid
        container
        spacing={2}
        className="Question-Container"
        // alighItems="flex-start"
      >
        <Grid item xs>
          <Typography
            variant="subtitle1"
            color="inherit"
            // style={{ fontFamily: "Assistant", fontWeight: "700" }}
          >
            {filteringType}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="Question-Container">
        <Grid item xs>
          {/* <Choice
            type={type}
            answersCallback={answersCallback}
            userAnswers={userAnswers}
            choices={choices}
            countResults={countResults}
          ></Choice> */}
          <Grid container justifyContent="flex-start">
            {allOfTags &&
              sortedCountResults &&
              Object.keys(sortedCountResults[filteringType]).map(
                (choice, i) => {
                  let count =
                    sortedCountResults[filteringType][choice] === undefined
                      ? 0
                      : sortedCountResults[filteringType][choice];
                  return (
                    <Fragment key={choice}>
                      {i < minNum && (
                        <Chip
                          variant="outlined"
                          clickable
                          size="medium"
                          label={choice + " (" + count + ")"}
                          disabled={count === 0 ? true : false}
                          onClick={() => {
                            handleSelected(filteringType, choice);
                          }}
                          icon={
                            isChipSelected(filteringType, choice) ? (
                              <DoneIcon color="inherit" />
                            ) : undefined
                          }
                          className={classes.chip}
                          // style={{
                          //   backgroundColor: selected[choice] ? colors[type] : undefined,
                          // }}
                          color={
                            isChipSelected(filteringType, choice)
                              ? "secondary"
                              : "default"
                          }
                          //   style={{ fontFamily: "Assistant", fontWeight: "600" }}
                        />
                      )}
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {i >= minNum && (
                          <Chip
                            variant="outlined"
                            key={"Choice " + i}
                            clickable
                            size="medium"
                            label={choice + " (" + count + ")"}
                            disabled={count === 0 ? true : false}
                            onClick={() => {
                              handleSelected(filteringType, choice);
                            }}
                            icon={
                              selected[choice] ? (
                                <DoneIcon color="inherit" />
                              ) : undefined
                            }
                            className={classes.chip}
                            // style={{
                            //   backgroundColor: selected[choice] ? colors[type] : undefined,
                            // }}
                            color={
                              selected[choice] !== true
                                ? "default"
                                : "secondary"
                            }
                            // style={{
                            //   fontFamily: "Assistant",
                            //   fontWeight: "600",
                            // }}
                          />
                        )}
                      </Collapse>
                    </Fragment>
                  );
                }
              )}
            {/* <Button onPointerUp={handleMoreButton}>...more</Button> */}
            {allOfTags[filteringType].length > minNum && (
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// const colors = {
//   "Chart Type": "#f86624",
//   "Data Type": "#ea3546",
//   Task: "#662e9b",
//   Purpose: "#43bccd",
// };

// const Choice = (props) => {
//   const classes = useStyles();
//   const { type, answersCallback, userAnswers, choices, countResults } = props;
//   const [selected, setSelected] = useState(userAnswers);
//   const [sortedCountResults, setSortedCountResults] = useState();

//   function sortJsObject(arr) {
//     let res = arr;
//     Object.keys(arr).map((k) => {
//       let dict = arr[k];
//       const sortable = Object.entries(dict)
//         .sort(([, a], [, b]) => b - a)
//         .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
//       res[k] = sortable;
//     });
//     setSortedCountResults(res);
//   }

//   useEffect(() => {
//     if (countResults) {
//       sortJsObject(countResults);
//     }
//   }, [countResults]);

//   const handleSelected = (type, choice) => {
//     setSelected({
//       ...selected,
//       [choice]: !selected[choice],
//     });
//   };
//   useEffect(() => {
//     if (Object.keys(selected).length > 0) {
//       answersCallback(type, selected);
//     }
//   }, [selected]);

//   const isChipSelected = (type, choice) => {
//     try {
//       return userAnswers[type][choice];
//     } catch {
//       return false;
//     }
//   };

//   const [expanded, setExpanded] = useState(false);
//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };
//   const minNum = 5;

//   return (
//     <Grid container justify="flex-start">
//       {choices &&
//         sortedCountResults &&
//         Object.keys(sortedCountResults[type]).map((choice, i) => {
//           let count =
//             sortedCountResults[type][choice] === undefined
//               ? 0
//               : sortedCountResults[type][choice];
//           return (
//             <Fragment key={choice}>
//               {i < minNum && (
//                 <Chip
//                   variant="outlined"
//                   clickable
//                   size="medium"
//                   label={choice + " (" + count + ")"}
//                   disabled={count === 0 ? true : false}
//                   onClick={() => {
//                     handleSelected(type, choice);
//                   }}
//                   icon={
//                     isChipSelected(type, choice) ? (
//                       <DoneIcon color="inherit" />
//                     ) : undefined
//                   }
//                   className={classes.chip}
//                   // style={{
//                   //   backgroundColor: selected[choice] ? colors[type] : undefined,
//                   // }}
//                   color={isChipSelected(type, choice) ? "secondary" : "default"}
//                   style={{ fontFamily: "Assistant", fontWeight: "600" }}
//                 />
//               )}
//               <Collapse in={expanded} timeout="auto" unmountOnExit>
//                 {i >= minNum && (
//                   <Chip
//                     variant="outlined"
//                     key={"Choice " + i}
//                     clickable
//                     size="medium"
//                     label={choice + " (" + count + ")"}
//                     disabled={count === 0 ? true : false}
//                     onClick={() => {
//                       handleSelected(type, choice);
//                     }}
//                     icon={
//                       selected[choice] ? (
//                         <DoneIcon color="inherit" />
//                       ) : undefined
//                     }
//                     className={classes.chip}
//                     // style={{
//                     //   backgroundColor: selected[choice] ? colors[type] : undefined,
//                     // }}
//                     color={selected[choice] !== true ? "default" : "secondary"}
//                     style={{ fontFamily: "Assistant", fontWeight: "600" }}
//                   />
//                 )}
//               </Collapse>
//             </Fragment>
//           );
//         })}
//       {/* <Button onPointerUp={handleMoreButton}>...more</Button> */}
//       {choices[type].length > minNum && (
//         <IconButton
//           className={clsx(classes.expand, {
//             [classes.expandOpen]: expanded,
//           })}
//           onClick={handleExpandClick}
//           aria-expanded={expanded}
//           aria-label="show more"
//         >
//           <ExpandMoreIcon />
//         </IconButton>
//       )}
//     </Grid>
//   );
// };

export default Filtering;
