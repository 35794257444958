import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";

import AccountMenu from "components/Auth/AccountMenu";
// import { logoutUser } from "actions";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";

// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// // import Link from "@material-ui/core/Link";
// import HomeIcon from "@material-ui/icons/Home";
// import WhatshotIcon from "@material-ui/icons/Whatshot";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(0.5),
    boxShadow: "none",
    borderBottom: "2px solid #dccaa0",
  },
  title: {
    lineHeight: 1,
    flexGrow: 1,
    "&:hover": {
      textDecoration: "none",
    },
  },
  toolbar: {
    // minHeight: theme.spacing(0),
  },
}));

// import style from "./NavBar.module.css";

// const useStyles = makeStyles((theme) => ({
//   link: {
//     display: "flex",
//   },
//   icon: {
//     marginRight: theme.spacing(0.5),
//     width: 20,
//     height: 20,
//   },
//   navbar: {
//     width: "100%",
//     display: "flex",
//     justifyContent: "space-between",
//     verticalAlign: "middle",
//     boxSizing: "border-box",
//     overflow: "auto",
//   },
//   rightMenu: {
//     display: "flex",
//   },
//   menuItem: {
//     marginRight: "5px",
//   },
// }));

function NavBar() {
  const classes = useStyles();
  const { displayName, isAuthenticated } = useSelector((state) => ({
    displayName: state.auth.user.email,
    isAuthenticated: state.auth.isAuthenticated,
  }));
  // const dispatch = useDispatch();

  // const handleLogout = () => {
  //   dispatch(logoutUser());
  // };

  // const handleViewExperiments = () => {
  //   history.push("/experiment");
  // };

  // function handleClick(event) {
  //   event.preventDefault();
  //   console.info("You clicked a breadcrumb.");
  // }

  return (
    <React.Fragment>
      <AppBar className={classes.root} position="static" color="inherit">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Link
            className={classes.title}
            color="inherit"
            component={RouterLink}
            to="/"
          >
            <Typography variant="h6" className={classes.title}>
              <img src="/img/logo-new.png" height="38px" />
            </Typography>
          </Link>

          {isAuthenticated ? (
            <React.Fragment>
              {EXPERIMENTAL_FEATURE && (
                <Button component={RouterLink} to="/explore">
                  Explore
                </Button>
              )}
              <Button component={RouterLink} to="/experiment">
                My Experiments
              </Button>
              <AccountMenu
                displayName={displayName}
                isAuthenticated={isAuthenticated}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button component={RouterLink} to="/login">
                Log In
              </Button>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default NavBar;
