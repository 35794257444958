import React from "react";
import { useSelector } from "react-redux";

import { default as PerceptionTemplate } from "components/Perception/Template";
import { default as BubbleViewTemplate } from "components/BubbleView/Template";
import { default as MemorabilityTemplate } from "components/Memorability/Template";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "25px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const DesignView = (props) => {
  const classes = useStyles();
  const { experimentId, readOnly } = props;
  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const renderMode = () => {
    if (experiment.type === "Graphical Perception") {
      return (
        <PerceptionTemplate experimentId={experimentId} readOnly={readOnly} />
      );
    } else if (experiment.type === "Attention Tracking") {
      return (
        <BubbleViewTemplate experimentId={experimentId} readOnly={readOnly} />
      );
    } else if (experiment.type === "Memorability") {
      return (
        <MemorabilityTemplate experimentId={experimentId} readOnly={readOnly} />
      );
    }
  };

  return (
    <div className={classes.content}>
      {/* <Typography variant="h6" display="block" gutterBottom>
      Creating {experiment.type === "Attention Tracking" ? "an" : "a"}{" "}
      <span style={{ fontWeight: "bold" }}>{experiment.type}</span>{" "}
      Experiment
    </Typography> */}
      {renderMode()}
    </div>
  );
};

export default DesignView;
