import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";

import ChartStimuli from "components/ChartStimuli";

import { makeGetCharts } from "actions/charts";
import { makeGetSurveys } from "actions/surveys";
import { makeGetTasks } from "actions/tasks";

import {
  standardDeviation,
  average,
  median,
  answerRate,
  rank,
  min,
  max,
} from "utils/stats";
import { parsePerceptionData } from "utils/dataProcessing";
import { BoxPlot, ViolinPlot, ErrorBar1D } from "utils/Charts";
import {
  personalizedResultShareMsg,
  personalizedResultSummaryMsg,
} from "utils/textResources";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Paper,
  Grid,
  Box,
  Typography,
  Tooltip,
  Chip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert } from "@material-ui/lab";
import { createdDateAscending } from "utils/date";

function Result(props) {
  const { experimentId, responseId, responses, isResult } = props;
  const [data, setData] = useState({});
  const [myData, setMyData] = useState({});

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getSurveys = makeGetSurveys();
  const { surveys } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: tasks[0].id,
      type: "perceptionSurvey",
    }),
  }));

  const getCharts = makeGetCharts();
  const { questions, options, charts } = useSelector((state) => ({
    questions: state.questions,
    options: state.options,
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  const { qIds } = useSelector((state) => ({
    qIds: Object.values(questions)
      .filter((q) => q.surveyId === surveys[0].id)
      .filter((q) => q.text !== "")
      .map((q) => q.id)
      .sort(createdDateAscending),
    // chartIds: Object.values(charts).map((c) => c.id),
  }));

  // console.log(responseId);
  const numOfResponses = Object.keys(responses).length;
  // const rid = "response_" + responseId;
  let idx = responses.findIndex((p) => p.id === responseId);
  const myResponse = responses[idx];
  // console.log(myResponse);
  const chartIds = Object.keys(myResponse.charts);

  useEffect(() => {
    async function fetchData() {
      if (responses.length > 0) {
        const result = await parsePerceptionData(
          responses,
          questions,
          options,
          false,
          responseId
        );
        // setMyData(result[0]);
        // setData(result[1]);
        const newResult0 = result[0].map((r) => {
          if (r.accuracy < 0) {
            return {
              ...r,
              accuracy: 0,
            };
          } else {
            return r;
          }
        });
        const newResult1 = result[1].map((r) => {
          if (r.accuracy < 0) {
            return {
              ...r,
              accuracy: 0,
            };
          } else {
            return r;
          }
        });
        setMyData(newResult0);
        setData(newResult1);
      }
    }
    fetchData();
  }, [responses.length]);

  // console.log(data);
  // console.log(myData);

  // const myData = data.filter((d) => d.responseId === rid)
  // console.log(myData)
  const myPercentage = () => {
    const groupbyId = _.groupBy(data, (d) => d.responseId);
    let dataByResponse = Object.values(groupbyId).map((v) =>
      v.map((vv) => vv.accuracy)
    );
    const avgData = dataByResponse.map((r) => average(r));
    const avgMyData = average(myData.map((d) => d.accuracy));
    // const num =
    //   rank(
    //     data.map((d) => d.accuracy),
    //     myData[0].accuracy,
    //     "descending"
    //   ) / numOfResponses;
    const num = rank(avgData, avgMyData, "descending") / numOfResponses;
    // console.log(num);

    if (num === 1) {
      return num * 100 + "%";
    } else {
      return Number(num).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 1,
      });
    }
  };
  // const myPercentage = () => 1.23;

  // question answer
  const tableColumns = (qId) => {
    // console.log(questions, qId);
    switch (questions[qId].type) {
      case "choice":
        if (questions[qId].answer) {
          return [
            "Visualization",
            "Result",
            "Your Answer",
            "Correct Answer",
            "Your Accuracy",
            "Average Accuracy",
          ];
        } else {
          return ["Visualization", "Your Answer", "Histogram"];
        }
      case "checkboxes":
        if (questions[qId].answer) {
          return [
            "Visualization",
            "Result",
            "Your Answer",
            "Correct Answer",
            "Your Accuracy",
            "Average Accuracy",
          ];
        } else {
          return ["Visualization", "Your Answer", "Histogram"];
        }
      case "short-answer":
        if (questions[qId].answer) {
          return [
            "Visualization",
            "Result",
            "Your Answer",
            "Correct Answer",
            "Your Accuracy Error",
            "Average Accuracy Error",
          ];
          // return [
          //   "Visualization",
          //   "ResultError",
          //   "Your Answer",
          //   "Error",
          //   "Rank",
          // ];
        } else {
          return ["Visualization", "Your Answer"];
        }
      case "paragraph":
        return ["Visualization", "Your Answer"];
      default:
        return [];
    }
  };

  const renderColumn = (label, qId, chartId) => {
    switch (label) {
      case "Visualization":
        return (
          <TableCell align="center">
            <ChartStimuli type="tiny" chartId={chartId} />
          </TableCell>
        );
      case "Result":
        return (
          <TableCell align="center">
            {/* <ErrorBarChart
              experimentId={experimentId}
              responseId={responseId}
              data={data
                .filter((d) => d.questionId === qId)
                .filter((dd) => dd.chartId === chartId)}
              dependentVar="error"
              independentVar="chartId"
            /> */}
            {/* <BoxPlot
              data={data
                .filter((d) => d.questionId === qId)
                .filter((d) => d.chartId === chartId)}
              dependentVar="correct"
              domainMin={min(data.map((d) => d["correct"]))}
              domainMax={max(data.map((d) => d["correct"]))}
            /> */}
            <ErrorBar1D
              name={isResult ? "You" : "Participant"}
              data={data
                .filter((d) => d.questionId === qId)
                .filter((d) => d.chartId === chartId)}
              myData={
                myData
                  .filter((d) => d.questionId === qId)
                  .filter((d) => d.chartId === chartId)[0]
              }
              text={false}
              dependentVar="accuracy"
              // domainMin={min(data.map((d) => d["accuracy"]))}
              // domainMax={max(data.map((d) => d["accuracy"]))}
              domainMin="0"
              domainMax="100"
            />
          </TableCell>
        );
      case "Your Answer":
        // console.log(qId, myData);
        try {
          return (
            <TableCell align="right">
              {
                myData
                  .filter((d) => d.questionId === qId)
                  .filter((dd) => dd.chartId === chartId)[0].answer
              }
              {" ("}
              {myData
                .filter((d) => d.questionId === qId)
                .filter((dd) => dd.chartId === chartId)[0].correct
                ? "Correct"
                : "Incorrect"}
              {")"}
            </TableCell>
          );
        } catch (TypeError) {
          return (
            <TableCell align="right">
              {
                myData
                  .filter((d) => d.questionId === qId)
                  .filter((dd) => dd.chartId === chartId)[0].answer
              }
            </TableCell>
          );
        }
      case "Correct Answer":
        return (
          <TableCell align="right">
            {
              myData
                .filter((d) => d.questionId === qId)
                .filter((dd) => dd.chartId === chartId)[0].questionAnswer
            }
          </TableCell>
        );
      case "Your Accuracy":
      case "Your Accuracy Error":
        return (
          <TableCell align="right">
            {myData
              .filter((d) => d.questionId === qId)
              .filter((dd) => dd.chartId === chartId)[0].accuracy + "%"}
          </TableCell>
        );
      case "Average Accuracy":
      case "Average Accuracy Error":
        return (
          <TableCell align="right">
            {answerRate(
              data
                .filter((d) => d.questionId === qId)
                .filter((dd) => dd.chartId === chartId)
                .map((d) => d.accuracy)
            ) + "%"}
          </TableCell>
        );
      case "Histogram":
        return <TableCell align="center">{"TODO"}</TableCell>;
      case "Error":
        return (
          <TableCell align="right">
            {myData
              .filter((d) => d.questionId === qId)
              .filter((dd) => dd.chartId === chartId)[0]
              .error.toFixed(2)}
          </TableCell>
        );
      case "Rank":
        return (
          <TableCell align="right">
            {rank(
              data
                .filter((d) => d.questionId === qId)
                .filter((d) => d.chartId === chartId)
                .map((d) => d.error),
              myData
                .filter((d) => d.questionId === qId)
                .filter((dd) => dd.chartId === chartId)[0].error,
              "ascending"
            )}
            /
            {
              data
                .filter((d) => d.questionId === qId)
                .filter((d) => d.chartId === chartId).length
            }
          </TableCell>
        );
      default:
        return "";
    }
  };
  // const userName = () => {
  //   if (myResponse.name[-1] === "s") {
  //     return myResponse.name + "'";
  //   } else {
  //     return myResponse.name + "'s";
  //   }
  // };

  const columnInfo = (label) => {
    if (label === "Average Accuracy") {
      return (
        <Box display="flex" alignItems="center">
          {label}&nbsp;
          <Tooltip title="The average accuracy indicates the percentage of participants answering the question correctly.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else if (label === "Average Accuracy Error") {
      return (
        <Box display="flex" alignItems="center">
          Average Accuracy&nbsp;
          <Tooltip title="The average accuracy indicates the average of how closely participants estimated the answer.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else if (label === "Your Accuracy Error") {
      return (
        <Box display="flex" alignItems="center">
          Your Accuracy&nbsp;
          <Tooltip title="The your accuracy represents how closley you estimated the answer. This is calculated by (100 - Relative error). Relative error = (Your answer - Correct answer) / Correct answer * 100">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else if (label === "ResultError") {
      return (
        <Box display="flex" alignItems="center">
          Result&nbsp;
          <Tooltip title="This chart displays a distribution of log absolute error.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else if (label === "Rank") {
      return (
        <Box display="flex" alignItems="center">
          {label}&nbsp;
          <Tooltip title="The ranking indicates how fast you responded among all other participants.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else if (label === "Error") {
      return (
        <Box display="flex" alignItems="center">
          Error&nbsp;
          <Tooltip title="Log absolute error is calculated by Log Base 2 (Absolute error + 0.125).">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      );
    } else {
      return label;
    }
  };

  return (
    <>
      {data.length > 0 && myData.length > 0 && (
        <>
          {/* {isResult && ( */}
          <>
            {experiment.prolificCode &&
              experiment.prolificCode !== "" &&
              isResult && (
                <Grid container>
                  <Grid item xs={12}>
                    <Alert severity="success" style={{ marginTop: "5px" }}>
                      Please copy this{" "}
                      <strong>{experiment.prolificCode}</strong> and paste in
                      the Prolific app.
                    </Alert>
                  </Grid>
                </Grid>
              )}
            <Grid container align="center" direction="column">
              <Grid item xs>
                <Box
                  mt={3}
                  p={2}
                  style={{
                    width: "400px",
                    minHeight: "200px",
                    background: "aliceblue",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {experiment.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "30px",
                    }}
                  >
                    <Box
                      fontSize="subtitle1"
                      // letterSpacing={-2}
                      component="span"
                      // fontWeight="fontWeightBold"
                    >
                      {personalizedResultSummaryMsg(isResult, myPercentage())}
                    </Box>
                  </Typography>
                  <ErrorBar1D
                    name={isResult ? "You" : "Participant"}
                    data={data}
                    myData={myData}
                    text={true}
                    size={300}
                    dependentVar="accuracy"
                    // domainMin={min(data.map((d) => d["correct"]))}
                    // domainMax={max(data.map((d) => d["correct"]))}
                    domainMin="0"
                    domainMax="100"
                  />
                </Box>
              </Grid>
            </Grid>
            {isResult && (
              <Grid
                container
                spacing={1}
                align="center"
                direction="column"
                style={{ marginTop: "10px" }}
              >
                <Grid item xs>
                  <Box width="400px" align="left">
                    <Typography variant="body1" gutterBottom>
                      Share to social media
                    </Typography>
                    <Tooltip title="Twitter">
                      <TwitterShareButton
                        url={
                          window.location.href.split("response")[0] + "share"
                        }
                        title={
                          experiment.title +
                          personalizedResultShareMsg(myPercentage())
                        }
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </Tooltip>
                    <Box display="inline-block" ml={1}>
                      <Tooltip title="Facebook">
                        <FacebookShareButton
                          url={
                            window.location.href.split("response")[0] + "share"
                          }
                          quote={
                            experiment.title +
                            personalizedResultShareMsg(myPercentage())
                          }
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}

            {/* <Grid container spacing={3}>
              <Grid item xs>
                <Typography variant="body1" display="block" gutterBottom>
                  <br />
                  {numOfResponses > 1
                    ? `There are total ` +
                      numOfResponses +
                      ` responses in this experiment.`
                    : `There is only 1 response in this experiment.`}{" "}
                </Typography>
              </Grid>
            </Grid> */}
          </>
          {/* )} */}
          <>
            {qIds.map((qId, index) => (
              <Fragment key={qId}>
                <Typography
                  variant="subtitle1"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "40px" }}
                >
                  <Chip
                    color="primary"
                    label={<strong>Q{index + 1}.</strong>}
                  />
                  &nbsp;
                  {questions[qId].text}
                </Typography>
                <Box mt={2} mb={1}>
                  <Chip
                    color="primary"
                    variant="outlined"
                    label="Accuracy"
                    icon={<CheckCircleOutlineIcon />}
                  />
                </Box>
                {/* <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  Accuracy:
                </Typography> */}
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {tableColumns(qId).map((label, index) => (
                          <TableCell align="center" key={label + index}>
                            {/* {label === "Answer Rate" ? (
                              <Box display="flex" alignItems="center">
                                {label}&nbsp;
                                <Tooltip title="The answer rate indicates the percentage of participants answering the question correctly">
                                  <InfoOutlinedIcon fontSize="small" />
                                </Tooltip>
                              </Box>
                            ) : (
                              label
                            )} */}
                            {columnInfo(label)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chartIds.map((chartId) => (
                        <TableRow key={chartId}>
                          {tableColumns(qId).map((label, index) => (
                            <Fragment key={label + index}>
                              {renderColumn(label, qId, chartId)}
                            </Fragment>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mt={4} mb={1}>
                  <Chip
                    color="primary"
                    variant="outlined"
                    label="Task Completion Time"
                    icon={<AccessTimeIcon />}
                  />
                </Box>
                {/* <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  Task Completion Time:
                </Typography> */}
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Visualization</TableCell>
                        <TableCell align="center">Result</TableCell>
                        <TableCell align="center">Elapsed Time (sec)</TableCell>
                        <TableCell align="center">
                          Average Elapsed Time (sec)
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" alignItems="center">
                            Rank&nbsp;
                            <Tooltip title="The ranking indicates how fast you responded among all other participants">
                              <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chartIds.map((chartId) => (
                        <TableRow key={chartId}>
                          <TableCell align="center">
                            <ChartStimuli type="tiny" chartId={chartId} />
                          </TableCell>
                          <TableCell align="center">
                            {/* <ErrorBarChart
                              experimentId={experimentId}
                              responseId={responseId}
                              data={data
                                .filter((d) => d.questionId === qId)
                                .filter((dd) => dd.chartId === chartId)}
                              dependentVar="time"
                              independentVar="chartId"
                            /> */}
                            {/* <BoxPlot
                              data={data
                                .filter((d) => d.questionId === qId)
                                .filter((d) => d.chartId === chartId)}
                              dependentVar="time"
                              domainMin={min(data.map((d) => d["time"]))}
                              domainMax={max(data.map((d) => d["time"]))}
                            /> */}
                            <ErrorBar1D
                              name={isResult ? "You" : "Participant"}
                              data={data
                                .filter((d) => d.questionId === qId)
                                .filter((d) => d.chartId === chartId)}
                              myData={
                                myData
                                  .filter((d) => d.questionId === qId)
                                  .filter((d) => d.chartId === chartId)[0]
                              }
                              text={false}
                              dependentVar="time"
                              domainMin="0" // {min(data.map((d) => d["time"]))}
                              domainMax={max(
                                data
                                  .filter((d) => d.questionId === qId)
                                  .filter((d) => d.chartId === chartId)
                                  .map((d) => d["time"])
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {myData
                              .filter((d) => d.questionId === qId)
                              .filter((dd) => dd.chartId === chartId)[0]
                              .time.toFixed(2) + " sec"}
                          </TableCell>
                          <TableCell align="right">
                            {average(
                              data
                                .filter((d) => d.questionId === qId)
                                .filter((dd) => dd.chartId === chartId)
                                .map((d) => d.time)
                            ) + " sec"}
                          </TableCell>
                          <TableCell align="right">
                            {rank(
                              data
                                .filter((d) => d.questionId === qId)
                                .filter((dd) => dd.chartId === chartId)
                                .map((d) => d.time),
                              myData
                                .filter((d) => d.questionId === qId)
                                .filter((dd) => dd.chartId === chartId)[0].time,
                              "ascending"
                            )}
                            /
                            {
                              data
                                .filter((d) => d.questionId === qId)
                                .filter((d) => d.chartId === chartId).length
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            ))}
          </>
        </>
      )}
    </>
  );
}

export default Result;
