import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import BubbleViewInterface from "components/BubbleView/Interface";

import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import {
  MobileStepper,
  Button,
  Snackbar,
  Tooltip,
  withStyles,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import _ from "underscore";

const ButtonTooltip = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(Button);

function Live(props) {
  const {
    experimentId,
    handleAnswers,
    handleNextStep,
    setIsValidSubmit,
    isValidSubmit,
    handleSubmit,
  } = props;

  const getTasks = makeGetTasks();
  const getCharts = makeGetCharts();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));
  const { beforeShuffleCharts } = useSelector((state) => ({
    beforeShuffleCharts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  const [charts, setCharts] = useState([]);
  console.log(beforeShuffleCharts, charts);

  // console.log(bubbleViewParams);
  useEffect(() => {
    setIsValidSubmit(false);

    const res = _.shuffle(beforeShuffleCharts);
    setCharts(res);
  }, []);

  const [isValidNextChart, setIsValidNextChart] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [response, setResponse] = useState({
    charts: {},
  });
  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  // for stepper
  let maxSteps = tasks.length;

  useEffect(() => {
    if (charts.length > 0) {
      setSelectedChart(charts[0].id);
    }
  }, [charts]);

  useEffect(() => {
    if (
      charts.every((item) => response.charts[item.id]) &&
      selectedChart !== null
    ) {
      setIsValidNextStep(true);
      handleAnswers(response);
    } else {
    }
  }, [response]);

  const recordResponse = (charCount, ans) => {
    // console.log(response);
    let index = charts.findIndex((q) => q.id === selectedChart);
    // if (charCount > 149 || tasks[index].params.task === "free") {
    //   setIsValidNextTask(true);
    // }
    if (charCount > 149) {
      setIsValidNextChart(true);
    }
    setResponse({
      ...response,
      charts: {
        ...response.charts,
        [charts[index].id]: {
          clicks: { ...ans.clicks },
          descs: { ...ans.descs },
          time: ans.time,
          canvas: ans.canvas,
        },
      },
    });
  };

  // console.log(response);
  const handlePrevChart = () => {
    let index = charts.findIndex((b) => b.id === selectedChart);
    index -= 1;
    if (index >= 0) {
      setSelectedChart(charts[index].id);
    } else {
      setSelectedChart(null);
    }
    setActiveStep(index);
  };

  const handleNextChart = () => {
    // setIsValidSubmit(true);
    let index = charts.findIndex((b) => b.id === selectedChart);
    index += 1;
    if (index < charts.length) {
      setSelectedChart(charts[index].id);
      setActiveStep(index);
      setIsValidNextChart(false);
    }
  };

  // const handleSnackBar = () => {
  //   console.log("snack");
  //   setErrorMsg("Please descibe the image with at least 150 characters.");
  // };

  const nextButtonText = () => {
    if (isValidSubmit && isValidNextStep) {
      return "Submit";
    } else if (isValidNextStep) {
      return "Go to Next Page";
    } else if (isValidNextChart) {
      return "Go to Next Chart";
    } else {
      return "Next Chart";
    }
  };

  const nextButtonAction = () => {
    if (isValidSubmit && isValidNextStep) {
      return handleSubmit();
    } else if (isValidNextStep) {
      return handleNextStep();
    } else if (isValidNextChart) {
      return handleNextChart();
    } else {
      return null;
    }
  };

  const nextButtonTooltip = () => {
    if (!isValidNextChart && tasks[0].params.task === "describe") {
      return "Please descibe the image with at least 150 characters.";
    } else {
      return "";
    }
  };

  const render = () => {
    let index = charts.findIndex((t) => t.id === selectedChart);
    // let selectedChartId = Object.values(charts).find(
    //   (c) => c.taskId === tasks[index].id
    // ).id;
    // console.log(charts, selectedTask, selectedChartId);
    return (
      <>
        <BubbleViewInterface
          isDesign={false}
          imgSrc={charts[index].source}
          {...tasks[0].params}
          recordResponse={recordResponse}
          isValidNextChart={isValidNextChart}
          setIsValidNextChart={setIsValidNextChart}
        />
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Tooltip
              title={nextButtonTooltip()}
              placement="left-start"
              interactive
            >
              <ButtonTooltip
                variant={
                  isValidNextStep || isValidNextChart ? "contained" : null
                }
                disableElevation
                color={
                  isValidNextStep || isValidNextChart ? "primary" : "default"
                }
                size="small"
                onPointerUp={() => nextButtonAction()}
                disabled={!isValidNextChart}
                component={!isValidNextChart ? "div" : undefined}
              >
                {nextButtonText()}
              </ButtonTooltip>
            </Tooltip>
          }
          backButton={
            <Button
              size="small"
              onPointerUp={handlePrevChart}
              // disabled={activeStep === 0}
              disabled={true}
            >
              Previous Chart
            </Button>
          }
        />
      </>
    );
  };

  return (
    <>
      {charts && (
        <>
          {charts.length > 0 && selectedChart !== null ? (
            render()
          ) : (
            <>
              {beforeShuffleCharts.length === 0 && (
                <Typography variant="h6">
                  No Image or Vega(-Lite) spec in Experiment Design.
                </Typography>
              )}
            </>
          )}
          <Snackbar open={errorMsg !== null} autoHideDuration={500}>
            <Alert
              onClose={() => setErrorMsg(null)}
              variant="filled"
              severity="error"
            >
              {errorMsg}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  ); // return
}

export default Live;
