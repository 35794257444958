import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  //https://material-ui.com/customization/palette/
  palette: {
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#2196f3",
    },
    gradient:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
  },
});

export const store = configureStore();

export const EXPERIMENTAL_FEATURE = false; // if false, all the experimental_features are hid.

ReactDOM.render(
  <Provider store={store}>
    {/* <HashRouter hasyType="noslash"> */}
    {/* <Router history={history}> */}
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
    {/* </HashRouter> */}
    {/* </Router> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
