import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// import ExperimentThumbnail from "components/ExperimentThumbnail";

import {
  makeGetPublicExperiments,
  requestExperimentsPublic,
} from "actions/experiments";

import {
  LANDING_PAGE_HEADLINE,
  LANDING_PAGE_QUESTION,
} from "utils/textResources";

import {
  Typography,
  Grid,
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Button,
  Box,
  Fab,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// const width = 225;
// const height = 225;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  card: {
    width: "100%",
    height: "200px",
  },
  cardDesc: {
    height: "80px",
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  customBoxDesc: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-all",
    overflow: "hidden",
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getPublicExperiments = makeGetPublicExperiments();
  const { experiments } = useSelector((state) => ({
    experiments: getPublicExperiments(state),
  }));

  // console.log(experiments);
  useEffect(() => {
    // componentDidMount
    dispatch(requestExperimentsPublic());
  }, []);

  const featuredExperiments = experiments.filter(
    (exp) => exp.isFeatured === true
  );

  return (
    <Grid container className={classes.root} spacing={4}>
      {/* <div className={style.firstsection}> */}
      {/* <div className={style.subsection}> */}
      <Grid item xs={7}>
        <Typography variant="h3" gutterBottom>
          <div dangerouslySetInnerHTML={{ __html: LANDING_PAGE_HEADLINE }} />
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Grid>
          <Grid item xs={12}>
            <img width="100%" src="/img/vislab-teaser.svg"></img>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {LANDING_PAGE_QUESTION}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {featuredExperiments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Featured Experiments:
          </Typography>
          <Grid container spacing={2}>
            {featuredExperiments.map((exp) => (
              <Grid key={exp.id} item xs={3}>
                <Card className={classes.card} variant="outlined">
                  <CardContent className={classes.content}>
                    <Box component="div" classes={{ root: classes.customBox }}>
                      <Typography variant="button" gutterBottom display="block">
                        {exp.title}
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      classes={{ root: classes.customBoxDesc }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.cardDesc}
                        classes={{ root: classes.customBoxDesc }}
                      >
                        {exp.desc}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box
                      display="flex"
                      width="100%"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Button
                        size="small"
                        component={Link}
                        to={`/experiment/${exp.id}/share`}
                        target="_blank"
                        endIcon={<ArrowForwardIcon />}
                      >
                        Participate
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LandingPage;
