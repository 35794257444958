import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectChart } from "actions/ui";
import { Vega, VegaLite } from "react-vega";

import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  small: {
    position: "relative",
    width: "150px",
    height: "150px",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "medium",
    textAlign: "center",
    color: "gray",
  },
  fit: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
  },
  vegafit: {
    // width: "100%",
    height: "100%",
    objectFit: "scale-down",
  },
  live: {
    position: "relative",
    margin: "0",
    width: "100%",
    height: "100%",
  },
  tiny: {
    position: "relative",
    width: "64px",
    height: "64px",
    margin: "0",
  },
  dragging: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
  },
  overlay: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -115%)",
    color: "black",
    backgroundColor: "white",
    pointerEvents: "none",
  },
  selectedArea: {
    borderStyle: "solid",
    borderColor: "green",
    borderWidth: "3px",
  },
}));

function ChartStimuli(props) {
  const classes = useStyles();
  const { chartId, type, label } = props;
  // console.log(chartId);
  const dispatch = useDispatch();

  const { chart, selectedChart } = useSelector((state) => ({
    chart: state.charts[chartId],
    selectedChart: state.ui.selectedChart,
  }));

  function handleSelectChart() {
    dispatch(selectChart(chartId));
  }

  return (
    <>
      {chart && (
        <>
          <Paper
            elevation={0}
            draggable="false"
            variant={type === "small" ? "outlined" : "elevation"}
            className={[
              type === "tiny" && classes.tiny,
              type === "live" && classes.live,
              type === "small" && classes.small,
              type === "small" && chartId === selectedChart
                ? classes.selectedArea
                : null,
              type === "small" && props.dragging ? classes.dragging : null,
            ].join(" ")}
            onPointerUp={type === "small" ? handleSelectChart : null}
            onDragEnter={type === "small" ? props.onDragEnter : null}
            onDragLeave={type === "small" ? props.onDragLeave : null}
            onDragOver={type === "small" ? props.onDragOver : null}
            onDrop={type === "small" ? props.onDrop : null}
          >
            {chart.type === "vega" && (
              <>
                {type === "live" || chart.source === null ? (
                  <Vega
                    spec={JSON.parse(chart.spec)}
                    actions={false}
                    width={type === "small" ? 150 : 512}
                    // height={type === "small" ? 150 : 512}÷
                    // hover
                    className={classes.vegafit}
                  />
                ) : (
                  <img src={chart.source} alt="chart" className={classes.fit} />
                )}
              </>
            )}

            {chart.type === "image" && chart.source && (
              <img src={chart.source} alt="chart" className={classes.fit} />
            )}

            {!chart.source && !chart.spec && type === "small" && (
              <div>Drop Image or Vega(-Lite) Spec</div>
            )}

            {type === "small" && <div className={classes.overlay}>{label}</div>}

            {!chart.source &&
              !chart.spec &&
              (type === "live") && (
                <Paper
                  elevation={0}
                  style={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "gray",
                    margin: "0",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  No Visualization
                </Paper>
              )}
          </Paper>
        </>
      )}
    </>
  );
}

export default ChartStimuli;
