import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";

import { updateExperiment } from "actions/experiments";

import {
  Typography,
  Grid,
  Snackbar,
  TextField,
  FormControlLabel,
  Switch,
  makeStyles,
  Tooltip,
  Avatar,
  Button,
  Box,
  MenuItem,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Dialog,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { deepPurple } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "25px",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  external: {
    marginTop: theme.spacing(2),
  },
  infoIcon: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px !important",
    },
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: 32,
    height: 32,
  },
}));

const chartTypeTagList = [
  "Bar Chart",
  "Pie Chart",
  "Line Chart",
  "Area Chart",
  "Scatter Plot",
  "Map",
  "Box Plot",
  "Network Diagram",
];

const dataTypeTagList = ["Table", "Time-series", "Map", "Network"];

const taskTypeTagList = [
  "Comparison",
  "Distribution",
  "Correlation",
  "Ranking",
  "Trend",
  "Map",
];

const DeployView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { experimentId, isLaunched, experimentShareState, tags, prolificCode } =
    props;
  const [shareState, setShareState] = useState(experimentShareState);
  const [chartTags, setChartTags] = useState(
    tags === undefined ? [] : tags.chartTags
  );
  const [dataTags, setDataTags] = useState(
    tags === undefined ? [] : tags.dataTags
  );
  const [taskTags, setTaskTags] = useState(
    tags === undefined ? [] : tags.taskTags
  );

  const handleIsLaunched = (e) => {
    let checked = e.target.checked;
    dispatch(updateExperiment(experimentId, { isLaunched: checked }));
  };

  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCopyURL = () => {
    navigator.clipboard.writeText(
      window.location.href.replace("edit", "share")
    );
    // setShowError(true)
    setOpen(true);
    setSeverity("success");
    setErrorMsg("Link Copied");
  };

  const handleShareStateChange = (event) => {
    setShareState(event.target.value);
    dispatch(
      updateExperiment(experimentId, { shareState: event.target.value })
    );
  };

  useEffect(() => {
    dispatch(
      updateExperiment(experimentId, {
        tags: {
          ...tags,
          chartTags: chartTags,
          dataTags: dataTags,
          taskTags: taskTags,
        },
      })
    );
  }, [chartTags, dataTags, taskTags]);
  // console.log(chartTags, dataTags, taskTags);
  // Retrieve value, filter, compute derived value, find extremum, sort, determine range, characterize distribution, find anomalies, cluster, correlate

  const handleTagChange = (event, type) => {
    let tagName = event.target.value;
    if (type === "chart") {
      if (chartTags.filter((tag) => tag === tagName).length) {
        setChartTags(chartTags.filter((tag) => tag !== tagName));
      } else {
        setChartTags((oldArray) => [...oldArray, tagName]);
      }
    } else if (type === "data") {
      if (dataTags.filter((tag) => tag === tagName).length) {
        setDataTags(dataTags.filter((tag) => tag !== tagName));
      } else {
        setDataTags((oldArray) => [...oldArray, tagName]);
      }
    } else if (type === "task") {
      if (taskTags.filter((tag) => tag === tagName).length) {
        setTaskTags(taskTags.filter((tag) => tag !== tagName));
      } else {
        setTaskTags((oldArray) => [...oldArray, tagName]);
      }
    }
  };

  const handleCheckboxChecked = (tag, type) => {
    if (type === "chart") {
      try {
        if (chartTags.includes(tag)) {
          return true;
        } else {
          return false;
        }
      } catch (TypeError) {
        return false;
      }
    } else if (type === "data") {
      try {
        if (dataTags.includes(tag)) {
          return true;
        } else {
          return false;
        }
      } catch (TypeError) {
        return false;
      }
    } else if (type === "task") {
      try {
        if (taskTags.includes(tag)) {
          return true;
        } else {
          return false;
        }
      } catch (TypeError) {
        return false;
      }
    }
  };

  const handleTagChipDelete = (tagName, type) => () => {
    if (type === "chart") {
      setChartTags(chartTags.filter((tag) => tag !== tagName));
    } else if (type === "data") {
      setDataTags(dataTags.filter((tag) => tag !== tagName));
    } else if (type === "task") {
      setTaskTags(taskTags.filter((tag) => tag !== tagName));
    }
  };

  const handleEnterUserTag = (e, type) => {
    if (e.keyCode === 13) {
      let tagName = e.target.value;
      if (type === "chart") {
        if (chartTags.filter((tag) => tag === tagName).length) {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(`${tagName} is already exist.`);
        } else {
          setChartTags((oldArray) => [...oldArray, tagName]);
          e.target.value = "";
        }
      } else if (type === "data") {
        if (dataTags.filter((tag) => tag === tagName).length) {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(`${tagName} is already exist.`);
        } else {
          setDataTags((oldArray) => [...oldArray, tagName]);
          e.target.value = "";
        }
      } else if (type === "task") {
        if (taskTags.filter((tag) => tag === tagName).length) {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(`${tagName} is already exist.`);
        } else {
          setTaskTags((oldArray) => [...oldArray, tagName]);
          e.target.value = "";
        }
      }
    }
  };

  const [prolificMenu, showProlificMenu] = useState(false);
  const [prolificCodeState, setProlificCodeState] = useState(
    prolificCode === undefined ? "" : prolificCode
  );
  const saveProlificCode = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      updateExperiment(experimentId, { prolificCode: prolificCodeState })
    );
    showProlificMenu(false);
  };
  const handleClickProlific = (event) => {
    event.stopPropagation();
    event.preventDefault();
    showProlificMenu(true);
  };
  const cancelProlific = (event) => {
    event.stopPropagation();
    event.preventDefault();
    showProlificMenu(false);
  };
  const handleProlificCodeState = (event) => {
    setProlificCodeState(event.target.value);
  };

  return (
    <div className={classes.content}>
      <Grid container spacing={4}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" display="block">
              Recruit participants for your experiment
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
              Share this link with any potential readers such as your colleagues
              and friends
            </Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLaunched || false}
                  onChange={(e) => handleIsLaunched(e)}
                />
              }
              label={
                <Typography variant="h6" display="block">
                  Launch Experiment
                </Typography>
              }
              labelPlacement="end"
            />
            <Typography variant="body2" display="block">
              {isLaunched
                ? "Anyone with the link can participate in the experiment."
                : "No one can participate in the experiment."}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              margin="dense"
              fullWidth
              value={window.location.href.replace("edit", "share")}
            />
          </Grid>
          <Grid item xs={6}>
            <Box marginLeft={3} marginTop={2}>
              <Button
                onPointerUp={handleCopyURL}
                variant="contained"
                color="primary"
                target="_blank"
              >
                Copy link
              </Button>
            </Box>
          </Grid>

          <Box marginTop={1}>
            <Box component="span">
              <Tooltip title="Twitter">
                <TwitterShareButton
                  url={window.location.href.replace("edit", "share")}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Tooltip>
            </Box>
            <Box component="span" marginLeft={2}>
              <Tooltip title="Facebook">
                <FacebookShareButton
                  url={window.location.href.replace("edit", "share")}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Tooltip>
            </Box>
            <Box component="span" marginLeft={2}>
              <Tooltip title="LinkedIn">
                <LinkedinShareButton
                  url={window.location.href.replace("edit", "share")}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Tooltip>
            </Box>
            <Box component="span" marginLeft={2}>
              <Tooltip title="Email">
                <EmailShareButton
                  onClick={() => {}}
                  openShareDialogOnClick
                  url={window.location.href.replace("edit", "share")}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </Tooltip>
            </Box>
            <Box component="span" marginLeft={2}>
              <Tooltip title="Prolific">
                <Button
                  onClick={handleClickProlific}
                  style={{ marginTop: "-23px", marginLeft: "-15px" }}
                >
                  <Avatar
                    mx="auto"
                    className={classes.avatar}
                    src="/img/prolific-logo.jpg"
                  />
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Grid>

        {prolificMenu && (
          <Dialog
            className={classes.dialog}
            open={prolificMenu}
            onClose={cancelProlific}
          >
            <Box p={3} onClick={(e) => e.stopPropagation()}>
              <Typography variant="subtitle1">
                To recruit participants using Prolific, please paste the
                experiment code in here.
              </Typography>
              <TextField
                style={{ width: 300 }}
                variant="outlined"
                size="small"
                value={prolificCodeState}
                onChange={(e) => handleProlificCodeState(e)}
              />
              <Box
                mt={3}
                boxSizing="border-box"
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  className={classes.margin}
                  color="secondary"
                  variant="outlined"
                  onClick={saveProlificCode}
                >
                  Save
                </Button>{" "}
                <Button
                  className={classes.margin}
                  onClick={cancelProlific}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Dialog>
        )}

        <Grid container item>
          <Grid item xs={6}>
            <Typography variant="h6" display="block">
              Share with Others
            </Typography>
            <FormControl fullWidth>
              <Select value={shareState} onChange={handleShareStateChange}>
                <MenuItem value={"public-design-result"} dense={true}>
                  <Box>
                    <Typography variant="subtitle1">
                      Public - Design & Result
                    </Typography>
                    <Typography variant="caption">
                      Your experiment design and result are shared with others.
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={"public-design-only"}>
                  <Box>
                    <Typography variant="subtitle1">
                      Public - Design Only
                    </Typography>
                    <Typography variant="caption">
                      Your experiment design is shared with others.
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={"public-result-only"}>
                  <Box>
                    <Typography variant="subtitle1">
                      Public - Result Only
                    </Typography>
                    <Typography variant="caption">
                      Your experiment result is shared with others.
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={"private"}>
                  <Box>
                    <Typography variant="subtitle1">Private</Typography>
                    <Typography variant="caption">
                      Only you can view this experiment.
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="subtitle1" display="block">
              Describe Your Experiment
            </Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={8}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle2">
                    Chart Type&nbsp;
                    {chartTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        className={classes.chip}
                        onDelete={handleTagChipDelete(tag, "chart")}
                      />
                    ))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <FormGroup
                      row
                      onChange={(e) => handleTagChange(e, "chart")}
                    >
                      <>
                        {chartTypeTagList.map((chartTypeTag) => (
                          <FormControlLabel
                            key={chartTypeTag}
                            value={chartTypeTag}
                            control={
                              <Checkbox
                                checked={handleCheckboxChecked(
                                  chartTypeTag,
                                  "chart"
                                )}
                                size="small"
                              />
                            }
                            label={
                              <Typography variant="body2">
                                {chartTypeTag}
                              </Typography>
                            }
                          />
                        ))}
                      </>
                    </FormGroup>
                    <TextField
                      style={{ width: 300 }}
                      variant="outlined"
                      className={classes.customTextField}
                      size="small"
                      onKeyDown={(e) => handleEnterUserTag(e, "chart")}
                      placeholder="Custom Tag: Type Tag and Press Enter"
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle2" className={classes.infoIcon}>
                    Data Type&nbsp;
                    {dataTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        className={classes.chip}
                        onDelete={handleTagChipDelete(tag, "data")}
                      />
                    ))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <FormGroup row onChange={(e) => handleTagChange(e, "data")}>
                      <>
                        {dataTypeTagList.map((dataTypeTag) => (
                          <FormControlLabel
                            key={dataTypeTag}
                            value={dataTypeTag}
                            control={<Checkbox />}
                            label={
                              <Typography variant="body2">
                                {dataTypeTag}
                              </Typography>
                            }
                          />
                        ))}
                      </>
                    </FormGroup>
                    <TextField
                      style={{ width: 300 }}
                      variant="outlined"
                      className={classes.customTextField}
                      size="small"
                      onKeyDown={(e) => handleEnterUserTag(e, "data")}
                      placeholder="Custom Tag: Type Tag and Press Enter"
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle2" className={classes.infoIcon}>
                    Task Type&nbsp;
                    {taskTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        className={classes.chip}
                        onDelete={handleTagChipDelete(tag, "task")}
                      />
                    ))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <FormGroup row onChange={(e) => handleTagChange(e, "task")}>
                      <>
                        {taskTypeTagList.map((taskTypeTag) => (
                          <FormControlLabel
                            key={taskTypeTag}
                            value={taskTypeTag}
                            control={<Checkbox />}
                            label={
                              <Typography variant="body2">
                                {taskTypeTag}
                              </Typography>
                            }
                          />
                        ))}
                      </>
                    </FormGroup>
                    <TextField
                      style={{ width: 300 }}
                      variant="outlined"
                      className={classes.customTextField}
                      size="small"
                      onKeyDown={(e) => handleEnterUserTag(e, "task")}
                      placeholder="Custom Tag: Type Tag and Press Enter"
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert variant="filled" severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DeployView;
