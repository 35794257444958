import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Result from "components/Memorability/Result";

import { updateResponse } from "actions/responses";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  Collapse,
  IconButton,
  Button,
  Typography,
  Box,
} from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import GetAppIcon from "@material-ui/icons/GetApp";
import _ from "underscore";
import {
  parseMemorabilityData,
  parseMemorabilityDataForExport,
} from "utils/dataProcessing";
// import { EXPERIMENTAL_FEATURE } from "index";
import Tooltip from "@material-ui/core/Tooltip";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// const columns = [
//   { id: "chart", label: "Chart", tip: "" },
//   {
//     id: "question",
//     label: "Question",
//     tip: "",
//   },
//   // {
//   //   id: 'myeanswer',
//   //   label: 'Answer',
//   //   tip: '',
//   // },
//   // {
//   //   id: 'correct',
//   //   label: 'Correct?',
//   //   tip: '',
//   // },
//   // {
//   //   id: 'err',
//   //   label: 'Error',
//   //   tip: 'log absolute error',
//   // },
//   // {
//   //   id: 'time',
//   //   label: 'Task Completion Time',
//   //   tip: '',
//   // },
//   {
//     id: "accuracy",
//     label: "Accuracy",
//     tip: "",
//   },
//   {
//     id: "taskTime",
//     label: "Task Time",
//     tip: "",
//   },
//   {
//     id: "rank",
//     label: "Rank",
//     tip: "",
//   },
//   {
//     id: "percentage",
//     label: "Percentage",
//     tip: "",
//   },
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  columns: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    width: "100%",
    margin: "0",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  header: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

export default function IndividualDashboard(props) {
  const { experimentId, responseId, responses } = props;
  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);

  const getTasks = makeGetTasks();
  const { tasks, selectedTask } = useSelector((state) => ({
    tasks: getTasks(state, props),
    selectedTask: state.ui.selectedTask,
  }));
  const getCharts = makeGetCharts();
  const { charts, selectedChart } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
    selectedChart: state.ui.selectedChart,
  }));

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseMemorabilityData(responses, charts, true);
        setData(result);
      }
    }
    fetch();
  }, [responses.length]);

  function CollapseRow(props) {
    const { data } = props; // object
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const { omitState } = useSelector((state) => ({
      omitState: state.responses[data.id].omit,
    }));
    const handleOmitCheck = (event) => {
      dispatch(updateResponse(data.id, { omit: event.currentTarget.checked }));
    };

    return (
      <>
        <Fragment key={data.id}>
          <TableRow>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">{data.ip}</TableCell>
            {/* <TableCell align="center">{data.screening}</TableCell> */}
            <TableCell align="right">
              {Number(data.far).toLocaleString(undefined, {
                style: "percent",
                minimumFractionDigits: 2,
              })}
            </TableCell>
            <TableCell align="right">
              {Number(data.hr).toLocaleString(undefined, {
                style: "percent",
                minimumFractionDigits: 2,
              })}
            </TableCell>
            <TableCell align="right">
              {Number(data.score).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </TableCell>
            <TableCell align="right" padding="checkbox">
              <Checkbox checked={omitState} onChange={handleOmitCheck} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "none",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto">
                {open && (
                  <Box p={3} bgcolor="#f5f5f5">
                    <Result
                      key={data.id}
                      experimentId={experimentId}
                      responseId={data.id}
                      responses={responses}
                      isResult={false}
                    />
                  </Box>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      </>
    );
  }

  function ExpandableRow(props) {
    const { rows } = props;

    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Identifier</TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  False Alarm Rate (%)&nbsp;
                  <Tooltip title="False alarm rate indicates the proportion of visualizations falsely recognized on their first appearances by the respondent.">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  Hit rate (%)&nbsp;
                  <Tooltip title="Hit rate indicates the proportion of visualizations successfully recognized on their second repeated appearances by the respondent.">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  Memorability Score&nbsp;
                  <Tooltip title=" Memorability score takes into account both Hit rate and False alarm rate. A higher hit rate and a low false alarm rate would lead to a higher respondent's memorability score. Since the memorability score is a unitless number, only relative magnitude comparisons are possible.">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  Omit&nbsp;
                  <Tooltip title=" If omit is selected, the results of this respondent will not be used for summary dashboards.">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <CollapseRow key={i} data={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  async function fetchDataforExport() {
    if (responses.length > 0) {
      const result = await parseMemorabilityDataForExport(responses, charts);
      setDataForExport(result);
    }
  }

  useEffect(() => {
    if (dataForExport.length > 0) {
      // console.log(dataForExport);
      let csvContent =
        "data:text/csv;charset=utf-8," +
        dataForExport.map((e) => Object.values(e).join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", experimentId + "_result.csv");
      // console.log(link);
      document.body.appendChild(link);
      link.click();

      // console.log(charts);
      charts.map((chart) => {
        var img = document.createElement("a");
        img.setAttribute("href", chart.source);
        img.setAttribute("download", chart.id + ".png");
        document.body.appendChild(img);
        img.click();
      });
    }
  }, [dataForExport]);

  const handleExport = () => {
    fetchDataforExport();
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <ExpandableRow rows={data} />
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
            style={{ marginTop: "25px" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onPointerUp={handleExport}
            >
              Export
            </Button>
          </Grid>
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
