/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { createResponse, checkDuplicatedResponse } from "actions/responses";
import { requestExperimentContent } from "actions/experiments";
import { selectExperiment } from "actions/ui";

import { default as PerceptionTemplateLive } from "components/Perception/TemplateLive";
import { default as BubbleViewTemplateLive } from "components/BubbleView/TemplateLive";
import { default as MemorabilityTemplateLive } from "components/Memorability/TemplateLive";
// import ResponseView from "components/ResponseView";

// import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
// import Divider from "@material-ui/core/Divider";
// import Grid from "@material-ui/core/Grid";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
// import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { url } from "actions";

// const ipApiUrl = 'https://api.ipstack.com/'; // 10,000 call/month
// const ipApiKey = process.env.REACT_APP_IPSTACK_API_ACCESS_KEY;

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(6),
  },
  navbar: {
    width: "100%",
    margin: "0",
    padding: "5px 0px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  rightMenu: {
    display: "flex",
  },
  menuItem: {
    marginRight: "5px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
}));

// DesignView: preview={false} live={false}
// PreviewView: preview={true} live={false}
// LiveView: preview={true} live={true}
const LiveView = (props) => {
  const classes = useStyles();
  let { previewMode, liveMode } = props;
  previewMode = previewMode !== undefined ? previewMode : true;
  liveMode = liveMode !== undefined ? liveMode : true;
  // console.log('live:  ', live);
  const dispatch = useDispatch();
  let history = useHistory();

  const [response, setResponse] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { experimentId } = useSelector((state) => ({
    experimentId: props.experimentId
      ? props.experimentId
      : props.match.params.experimentId,
  }));

  const { experiment, loggedInUsername, username } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
    loggedInUsername: state.auth.user.uid,
    username: state.auth.user.uid,
  }));

  const [isLoading, setIsLoading] = useState(!experiment);
  const [responseIP, setResponseIP] = useState("");

  useEffect(() => {
    async function fetchData() {
      // if (!previewMode) {
      if (liveMode) {
        let loggedInUsername = "";
        if (isLoading) {
          await dispatch(
            requestExperimentContent(loggedInUsername, experimentId, true)
          ); //retrieve form content from server
          await dispatch(selectExperiment(experimentId)); // when acceced directly from the url
        }
        if (experiment != null) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // if (!previewMode) {
    if (liveMode) {
      // const requestUrl = `${ipApiUrl}check?access_key=${ipApiKey}`;
      // const requestUrl = "https://api.ipify.org?format=json";
      // fetch(requestUrl, { method: "GET" })
      //   .then((res) => res.json())
      //   .then((json) => {
      //     // setResponse({
      //     //   ...response,
      //     //   ip: json.ip,
      //     // });
      //     setResponseIP(json.ip); // 9/19
      //   });

      fetch("https://www.cloudflare.com/cdn-cgi/trace")
        .then((res) => res.text())
        .then((text) => {
          setResponseIP(
            text.split("\n").join("<br>").split("ip=")[1].split("<br>")[0]
          );
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const [duplicated, setDuplicated] = useState();
  async function fetchDuplicatedResponse(responseIP) {
    const result = await axios({
      baseURL: url,
      url: "/check_duplicated_response",
      method: "post",
      data: {
        data: responseIP,
      },
    });
    setDuplicated(result.data.result);
  }
  useEffect(() => {
    // send ip address to the server
    // check server return
    // if ip address is exist, experiment is over.
    if (!duplicated) {
      fetchDuplicatedResponse(responseIP);
    }
  }, [responseIP]);
  console.log(duplicated);

  const handleReleaseError = () => {
    setShowError(false);
    setErrorMsg("");
  };

  const handleAnswers = (ans) => {
    setResponse({
      ...response,
      ...ans,
      type: experiment.type,
      ip: responseIP, // 9/19
    });
  };

  const handleSubmit = () => {
    if (liveMode) {
      dispatch(createResponse(experimentId, history, liveMode, response));
    } else {
      dispatch(
        createResponse(experimentId, history, liveMode, {
          ...response,
          ip: "[Preview]",
          omit: true,
        })
      );
    }
  };

  const renderTemplate = () => {
    if (experiment.type === "Graphical Perception") {
      return (
        <PerceptionTemplateLive
          experimentId={experimentId}
          previewMode={previewMode}
          handleAnswers={handleAnswers}
          handleSubmit={handleSubmit}
        />
      );
    } else if (experiment.type === "Attention Tracking") {
      return (
        <BubbleViewTemplateLive
          experimentId={experimentId}
          previewMode={previewMode}
          handleAnswers={handleAnswers}
          handleSubmit={handleSubmit}
        />
      );
    } else if (experiment.type === "Memorability") {
      return (
        <MemorabilityTemplateLive
          experimentId={experimentId}
          previewMode={previewMode}
          handleAnswers={handleAnswers}
          handleSubmit={handleSubmit}
        />
      );
    }
  };

  // const imageUri = encodeURI(
  //   "https://storage.googleapis.com/ngoapp/1515572324702classes 3.jpg"
  // );

  return (
    <>
      {isLoading ? (
        <Box mt={3} pt={5}>
          <Box width="100%" display="flex" justifyContent="center">
            <img width="64px" src="/img/loading.gif"></img>
          </Box>
          <Box mt={3} width="100%" display="flex" justifyContent="center">
            <Typography variant="h5">Loading</Typography>
          </Box>
        </Box>
      ) : (
        <>
          {liveMode && username && username === loggedInUsername && (
            <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
              <Fab
                variant="extended"
                color="secondary"
                size="medium"
                aria-label="edit"
                component={Link}
                to={`/experiment/${experimentId}/edit`}
              >
                <EditIcon className={classes.editIcon} /> Edit
              </Fab>
            </Box>
          )}
          {!liveMode ? ( // preview mode
            <div className={classes.content}>{renderTemplate()}</div>
          ) : experiment &&
            (experiment.isLaunched || experiment.public) &&
            duplicated &&
            duplicated === "not duplicated" ? ( // livemode & launched
            <div className={classes.content}>{renderTemplate()}</div>
          ) : (
            <div className={classes.content}>
              {duplicated === "duplicated"
                ? "Sorry! It seems you are already parcitipated in this experiment. Thank you for your participation."
                : "Sorry! This experiment is not launched or no longer available."}
            </div>
          )}

          {showError && (
            <Snackbar open={errorMsg !== null} autoHideDuration={1000}>
              <Alert
                onClose={() => handleReleaseError()}
                variant="filled"
                severity="error"
              >
                {errorMsg}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  );
};

export default LiveView;
