import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Filtering from "components/Filtering";

import {
  makeGetPublicExperiments,
  requestExperimentsPublic,
} from "actions/experiments";

import {
  Typography,
  Grid,
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Button,
  Box,
  Fab,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { WorkOutlined } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";

// const width = 225;
// const height = 225;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  card: {
    width: "100%",
    height: "200px",
  },
  cardDesc: {
    height: "80px",
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  customBoxDesc: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-all",
    overflow: "hidden",
  },
}));

const ExplorePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getPublicExperiments = makeGetPublicExperiments();
  const { experiments } = useSelector((state) => ({
    experiments: getPublicExperiments(state),
  }));

  useEffect(() => {
    // componentDidMount
    dispatch(requestExperimentsPublic());
  }, []);

  const sharedExperiments = experiments.filter(
    (exp) =>
      exp.shareState === "public-design-result" ||
      exp.shareState === "public-design-only" ||
      exp.shareState === "public-result-only"
  );

  const filteringTypes = [
    "Chart Type",
    "Data Type",
    "Task Type",
    "Template Type",
  ];
  //   const [questionsState, setQuestionsState] = useState(questions);
  const [filteringConditions, setFilteringCoditions] = useState(
    props.location.state ? props.location.state.filteringConditions : {}
  );

  const [countResults, setCountResults] = useState();

  const answersCallback = (filteringType, selected) => {
    setFilteringCoditions({
      ...filteringConditions,
      [filteringType]: selected,
    });
  };

  const data = sharedExperiments.map((exp) => ({
    "Chart Type": exp.tags.chartTags,
    "Data Type": exp.tags.dataTags,
    "Task Type": exp.tags.taskTags,
    "Template Type": new Array(exp.type),
    id: exp.id,
    title: exp.title,
    desc: exp.desc,
  }));
  // console.log("ddddddd-------", data, [
  //   ...new Set(data.map((item) => item["Chart Type"])),
  // ]);
  const allOfTags = {
    "Chart Type": [...new Set(data.map((item) => item["Chart Type"]))],

    "Data Type": [...new Set(data.map((item) => item["Data Type"]))],
    "Task Type": [...new Set(data.map((item) => item["Task Type"]))],
    "Template Type": [...new Set(data.map((item) => item["Template Type"]))],
  };
  // console.log(allOfTags);
  const [results, setResults] = useState(data);
  // console.log(data, allOfTags, results, countResults);
  const counterChoices = () => {
    // console.log(data, allOfTags, results, countResults);
    let res = {};
    // console.log(allOfTags);
    Object.entries(allOfTags).map(([type, tags]) => {
      let obj = {};
      tags.map((tag) => {
        // console.log(tag, tag[0]);
        tag.map((k) => {
          // let k = tag[0];
          obj[k] = 0;
          results.map((exp) => {
            //   console.log(exp[type], k, exp[type].includes(k));
            //   try {
            if (exp[type].includes(k)) {
              obj[k] += 1;
            }
          });
          //   } catch (TypeError) {
          //     obj[k] = 0;
          //   }
        });
      });
      // console.log(obj);
      //   let arr = results; // retrieved data
      //   for (let i = 0; i < arr.length; i++) {
      //     //   for (let j = 0; j < arr[i][type].length; j++) {
      //     //     obj[arr[i][type]] = (obj[arr[i][type]] || 0) + 1;
      //     //   }
      //     // obj[arr[i][type]] = (obj[arr[i][type]] || 0) + 1;
      //     Object.keys(obj).map(t => (
      //         Object.
      //     ))
      //   }
      res[type] = obj;
    });
    // console.log(res);
    setCountResults(res);
  };

  const search = () => {
    let choices = {};
    for (var key in filteringConditions) {
      choices[key] = Object.entries(filteringConditions[key]).map(([k, v]) => {
        // console.log(key, k, v);
        if (v === true) return k;
      });

      choices[key] = choices[key].filter((x) => x !== undefined);
    }
    // console.log(choices);
    const searchResults = data.filter((datum) => {
      for (var key in filteringConditions) {
        // console.log(key);
        if (datum[key] === undefined) return false;
        if (!choices[key].length) continue;
        if (datum[key].some((d) => choices[key].includes(d)) === false)
          return false;
      }
      return true;
    });

    // console.log(searchResults);
    setResults(searchResults);
  };
  useEffect(() => {
    // console.log(filteringConditions);
    search();
  }, [filteringConditions]);

  useEffect(() => {
    if (results.length === 0) {
      setResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (results) {
      counterChoices();
    }
  }, [results]);

  const handleReset = () => {
    setFilteringCoditions({});
    setCountResults();
    setResults(data);
  };
  // console.log(filteringConditions, results);
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Explore
        </Typography>
      </Grid>
      <Grid container item spacing={5}>
        {filteringTypes.map((filteringType) => {
          return (
            <Fragment key={filteringType}>
              <Grid item xs>
                <Filtering
                  //   className="Question"
                  filteringType={filteringType}
                  //   text={text}
                  answersCallback={answersCallback}
                  filteringConditions={filteringConditions}
                  allOfTags={allOfTags}
                  countResults={countResults}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      {/* <div className={classes.content}> */}
      <Grid item xs>
        <Button size="small" onClick={handleReset}>
          <ClearIcon />
          remove all
        </Button>
      </Grid>

      {/* <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Explore
        </Typography> */}
      {results.length > 0 ? (
        <Grid container item spacing={2}>
          {/* {sharedExperiments.map((exp) => ( */}
          {results.map((exp) => (
            <Grid key={exp.id} item xs={3}>
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.content}>
                  <Box component="div" classes={{ root: classes.customBox }}>
                    <Typography variant="button" gutterBottom display="block">
                      {exp.title}
                    </Typography>
                  </Box>
                  <Box
                    component="div"
                    classes={{ root: classes.customBoxDesc }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.cardDesc}
                      classes={{ root: classes.customBoxDesc }}
                    >
                      {exp.desc}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      component={Link}
                      to={`/experiment/${exp.id}/edit-readonly`}
                      target="_blank"
                      endIcon={<ArrowForwardIcon />}
                    >
                      See Inside
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Typography variant="body1">No Shared Experiments</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ExplorePage;
