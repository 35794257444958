import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
} from 'actions';

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.questions.reduce((acc, question) => {
        return {
          ...acc,
          [question.id]: question,
        };
      }, state);
    case CREATE_QUESTION:
    case UPDATE_QUESTION:
      return {
        ...state,
        [action.questionId]: {
          ...(state[action.questionId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_QUESTION: {
      let newState = {
        ...state,
      };
      delete newState[action.questionId];
      return newState;
    }
    default:
      return state;
  }
};
