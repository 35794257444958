import uniqueId from "utils/uniqueId";
import { createSelector } from "reselect";
import { selectQuestion, alertServerError } from "actions/ui";
import { createOption } from "actions/options";
import { url, requestInfo } from "actions/index";
import { createdDateAscending } from "utils/date";

// action types
export const CREATE_QUESTION = "CREATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
// export const CREATE_QUESTION_ALL = 'CREATE_QUESTION_ALL';

// actions
const path_dict = {
  CREATE_QUESTION: "create_question",
  UPDATE_QUESTION: "update_question",
  DELETE_QUESTION: "delete_question",
  //   CREATE_QUESTION_ALL: 'upsert_question',
};

export const createQuestion = (
  surveyId,
  // visId,
  attrs = {},
  fromJson = false,
  options = null,
  duplicate = false
) => {
  return async (dispatch) => {
    let questionId = uniqueId("question_");
    let createdAt = Date.now();
    if (!duplicate) {
      if (fromJson) {
        attrs = {
          ...attrs,
          surveyId,
          id: questionId,
          createdAt: createdAt,
          updatedAt: createdAt,
        };
      } else {
        attrs = {
          ...attrs,
          surveyId,
          id: questionId,
          text: "",
          // allowMultipleAnswers: false,
          createdAt: createdAt,
          updatedAt: createdAt,
        };
      }
    } else {
      questionId = attrs.id;
    }
    let action = { type: CREATE_QUESTION, questionId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
          dispatch(selectQuestion(questionId));
          if (!duplicate) {
            if (fromJson) {
              options.map((optionAttr) => {
                dispatch(createOption(questionId, null, optionAttr, fromJson));
              });
            } else {
              dispatch(createOption(questionId, options, {}, false));
            }
          }
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateQuestion = (questionId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_QUESTION, questionId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteQuestion = (questionId) => {
  return async (dispatch) => {
    let action = { type: DELETE_QUESTION, questionId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

//selectors
export const makeGetQuestions = () => {
  return createSelector(
    (state, props) => props.surveyId,
    (state) => state.questions,
    (surveyId, questions) =>
      Object.values(questions)
        .filter((question) => question.surveyId === surveyId)
        .sort(createdDateAscending)
  ); // return annotations for the panel
};
