/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import axios from "axios";

import Heatmap from "components/BubbleView/Heatmap";

import { url } from "actions/index";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import { parseBubbleViewData } from "utils/dataProcessing";
import { ErrorBar1D } from "utils/Charts";
import { rank, min, max, sum, median } from "utils/stats";

import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Tooltip,
  Chip,
} from "@material-ui/core";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  columns: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    width: "100%",
    margin: "0",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  header: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  canvas: {
    position: "relative",
    width: "350px",
    height: "350px",
  },
  fit: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
    objectPosition: "0 0",
  },
});

function Result(props) {
  const { experimentId, responseId, responses, isResult } = props;
  const classes = useStyles();
  // console.log(responses);
  const [data, setData] = useState({});
  const [myData, setMyData] = useState({});
  const [responseIndex, setResponseIndex] = useState(0);
  const numOfResponses = Object.keys(responses).length;
  // const rid = "response_" + responseId;
  let index = responses.findIndex((p) => p.id === responseId);
  const myResponse = responses[index];

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseBubbleViewData(responses, false, responseId);
        setMyData(result[0]);
        setData(result[1]);
      }
    }
    fetch();
  }, [responses.length]);
  // console.log(data, myData);

  const [ccScore, setCCScore] = useState(0);
  async function fetchCCScore(data1, data2) {
    const result = await axios({
      baseURL: url,
      url: "/cc_score",
      method: "post",
      data: {
        data1: data1,
        data2: data2,
      },
    });
    // console.log(result.data.result);
    setCCScore(result.data.result);
  }

  useEffect(() => {
    if (data.length > 0 && myData.length > 0 && responses.length > 1) {
      const othersDict = {};
      const myDict = {};
      charts.map((chart) => {
        const othersList = [];
        const myList = [];
        responses.map((response, i) => {
          if (response.id === responseId) {
            setResponseIndex(i);
          }

          const othersData = data
            .filter((d) => d.responseId !== response.id)
            .filter((d) => d.chartId === chart.id);
          // .reduce((a, b) => a.clicks.concat(b.clicks));
          // const others = othersData.map((d) => d.clicks).flat(1);
          const my = data
            .filter((d) => d.responseId === response.id)
            .filter((d) => d.chartId === chart.id);
          // .reduce((a, b) => a.clicks.concat(b.clicks)).clicks;
          othersList.push(othersData.map((d) => d.clicks).flat(1));
          myList.push(my.map((d) => d.clicks).flat(1));

          // console.log(myList);
        });
        othersDict[chart.id] = othersList;
        myDict[chart.id] = myList;
      });

      fetchCCScore(othersDict, myDict);
    } else if (data.length > 0 && myData.length > 0 && responses.length === 1) {
      // const othersDict = {};
      const myDict = {};
      charts.map((chart) => {
        const othersList = [];
        const myList = [];
        responses.map((response, i) => {
          if (response.id === responseId) {
            setResponseIndex(i);
          }

          // const othersData = data
          //   .filter((d) => d.responseId === response.id)
          //   .filter((d) => d.chartId === chart.id);
          // .reduce((a, b) => a.clicks.concat(b.clicks));
          // const others = othersData.map((d) => d.clicks).flat(1);
          const my = data
            .filter((d) => d.responseId === response.id)
            .filter((d) => d.chartId === chart.id);
          // .reduce((a, b) => a.clicks.concat(b.clicks)).clicks;

          // othersList.push(othersData.map((d) => d.clicks).flat(1));
          myList.push(my.map((d) => d.clicks).flat(1));
        });
        // othersDict[chart.id] = myList;
        myDict[chart.id] = myList;
      });
      fetchCCScore(myDict, myDict);
      // setCCScore(1);
    }
  }, [data, myData]);

  const myPercentage = () => {
    // console.log(score, myScore);
    // let percent =
    //   (rank(
    //     score,
    //     myScore
    //   ) /
    //     numOfResponses) *
    //   100;

    // console.log(percent);
    let val = myScore[0].score;
    // console.log(val);
    if (val > 0.7) {
      return "very close.";
    } else if (0.3 < val && val < 0.7) {
      return "somewhat close.";
    } else {
      return "not close.";
    }
  };

  // const myPercentage = 1.23;
  // console.log(responseId, myData, data, ccScore);
  const [score, setScore] = useState([]);
  const [myScore, setMyScore] = useState([]);
  useEffect(() => {
    let s = [];
    let ms = [];
    let listLength;
    // console.log(ccScore);
    Object.values(ccScore).map((val) => {
      listLength = val.length;
    });
    for (let i = 0; i < listLength; i++) {
      let sum = 0;
      charts.map((chart) => {
        sum += ccScore[chart.id][i];
      });
      // console.log(sum);
      if (responseIndex !== i) {
        s.push({ score: sum / charts.length, responseId: i });
      } else {
        ms.push({ score: sum / charts.length, responseId: i });
      }
    }
    if (responses.length !== 1) {
      setScore(s);
      setMyScore(ms);
    } else {
      setScore(ms);
      setMyScore(ms);
    }
  }, [ccScore]);

  // console.log(data, myData);
  // console.log(score, myScore);
  return (
    <>
      {data.length > 0 &&
        myData.length > 0 &&
        score.length > 0 &&
        myScore.length > 0 && (
          <>
            {experiment.prolificCode &&
              experiment.prolificCode !== "" &&
              isResult && (
                <Grid container>
                  <Grid item xs={12}>
                    <Alert severity="success" style={{ marginTop: "5px" }}>
                      Please copy this{" "}
                      <strong>{experiment.prolificCode}</strong> and paste in
                      the Prolific app.
                    </Alert>
                  </Grid>
                </Grid>
              )}
            <Grid container align="center" direction="column">
              <Grid item xs>
                <Box
                  mt={3}
                  p={2}
                  style={{
                    width: "400px",
                    minHeight: "200px",
                    background: "aliceblue",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {experiment.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "30px",
                    }}
                  >
                    <Box
                      fontSize="subtitle1"
                      // letterSpacing={-2}
                      component="span"
                      // fontWeight="fontWeightBold"
                    >
                      {`The similarity between the areas `}
                      {isResult ? "you" : "Participant"}
                      {` paid attention to those of other participants is `}
                      {myPercentage()}
                    </Box>
                  </Typography>
                  {responses.length > 1 && (
                    <ErrorBar1D
                      name={isResult ? "You" : "Participant"}
                      data={score}
                      myData={myScore}
                      text={true}
                      size={300}
                      dependentVar="score"
                      domainMin={min(score.map((d) => d["score"]))}
                      domainMax={max(score.map((d) => d["score"]))}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            {isResult && (
              <Grid
                container
                spacing={1}
                align="center"
                direction="column"
                style={{ marginTop: "10px" }}
              >
                <Grid item xs>
                  <Box width="400px" align="left">
                    <Typography variant="body1">
                      Share to social media
                    </Typography>
                    <Tooltip title="Twitter">
                      <TwitterShareButton
                        url={
                          window.location.href.split("response")[0] + "share"
                        }
                        title={
                          experiment.title +
                          " The similarity between the areas you paid attention to and other participants in this experiment is " +
                          myPercentage() +
                          " Try this! "
                        }
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </Tooltip>
                    <Box display="inline-block" ml={1}>
                      <Tooltip title="Facebook">
                        <FacebookShareButton
                          url={
                            window.location.href.split("response")[0] + "share"
                          }
                          quote={
                            experiment.title +
                            " The similarity between the areas you paid attention to and other participants in this experiment is " +
                            myPercentage() +
                            " Try this! "
                          }
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Typography
                variant="h6"
                display="block"
                style={{ marginTop: "40px" }}
              >
                Results
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                The following heatmaps shows all clicks aggregated per image and
                compare your pattern against all participants combined.
              </Typography>
            </Grid>

            <Grid container>
              <Grid container item spacing={3} direction="column">
                {charts.map((chart) => (
                  <Fragment key={chart.id}>
                    {/* <Grid item xs>
                      <Typography variant="body1" display="block" gutterBottom>
                        You took{" "}
                        <Box component="span" fontWeight="fontWeightBold" m={1}>
                          {data[task.id].myTime} seconds
                        </Box>{" "}
                        to complete the task. The median task completion time is{" "}
                        <Box component="span" fontWeight="fontWeightBold" m={1}>
                          {data[task.id].totalMedianTime} seconds
                        </Box>
                        .{" "}
                        {numOfResponses > 1
                          ? `There are total ` +
                            numOfResponses +
                            ` responses in this experiment.`
                          : `There is only 1 response in this experiment.`}
                      </Typography>
                    </Grid> */}

                    <Grid container item spacing={5}>
                      <Grid item xs={6}>
                        <Box mt={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            <Chip
                              color="primary"
                              variant="outlined"
                              label={isResult ? "You" : "Participant"}
                              icon={<PersonOutlineOutlinedIcon />}
                            />
                          </Typography>
                          <Box mt={1}>
                            <Heatmap
                              imgUrl={chart.source}
                              // data={data[task.id]}
                              data={myData.filter(
                                (d) => d.chartId === chart.id
                              )}
                              // .reduce((a, b) => a.clicks.concat(b.clicks))}
                              bubble={tasks[0].params.bubble}
                              numOfResponses={numOfResponses}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mt={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            <Chip
                              color="primary"
                              variant="outlined"
                              label="All Participants"
                              icon={<PeopleAltOutlinedIcon />}
                            />
                          </Typography>
                          <Box mt={1}>
                            <Heatmap
                              imgUrl={chart.source}
                              // data={data[task.id]}
                              data={data.filter((d) => d.chartId === chart.id)}
                              // .reduce((a, b) => a.clicks.concat(b.clicks))}
                              bubble={tasks[0].params.bubble}
                              numOfResponses={numOfResponses}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </>
        )}
    </>
  );
}

export default Result;
