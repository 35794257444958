import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_CHART,
  UPDATE_CHART,
  DELETE_CHART,
  DELETE_MEMORABILITY_FILLER,
  SET_UPLOAD_FILE,
  SET_UPLOAD_PROGRESS,
  SUCCESS_UPLOAD_FILE,
  FAILURE_UPLOAD_FILE,
  modifyFiles,
} from "actions";
import { RECEIVE_MEMORABILITY_FILLER } from "actions";
// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.charts.reduce((acc, chart) => {
        return {
          ...acc,
          [chart.id]: chart,
        };
      }, state);
    case CREATE_CHART:
    case UPDATE_CHART:
      return {
        ...state,
        [action.chartId]: {
          ...(state[action.chartId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_CHART: {
      let newState = {
        ...state,
      };
      delete newState[action.chartId];
      return newState;
    }
    case RECEIVE_MEMORABILITY_FILLER: // necessary when directly accessing from url
      // console.log(action);
      return {
        ...state,
        // [action.images.id]: {
        //   ...state[action.images.id],
        //   ...action.images,
        fillers: {
          ...action.images,
        },
      };
    case DELETE_MEMORABILITY_FILLER:
      let newState = {
        ...state,
      };
      delete newState["fillers"];
      return newState;

    /// for uploading filler images
    case SET_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.attrs),
        },
      };

    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.attrs.id]: {
            ...state.fileProgress[action.attrs.id],
            progress: action.attrs.progress,
          },
        },
      };

    case SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.attrs]: {
            ...state.fileProgress[action.attrs],
            status: 1,
          },
        },
        fillers: {
          ...action.attrs.source,
        },
      };

    case FAILURE_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.attrs]: {
            ...state.fileProgress[action.attrs],
            status: 0,
            progress: 0,
          },
        },
      };
    default:
      return state;
  }
};
