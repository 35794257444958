import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_TUTORIAL,
  UPDATE_TUTORIAL,
} from "actions";

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.tutorials.reduce((acc, tutorial) => {
        return {
          ...acc,
          [tutorial.id]: tutorial,
        };
      }, state);
    case CREATE_TUTORIAL:
    case UPDATE_TUTORIAL:
      return {
        ...state,
        [action.tutorialId]: {
          ...(state[action.tutorialId] || {}),
          ...action.attrs,
        },
      };
    default:
      return state;
  }
};
