import React, { Fragment } from "react";

import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

/*
rows = [{key:value}, {key:value}]
*/

export default function PaginatedTable(props) {
  const { rows, pagination } = props;
  // console.log(rows);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          {/* {header === true && (
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <Fragment key={column.id + index}>
                      <TableCell>
                        <>
                          {column.label}
                          {column.tip !== "" && (
                            <Tooltip title={column.tip} interactive>
                              <HelpOutlineIcon fontSize="small" />
                            </Tooltip>
                          )}
                        </>
                      </TableCell>
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>
            )} */}
          <TableBody>
            {pagination ? (
              <>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Fragment key={index}>
                      <TableRow>
                        <TableCell align="left">{row.answer}</TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            ) : (
              <>
                {Object.values(rows).map((row, i) => (
                  <Fragment key={i}>
                    <TableRow>
                      <TableCell align="left">{row.answer}</TableCell>
                    </TableRow>
                  </Fragment>
                ))}
                {/* {type === "memorability" && (
                    <>
                      {Object.values(rows).map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">
                            <ChartStimuli type="small" chartId={row.id} />
                          </TableCell>
                          {!isResult && (
                            <TableCell align="left">{row.accuracy}</TableCell>
                          )}
                          {isResult && (
                            <TableCell align="left">{row.myHr}</TableCell>
                          )}
                          <TableCell align="left">{row.hr}%</TableCell>
                          {isResult && (
                            <TableCell align="left">{row.myFar}</TableCell>
                          )}
                          <TableCell align="left">{row.far}%</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )} */}
                {/* {type === "demographic" && (
                    <>
                      {rows.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{row.text}</TableCell>
                            <TableCell align="left">
                              {(row.count / nTotal).toFixed(2) * 100}%
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )} */}
                {/* {type === "perception" && (
                    <>
                      {Object.values(rows).map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{row.question}</TableCell>
                          {isResult && (
                            <TableCell align="left">
                              {row.ranking}/{nTotal}
                            </TableCell>
                          )}
                          {isResult && (
                            <TableCell align="left">{row.answer}</TableCell>
                          )}
                          {!isResult && (
                            <TableCell align="left">
                              {row.accuracyAvg}
                            </TableCell>
                          )}
                          {isResult && (
                            <TableCell align="left">{row.time}</TableCell>
                          )}
                          <TableCell align="left">{row.timeMedian}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )} */}
                {/* {type === "perception-indiv" && (
                    <>
                      {Object.values(rows).map((row, index) => (
                        <TableRow key={index}>
                          <TableCell aligh="left">{row.name}</TableCell>
                          <TableCell align="left">{row.question}</TableCell>
                          <TableCell align="left">{row.answer}</TableCell>
                          <TableCell align="left">{row.correct}</TableCell>
                          <TableCell align="left">{row.error}</TableCell>
                          <TableCell align="left">{row.time}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  )} */}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}
