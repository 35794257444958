import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_RESPONSE,
  UPDATE_RESPONSE,
  DELETE_RESPONSE,
} from 'actions';

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_RESPONSE:
    case UPDATE_RESPONSE:
      return {
        ...state,
        [action.responseId]: {
          ...(state[action.responseId] || {}),
          ...action.attrs,
        },
      };
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.responses.reduce((acc, response) => {
        return {
          ...acc,
          [response.id]: response,
        };
      }, state);
    case DELETE_RESPONSE: {
      let newState = {
        ...state,
      };
      delete newState[action.responseId];
      return newState;
    }
    default:
      return state;
  }
};
