import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TitleIntroductionLive from "components/TitleIntroductionLive";
// import ConsentLive from "components/ConsentLive";
import DemographicLive from "components/DemographicLive";
import PostSurveyLive from "components/PostSurveyLive";
import TutorialLive from "components/TutorialLive";
import PracticeLive from "components/PracticeLive";
import Screening from "components/Screening/Screening";
import Startline from "components/Startline";

import { makeStyles, Button, Typography, Grid } from "@material-ui/core";
import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

const ExperimentTemplateLive = (props) => {
  const { experimentId, previewMode, handleAnswers, handleSubmit, type } =
    props;

  const ExperimentComponent = props.experimentComponent;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const {
    // showConsent,
    showDemographicSurvey,
    showPostSurvey,
    showTutorial,
    showPractice,
    showScreening,
  } = useSelector((state) => ({
    // showConsent: experiment.showConsent,
    showDemographicSurvey: experiment.showDemographicSurvey,
    showPostSurvey: experiment.showPostSurvey,
    showTutorial: experiment.showTutorial,
    showPractice: experiment.showPractice,
    showScreening: experiment.screening.show,
  }));

  // for control next button's visibility and submit the response
  const [isValidSubmit, setIsValidSubmit] = useState(false);

  const getSteps = () => {
    return {
      Introduction: 0,
      // ...(showConsent ? { Consent: 1 } : {}),
      ...(showTutorial ? { Instruction: 1 } : {}),
      ...(showPractice ? { Practice: 2 } : {}),
      ...(EXPERIMENTAL_FEATURE && showScreening ? { "Screening Test": 3 } : {}),
      Startline: 4,
      Experiment: 5,
      ...(showDemographicSurvey ? { "Demographic Survey": 6 } : {}),
      ...(showPostSurvey ? { "Post Survey": 7 } : {}),
    };
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TitleIntroductionLive
            experimentId={experimentId}
            setIsValidSubmit={setIsValidSubmit}
            handleAnswers={handleAnswers}
          />
        );
      // case 1:
      //   return (
      //     <ConsentLive
      //       experimentId={experimentId}
      //       setIsValidSubmit={setIsValidSubmit}
      //       type={type}
      //     />
      //   );
      case 1:
        return (
          <TutorialLive
            experimentId={experimentId}
            type={type}
            previewMode={previewMode}
            setIsValidSubmit={setIsValidSubmit}
            handleAnswers={handleAnswers}
          />
        );
      case 2:
        return <PracticeLive type={type} setIsValidSubmit={setIsValidSubmit} />;
      case 3:
        return (
          <Screening
            experimentId={experimentId}
            live={true}
            previewMode={previewMode}
            setIsValidSubmit={setIsValidSubmit}
            handleAnswers={handleAnswers}
          />
        );
      case 4:
        return <Startline setIsValidSubmit={setIsValidSubmit} />;
      case 5:
        return (
          <ExperimentComponent
            experimentId={experimentId}
            previewMode={previewMode}
            handleAnswers={handleAnswers}
            handleNextStep={handleNextStep}
            setIsValidSubmit={setIsValidSubmit}
            isValidSubmit={isValidSubmit}
            handleSubmit={handleSubmit}
          />
        );
      case 6:
        return (
          <DemographicLive
            experimentId={experimentId}
            handleNextStep={handleNextStep}
            handleAnswers={handleAnswers}
            setIsValidSubmit={setIsValidSubmit}
            isValidSubmit={isValidSubmit}
            handleSubmit={handleSubmit}
          />
        );
      case 7:
        return (
          <PostSurveyLive
            experimentId={experimentId}
            handleNextStep={handleNextStep}
            handleAnswers={handleAnswers}
            setIsValidSubmit={setIsValidSubmit}
            isValidSubmit={isValidSubmit}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return "Unknown step";
    }
  };
  const steps = getSteps();
  // console.log(steps);
  const maxSteps = Object.keys(steps).length;

  const handleNextStep = () => {
    if (activeStep + 1 === maxSteps) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  // const handleBackStep = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  // console.log(activeStep, Object.keys(steps).length);
  // console.log(Object.values(steps)[activeStep]);

  useEffect(() => {
    if (activeStep === Object.keys(steps).length - 1) {
      // console.log(activeStep, Object.keys(steps).length - 1);
      setIsValidSubmit(true);
    }
  }, [activeStep, steps]);

  return (
    <>
      <div className={classes.root}>
        <Grid container align="center" direction="column">
          <Grid item xs>
            {getStepContent(Object.values(steps)[activeStep])}
          </Grid>

          <Grid container align="right" justifyContent="space-between">
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              style={{ marginTop: "30px" }}
            >
              {(Object.values(steps)[activeStep] === 0 ||
                Object.values(steps)[activeStep] === 1 ||
                Object.values(steps)[activeStep] === 2 ||
                Object.values(steps)[activeStep] === 3 ||
                Object.values(steps)[activeStep] === 4) && (
                <>
                  {/* <Button
                  disableElevation
                  disabled={activeStep === 0}
                  onClick={handleBackStep}
                  className={classes.button}
                >
                  Back
                </Button> */}
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={
                      activeStep === Object.keys(steps).length - 1
                        ? handleSubmit
                        : handleNextStep
                    }
                    className={classes.button}
                    disabled={!isValidSubmit || false}
                  >
                    {activeStep === Object.keys(steps).length - 1
                      ? "Show Result"
                      : "Next"}
                  </Button>
                </>
              )}

              <Typography variant="overline" style={{ marginTop: "12px" }}>
                Page {activeStep + 1} of {maxSteps}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ExperimentTemplateLive;
