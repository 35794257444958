/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";

import {
  requestMemorabilityFiller,
  deleteMemorabilityFiller,
} from "actions/charts";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import { calcNewImageSize } from "utils/bubbleView";
import {
  // randomSplit,
  // randomImageSequence,
  randomizeImageSequence,
} from "utils/memorability";

import {
  Typography,
  CircularProgress,
  Backdrop,
  MobileStepper,
  Button,
  Box,
  makeStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
// import { useHistory } from "react-router-dom";
// const fillerName = 'chi2020';

const useStyles = makeStyles((theme) => ({
  overlayLarge: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  fit: {
    width: "100%",
    height: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StyledMobileStepper = withStyles({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    backgroundColor: "white",
  },
  progress: {
    width: "100%",
  },
})(MobileStepper);

let image, canvas;
var imgCount = 120;
var combinedImgSet = null;
var correctImgSet = [];
var incorrectImgSet = [];
let falseAlarmImgSet = [];
let fillers = [];
let vigilances = [];
let targets = [];
var firstRound = [];
let isFirst = false;
let ccc = 0;
let answerStatus = "neutral"; // "correct", "incorrect", "neutral"

function Live(props) {
  // var combinedImgSet = null;

  // var RECOG_INTERVAL = 1.0;
  // var FIXATE_TIME = 1.5;
  var imgIndex = -1;
  // var countDown = RECOG_INTERVAL;
  var countdown = 5;
  const [countDown, setCountDown] = useState(5);

  var elapsed = 0.0;

  const {
    experimentId,
    handleAnswers,
    handleNextStep,
    setIsValidSubmit,
    isValidSubmit,
    handleSubmit,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedTask, setSelectedTask] = useState(null);

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getCharts = makeGetCharts();
  const { targetImages, fillerImages } = useSelector((state) => ({
    targetImages: getCharts(state, {
      taskId: tasks[0].id,
    }),
    fillerImages: state.charts.fillers,
  }));

  // const [isLoading, setIsLoading] = useState(!fillerImages);
  const [isLoading, setIsLoading] = useState(true);

  const [displayTime] = useState(parseFloat(tasks[0].params.displayTime));
  const [pauseTime] = useState(parseFloat(tasks[0].params.pauseTime));
  const [fillerUpload] = useState(tasks[0].params.fillerUpload);

  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [isStart, setIsStart] = useState(false);

  const [response, setResponse] = useState({
    charts: {},
  });

  useEffect(() => {
    setIsValidSubmit(false);
  }, []);

  const canvasEl = useRef(null);
  const canvasContainerEl = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);

  var recogTimer;
  // for stepper

  // const numOfTargetsAndVigilance = 12;
  // const numOfFillers = 96; // 96 (original), 36 (short version)
  const NUMBER_OF_FILLER_IMAGES = imgCount;
  const NUMBER_OF_VIGILANCE = 15;

  // numOfFillers + numOfTargetsAndVigilance - targetImages.length;
  // target + vigilance = 12 images
  // 12 images * 2 repeat + 96 images = 120 images

  // const sequenceParams = {
  //   target: [0, 28, 91, 109], // min: 0, max: 28, min2: 91, max2: 109
  //   vigilance: [1, 112, 1, 7], //{ min: 1, max: 112, min2: 1, max2: 7 },
  // };

  useEffect(() => {
    if (tasks) {
      setSelectedTask(tasks[0].id);
    }
    async function fetchData() {
      if (isLoading) {
        await dispatch(requestMemorabilityFiller(NUMBER_OF_FILLER_IMAGES));
        // await dispatch(
        //   requestMemorabilityFiller(
        //     NUMBER_OF_FILLER_IMAGES,
        //     fillerUpload,
        //     experimentId
        //   )
        // );
      }
      if (fillerImages !== undefined) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      dispatch(deleteMemorabilityFiller());
      clearTimeout(recogTimer);
    };
  }, []);

  useEffect(() => {
    if (response.charts.param && selectedTask !== null) {
      // console.log('submit', response);
      setIsValidNextStep(true);
      handleAnswers(response);
    } else {
    }
  }, [response]);

  function pressSpaceBar(e) {
    // console.log(e);
    if (e.keyCode === 32) e.preventDefault(); //disable spacebar scrolling
    OnKeyPress(e.keyCode);
  }

  useEffect(() => {
    if (!isLoading) {
      // let fillerImagesFilename = Object.values(fillerImages).map((v) => v);
      // let splitted = randomSplit(
      //   fillerImagesFilename,
      //   targetImages.length,
      //   numOfTargetsAndVigilance
      // );
      // fillers = splitted[0].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      // vigilances = splitted[1].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      targets = Object.values(targetImages).map((target) => {
        return {
          type: "t",
          id: target.id,
          source: target.source,
        };
      });
      // console.log(targets);

      [combinedImgSet, vigilances, fillers] = randomizeImageSequence(
        [91, 109], // target spacing
        [1, 7], // vigilance spacing
        _.shuffle(targets),
        _.shuffle(fillerImages),
        imgCount,
        NUMBER_OF_VIGILANCE
      );
      console.log(vigilances, fillers);

      // preload images
      for (var i = 0; i < fillers.length; i++) {
        const imageObj = new Image();
        imageObj.src = fillers[i].source;
      }

      for (var i = 0; i < vigilances.length; i++) {
        const imageObj = new Image();
        imageObj.src = vigilances[i].source;
      }

      for (var i = 0; i < targets.length; i++) {
        const imageObj = new Image();
        imageObj.src = targets[i].source;
      }

      // imgCount = fillers.length + vigilances.length * 2 + targets.length * 2;
      if (
        imgCount !==
        fillers.length + vigilances.length * 2 + targets.length * 2
      ) {
        console.log(fillers.length, vigilances.length, targets.length);
        throw "imgCount is not matched with fillers.length + vigilances.length * 2 + targets.length * 2.";
      }
      setTotalSteps(imgCount);

      // combinedImgSet = [];
      // for (var i = 0; i < imgCount; i++) {
      //   combinedImgSet[i] = "";
      // }

      // console.log(combinedImgSet);

      // fillers = splitted[0].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      // vigilances = splitted[1].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      // const sequenceParams = {
      //   target: { min: 3, max: 10, min2: 31, max2: 49 },
      //   vigilance: { min: 1, max: 52, min2: 1, max2: 7 },
      // };

      //       randomImageSequence(
      //         sequenceParams["target"],
      //         combinedImgSet,
      //         _.shuffle(targets)
      //       );
      //       randomImageSequence(
      //         sequenceParams["vigilance"],
      //         combinedImgSet,
      //         _.shuffle(vigilances)
      //       );
      //       console.log(combinedImgSet);
      //       /* All the repeat images appeared 91-109 images apart.
      // Vigilance images Repeated twice with a spacing of 1-7 images */
      //       var j = 0;
      //       fillers = _.shuffle(fillers);
      //       for (var i = 0; i < imgCount; i++) {
      //         if (combinedImgSet[i] === "") {
      //           combinedImgSet[i] = fillers[j];
      //           j++;
      //         }
      //       }

      document.addEventListener("keydown", pressSpaceBar);
    } else {
      if (fillerImages) {
        if (Object.keys(fillerImages).length > 0) {
          setIsLoading(false);
        }
      }
    }

    return () => {
      document.removeEventListener("keydown", pressSpaceBar);
    };
  }, [isLoading]);

  const recordResponse = (selectedTask, ans) => {
    setResponse({
      ...response,
      charts: {
        ...response.charts,
        param: {
          target: ans.target,
          filler: ans.filler,
          vigilance: ans.vigilance,
          correct: ans.correct,
          incorrect: ans.incorrect,
          falseAlarm: ans.falseAlarm,
          firstRound: ans.firstRound,
        },
      },
    });
  };

  // const handleStart = () => {
  //   recognition();
  //   setIsStart(true);
  // };
  useEffect(() => {
    recognition();
    setIsStart(true);
  }, []);

  function OnKeyPress(keyCode) {
    if (keyCode === 32) {
      // user has pressed space
      try {
        const imgEl = canvasEl.current;
        const id = imgEl.dataset.id;

        // const ctx = imgEl.getContext("2d");
        // ctx.lineWidth = 5;
        // ctx.strokeStyle = 'rgba(76,175,80,1)';
        // ctx.strokeRect(0, 0, imgEl.width, imgEl.height);
        // ctx.stroke();

        if (id !== "") {
          var isRepeatable = _.isUndefined(
            _.find(fillers, function (obj) {
              return obj._id === id;
            })
          );
          if (isFirst) {
            // console.log("false alarm", id, vigilances);
            falseAlarmImgSet.push(id);
            // ctx.strokeStyle = "rgba(244,68,54,1)";
            answerStatus = "false alarm";
          } else if (!isFirst && isRepeatable) {
            // console.log("correct", id, vigilances);
            correctImgSet.push(id);
            // ctx.strokeStyle = "rgba(76,175,80,1)";
            answerStatus = "correct";
          }
        }

        // ctx.strokeRect(0, 0, imgEl.width, imgEl.height);
        // ctx.stroke();

        return;
      } catch (error) {
        // console.log(error, 'imageRender');
        clearTimeout(recogTimer);
      }
    }
  }

  function recognition() {
    //update countdown timer
    recogTimer = setInterval(function () {
      elapsed += 0.5;
      if (imgIndex === -1) {
        if (elapsed % 1 === 0) {
          countdown -= 1;
          setCountDown((prev) => prev - 1);
          if (countdown === -1) {
            imgIndex = 0;
            elapsed = 0.0;
          }
        }
      } else {
        // console.log(imgCount, imgIndex);
        // if (elapsed === RECOG_INTERVAL) {
        if (elapsed === displayTime) {
          imageRender(canvasEl, "", "", "clear");
          if (imgCount === 0) {
            //move on to next stage
            clearTimeout(recogTimer);
            setIsValidNextStep(true);
            recordResponse(selectedTask, {
              target: targets.map((target) => target.id),
              filler: fillers.map((filler) => filler.id),
              vigilance: vigilances.map((vigilance) => vigilance.id),
              correct: _.uniq(correctImgSet),
              incorrect: _.uniq(incorrectImgSet),
              falseAlarm: _.uniq(falseAlarmImgSet),
              firstRound: _.uniq(firstRound),
            });
            // console.log(ccc);

            // window.removeEventListener("keydown", pressSpaceBar);
            return;
          }
        }

        //change image
        // if (elapsed === RECOG_INTERVAL + FIXATE_TIME) {
        if (elapsed === pauseTime + displayTime) {
          let first = _.isUndefined(
            _.find(firstRound, function (obj) {
              return obj === combinedImgSet[imgIndex].id;
            })
          );
          // console.log(first, firstRound);
          if (first) {
            firstRound.push(combinedImgSet[imgIndex].id);
            isFirst = true;
          } else {
            isFirst = false;
            ccc++;
          }

          imageRender(
            canvasEl,
            combinedImgSet[imgIndex].source,
            combinedImgSet[imgIndex].id,
            "draw"
          );

          // countDown = RECOG_INTERVAL;
          imgCount -= 1;
          imgIndex += 1;
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          elapsed = 0.0;
          if (imgIndex === combinedImgSet.length) {
            imgIndex = 0;
          }
        }
      }
    }, 500);
  }

  const imageRender = (canvasEl, imgSrc, imgId, mode) => {
    canvas = canvasEl.current;
    image = new Image();
    try {
      const ctx = canvas.getContext("2d");

      if (mode === "clear") {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // console.log(answerStatus);

        if (answerStatus === "neutral") {
          ctx.font = "80px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText(".", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "incorrect") {
          ctx.font = "50px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText("x", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "false alarm") {
          ctx.font = "50px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText("x", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "correct") {
          ctx.font = "bold 50px Arial";
          ctx.fillStyle = "green";
          ctx.textAlign = "center";
          ctx.fillText("+", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        }
      } else if (mode === "draw") {
        image.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const newSize = calcNewImageSize(
            image.naturalWidth,
            image.naturalHeight,
            canvas.width,
            canvas.height
          );
          ctx.drawImage(image, 0, 0, newSize.width, newSize.height);
        };
        image.src = imgSrc;
        canvas.dataset.id = imgId;
      } else {
      }
    } catch (error) {
      // console.log(error, 'imageRender');
      clearTimeout(recogTimer);
      // window.removeEventListener("keydown", pressSpaceBar);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        selectedTask !== null && (
          <>
            {/* {!isStart ? (
              <Grid container align="left">
                <Grid item xs>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    onPointerUp={handleStart}
                  >
                    Start
                  </Button>
                </Grid>
              </Grid>
            ) : null} */}
            <Typography variant="body1" display="block" gutterBottom>
              {isValidNextStep
                ? "Done!"
                : "Each chart appears for " +
                  displayTime +
                  "s and disappears for " +
                  pauseTime +
                  "s. When a chart appears for the second time, press space bar."}
            </Typography>

            <Grid container align="center" direction="column">
              {!isValidNextStep && (
                <>
                  <Grid item xs>
                    ({activeStep} / {totalSteps})
                  </Grid>
                  <Grid item xs>
                    <Box mt={2} mb={2}>
                      <StyledMobileStepper
                        variant="progress"
                        steps={totalSteps}
                        position="static"
                        activeStep={activeStep}
                        color="secondary"
                      />
                    </Box>
                  </Grid>
                </>
              )}
              {isStart && countDown > -1 && (
                <Grid item xs>
                  <Typography
                    variant="h6"
                    display="block"
                    color="secondary"
                    gutterBottom
                  >
                    Countdown: {countDown}
                  </Typography>
                </Grid>
              )}
              {isValidNextStep ? (
                <Grid item xs>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      size="small"
                      // onPointerUp={
                      //   isValidSubmit && isValidNextStep
                      //     ? handleSubmit
                      //     : isValidNextStep
                      //     ? handleNextStep
                      //     : handleStart
                      // }
                      onPointerUp={
                        isValidSubmit && isValidNextStep
                          ? handleSubmit
                          : handleNextStep
                      }
                    >
                      {/* {isValidSubmit && isValidNextStep
                      ? "See Result"
                      : isValidNextStep
                      ? "Go to Next Page"
                      : "Start"} */}
                      {isValidSubmit && isValidNextStep
                        ? "See Result"
                        : "Go to Next Page"}
                    </Button>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs>
                <div ref={canvasContainerEl}>
                  <canvas ref={canvasEl} width={512} height={512} />
                </div>
              </Grid>
            </Grid>
          </>
        )
      )}
    </div>
  );
}

export default Live;
