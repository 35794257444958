import {
  CREATE_EXPERIMENT,
  UPDATE_EXPERIMENT,
  DELETE_EXPERIMENT,
  DUPLICATE_EXPERIMENT,
  RECEIVE_EXPERIMENTS,
  RECEIVE_EXPERIMENT_CONTENT,
} from "actions";

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENTS: // populate the experiments from the server
      return action.experiments.reduce((acc, experiment) => {
        return {
          ...acc,
          [experiment.id]: experiment,
        };
      }, state);

    case RECEIVE_EXPERIMENT_CONTENT: // necessary when directly accessing from url
      return {
        ...state,
        [action.experiment.id]: {
          ...state[action.experiment.id],
          ...action.experiment,
        },
      };
    case CREATE_EXPERIMENT:
    case UPDATE_EXPERIMENT:
      return {
        ...state,
        [action.experimentId]: {
          ...(state[action.experimentId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_EXPERIMENT: {
      let newState = {
        ...state,
      };
      delete newState[action.experimentId];
      return newState;
    }
    default:
      return state;
  }
};
