import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createOption,
  updateOption,
  deleteOption,
  makeGetOptions,
} from "actions/options";
import { updateQuestion } from "actions/questions";
import { selectOption } from "actions/ui";

import { DEFALUT_QUESTION_LIST } from "utils/textResources";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";

// import Divider from "@material-ui/core/Divider";
// import { makeStyles } from "@material-ui/core/styles";
// import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
// import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// const useStyles = makeStyles((theme) => ({
//   content: {
//     marginTop: "5px",
//     "& .MuiTextField-root": {
//       margin: theme.spacing(0.5),
//     },
//   },
//   margin: {
//     margin: theme.spacing(1),
//   },
//   withoutLabel: {
//     marginTop: theme.spacing(3),
//   },
//   textField: {
//     // width: "25ch",
//   },
// }));

// const YellowTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     // boxShadow: theme.shadows[1],
//     fontSize: 13,
//     color: '#f44336',
//   },
// }))(Tooltip);

function Question(props) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const getOptions = makeGetOptions();
  const { questionId, type, readOnly } = props;

  const { question, selectedQuestion, options, selectedOption } = useSelector(
    (state) => ({
      question: state.questions[questionId],
      selectedQuestion: state.ui.selectedQuestion,
      options: getOptions(state, props),
      selectedOption: state.ui.selectedOption,
    })
  );
  // console.log(question, questionId, selectedQuestion);
  const [showError, setShowError] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(null);
  // const [inputAnswer, setInputAnswer] = useState({
  //   answer: '',
  //   checked: false,
  // });

  const handleInputAnswerChange = (event) => {
    dispatch(updateQuestion(questionId, { answer: event.target.value }));
    // setInputAnswer({ ...inputAnswer, [prop]: event.target.value });
  };
  // const handleInputAnswerChecked = () => {
  //   // setInputAnswer({ ...inputAnswer, checked: !inputAnswer.checked });
  //   return question.answer !== undefined;
  // };
  // const handleInputAnswerCheckedChange = (event) => {
  //   dispatch(updateQuestion(questionId, { allowTextAnswer: event.target.value }));
  // };
  // const handleMouseDownInputAnswer = (event) => {
  //   event.preventDefault();
  // };
  useEffect(() => {
    function selectOptionSanityCheck() {
      if (options.length > 0) {
        if (!options.find((o) => o.id === selectedOption)) {
          dispatch(selectOption(options[0].id));
        }
      } else {
        dispatch(selectOption(null));
      }
    }
    selectOptionSanityCheck();
  }, []);

  const handleCreateOption = () => {
    dispatch(createOption(questionId, options, {}, false));
  };
  const handleDeleteOption = (optionId) => {
    // console.log(selectedOption, optionId) // selectedOption == null
    if (selectedOption === optionId) {
      // change
      let index = options.findIndex((o) => o.id === optionId);
      if (index + 1 < options.length) {
        dispatch(selectOption(options[index + 1].id));
      } else if (index - 1 >= 0) {
        dispatch(selectOption(options[index - 1].id));
      } else {
        dispatch(selectOption(null));
      }
    }

    // if deleted option was an answer, it should be removed from question.answer.
    try {
      if (question.type === "checkboxes") {
        // console.log(question);
        if (
          Object.keys(question.answer).filter(
            (k) => k === optionId && question.answer[k] === true
          ).length > 0
        ) {
          dispatch(
            updateQuestion(questionId, {
              answer: {
                ...question.answer,
                [optionId]: false,
              },
            })
          );
          // question.answer[optionId] = false;
        }
      } else {
        if (question.answer === optionId) {
          // question.answer = null;
          dispatch(
            updateQuestion(questionId, {
              answer: null,
            })
          );
        }
      }
    } catch (TypeError) {}
    dispatch(deleteOption(optionId));
  };

  function initOptionText() {
    let out = {};

    for (let i = 0; i < options.length; i++) {
      out[options[i].id] = options[i].text;
    }
    // console.log(options, out);
    return out;
  }

  const [optionsText, setOptionsText] = useState(initOptionText());

  const handleOptionChange = (id, event) => {
    // console.log(event.target.value);
    // dispatch(updateOption(id, { text: event.target.value }));
    setOptionsText({
      ...optionsText,
      [id]: event.target.value,
    });
  };
  // console.log(optionsText);
  const handleOptionUpdate = (id, event) => {
    dispatch(updateOption(id, { text: optionsText[id] }));
  };

  useEffect(() => {
    setOptionsText(initOptionText());
    // console.log(options);
  }, [question]);

  const [questionText, setQuestionText] = useState(question.text);

  const handleQuestionChange = (event) => {
    setQuestionText(event.target.value);
  };

  const handleQuestionUpdate = () => {
    dispatch(updateQuestion(questionId, { text: questionText }));
  };

  useEffect(() => {
    setQuestionText(question.text);
    // console.log(question.text);
  }, [question, questionId]);

  // const handleAllowMultipleAnswers = (event) => {
  //   dispatch(
  //     updateQuestion(questionId, { allowMultipleAnswers: event.target.checked })
  //   );
  // };

  const handleOptionCheckboxChange = (event) => {
    // checkbox
    dispatch(
      updateQuestion(questionId, {
        answer: {
          ...question.answer,
          [event.target.value]: event.target.checked,
        },
      })
    );
  };

  const handleOptionCheckboxChecked = (option) => {
    try {
      return question.answer[option.id];
    } catch (TypeError) {
      return false;
    }
  };

  const handleOptionRadioChange = (event) => {
    dispatch(updateQuestion(questionId, { answer: event.target.value }));
  };

  const handleOptionRadioChecked = (option) => {
    try {
      return question.answer === option.id ? true : false;
    } catch (TypeError) {
      return false;
    }
  };

  useEffect(() => {
    if (
      question.answer === undefined ||
      question.answer === null ||
      Object.keys(question.answer).length === 0 ||
      Object.values(question.answer).every((v) => v === false) ||
      question.answer === ""
    ) {
      setShowError(true);
      // setErrorMsg('Please indicate the answer.');
    } else {
      // handleReleaseError();
      setShowError(false);
    }
  }, [options, question.answer]);

  const questionList = DEFALUT_QUESTION_LIST;

  return (
    <div>
      <Box mt={1}>
        {type !== "perceptionSurvey" ? (
          <>
            <TextField
              // placeholder="Type a question"
              label="Type a question"
              fullWidth
              // style={{ width: 400 }}
              multiline
              value={questionText === undefined ? "" : questionText}
              onChange={(e) => handleQuestionChange(e)}
              onBlur={handleQuestionUpdate}
              variant="filled"
              size="small"
              // InputLabelProps={{ shrink: true }}
              disabled={readOnly}
            />
          </>
        ) : (
          <>
            {readOnly ? (
              <TextField
                label="Type or select a question"
                multiline
                fullWidth
                value={questionText}
                variant="filled"
                size="small"
                disabled={readOnly}
              />
            ) : (
              <Autocomplete
                value={questionText}
                id="type-combo-box"
                size="small"
                options={questionList}
                onInputChange={(e, newInputValue) =>
                  setQuestionText(newInputValue)
                }
                onBlur={handleQuestionUpdate}
                // style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant="standard"
                    label="Type or select a question"
                    multiline
                    fullWidth
                    // placeholder={
                    //   questionList[0] === questionText
                    //     ? questionList[0]
                    //     : undefined
                    // }
                    value={questionText === undefined ? "" : questionText}
                    onChange={(e) => handleQuestionChange(e)}
                    onBlur={handleQuestionUpdate}
                    variant="filled"
                    size="small"
                    // InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            )}
          </>
        )}
      </Box>

      {question.type === "paragraph" && (
        <Box mt={2}>
          {options.map((option) => (
            <Fragment key={option.id}>
              <TextField
                // variant="outlined"
                fullWidth
                multiline
                rows={5}
                // value={option.text}
                // onChange={(e) => handleOptionChange(option.id, e)}
                placeholder="Participants enter their response here."
                disabled
                variant="filled"
                size="small"
              />
            </Fragment>
          ))}
        </Box>
      )}

      {question.type === "short-answer" && (
        <Box mt={2}>
          {options.map((option) => (
            <Grid container direction="row" key={option.id} spacing={2}>
              <Grid item xs={8}>
                <TextField
                  // variant="outlined"
                  fullWidth
                  style={{ marginTop: "4px" }}
                  // style={{ width: "60%" }}
                  // value={option.text}
                  // onChange={(e) => handleOptionChange(option.id, e)}
                  placeholder="Participants enter their response here."
                  disabled
                  type="number"
                  label="Number only"
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel>Answer</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type="number"
                    value={question.answer === undefined ? "" : question.answer}
                    onChange={(e) => handleInputAnswerChange(e)}
                    // disabled={question.answer === undefined}
                    // startAdornment={
                    //   <InputAdornment position="start">
                    //     <IconButton
                    //       // onClick={(e) => handleInputAnswerCheckedChange(e)}
                    //       // onMouseDown={handleMouseDownInputAnswer}
                    //       edge="start"
                    //     >
                    //       {question.answer !== undefined ? (
                    //         <CheckBoxIcon color="secondary" />
                    //       ) : (
                    //         <CheckBoxOutlineBlankIcon />
                    //       )}
                    //     </IconButton>
                    //   </InputAdornment>
                    // }
                    endAdornment={
                      showError && (
                        <Tooltip title="Indicate an answer" interactive>
                          <Button style={{ marginLeft: "-30px" }}>
                            <ErrorOutlineIcon color="secondary" />
                          </Button>
                        </Tooltip>
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}

      {(question.type === "choice" || question.type === "checkboxes") && (
        <>
          <Box mt={1}>
            {type === "perceptionSurvey" && (
              <Typography variant="body2" display="block" gutterBottom>
                Add answer options and specify the correct one among them.{" "}
              </Typography>
            )}
            {options.map((option) => (
              <Box mt={1} key={option.id}>
                <TextField
                  placeholder="Type a option"
                  label="Option"
                  // fullWidth
                  style={{ width: "50%" }}
                  value={optionsText[option.id]}
                  onChange={(e) => handleOptionChange(option.id, e)}
                  onBlur={(e) => handleOptionUpdate(option.id, e)}
                  variant="filled"
                  size="small"
                  disabled={readOnly}
                />
                {!readOnly && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    onPointerUp={() => handleDeleteOption(option.id)}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                )}

                {type === "perceptionSurvey" && !readOnly && (
                  <>
                    <FormControlLabel
                      style={{ marginLeft: "5px" }}
                      key={option.id}
                      label="Answer"
                      value={option.id}
                      control={
                        question.type === "choice" ? (
                          <Radio
                            checked={handleOptionRadioChecked(option)}
                            value={option.id}
                            onChange={(e) => handleOptionRadioChange(e)}
                          />
                        ) : (
                          <Checkbox
                            checked={handleOptionCheckboxChecked(option)}
                            value={option.id}
                            onChange={(e) => handleOptionCheckboxChange(e)}
                          />
                        )
                      }
                    />

                    {showError && (
                      <Tooltip title="Indicate an answer" interactive>
                        <Button style={{ marginLeft: "-30px" }}>
                          <ErrorOutlineIcon color="secondary" />
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
          {!readOnly && (
            <Box mt={2}>
              <Button
                variant="outlined"
                color="primary"
                onPointerUp={() => handleCreateOption()}
              >
                Add Option
              </Button>
            </Box>
          )}
        </>
      )}

      {/* {showError && (
        <Snackbar open={errorMsg !== null} autoHideDuration={1000}>
          <Alert onClose={() => handleReleaseError()} variant="filled" severity="warning">
            {errorMsg}
          </Alert>
        </Snackbar>
      )} */}
    </div>
  );
}

export default Question;
