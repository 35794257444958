import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

import ChartStimuli from "components/ChartStimuli";

import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";
import { ErrorBar1D } from "utils/Charts";
import { rank, min, max, sum } from "utils/stats";
import {
  Typography,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  parseMemorabilityData,
  parseMemorabilityDataByChart,
} from "utils/dataProcessing";
import {
  personalizedResultShareMsg,
  personalizedResultSummaryMsg,
} from "utils/textResources";


function Result(props) {
  const { experimentId, responseId, responses, isResult } = props;
  // const classes = useStyles();

  const [data, setData] = useState([]);
  const [myData, setMyData] = useState([]);

  const [dataByChart, setDataByChart] = useState([]);
  const [myDataByChart, setMyDataByChart] = useState([]);

  const numOfResponses = Object.keys(responses).length;
  // const rid = "response_" + responseId;
  let index = responses.findIndex((p) => p.id === responseId);
  // const myResponse = responses[index];

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));
  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseMemorabilityData(
          responses,
          false,
          responseId
        );
        setMyData(result[0]);
        setData(result[1]);
      }
    }
    fetch();
  }, [responses.length]);

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseMemorabilityDataByChart(
          responses,
          charts,
          false,
          responseId
        );
        setMyDataByChart(result[0]);
        setDataByChart(result[1]);
      }
    }
    fetch();
  }, [responses.length]);

  // console.log(myData, myDataByChart, dataByChart);
  // console.log(data, myData);

  const myPercentage = () => {
    const num =
      rank(
        data.map((d) => d.score),
        myData[0].score,
        "descending"
      ) / numOfResponses;

    return Number(num).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 1,
    });
  };

  return (
    <>
      {data.length > 0 && (
        <>
          <>
            {isResult &&
              experiment.prolificCode &&
              experiment.prolificCode !== "" && (
                <Grid container>
                  <Grid item xs={12}>
                    <Alert severity="success" style={{ marginTop: "5px" }}>
                      Please copy this{" "}
                      <strong>{experiment.prolificCode}</strong> and paste in
                      the Prolific app.
                    </Alert>
                  </Grid>
                </Grid>
              )}
            <Grid container align="center" direction="column">
              <Grid item xs>
                <Box
                  mt={3}
                  p={2}
                  style={{
                    width: "400px",
                    minHeight: "200px",
                    background: "aliceblue",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {experiment.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "10px",
                      marginBottom: "30px",
                    }}
                  >
                    <Box
                      fontSize="subtitle1"
                      // letterSpacing={-2}
                      component="span"
                      // fontWeight="fontWeightBold"
                    >
                      {personalizedResultSummaryMsg(isResult, myPercentage())}
                    </Box>
                  </Typography>
                  <ErrorBar1D
                    name={isResult ? "You" : "Participant"}
                    data={data}
                    myData={myData}
                    text={true}
                    size={300}
                    dependentVar="score"
                    domainMin={min(data.map((d) => d["score"]))}
                    domainMax={max(data.map((d) => d["score"]))}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
          {isResult && (
            <Grid
              container
              spacing={1}
              align="center"
              direction="column"
              style={{ marginTop: "10px" }}
            >
              <Grid item xs>
                <Box width="400px" align="left">
                  <Typography variant="body1" gutterBottom>
                    Share to social media
                  </Typography>
                  <Tooltip title="Twitter">
                    <TwitterShareButton
                      url={window.location.href.split("response")[0] + "share"}
                      title={
                        experiment.title +
                        personalizedResultShareMsg(myPercentage())
                      }
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </Tooltip>
                  <Box display="inline-block" ml={1}>
                    <Tooltip title="Facebook">
                      <FacebookShareButton
                        url={
                          window.location.href.split("response")[0] + "share"
                        }
                        quote={
                          experiment.title +
                          personalizedResultShareMsg(myPercentage())
                        }
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs>
              <Box mt={2}>
                <Typography variant="body1" display="block" gutterBottom>
                  {numOfResponses > 1 ? (
                    <>
                      <strong>{numOfResponses}</strong> people participated in
                      this experiment.
                    </>
                  ) : (
                    <>
                      So far, <strong>only you</strong> participated in this
                      experiment.
                    </>
                  )}
                  You remembered{" "}
                  <strong>
                    {myData[0].nCorrect} /&nbsp;
                    {myData[0].nTarget + myData[0].nVigilance}
                  </strong>{" "}
                  repeated images. You also falsely recognized{" "}
                  <strong>
                    {myData[0].nFalseAlarm} /&nbsp;
                    {myData[0].nFiller +
                      myData[0].nVigilance +
                      myData[0].nTarget}{" "}
                  </strong>
                  first-presented images as repeated.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Typography
            variant="h6"
            display="block"
            style={{ marginTop: "40px" }}
          >
            Results
          </Typography>
          <Box mt={1}>
            <Typography variant="subtitle1" display="block" gutterBottom>
              The following table shows your hit rate per image and compare your
              result against all participants combined.
            </Typography>
          </Box>

          {myDataByChart.length > 0 && dataByChart.length > 0 && (
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="inherit">
                          Target visualization
                        </TableCell>
                        <TableCell align="right">Did you recognize?</TableCell>
                        <TableCell align="right">
                          How many participants recognized?
                        </TableCell>
                        <TableCell align="right">
                          How many participants falsely recognized?
                        </TableCell>
                        <TableCell align="right">Memorability score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {charts.map((chart) => (
                          <TableRow key={chart.id}>
                            <TableCell align="inherit">
                              <ChartStimuli type="tiny" chartId={chart.id} />
                            </TableCell>
                            <TableCell align="right">
                              {
                                myDataByChart.filter(
                                  (i) => i.chartId === chart.id
                                )[0].correct
                              }
                            </TableCell>
                            <TableCell align="right">
                              {Number(
                                dataByChart.filter(
                                  (d) => d.chartId === chart.id
                                )[0].hr
                              ).toLocaleString(undefined, {
                                style: "percent",
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell align="right">
                              {Number(
                                dataByChart.filter(
                                  (d) => d.chartId === chart.id
                                )[0].far
                              ).toLocaleString(undefined, {
                                style: "percent",
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell align="right">
                              {Number(
                                dataByChart.filter(
                                  (d) => d.chartId === chart.id
                                )[0].score
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );

}

export default Result;
