/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";

import ChartStimuli from "components/ChartStimuli";

import { ErrorBar1D } from "utils/Charts";
import { average, standardDeviation, median, min, max, sum } from "utils/stats";
import {
  parseMemorabilityDataByChart,
  parseMemorabilityDataByResponse,
  parseMemorabilityData,
} from "utils/dataProcessing";

import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import {
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ltqnorm } from "utils/memorability";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  tablerow: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.48)",
  },
}));
// const columns = [
//   { id: "target", label: "Target Image", tip: "" },
//   {
//     id: "accuracy",
//     label: "Average Accuracy",
//     tip: "Average accuracy across participants",
//   },
//   {
//     id: "far",
//     label: "False Alarm Rate",
//     tip: "How many times participants responded on the first presentation of the image.",
//   },
//   {
//     id: "hr",
//     label: "Hit Rate",
//     tip: "The proportion of times participants responded on the second (repeat) presentation of the image.",
//   },
//   // { id: 'score', label: 'Memorability Score' },
// ];

function Dashboard(props) {
  const classes = useStyles();
  const { experimentId, responses, demographicData, demographicQuestions } =
    props;
  const numOfResponses = Object.keys(responses).length;

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));
  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  const chartIds = Object.values(charts).map((c) => c.id);

  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseMemorabilityData(responses, charts);
        setData(result);
      }
    }
    fetch();
  }, [responses.length]);

  const [dataByChart, setDataByChart] = useState({});
  useEffect(() => {
    async function fetchByChart() {
      if (responses.length > 0) {
        const result = await parseMemorabilityDataByChart(responses, charts);
        setDataByChart(result);
      }
    }
    fetchByChart();
  }, [responses.length]);

  const [dataByResponse, setDataByResponse] = useState({});
  useEffect(() => {
    async function fetchByResponse() {
      if (responses.length > 0) {
        const result = await parseMemorabilityDataByResponse(responses, charts);
        setDataByResponse(result);
      }
    }
    fetchByResponse();
  }, [responses.length]);

  const [demographicColumn, setDemographicColumn] = useState({});
  const handleDemographicFilter = (e, value) => {
    if (value === null) value = {};
    setDemographicColumn(value);
  };

  const initialColumnNames = [
    "Visualization",
    "False Alarm Rate (%)",
    "Hit Rate (%)",
    "Memorability Score",
  ];
  const [columnNames, setColumnNames] = useState(initialColumnNames);

  useEffect(() => {
    if (Object.keys(demographicColumn).length > 0) {
      // add demographic questions into TableHead after 'Condition'
      let cNames = [
        ...initialColumnNames.slice(0, 1),
        demographicColumn.text,
        ...initialColumnNames.slice(1),
      ];
      setColumnNames(cNames);
    } else {
      setColumnNames(initialColumnNames);
    }
  }, [demographicColumn]);

  const calcFalseAlarmRate = (arr) => {
    // console.log(arr);
    const values = arr.map((a) => a.fa);
    return sum(values) / values.length;
  };
  const calcHitRate = (arr) => {
    const values = arr.map((a) => a.hit);
    return sum(values) / values.length;
  };
  const calcMemorabilityScore = (arr) => {
    return (
      ltqnorm(calcHitRate(arr) / 100) - ltqnorm(calcFalseAlarmRate(arr) / 100)
    );
  };

  const sortedDataByChart =
    dataByChart.length > 0 &&
    dataByChart.sort((a, b) =>
      a.score < b.score ? 1 : -1
    );
  // console.log(demographicData);
  function renderTableHeader(el) {
    switch (el) {
      case "False Alarm Rate (%)":
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            False Alarm Rate (%)&nbsp;
            <Tooltip title="False alarm rate indicates the proportion of participants falsely responded on the first appearances of visualizations.">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        );
      case "Hit Rate (%)":
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            Hit Rate (%)&nbsp;
            <Tooltip title="Hit rate indicates the proportion of participants successfully responded on the second repeated appearances of visualizations.">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        );
      case "Memorability Score":
        return (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            Memorability Score&nbsp;
            <Tooltip title=" Memorability score takes into account both Hit rate and False alarm rate. A higher hit rate and a low false alarm rate would lead to a higher memorability score. In other words, many falsely recognized visualizations mean a lower memorability score. Visualizations that are easily confused for others.">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
        );
      default:
        return el;
    }
  }
  return (
    <>
      {data.length > 0 &&
      dataByChart.length > 0 &&
      dataByResponse.length > 0 ? (
        <>
          <Box mt={1}>
            <Alert severity="warning" style={{ marginTop: "10px" }}>
              This result is not a scientific fact but could be used as a design
              feedback.
            </Alert>
          </Box>
          <Box mt={3}>
            <Typography variant="h5" display="block" gutterBottom>
              Overview
            </Typography>
          </Box>

          {sortedDataByChart[0].score !==
          sortedDataByChart[dataByChart.length - 1].score ? (
            <>
              <Grid container spacing={5}>
                <Grid item xs>
                  <Typography variant="h6" display="block">
                    Most Memorable Visualization
                  </Typography>{" "}
                  <Typography variant="body2" display="block" gutterBottom>
                    Memorability Score:{" "}
                    {Number(
                      sortedDataByChart[0].score
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                    {", "}
                    False Alarm Rate:{" "}
                    {Number(sortedDataByChart[0].far).toLocaleString(
                      undefined,
                      {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }
                    )}
                    {", "}
                    Hit Rate:{" "}
                    {Number(sortedDataByChart[0].hr).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                  <Box mt={1}>
                    <ChartStimuli chartId={sortedDataByChart[0].chartId} />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" display="block">
                    Least Memorable Visualization
                  </Typography>{" "}
                  <Typography variant="body2" display="block" gutterBottom>
                    Memorability Score:{" "}
                    {Number(
                      sortedDataByChart[dataByChart.length - 1]
                        .score
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                    {", "}
                    False Alarm Rate:{" "}
                    {Number(
                      sortedDataByChart[dataByChart.length - 1].far
                    ).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                    {", "}
                    Hit Rate:{" "}
                    {Number(
                      sortedDataByChart[dataByChart.length - 1].hr
                    ).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                  <Box mt={1}>
                    <ChartStimuli
                      chartId={
                        sortedDataByChart[dataByChart.length - 1].chartId
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item container direction="row">
              <Grid item xs>
                <Typography variant="h6" display="block" gutterBottom>
                  <Box component="span" fontWeight="fontWeightBold">
                    Target Visualization
                  </Box>
                </Typography>
                <Typography variant="body2" display="block" gutterBottom>
                  Memorability Score:{" "}
                  {Number(
                    sortedDataByChart[0].score
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
                <ChartStimuli chartId={sortedDataByChart[0].chartId} />
              </Grid>
              <Grid item xs>
                {sortedDataByChart.length > 1 && (
                  <>
                    <Typography variant="h6" display="block" gutterBottom>
                      <Box component="span" fontWeight="fontWeightBold">
                        Target Visualization
                      </Box>
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                      Memorability Score:{" "}
                      {Number(
                        sortedDataByChart[dataByChart.length - 1]
                          .score
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                    <ChartStimuli
                      chartId={
                        sortedDataByChart[dataByChart.length - 1].chartId
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}

          <Box mt={6}>
            <Typography variant="h5" display="block" gutterBottom>
              Breakdown Report
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
              Choose a demographic factor to analyze visualization memorability.
            </Typography>
          </Box>

          <Box mt={2}>
            <Grid item container spacing={2}>
              <Grid item xs>
                <Autocomplete
                  disabled={Object.keys(demographicData).length === 0}
                  // multiple
                  freeSolo
                  options={demographicQuestions}
                  getOptionLabel={(option) => option.text}
                  filterSelectedOptions
                  onChange={handleDemographicFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Demographic factor"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columnNames.map((el, i) => (
                      <TableCell
                        align={columnNames.length - i < 4 ? "right" : "left"}
                        key={i}
                      >
                        {renderTableHeader(el)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chartIds.map((chartId) =>
                    Object.keys(demographicColumn).length > 0 ? (
                      <>
                        {demographicColumn.options.map((o, j) => (
                          <Fragment key={chartId + o.id}>
                            <TableRow
                              className={
                                j === demographicColumn.options.length - 1
                                  ? classes.tablerow
                                  : null
                              }
                            >
                              <TableCell align="right">
                                <ChartStimuli type="tiny" chartId={chartId} />
                              </TableCell>
                              <TableCell align="left">{o.text}</TableCell>
                              <TableCell align="right">
                                {Number(
                                  calcFalseAlarmRate(
                                    dataByResponse
                                      .filter(
                                        (d) =>
                                          d.demographicSurvey[
                                            demographicColumn.id
                                          ] === o.id
                                      )
                                      .filter((dd) => dd.chartId === chartId)
                                  )
                                ).toLocaleString(undefined, {
                                  style: "percent",
                                  minimumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell align="right">
                                {Number(
                                  calcHitRate(
                                    dataByResponse
                                      .filter(
                                        (d) =>
                                          d.demographicSurvey[
                                            demographicColumn.id
                                          ] === o.id
                                      )
                                      .filter((d) => d.chartId === chartId)
                                  ).toLocaleString(undefined, {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                  })
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {Number(
                                  calcMemorabilityScore(
                                    dataByResponse
                                      .filter(
                                        (d) =>
                                          d.demographicSurvey[
                                            demographicColumn.id
                                          ] === o.id
                                      )
                                      .filter((dd) => dd.chartId === chartId)
                                  )
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      <Fragment key={chartId}>
                        <TableRow>
                          <TableCell align="right">
                            <ChartStimuli type="tiny" chartId={chartId} />
                          </TableCell>
                          <TableCell align="right">
                            {Number(
                              calcFalseAlarmRate(
                                dataByResponse.filter(
                                  (dd) => dd.chartId === chartId
                                )
                              )
                            ).toLocaleString(undefined, {
                              style: "percent",
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            {Number(
                              calcHitRate(
                                dataByResponse.filter(
                                  (d) => d.chartId === chartId
                                )
                              )
                            ).toLocaleString(undefined, {
                              style: "percent",
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            {Number(
                              calcMemorabilityScore(
                                dataByResponse.filter(
                                  (dd) => dd.chartId === chartId
                                )
                              )
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
