import React, { useState, useEffect } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import {
  Typography,
  CircularProgress,
  Backdrop,
  MobileStepper,
  Button,
  makeStyles,
  withStyles,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  showAnswer: {
    color: "red",
  },
}));

function Practice(props) {
  const classes = useStyles();
  const { setIsValidSubmit } = props;

  const charts = [
    {
      key: "c0",
      source: process.env.PUBLIC_URL + "/img/Practices/bar.png",
    },
    // {
    //   key: "c1",
    //   source: process.env.PUBLIC_URL + "/img/Practices/pie.png",
    // },
  ];

  const questions = [
    {
      id: "q0",
      type: "choice",
      text: "Which country is the third largest donor?",
      options: [
        { id: "o0", text: "US", answer: false },
        { id: "o1", text: "Europoan Commission", answer: false },
        { id: "o2", text: "Japan", answer: true },
        { id: "o3", text: "Netherlands", answer: false },
      ],
    },
    {
      id: "q1",
      type: "short-answer",
      text: "What rank is the US among the countries which donate to Syria?",
      answer: ["1", "first"],
    },
  ];

  let maxSteps = questions.length;

  const [response, setResponse] = useState({
    answer: {},
  });
  const [responseCharts, setResponseCharts] = useState({
    charts: {},
  });
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedExperimentChart, setSelectedExperimentChart] = useState();
  const [showAnswer, setShowAnswer] = useState(false);

  // onboarding
  const onBoardingSteps = [
    {
      content: <h2>Let's get to know the interface!</h2>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: "center",
      target: "body",
      disableOverlayClose: true,
    },
    {
      title: "Target visualization",
      target: ".practice-image",
      content:
        "A visualization will appear here. Read this visualization to answer the question below.",
      disableOverlayClose: true,
      spotlightClicks: true,
    },
    {
      title: "Multiple choice question",
      target: ".practice-question",
      content:
        "A question will appear here. Answer the question as quickly as possible using the visualization above. In this multiple choice question, you need to click a single correct answer.",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
    },
    {
      title: "Move to the next question",
      target: ".practice-nextButton",
      content:
        "If there is more than one question, you can click this button to move to the next question. You need to finish the current question first as you will not be able to come back.",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
    },
    {
      title: "Short answer question",
      target: ".practice-question",
      content:
        "In this short answer question, you need to type correct text, typically a number.",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
    },
    {
      title: "Now it's your turn",
      target: "body",
      content: "Let's try this example task before diving into the main task.",
      // disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      // hideFooter: false,
      // placement: "bottom",
      // spotlightClicks: true,
      placement: "center",
      target: "body",
    },
  ];
  const [onBoardingRun, setOnBoardingRun] = useState(true);
  const [onBoardingStepIndex, setOnBoardingStepIndex] = useState(0);

  useEffect(() => {
    if (setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    if (questions.length > 0 && selectedQuestion === null) {
      // if (questions.length > 0) {
      setSelectedQuestion(questions[0].id);
      setActiveStep(0);
      maxSteps = questions.length;
    }
    if (charts.length > 0) {
      setSelectedExperimentChart(charts[0].key);
    }
  }, []);

  useEffect(() => {
    // console.log("chart changed");
    if (questions.length > 0) {
      // console.log("inside");
      setSelectedQuestion(questions[0].id);
      setActiveStep(0);
      maxSteps = questions.length;
      setIsValidNextChart(false);
      setResponse({ answer: {} });
    }
  }, [selectedExperimentChart]);

  useEffect(() => {
    if (showAnswer) {
      setIsValidNextQuestion(true);
    } else {
      setIsValidNextQuestion(false);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (
      charts.every((item) => responseCharts.charts[item.key]) &&
      responseCharts.charts !== {}
    ) {
      setIsValidNextStep(true);
      //   handleAnswers(responseCharts);
    } else {
      setIsValidNextStep(false);
    }
  }, [responseCharts]);

  useEffect(() => {
    if (
      questions.every((item) => response.answer[item.id]) &&
      selectedExperimentChart !== null
    ) {
      handleAnswersCharts(selectedExperimentChart, response);
      setIsValidNextChart(true);
      setIsValidSubmit(true);
    } else {
      setIsValidNextChart(false);
    }
  }, [response]);

  const getQuestion = () => {
    return questions.find((q) => q.id === selectedQuestion);
  };

  const handleAnswersCharts = (chartId, ans) => {
    setResponseCharts({
      ...responseCharts,
      charts: {
        ...responseCharts.charts,
        [chartId]: {
          answer: { ...ans.answer },
        },
      },
    });
  };

  const handleRadioChange = (event) => {
    let question = getQuestion();
    // endTime = new Date();
    // const timeDiff = endTime - startTime;
    // console.log(timeDiff + ' ms');
    setResponse({
      ...response,
      answer: {
        ...response.answer,
        [question.id]: event.target.value,
      },
      // time: {
      //   ...response.time,
      //   [question.id]: (timeDiff / 1000).toFixed(3),
      // },
    });
    setIsValidNextQuestion(true);

    setOnBoardingStepIndex(onBoardingStepIndex + 1);
  };

  const handleTextAnswers = (e) => {
    let question = getQuestion();
    // endTime = new Date();
    // const timeDiff = endTime - startTime;
    setResponse({
      ...response,
      answer: {
        ...response.answer,
        [question.id]: e.target.value,
      },
      // time: {
      //   ...response.time,
      //   [question.id]: (timeDiff / 1000).toFixed(3),
      // },
    });
    setIsValidNextQuestion(true);

    // setOnBoardingStepIndex(onBoardingStepIndex + 1);
  };

  const handleTextAnswersOnBoarding = () => {
    setOnBoardingStepIndex(onBoardingStepIndex + 1);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [activeChartIndex, setActiveChartIndex] = useState(0);
  const [isValidNextChart, setIsValidNextChart] = useState(false);
  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [isValidNextQuestion, setIsValidNextQuestion] = useState(false);
  // const handleNextChart = () => {
  //   let index = charts.findIndex((q) => q.key === selectedExperimentChart);
  //   index = index + 1;
  //   // console.log(index, selectedExperimentChart);

  //   if (index < charts.length) {
  //     setSelectedExperimentChart(charts[index].key);
  //     setActiveChartIndex(index);
  //   }
  // };

  // const handlePrevChart = () => {
  //   let index = charts.findIndex((q) => q.key === selectedExperimentChart);
  //   index = index - 1;
  //   // console.log(index, selectedExperimentChart);

  //   if (index >= 0) {
  //     setSelectedExperimentChart(charts[index].key);
  //   } else {
  //     setSelectedExperimentChart(null);
  //   }
  //   setActiveChartIndex(index);
  // };

  const handlePrevQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index -= 1;
    if (index >= 0) {
      setSelectedQuestion(questions[index].id);
    } else {
      setSelectedQuestion(null);
    }
    setActiveStep(index);
  };

  const handleNextQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index += 1;
    if (index < questions.length) {
      setSelectedQuestion(questions[index].id);
      setActiveStep(index);
    } else {
    }
    // console.log("nexxt", onBoardingStepIndex);
    setOnBoardingStepIndex(4);
    // setActiveStep(index);
  };

  const handleCheckAnswer = () => {
    setSelectedExperimentChart(charts[0].key);
    setShowAnswer(true);
    setIsValidNextStep(false);
    setActiveChartIndex(0);
  };
  // console.log(response, responseCharts, selectedExperimentChart);
  const nextButtonText = () => {
    if (isValidNextStep) {
      return "Check Answer";
      // } else if (isValidNextChart) {
      //   return "Go to Next Chart";
      // } else if (
      //   showAnswer &&
      //   activeStep === questions.length - 1 &&
      //   activeChartIndex !== charts.length - 1
      // ) {
      //   return "Go to Next Chart";
    } else {
      return "Next Question";
    }
  };

  const nextButtonAction = () => {
    // console.log(activeStep);
    if (!showAnswer && isValidNextStep) {
      handleCheckAnswer();
      handlePrevQuestion();
      // } else if (!showAnswer && isValidNextChart) {
      //   handleNextChart();
      // } else if (activeStep === questions.length - 1 && showAnswer) {
      //   handleNextChart();
    } else {
      handleNextQuestion();
    }
  };

  const prevButtonAction = () => {
    // console.log(activeStep);
    // if (showAnswer && activeStep === 0) {
    //   handlePrevChart();
    // } else {
    handlePrevQuestion();
    // }
  };

  const handleJoyrideCallback = (CallBackProps) => {
    const { action, index, status, type } = CallBackProps;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setOnBoardingRun(false);
      setOnBoardingStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 1) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 2) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 3) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
        handleNextQuestion();
      } else if (index === 4) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 5) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
        handlePrevQuestion();
      } else {
        setOnBoardingStepIndex(stepIndex);
      }
    }
  };

  const render = () => {
    let curQuestion = getQuestion();

    return (
      <div>
        {curQuestion && (
          <>
            <div className="practice-wrapper">
              <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                // getHelpers={getHelpers}
                run={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                steps={onBoardingSteps}
                stepIndex={onBoardingStepIndex}
                styles={{
                  tooltipContainer: {
                    textAlign: "left",
                  },
                  tooltipContent: {
                    padding: "20px 0px",
                  },
                  options: {
                    zIndex: 10000,
                  },
                }}
                floaterProps={{ disableAnimation: true }}
              />
              {selectedQuestion !== null && (
                <>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      align="center"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Paper
                          elevation={0}
                          variant="elevation"
                          style={{
                            position: "relative",
                            margin: "0",
                            width: "512px",
                            // height: "512px",
                          }}
                          className="practice-image"
                        >
                          <img
                            src={
                              charts.find(
                                (c) => c.key === selectedExperimentChart
                              ).source
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "scale-down",
                            }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>

                    <Grid container item align="left">
                      <Paper
                        elevation={0}
                        variant="elevation"
                        className="practice-question"
                        style={{ padding: "10" }}
                      >
                        <Grid item xs>
                          <Typography
                            variant="subtitle1"
                            display="block"
                            gutterBottom
                            style={{ marginTop: "20px" }}
                          >
                            {curQuestion.text}
                          </Typography>

                          {curQuestion.type === "choice" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {curQuestion.options.map((option) => (
                                <FormControlLabel
                                  label={
                                    <Typography
                                      variant="subtitle1"
                                      className={
                                        showAnswer && option.answer
                                          ? classes.showAnswer
                                          : undefined
                                      }
                                    >
                                      {option.text}
                                      {showAnswer &&
                                        option.answer &&
                                        " (Answer)"}
                                    </Typography>
                                  }
                                  value={option.id}
                                  key={option.id}
                                  data-id={option.id}
                                  control={
                                    <Radio
                                      style={{ margin: "5px" }}
                                      checked={
                                        showAnswer
                                          ? responseCharts.charts[
                                              selectedExperimentChart
                                            ].answer[curQuestion.id] ===
                                            option.id
                                          : response.answer[curQuestion.id] ===
                                            option.id
                                      }
                                      // disabled={showAnswer}
                                    />
                                  }
                                  onChange={
                                    showAnswer
                                      ? undefined
                                      : (e) => handleRadioChange(e)
                                  }
                                />
                              ))}
                            </div>
                          )}

                          {curQuestion.type === "short-answer" && (
                            <>
                              <TextField
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleTextAnswers(e)}
                                onBlur={handleTextAnswersOnBoarding}
                                value={
                                  showAnswer
                                    ? responseCharts.charts[
                                        selectedExperimentChart
                                      ].answer[curQuestion.id]
                                    : response.answer[curQuestion.id]
                                }
                                // type="number"
                                // label="Number only"
                              />
                              <span className={classes.showAnswer}>
                                {showAnswer && "Answer: " + curQuestion.answer}
                              </span>
                            </>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs></Grid>
                  </Grid>

                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        variant={isValidNextStep ? "contained" : null}
                        disableElevation
                        color={isValidNextStep ? "primary" : "default"}
                        size="small"
                        onPointerUp={() => {
                          nextButtonAction();
                        }}
                        // disabled={activeStep === maxSteps - 1}
                        // disabled={
                        //   showAnswer
                        //     ? activeChartIndex === charts.length - 1
                        //     : false
                        // }
                        disabled={
                          (!isValidNextStep &&
                            activeStep === questions.length - 1) ||
                          !isValidNextQuestion
                        }
                        className="practice-nextButton"
                      >
                        {nextButtonText()}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        disabled={true}
                        onPointerUp={() => {
                          prevButtonAction();
                        }}
                      >
                        Previous Question
                      </Button>
                    }
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return render();
}

export default Practice;
