import React from "react";
import ExperimentTemplate from "components/ExperimentTemplate";
import Design from "components/BubbleView/Design";

function Template(props) {
  const { experimentId, readOnly } = props;

  return (
    <ExperimentTemplate
      experimentId={experimentId}
      type="Attention Tracking"
      experimentComponent={Design}
      readOnly={readOnly}
    />
  );
}

export default Template;
