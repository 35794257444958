import React, { useEffect, useState, useRef } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { imageWidth, imageHeight, setup } from "utils/bubbleView";

import {
  FormLabel,
  Grid,
  TextField,
  Chip,
  makeStyles,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import { EXPERIMENTAL_FEATURE } from "index";

// const StackBlur = window.StackBlur;
const JsDiff = window.JsDiff;

let click_count = 0;
let clicks = [];
let descs = [];
let originalDesc, updatedDesc;

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function BubbleViewInterface(props) {
  const {
    imgSrc,
    task,
    blur,
    bubble,
    time,
    mouse,
    recordResponse,
    isValidNextChart,
    setIsValidNextChart,
    isDesign,
    isPractice,
    setIsValidSubmit,
  } = props;
  const classes = useStyles();
  const canvasEl = useRef(null);
  const canvasContainerEl = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [descState, setDescState] = useState("");
  const [updatedDescState, setUpdatedDescState] = useState("");
  // let startTime, endTime, start;
  const [startTime, setStartTime] = useState(null);
  let endTime;

  // timer
  var countTimer;
  var countdown = 5;
  const [countDown, setCountDown] = useState(5);
  var elapsed = 0.0;

  var bubbleTimer;
  var bubbleElapsed = 0.0;
  var bubbleViewTime = parseInt(time);
  const [bubbleViewTimeEl, setBubbleViewTimeEl] = useState(bubbleViewTime);

  const [isStart, setIsStart] = useState(false);

  const [pauseEvent, setPauseEvent] = useState(false);
  // console.log(imgSrc);
  function logClick(log) {
    click_count++;
    setClickCount(click_count);
    clicks.push(log);
  }

  function resetBubbleView() {
    //  reset bubbleview interface
    if (imgSrc !== null) {
      // console.log("setup", pauseEvent, mouse);
      setup(imgSrc, canvasEl, bubble, blur, mouse, logClick, pauseEvent);
    }
    setDescState("");
    setCharCount(0);
    setClickCount(0);
    click_count = 0;
    clicks = [];
    descs = [];
    setUpdatedDescState("");

    setStartTime(new Date());

    if (pauseEvent) {
      countdown = 5;
      setCountDown(5);
      bubbleViewTime = parseInt(time);
      setBubbleViewTimeEl(bubbleViewTime);
    }
  }

  const handleDescChange = (event) => {
    setDescState(event.target.value);
  };

  const handleDescKeyUp = (event) => {
    setCharCount(event.target.value.length);
  };

  function handleFocusInDesc() {
    originalDesc = descState;
  }

  function handleFocusOutDesc() {
    updatedDesc = descState;
    let diff = JsDiff.diffChars(originalDesc, updatedDesc);
    console.log(diff);
    descs.push({
      diff: diff,
      timestamp: Date.now(),
    });
    setUpdatedDescState(updatedDesc);
  }

  useEffect(() => {
    if (isPractice && setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    resetBubbleView();
  }, [bubble, blur, mouse, imgSrc, pauseEvent]);

  // console.log(countDown, bubbleViewTimeEl);
  useEffect(() => {
    if (!isDesign && imgSrc) {
      // if (task === "free" && (countDown !== -1 || bubbleViewTimeEl === -1)) {
      //   // no click logging
      //   // when free mode, before start, after elapsed time
      // }
      if (task === "free" && countDown !== -1) {
      } else if (
        task === "free" &&
        countDown === -1 &&
        bubbleViewTimeEl === -1
      ) {
      } else {
        endTime = new Date();
        const timeDiff = endTime - startTime;
        console.log(descs);
        recordResponse(charCount, {
          clicks: clicks,
          descs: descs,
          time: (timeDiff / 1000).toFixed(3),
          canvas: {
            width: canvasEl.current.width,
            height: canvasEl.current.height,
          },
        });
      }

      if (isPractice && (clickCount > 0 || charCount > 0)) {
        setIsValidSubmit(true);
      }
    }
  }, [clickCount, charCount, updatedDescState]);
  // }, [clickCount, descs]);

  function countDownTimer() {
    countTimer = setInterval(function () {
      elapsed += 0.5;

      if (elapsed % 1 === 0) {
        countdown -= 1;
        setCountDown((prev) => prev - 1);
        if (countdown === -1) {
          elapsed = 0.0;
          bubbleViewTimer();
        }
      } else {
      }
    }, 500);
  }

  function bubbleViewTimer() {
    clearTimeout(countTimer);
    bubbleTimer = setInterval(function () {
      bubbleElapsed += 0.5;

      if (bubbleElapsed % 1 === 0) {
        bubbleViewTime -= 1;
        setBubbleViewTimeEl((prev) => prev - 1);
        if (bubbleViewTime === -1) {
          bubbleElapsed = 0.0;
          setIsValidNextChart(true);
          setIsStart(false);
          clearTimeout(bubbleTimer);
        }
      } else {
      }
    }, 500);
  }

  useEffect(() => {
    // pauseEvent
    // if (task === "free" && (countDown !== -1 || bubbleViewTimeEl === -1)) {
    if (task === "free" && countDown !== -1) {
      // no click event before click start button
      setPauseEvent(true);
    } else if (task === "free" && countDown === -1 && bubbleViewTimeEl === -1) {
      setPauseEvent(true);
    } else {
      setPauseEvent(false);
    }
    // console.log(countDown, bubbleViewTimeEl);
  }, [task, countDown, bubbleViewTimeEl, imgSrc]);

  // const handleStart = () => {
  //   countDownTimer();
  //   // bubbleViewTimer();
  //   setIsStart(true);
  // };
  useEffect(() => {
    if (!isDesign && task === "free") {
      countDownTimer();
      setIsStart(true);
    }
  }, [imgSrc]);

  // onboarding
  const onBoardingSteps = [
    {
      content: <h2>Let's get to know the interface!</h2>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: "center",
      target: "body",
    },
    {
      target: ".practice-image",
      content:
        "You will see a blurred image. When you click an image, a small clear area of the image will be revealed at the original resolution.",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
      title: "Visualization",
    },
    {
      target: ".practice-describe",
      content:
        "Please describe the image as much detail as possible while browsing the visualization. You need to write a minimum of 100 characters to complete this task.",
      title: "Description",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
    },
    {
      target: ".practice-counts",
      title: "Number of Clicks and Characters",
      content:
        "You can check your click counts and the number of characters you write.",
      placement: "bottom",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      spotlightClicks: true,
    },
    // {
    //   target: ".practice-next",
    //   title: "Next",
    //   content: "Click next.",
    //   disableBeacon: true,
    //   disableOverlayClose: true,
    //   hideCloseButton: false,
    //   hideFooter: false,
    //   placement: "bottom",
    //   spotlightClicks: true,
    // },
    {
      target: "body",
      content: "Let's try this example.",
      title: "Now it's your turn",
      disableOverlayClose: true,
      placement: "center",
    },
  ];
  const [onBoardingRun, setOnBoardingRun] = useState(true);
  const [onBoardingStepIndex, setOnBoardingStepIndex] = useState(0);

  const handleJoyrideCallback = (CallBackProps) => {
    const { action, index, status, type } = CallBackProps;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setOnBoardingRun(false);
      setOnBoardingStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 1) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 2) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 3) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 4) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 5) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else {
        setOnBoardingStepIndex(stepIndex);
      }
    }
  };

  return (
    <>
      {isDesign && (
        <Grid container spacing={3}>
          <Grid item xs>
            <div ref={canvasContainerEl}>
              <canvas ref={canvasEl} width={imageWidth} height={imageHeight} />
            </div>
          </Grid>
        </Grid>
      )}

      {!isDesign && (
        <>
          <div className="practice-wrapper">
            {isPractice && (
              <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                // getHelpers={getHelpers}
                run={onBoardingRun}
                steps={onBoardingSteps}
                stepIndex={onBoardingStepIndex}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                styles={{
                  tooltipContainer: {
                    textAlign: "left",
                  },
                  tooltipContent: {
                    padding: "20px 0px",
                  },
                  options: {
                    zIndex: 10000,
                  },
                }}
                floaterProps={{ disableAnimation: true }}
              />
            )}
            {task === "describe" ? (
              <>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant="h6">
                      Click and describe the image
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} align="left">
                      <Grid item xs>
                        {/* <Paper className="practice-image" elevation={0}> */}
                        <div ref={canvasContainerEl}>
                          {imgSrc ? (
                            <canvas
                              ref={canvasEl}
                              width={imageWidth}
                              height={imageHeight}
                            />
                          ) : (
                            <div
                              style={{
                                width: imageWidth,
                                height: imageHeight,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "gray",
                                margin: "0",
                                // border: "1px solid gray",
                              }}
                            >
                              No Visualization
                            </div>
                          )}
                        </div>
                        {/* </Paper> */}
                      </Grid>
                      <Grid item xs>
                        {/* <div> */}
                        {/* <Paper className="practice-counts" elevation={0}> */}
                        <Chip
                          className={classes.chip}
                          label={clickCount + " clicks"}
                          size="small"
                        />
                        <Chip
                          className={classes.chip}
                          label={charCount + " characters"}
                          size="small"
                        />
                        {/* </Paper> */}

                        {/* <Paper
                          // style={{ padding: "0px" }}
                          className="practice-describe"
                          elevation={0}
                        > */}
                        {/* <FormLabel component="p">
                            Describe the image in as much detail as possible...
                          </FormLabel> */}
                        <TextField
                          fullWidth
                          // variant="outlined"
                          label="Describe the image in as much detail as possible..."
                          value={descState}
                          id="desc"
                          name="desc"
                          multiline
                          rows={20}
                          onChange={handleDescChange}
                          onKeyUp={handleDescKeyUp}
                          onBlur={handleFocusOutDesc}
                          onFocus={handleFocusInDesc}
                          // InputProps={{ borderBottom: "none" }}
                        />
                        {/* </Paper> */}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {/* <div> */}
                {/* <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    onPointerUp={handleStart}
                    disabled={isStart || isValidNextChart}
                  >
                    Start
                  </Button> */}
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Typography variant="body1" display="block" gutterBottom>
                      {isValidNextChart
                        ? "Done!"
                        : `You will have ` + time + `s to see an image.`}
                    </Typography>
                    {isStart && countDown > -1 && (
                      <Typography
                        variant="h6"
                        display="block"
                        color="secondary"
                        gutterBottom
                      >
                        Countdown: {countDown}
                      </Typography>
                    )}
                    {isStart && bubbleViewTimeEl > -1 && (
                      <Typography
                        variant="h6"
                        display="block"
                        color="secondary"
                        gutterBottom
                      >
                        {bubbleViewTimeEl}s / {time}s
                      </Typography>
                    )}
                    <Typography variant="h6">
                      Click anywhere you want to look
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div ref={canvasContainerEl}>
                      {imgSrc ? (
                        <canvas
                          ref={canvasEl}
                          width={imageWidth}
                          height={imageHeight}
                        />
                      ) : (
                        <div
                          style={{
                            width: imageWidth,
                            height: imageHeight,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "gray",
                            margin: "0",
                            // border: "1px solid gray",
                          }}
                        >
                          No Visualization
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {/* </div> */}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default BubbleViewInterface;
