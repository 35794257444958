import uniqueId from "utils/uniqueId";
import { url, requestInfo } from "actions/index";
import { alertServerError, selectTask } from "actions/ui";
import { createChart } from "actions/charts";
import { createVariable } from "actions/variables";
import { createSurvey } from "actions/surveys";
import { createSelector } from "reselect";

export const CREATE_TASK = "CREATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";

const path_dict = {
  CREATE_TASK: "create_task",
  UPDATE_TASK: "update_task",
  DELETE_TASK: "delete_task",
};

export const createTask = (experimentId, attrs = {}, duplicate = false) => {
  return async (dispatch) => {
    let taskId = uniqueId("task_");
    let createdAt = Date.now();
    if (!duplicate) {
      attrs = {
        ...attrs, //override the above if specified
        experimentId,
        id: taskId,
        params: attrs.params,
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    } else {
      taskId = attrs.id;
    }

    let action = { type: CREATE_TASK, taskId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(selectTask(taskId));
          if (!duplicate) {
            if (Object.values(attrs.params).length === 0) {
              // if type is perception
              // console.log("perception");
              // dispatch(createVariable(taskId));
              dispatch(createSurvey(taskId, { type: "perceptionSurvey" }));
              dispatch(createChart(taskId));
              dispatch(createChart(taskId));
            } else {
              // memorability or bubbleView
              dispatch(createChart(taskId));
              dispatch(createChart(taskId));
            }
          }
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateTask = (taskId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_TASK, taskId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteTask = (taskId) => {
  return async (dispatch) => {
    let action = { type: DELETE_TASK, taskId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const makeGetTasks = () => {
  return createSelector(
    (state, props) => props.experimentId,
    (state) => state.tasks,
    (experimentId, tasks) =>
      Object.values(tasks).filter((task) => task.experimentId === experimentId)
  );
};
