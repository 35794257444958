import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { size, toArray } from "lodash";

import { uploadFile } from "actions/charts";

import UploadItem from "components/Memorability/UploadItem/";

import Styles from "./UploadProgress.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

function UploadProgress(props) {
  // const { fileProgress, uploadFile } = props;
  const { experimentId } = props;
  const dispatch = useDispatch();

  const check_img_size = (files) => {
    // console.log(files);
    if (files !== undefined) {
      let fails = [];
      let success = [];
      if (files.length) {
        files.forEach(async (file) => {
          if (file.file.size > 1000000) {
            fails.push(file.file.name);
          } else {
            console.log(file.file.name);
            success.push(file);
          }
        });
      }
      if (fails.length > 0) {
        setErrorMsg(fails.join(",") + " Size too big (>1MB)");
      }

      return success;
    }
    return {};
  };

  const { fileProgress } = useSelector((state) => ({
    fileProgress: state.charts.fileProgress,
    // fileProgress: check_img_size(state.manage.FileProgress),
  }));
  // console.log(fileProgress);
  const uploadedFileAmount = size(fileProgress);
  const [errorMsg, setErrorMsg] = useState(null);

  // useEffect(() => {
  //   if (Object.keys(fileProgress).length > 0) {
  //     check_img_size(fileProgress);
  //   }
  // }, [fileProgress]);
  const [files, setFiles] = useState({});
  useEffect(() => {
    // console.log(fileProgress);
    console.log(uploadedFileAmount);
    const fileToUpload = toArray(fileProgress).filter(
      (file) => file.progress === 0
    );
    console.log(fileToUpload);
    // dispatch(uploadFile(fileToUpload, { name: name }));
    // check image file size
    if (fileToUpload !== undefined) {
      let fs = check_img_size(fileToUpload);
      console.log(fs);
      setFiles(fs);
      dispatch(uploadFile(fs, { experimentId: experimentId }));
    }
  }, [uploadedFileAmount]);
  console.log(files);

  return uploadedFileAmount > 0 ? (
    <>
      <div className={Styles.wrapper}>
        {size(files) &&
          toArray(files).map((file, index) => (
            <div key={index}>
              <div className={Styles.wrapperItem}>
                <div className={Styles.leftSide}>
                  <div className={Styles.progressBar}>
                    <div style={{ width: `${file.progress}%` }} />
                  </div>
                  <label>{file.file.name}</label>
                  {/* <img src={} */}
                </div>
                <span className={Styles.percentage}>{file.progress}%</span>
              </div>
            </div>
          ))}
      </div>
      <Snackbar open={errorMsg !== null} autoHideDuration={1000}>
        <Alert
          onClose={() => setErrorMsg(null)}
          variant="filled"
          severity="error"
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  ) : null;
}

// const UploadItem = props => {
//   const { file, progress } = props.file

//   return (
//     <div className={Styles.wrapperItem}>
//       <div className={Styles.leftSide}>
//         <div className={Styles.progressBar}>
//           <div style={{ width: `${progress}%` }} />
//         </div>
//         <label>{file.name}</label>
//       </div>
//       <span className={Styles.percentage}>{progress}%</span>
//     </div>
//   )
// }

export default UploadProgress;
// export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress);
