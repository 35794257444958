import * as d3 from "d3";
import _ from "underscore";
import { ltqnorm } from "utils/memorability";
import { average, sum } from "utils/stats";

const hasAnswer = (question) => {
  if (
    question.answer === undefined ||
    question.answer === null ||
    Object.keys(question.answer).length === 0 ||
    Object.values(question.answer).every((v) => v === false) ||
    question.answer === ""
  ) {
    return false;
  } else {
    return true;
  }
};

export function parsePerceptionData(
  responses,
  questions,
  options,
  forIndividualDashboard = false,
  responseId = null
) {
  let outputs = [];
  let omittedOutputs = [];
  let outputs_list = [];
  let individualOutput = [];
  if (responses) {
    responses.map((response) => {
      // screening result
      // let taskTime = 0;
      // let numHasAnswer = 0;
      // let sumCorrect = 0;
      let times = [];
      let accuracies = [];

      Object.entries(response.charts).map(([chartId, chartAnswer]) => {
        Object.entries(chartAnswer.answer).map(([qId, qAns]) => {
          let output = [];
          try {
            if (questions[qId].type === "paragraph") {
              output.push({
                responseId: response.id,
                chartId: chartId,
                questionId: qId,
                question: questions[qId].text,
                questionType: questions[qId].type,
                questionAnswer: null,
                hasAnswer: false,
                answer: qAns,
                correct: null,
                accuracy: null,
                error: null,
                time: +chartAnswer.time[qId],
                demographicSurvey: response.demographicSurvey,
                // idx: 0,
              });

              // taskTime = +taskTime + +chartAnswer.time[qId];
            } else if (questions[qId].type === "short-answer") {
              // console.log(questions[qId]);
              output.push({
                responseId: response.id,
                chartId: chartId,
                questionId: qId,
                question: questions[qId].text,
                questionType: questions[qId].type,
                questionAnswer: questions[qId].answer,
                hasAnswer: hasAnswer(questions[qId]),
                answer: qAns,
                correct: qAns === questions[qId].answer ? 1 : 0,
                accuracy:
                  100 -
                  (
                    (Math.abs(questions[qId].answer - qAns) / qAns) *
                    100
                  ).toFixed(2),
                error: isNaN(parseFloat(qAns.trim().toLowerCase()))
                  ? null
                  : Math.log2(Math.abs(qAns - questions[qId].answer) + 1 / 8),
                time: +chartAnswer.time[qId],
                demographicSurvey: response.demographicSurvey,
                // idx: 0,
              });
              // console.log(output);

              // taskTime = +taskTime + +chartAnswer.time[qId];
              // numHasAnswer =
              //   +numHasAnswer + +(hasAnswer(questions[qId]) === true ? 1 : 0);
              // sumCorrect =
              //   +sumCorrect + +(qAns === questions[qId].answer ? 1 : 0);
            } else if (questions[qId].type === "checkboxes") {
              const correctAnswer = Object.entries(questions[qId].answer).map(
                ([k, v]) => {
                  if (v) {
                    return k;
                  }
                }
              );

              const numOfAnswers = Object.keys(qAns).length;

              Object.keys(qAns).map((r) => {
                output.push({
                  responseId: response.id,
                  chartId: chartId,
                  questionId: qId,
                  question: questions[qId].text,
                  questionType: questions[qId].type,
                  questionAnswer: options[questions[qId].answer].text,
                  hasAnswer: hasAnswer(questions[qId]),
                  answer: options[r].text,
                  correct:
                    correctAnswer.find((c) => c === options[r].id) === r
                      ? Math.floor(1 / numOfAnswers)
                      : 0,
                  accuracy:
                    correctAnswer.find((c) => c === options[r].id) === r
                      ? Math.floor(1 / numOfAnswers) * 100
                      : 0,
                  error: null,
                  time: +chartAnswer.time[qId],
                  idx: options[r].idx,
                  demographicSurvey: response.demographicSurvey,
                });

                // taskTime = +taskTime + +chartAnswer.time[qId];
                // numHasAnswer =
                //   +numHasAnswer +
                //   +(hasAnswer(questions[qId])
                //     ? Math.floor(1 / numOfAnswers)
                //     : 0);
                // sumCorrect =
                //   +sumCorrect +
                //   +(correctAnswer.find((c) => c === options[r].id) === r
                //     ? Math.floor(1 / numOfAnswers)
                //     : 0);
              });
            } else {
              // console.log(options);
              output.push({
                responseId: response.id,
                chartId: chartId,
                questionId: qId,
                question: questions[qId].text,
                questionType: questions[qId].type,
                questionAnswer: options[questions[qId].answer].text,
                hasAnswer: hasAnswer(questions[qId]),
                answer: options[qAns].text,
                correct: hasAnswer(questions[qId])
                  ? options[qAns].id === options[questions[qId].answer].id
                    ? 1
                    : 0
                  : null,
                accuracy: hasAnswer(questions[qId])
                  ? options[qAns].id === options[questions[qId].answer].id
                    ? 100
                    : 0
                  : null,
                error: null,
                time: +chartAnswer.time[qId],
                idx: options[qAns].idx,
                demographicSurvey: response.demographicSurvey,
              });

              // taskTime = +taskTime + +chartAnswer.time[qId];
              // numHasAnswer =
              //   +numHasAnswer + +(hasAnswer(questions[qId]) ? 1 : 0);
              // sumCorrect =
              //   +sumCorrect +
              //   +(hasAnswer(questions[qId])
              //     ? options[qAns].id === options[questions[qId].answer].id
              //       ? 1
              //       : 0
              //     : null);
            }
            // outputs: all responses, omittedOutputs: responses which omit=false response
            if (!response.omit) {
              omittedOutputs.push(...output);
            }
            outputs.push(...output);
            if (responseId && response.id === responseId) {
              individualOutput.push(...output);
            }

            times.push(output[output.length - 1].time);
            accuracies.push(output[output.length - 1].accuracy);
          } catch (TypeError) {
            console.log("Question is deleted: ", qId);
          }
        });
      });
      // console.log(times, accuracies);
      outputs_list.push({
        id: response.id,
        ip: response.ip,
        screening: "pass",
        // taskTime: taskTime.toFixed(2) + " sec",
        taskTime: average(times) + " sec",
        accuracy: average(accuracies) + "%",
        // accuracy: average(accuracies),
        // numHasAnswer === 0
        //   ? "Not Available"
        //   : (sumCorrect / numHasAnswer).toFixed(2) * 100 + "%",
        // outlier: "No",
        omit: response.omit,
        // responses: chartAnswers,
      });
      // console.log(outputs_list);
    });
  }

  // console.log("lllll", outputs.length);
  // return transformed;
  if (forIndividualDashboard) {
    // IndividualDashboard table
    return outputs_list;
  } else {
    if (responseId) {
      // Participant result
      // return [individualOutput, outputs]; // for result view
      return [individualOutput, omittedOutputs];
    } else {
      return omittedOutputs; // dashboard
    }
  }
}

export function parsePerceptionDataForExport(responses, questions, options) {
  let outputs = [];
  let qIds = Object.entries(responses[0].charts)
    .map(([chartId, chartAnswer]) =>
      Object.keys(chartAnswer.answer).map((qId) => qId)
    )
    .flat();
  // console.log(qIds);

  let qHeader = qIds.map((q, index) => [
    "question" + index,
    "question" + index + "Type",
    "question" + index + "CorrectAnswer",
    "question" + index + "UserAnswer",
    "question" + index + "Accuracy",
    "question" + index + "Error",
    "question" + index + "Time",
  ]);
  let header = [
    "responseId",
    "responseIp",
    "chartId",
    ...qHeader.flat(),
    "questionTotalTime",
    "questionTotalAccuracy",
    "responseOmit",
  ];

  outputs.push(header);

  if (responses) {
    responses.map((response) => {
      // screening result
      // let taskTime = 0;
      // let numHasAnswer = 0;
      // let sumCorrect = 0;
      let times = [];
      let accuracies = [];
      // console.log(response);

      Object.entries(response.charts).map(([chartId, chartAnswer]) => {
        let row = {
          responseId: response.id,
          responseIp: response.ip,
          chartId: chartId,
        };
        Object.entries(chartAnswer.answer).map(([qId, qAns], index) => {
          try {
            // common
            row = {
              ...row,
              // questionId: qId,
              ["question" + index]: `\"${questions[qId].text}\"`,
              ["question" + index + "Type"]: questions[qId].type,
            };

            if (questions[qId].type === "paragraph") {
              row = {
                ...row,
                ["question" + index + "CorrectAnswer"]: null,
                ["question" + index + "UserAnswer"]: `\"${qAns}\"`,
                ["question" + index + "Accuracy"]: null,
                ["question" + index + "Error"]: null,
                ["question" + index + "Time"]: +chartAnswer.time[qId],
              };

              // taskTime = +taskTime + +chartAnswer.time[qId];
            } else if (questions[qId].type === "short-answer") {
              row = {
                ...row,
                ["question" + index + "CorrectAnswer"]: hasAnswer(
                  questions[qId]
                )
                  ? questions[qId].answer
                  : null,
                ["question" + index + "UserAnswer"]: `\"${qAns}\"`,
                ["question" + index + "Accuracy"]:
                  // qAns === questions[qId].answer ? 1 : 0,
                  100 -
                  (
                    (Math.abs(questions[qId].answer - qAns) / qAns) *
                    100
                  ).toFixed(2),
                ["question" + index + "Error"]: isNaN(
                  parseFloat(qAns.trim().toLowerCase())
                )
                  ? null
                  : Math.log2(Math.abs(qAns - questions[qId].answer) + 1 / 8),
                ["question" + index + "Time"]: +chartAnswer.time[qId],
              };

              // taskTime = +taskTime + +chartAnswer.time[qId];
              // numHasAnswer =
              //   +numHasAnswer + +(hasAnswer(questions[qId]) === true ? 1 : 0);
              // sumCorrect =
              //   +sumCorrect + +(qAns === questions[qId].answer ? 1 : 0);
            } else if (questions[qId].type === "checkboxes") {
              const correctAnswer = Object.entries(questions[qId].answer).map(
                ([k, v]) => {
                  if (v) {
                    return k;
                  }
                }
              );

              const numOfAnswers = Object.keys(qAns).length;

              const answersList = [];
              const answers = Object.keys(qAns).map((r) => {
                answersList.push(options[r].text);
              });

              const corrects = Object.keys(qAns)
                .map((r) =>
                  correctAnswer.find((c) => c === options[r].id) === r
                    ? Math.floor(1 / numOfAnswers) * 100
                    : 0
                )
                .reduce((partialSum, a) => partialSum + a, 0);

              row = {
                ...row,
                ["question" + index + "CorrectAnswer"]: correctAnswer, // text
                ["question" + index + "UserAnswer"]: answers,
                ["question" + index + "Accuracy"]: corrects,
                ["question" + index + "Error"]: null,
                ["question" + index + "Time"]: +chartAnswer.time[qId],
                // idx: options[r].idx,
                // demographicSurvey: response.demographicSurvey,
              };

              // taskTime = +taskTime + +chartAnswer.time[qId];
            } else {
              // radio button
              row = {
                ...row,
                ["question" + index + "CorrectAnswer"]: hasAnswer(
                  questions[qId]
                )
                  ? options[questions[qId].answer].text
                  : null,
                ["question" + index + "UserAnswer"]: options[qAns].text,
                ["question" + index + "Accuracy"]: hasAnswer(questions[qId])
                  ? options[qAns].id === options[questions[qId].answer].id
                    ? 100
                    : 0
                  : null,
                ["question" + index + "Error"]: null,
                ["question" + index + "Time"]: +chartAnswer.time[qId],
                // idx: options[qAns].idx,
                // demographicSurvey: response.demographicSurvey,
              };

              // taskTime = +taskTime + +chartAnswer.time[qId];
              // numHasAnswer =
              //   +numHasAnswer + +(hasAnswer(questions[qId]) ? 1 : 0);
              // sumCorrect =
              //   +sumCorrect +
              //   +(hasAnswer(questions[qId])
              //     ? options[qAns].id === options[questions[qId].answer].id
              //       ? 1
              //       : 0
              //     : null);
            }

            times.push(row["question" + index + "Time"]);
            accuracies.push(row["question" + index + "Accuracy"]);
          } catch (TypeError) {
            console.log("Question is deleted: ", qId);
          }
        });
        // console.log(times, accuracies);
        row = {
          ...row,
          questionTotalTime: average(times),
          questionTotalAccuracy: average(accuracies),
          responseOmit: response.omit,
        };
        outputs.push(row);
      });
    });
  }

  return outputs;
}

const getQuestion = (arr, id) => {
  return arr.find((q) => q.id === id);
};

///////////////////////////////////
export function parseDemographicData(
  responses,
  demographicQuestions,
  demographicOptions
) {
  let outputs = [];
  const numOfResponses = Object.keys(responses).length;
  if (responses) {
    responses.map((response) => {
      let output = [];
      try {
        Object.entries(response.demographicSurvey).map(
          ([questionId, answer]) => {
            let curQuestion = getQuestion(demographicQuestions, questionId);

            if (curQuestion) {
              if (curQuestion.type === "checkboxes") {
                Object.keys(answer).map((key) => {
                  output.push({
                    answer: demographicOptions[key].text,
                    idx: demographicOptions[key].idx,
                    question: curQuestion.text,
                    questionId: curQuestion.id,
                    type: curQuestion.type,
                  });
                });
              } else {
                output.push({
                  answer: demographicOptions[answer].text,
                  idx: demographicOptions[answer].idx,
                  question: curQuestion.text,
                  questionId: curQuestion.id,
                  type: curQuestion.type,
                });
              }
            }
          }
        );
      } catch (error) {
        console.log("at that time, there was no demographic survey.");
      }

      if (!response.omit) {
        outputs.push(...output);
      }
    });
  }
  let results = [];
  if (outputs.length > 0) {
    let demorgaphicQuestionIds = Object.values(demographicQuestions).map(
      (q) => q.id
    );

    demorgaphicQuestionIds.map((questionId) => {
      let output = outputs.filter((o) => o.questionId === questionId);
      let options = Object.values(demographicOptions).filter(
        (o) => o.questionId === questionId
      );
      const nOptions = options.length;

      var histGenerator = d3
        .histogram()
        .domain([0, nOptions])
        .thresholds(nOptions);
      var bin = histGenerator(output.map((q) => q.idx));
      // console.log(bin);
      var binnedData = bin.map((b) => ({
        text: options.find((opt) => opt.idx === b.x0).text,
        count: b.length,
        percent: (b.length / numOfResponses).toFixed(2) * 100,
        question: b.text,
      }));

      results.push({
        questionId: questionId,
        binnedData: binnedData,
      });
    });
  }

  return results;
}

export function parseDemographicDataForExport(
  responses,
  demographicQuestions,
  demographicOptions
) {
  let outputs = [];
  let qIds = Object.entries(responses[0].demographicSurvey)
    .map((qId) => qId)
    .flat();

  let qHeader = qIds.map((q, index) => [
    "demographicQuestion" + index,
    "demographicQuestion" + index + "UserAnswer",
  ]);

  if (responses) {
    responses.map((response) => {
      let row = {};
      Object.entries(response.demographicSurvey).map(
        ([qId, qAnswer], index) => {
          row = {
            ...row,
            ["demographicQuestion" +
            index]: `\"${demographicQuestions[qId].text}\"`,
            ["demographicQuestion" +
            index +
            "UserAnswer"]: `\"${demographicOptions[qAnswer].text}\"`,
          };
        }
      );
    });
  }
}
///////////////////////////////////
export function parsePostData(responses, postQuestions, postOptions) {
  let outputs = [];
  let omittedOutputs = [];
  if (responses) {
    responses.map((response) => {
      try {
        Object.entries(response.postSurvey).map(([questionId, answer]) => {
          let output = [];
          let curQuestion = getQuestion(postQuestions, questionId);
          // console.log(curQuestion);
          if (curQuestion) {
            // if (!outputs[questionId]) {
            //   outputs[questionId] = [];
            // }

            if (curQuestion.type === "choice") {
              output.push({
                answer: postOptions[answer].text,
                idx: postOptions[answer].idx,
                // question: curQuestion.text,
                // type: curQuestion.type,
              });
            } else if (curQuestion.type === "checkboxes") {
              Object.keys(answer).map((key) => {
                output.push({
                  answer: postOptions[key].text,
                  idx: postOptions[key].idx,
                });
              });
            } else {
              if (answer != " ") {
                // text questions
                output.push({
                  answer: answer,
                  idx: 0,
                  // question: curQuestion.text,
                  // type: curQuestion.type,
                });
              }
            }
          }
          // console.log(output);
          // outputs.push({
          //   questionId: questionId,
          //   question: curQuestion.text,
          //   type: curQuestion.type,
          //   data: output,
          // });
          if (!response.omit) {
            omittedOutputs.push({
              questionId: questionId,
              question: curQuestion.text,
              type: curQuestion.type,
              data: output,
            });
          }
        });
      } catch (error) {
        console.log("at that time, there was no post survey.");
      }
    });
  }
  // return outputs;
  return omittedOutputs;
}

///////////////////////////////////
export function parseBubbleViewData(
  responses,
  forIndividualDashboard = false,
  responseId = null
) {
  let outputs = [];
  let omittedOutputs = [];
  let outputs_list = [];
  let individualOutput = [];

  if (responses) {
    {
      // collect by response, aggregate later
      // console.log(responses);
      responses.map((response) => {
        Object.entries(response.charts).map(([chartId, chartAnswer]) => {
          let clickResult = [];
          let descsResult = [];
          Object.values(chartAnswer.clicks).map((click) => {
            clickResult.push({
              x: click.cx,
              y: click.cy,
              value: 1,
              timestame: click.timestamp,
              width: chartAnswer.canvas.width,
              height: chartAnswer.canvas.height,
            });
          });
          Object.values(chartAnswer.descs).map((desc) => {
            descsResult.push(desc);
          });
          outputs.push({
            responseId: response.id,
            chartId: chartId,
            clicks: clickResult,
            descs: descsResult,
            demographicSurvey: response.demographicSurvey,
          });
          if (!response.omit) {
            omittedOutputs.push({
              responseId: response.id,
              chartId: chartId,
              clicks: clickResult,
              descs: descsResult,
              demographicSurvey: response.demographicSurvey,
            });
          }
          if (response.id === responseId) {
            individualOutput.push({
              responseId: response.id,
              chartId: chartId,
              clicks: clickResult,
              descs: descsResult,
              demographicSurvey: response.demographicSurvey,
            });
          }
        });
        let overview = {
          id: response.id,
          ip: response.ip,
          screening: "pass",
          omit: response.omit,
        };

        Object.entries(response.charts).map(([chartId, answer]) => {
          overview = {
            ...overview,
            [chartId + "_clicks"]: Object.values(answer.clicks).length,
            [chartId + "_time"]: answer.time,
          };
        });
        outputs_list.push(overview);
      });
    }
  }

  // console.log(outputs_list);

  if (forIndividualDashboard) {
    return outputs_list;
  } else {
    if (responseId) {
      // return [individualOutput, outputs];
      return [individualOutput, omittedOutputs];
    } else {
      return omittedOutputs;
    }
  }
}

export function parseBubbleViewDataForExport(responses) {
  let outputs = [];

  let chartIds = Object.keys(responses[0].charts).map((id) => id);
  let cHeader = chartIds.map((id, index) => [
    "chart" + index + "Id",
    "chart" + index + "ClickCount",
    "chart" + index + "Clicks",
    "chart" + index + "CharCount",
    "chart" + index + "Desc",
  ]);
  let header = ["responseId", "responseIp", ...cHeader.flat(), "responseOmit"];
  outputs.push(header);

  if (responses) {
    {
      responses.map((response) => {
        let row = {
          responseId: response.id,
          responseIp: response.ip,
        };
        Object.entries(response.charts).map(([chartId, chartAnswer], index) => {
          let clickResult = [];
          let descsResult = [];
          Object.values(chartAnswer.clicks).map((click) => {
            clickResult.push({
              x: click.cx,
              y: click.cy,
              value: 1,
              timestame: click.timestamp,
              width: chartAnswer.canvas.width,
              height: chartAnswer.canvas.height,
            });
          });
          Object.values(chartAnswer.descs).map((desc) => {
            descsResult.push(desc);
          });
          // console.log(descsResult, descsResult[descsResult.length - 1]);
          let resultDesc = "";
          if (descsResult.length > 0) {
            descsResult[descsResult.length - 1]["diff"].map((d) => {
              // resultDesc += d.value;
              if (d.added === undefined && d.deleted === undefined) {
                resultDesc += d.value;
              } else {
                if (d.added) {
                  resultDesc += d.value;
                } else if (d.deleted) {
                  resultDesc.replace(d.value, "");
                } else {
                }
              }
            });
          }

          row = {
            ...row,
            ["chart" + index + "Id"]: chartId,
            ["chart" + index + "ClickCount"]: clickResult.length,
            ["chart" + index + "Clicks"]: `\"${JSON.stringify(clickResult)}\"`,
            ["chart" + index + "CharCount"]: resultDesc.length,
            ["chart" + index + "Desc"]: `\"${resultDesc}\"`,
          };
        });

        row = {
          ...row,
          responseOmit: response.omit,
        };

        // console.log(response.demographicSurvey);
        outputs.push(row);
      });
    }
  }
  return outputs;
}

// by response
export function parseMemorabilityData(
  responses,
  forIndividualDashboard = false,
  responseId = null
) {
  let outputs = [];
  let removeOmitOutputs = [];
  let outputs_list = [];
  let individualOutput = [];
  if (responses) {
    responses.map((response) => {
      let output = [];

      const ntarget = response.charts.param.target.length;
      const nfiller = response.charts.param.filler.length;
      const nvigilance = response.charts.param.vigilance.length;
      const ncorrect = response.charts.param.correct.length;
      const nfalseAlarm = response.charts.param.falseAlarm.length;
      const hitRate = ncorrect / (nvigilance + ntarget);
      const falseAlarmRate = nfalseAlarm / (nvigilance + ntarget + nfiller);

      const score = ltqnorm(hitRate) - ltqnorm(falseAlarmRate);

      //////////////

      output.push({
        responseId: response.id,
        demographicSurvey: response.demographicSurvey,
        hr: hitRate,
        far: falseAlarmRate,
        score: score,
        nTarget: ntarget,
        nFiller: nfiller,
        nVigilance: nvigilance,
        nCorrect: ncorrect,
        nFalseAlarm: nfalseAlarm,
      });

      if (!response.omit) {
        removeOmitOutputs.push(...output);
      }
      outputs.push(...output);
      if (responseId && response.id === responseId) {
        individualOutput.push(...output);
      }
      outputs_list.push({
        id: response.id,
        ip: response.ip,
        hr: hitRate.toFixed(2),
        far: falseAlarmRate.toFixed(2),
        score: score.toFixed(2),
        omit: response.omit,
      });
    });
  }
  // return results;
  if (forIndividualDashboard) {
    return outputs_list;
  } else {
    if (responseId) {
      // return [individualOutput, outputs];
      return [individualOutput, removeOmitOutputs];
    } else {
      return removeOmitOutputs;
    }
  }
}

export function parseMemorabilityDataByChart(
  responses,
  charts,
  forIndividualDashboard = false,
  responseId = null
) {
  let outputs = [];
  let removeOmitOutputs = [];
  let outputs_list = [];
  let individualOutput = [];
  if (responses) {
    charts.map((chart) => {
      let hits = [];
      let fas = [];
      let removeOmitHits = [];
      let removeOmitFas = [];

      responses.map((response) => {
        const hit = _.isUndefined(
          _.find(response.charts.param.correct, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;
        const fa = _.isUndefined(
          _.find(response.charts.param.falseAlarm, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;

        if (!response.omit) {
          removeOmitHits.push(hit);
          removeOmitFas.push(fa);
        }
        hits.push(hit);
        fas.push(fa);

        if (responseId && response.id === responseId) {
          individualOutput.push({
            chartId: chart.id,
            correct: hit ? "Yes" : "No",
          });
        }
      });
      // console.log(ncorrect, nfalseAlarm);
      try {
        if (hits.length !== responses.length) {
          throw "Hit length is not same with # of responses.";
        }
        if (fas.length !== responses.length) {
          throw "fas length error";
        }
      } catch (error) {
        console.log(error, hits.length, fas.length);
      }
      const hitRate = sum(hits) / hits.length;
      const falseAlarmRate = sum(fas) / fas.length;
      const score = ltqnorm(hitRate) - ltqnorm(falseAlarmRate);
      // console.log(score);
      outputs.push({
        chartId: chart.id,
        hr: hitRate,
        far: falseAlarmRate,
        score: score,
      });

      const removeOmitHitRate = sum(removeOmitHits) / removeOmitHits.length;
      const removeOmitFalseAlarmRate =
        sum(removeOmitFas) / removeOmitFas.length;
      const removeOmitScore =
        ltqnorm(removeOmitHitRate) - ltqnorm(removeOmitFalseAlarmRate);

      removeOmitOutputs.push({
        chartId: chart.id,
        hr: removeOmitHitRate,
        far: removeOmitFalseAlarmRate,
        score: removeOmitScore,
      });
    });
  }
  // return results;
  if (forIndividualDashboard) {
    return outputs_list;
  } else {
    if (responseId) {
      return [individualOutput, removeOmitOutputs];
    } else {
      return removeOmitOutputs;
    }
  }
}

// by response
export function parseMemorabilityDataByResponse(responses, charts) {
  let removeOmitOutputs = [];

  if (responses) {
    responses.map((response) => {
      charts.map((chart) => {
        let output = [];
        const hit = _.isUndefined(
          _.find(response.charts.param.correct, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;
        const fa = _.isUndefined(
          _.find(response.charts.param.falseAlarm, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;

        output.push({
          responseId: response.id,
          demographicSurvey: response.demographicSurvey,
          chartId: chart.id,
          hit: hit,
          fa: fa,
        });

        if (!response.omit) {
          removeOmitOutputs.push(...output);
        }
      });
    });
  }
  return removeOmitOutputs;
}

export function parseMemorabilityDataForExport(responses, charts) {
  let outputs = [];

  let cHeader = charts.map((id, index) => [
    "chart" + index + "Id",
    "chart" + index + "Hits",
    "chart" + index + "Misses",
    "chart" + index + "FalseAlarms",
    "chart" + index + "CorrectRejections",
  ]);
  let header = [
    "responseId",
    "responseIp",
    ...cHeader.flat(),
    "hitRate",
    "falseAlarmRate",
    "memorabilityScore",
    "responseOmit",
  ];
  outputs.push(header);

  if (responses) {
    responses.map((response) => {
      let row = {
        responseId: response.id,
        responseIp: response.ip,
      };

      charts.map((chart, index) => {
        // let output = [];
        const hit = _.isUndefined(
          _.find(response.charts.param.correct, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;
        const fa = _.isUndefined(
          _.find(response.charts.param.falseAlarm, function (obj) {
            return obj === chart.id;
          })
        )
          ? 0
          : 1;

        row = {
          ...row,
          ["chart" + index + "Id"]: chart.id,
          ["chart" + index + "Hits"]: hit,
          ["chart" + index + "Misses"]: hit ? 0 : 1,
          ["chart" + index + "FalseAlarms"]: fa,
          ["chart" + index + "CorrectRejections"]: fa ? 0 : 1,
        };
      });

      const ntarget = response.charts.param.target.length;
      const nfiller = response.charts.param.filler.length;
      const nvigilance = response.charts.param.vigilance.length;
      const ncorrect = response.charts.param.correct.length;
      const nfalseAlarm = response.charts.param.falseAlarm.length;
      // console.log(ncorrect, nfalseAlarm);
      const hitRate = ncorrect / (nvigilance + ntarget);
      const falseAlarmRate = nfalseAlarm / (nvigilance + ntarget + nfiller);

      const score = ltqnorm(hitRate) - ltqnorm(falseAlarmRate);

      row = {
        ...row,
        hitRate: hitRate,
        falseAlarmRate: falseAlarmRate,
        score: score,
        responseOmit: response.omit,
      };
      outputs.push(row);
    });
  }
  return outputs;
}
