import {
  SAVE_USER,
  // LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  // LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  // LOGIN_VERIFIED,
  // VERIFY_REQUEST,
  // VERIFY_SUCCESS,
  // SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  // SIGNUP_REQUEST,
  SET_ACCOUNT_EMAIL,
} from "actions";

// reducer function
export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    user: {},
  },
  action
) => {
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
      };
    // case LOGIN_REQUEST:
    //   return {
    //     ...state,
    //     isLoggingIn: true,
    //     loginError: false,
    //   };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
      };
    // case LOGOUT_REQUEST:
    //   return {
    //     ...state,
    //     isLoggingOut: true,
    //     logoutError: false,
    //   };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {},
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      };
    // case LOGIN_VERIFIED:
    //   return {
    //     ...state,
    //     isLoggingIn: false,
    //     isAuthenticated: true,
    //     user: action.user,
    //   };
    // case VERIFY_REQUEST:
    //   return {
    //     ...state,
    //     isVerifying: true,
    //     verifyingError: false,
    //   };
    // case VERIFY_SUCCESS:
    //   return {
    //     ...state,
    //     isVerifying: false,
    //   };
    // case SIGNUP_REQUEST:
    //   return {
    //     ...state,
    //     signupError: false,
    //   };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        signupError: true,
      };
    case SET_ACCOUNT_EMAIL:
      return {
        ...state,
        user: { accountEmail: action.email },
      };
    default:
      return state;
  }
};
