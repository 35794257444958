import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeGetTutorial, selectTutorial } from "actions";
import { DEFAULT_TUTORIAL_CONTENTS } from "utils/textResources";

import ReactQuill from "react-quill";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  quill: {
    marginTop: theme.spacing(2),
    "& .ql-container": {
      fontSize: "16px",
    },
    "& .ql-editor": {
      padding: "0px",
    },
  },
}));
function TutorialLive(props) {
  const classes = useStyles();
  const { experimentId, type, previewMode, setIsValidSubmit } = props;
  const dispatch = useDispatch();
  // const { showTutorial } = useSelector((state) => ({
  //   showTutorial: state.experiments[experimentId].showTutorial,
  // }));

  useEffect(() => {
    if (setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    if (previewMode) {
      setIsValidSubmit(true);
    }
  }, [previewMode]);

  const getTutorial = makeGetTutorial();
  const { tutorial } = useSelector((state) => ({
    tutorial: getTutorial(state, props),
  }));

  const defaultContent = DEFAULT_TUTORIAL_CONTENTS[type];

  const [value, setValue] = useState(
    tutorial.length === 0 || tutorial[0].text === undefined
      ? defaultContent
      : tutorial[0].text
  );

  useEffect(() => {
    if (tutorial.length !== 0) {
      dispatch(selectTutorial(tutorial[0].id));
    }
  }, []);

  return (
    <>
      {/* <Grid container align="left">
        <Grid item xs>
      
        </Grid>
      </Grid> */}
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        // style={{ marginTop: "20px" }}
      >
        Tutorial
      </Typography>
      <Divider variant="fullWidth" />
      <ReactQuill
        className={classes.quill}
        theme={null}
        value={value}
        readOnly={true}
        modules={{ toolbar: false }}
      />
    </>
  );
}

export default TutorialLive;
