import uniqueId from "utils/uniqueId";
import { createSelector } from "reselect";
import { alertServerError } from "actions/ui";
import { url, requestInfo } from "actions/index";
import {
  createTask,
  createTutorial,
  createSurvey,
  createQuestion,
  createOption,
  createChart,
  updateChart,
  createVariable,
} from "actions";
import { dateDescending, createdDateAscending, idxAscending } from "utils/date";
import {
  DEFAULT_BUBBLEVIEW_PARAMS,
  DEFAULT_MEMORABILITY_PARAMS,
  DEFAULT_TEMPLATE_TITLE_DESCRIPTION,
} from "utils/textResources";

// action types
export const CREATE_EXPERIMENT = "CREATE_EXPERIMENT";
export const UPDATE_EXPERIMENT = "UPDATE_EXPERIMENT";
export const DELETE_EXPERIMENT = "DELETE_EXPERIMENT";
export const DUPLICATE_EXPERIMENT = "DUPLICATE_EXPERIMENT";
export const REQUEST_EXPERIMENTS = "REQUEST_EXPERIMENTS";
export const RECEIVE_EXPERIMENTS = "RECEIVE_EXPERIMENTS";
export const REQUEST_EXPERIMENT_CONTENT = "REQUEST_EXPERIMENT_CONTENT";
export const RECEIVE_EXPERIMENT_CONTENT = "RECEIVE_EXPERIMENT_CONTENT";
export const REQUEST_EXPERIMENTS_PUBLIC = "REQUEST_EXPERIMENTS_PUBLIC";
export const REQUEST_EXPERIMENTS_REMIXES = "REQUEST_EXPRERIMENTS_REMIXES";

const path_dict = {
  REQUEST_EXPERIMENTS: "request_experiments",
  DELETE_EXPERIMENT: "delete_experiment",
  CREATE_EXPERIMENT: "create_experiment",
  UPDATE_EXPERIMENT: "update_experiment",
  DUPLICATE_EXPERIMENT: "DUPLICATE_EXPERIMENT",
  REQUEST_EXPERIMENT_CONTENT: "request_experiment_content",
  REQUEST_EXPERIMENTS_PUBLIC: "request_experiments_public",
  REQUEST_EXPRERIMENTS_REMIXES: "request_experiments_remixes",
};

// actions
export const createExperiment = (
  username,
  history,
  type,
  attrs = {},
  duplicate = false
) => {
  return async (dispatch) => {
    let experimentId = !duplicate ? uniqueId() : attrs.id;
    let createdAt = Date.now();
    if (!duplicate) {
      attrs = {
        ...attrs,
        username,
        id: experimentId,
        title: DEFAULT_TEMPLATE_TITLE_DESCRIPTION[type].title,
        desc: DEFAULT_TEMPLATE_TITLE_DESCRIPTION[type].description,
        coverImage: null,
        type: type,
        screening: { show: false, isInclusion: "Exclusion" },
        showTutorial: false,
        showPractice: false,
        showConsent: false,
        showDemographicSurvey: false,
        showPostSurvey: false,
        isLaunched: false,
        shareState: "private",
        parentRemix: null,
        tags: { chartTags: [], dataTags: [], taskTags: [] },
        isFeatured: false,
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    }

    let action = { type: CREATE_EXPERIMENT, experimentId, username, attrs };
    // dispatch(action);
    let path = path_dict[action.type];

    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
          if (!duplicate) {
            if (type === "Memorability") {
              dispatch(
                createTask(experimentId, {
                  params: DEFAULT_MEMORABILITY_PARAMS,
                })
              );
            } else if (type === "Attention Tracking") {
              dispatch(
                createTask(experimentId, {
                  params: DEFAULT_BUBBLEVIEW_PARAMS,
                })
              );
            } else if (type === "Graphical Perception") {
              dispatch(createTask(experimentId, { params: {} }));
            }

            dispatch(createTutorial(experimentId));

            history.push(`/experiment/${experimentId}/edit`);
          }
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateExperiment = (experimentId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_EXPERIMENT, experimentId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteExperiment = (experimentId) => {
  return async (dispatch) => {
    let action = { type: DELETE_EXPERIMENT, experimentId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify({ experimentId: experimentId }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const duplicateExperiment = (
  sourceExperimentId,
  username,
  states,
  history
) => {
  return async (dispatch) => {
    // single
    /////////
    const newExperimentId = uniqueId("");
    let experimentAttrs = {
      ...states.experiments[sourceExperimentId],
      title: "[Remixed] " + states.experiments[sourceExperimentId].title,
      username: username,
      id: newExperimentId,
      _id: newExperimentId,
      isLaunched: false,
      shareState: "private",
      parentRemix: sourceExperimentId,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    // console.log(experimentAttrs);
    dispatch(createExperiment(username, null, "", experimentAttrs, true));

    const newTaskId = uniqueId("task_");
    const taskId = Object.values(states.tasks).find(
      (task) => task.experimentId === sourceExperimentId
    ).id;
    // console.log(taskId, states, states.tasks[taskId]);
    let taskAttrs = {
      ...states.tasks[taskId],
      experimentId: newExperimentId,
      id: newTaskId,
      _id: newTaskId,
      params: { ...states.tasks[taskId].params },
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    // parameter copy.. deep copy
    // console.log(taskAttrs);
    dispatch(createTask(newExperimentId, taskAttrs, true));
    // console.log(taskId);

    const newTutorialId = uniqueId("tutorial_");
    const tutorialId = Object.values(states.tutorials).find(
      (tutorial) => tutorial.experimentId === sourceExperimentId
    ).id;
    if (tutorialId) {
      let tutorialAttrs = {
        ...states.tutorials[tutorialId],
        experimentId: newExperimentId,
        id: newTutorialId,
        _id: newTutorialId,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      // fetchFunc({ type: CREATE_TUTORIAL, newTutorialId, tutorialAttrs });
      dispatch(createTutorial(newExperimentId, tutorialAttrs, true));
    }
    // console.log(tutorialId);

    ///////////
    // Multple
    //////////
    const chartIds = Object.values(states.charts)
      .filter((chart) => chart.taskId === taskId)
      .map((c) => c.id);
    chartIds.map((chartId) => {
      let newChartId = uniqueId("chart_");
      let chartAttrs = {
        ...states.charts[chartId],
        id: newChartId,
        _id: newChartId,
        taskId: newTaskId,
        chartId: newChartId,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      // console.log(states.charts[chartId], chartAttrs);
      // fetchFunc({ type: CREATE_CHART, chartId, chartAttrs });
      dispatch(createChart(newTaskId, chartAttrs, true));
    });
    // console.log(chartIds);
    // } else {
    //   const variableIds = Object.values(states.variables)
    //     .filter((variable) => variable.taskId === taskId)
    //     .map((v) => v.id);
    //   console.log(variableIds);
    //   variableIds.map((variableId) => {
    //     let newVariableId = uniqueId("variable_");

    //     let newConditions = [];
    //     let conditions = {};
    //     Object.entries(states.variables[variableId].conditions).map(
    //       ([key, condition]) => {
    //         let newConditionId = uniqueId("condition_");
    //         let temp = {
    //           // ...condition[key],
    //           combLabel: [
    //             {
    //               label: condition.combLabel[0].label,
    //               variableId: newVariableId,
    //             },
    //           ],
    //           id: newConditionId,
    //           key: newConditionId,
    //           variableId: newVariableId,
    //           label: condition.label,
    //           design: condition.design,
    //         };
    //         conditions[key] = {
    //           chartId: condition.id,
    //           label: condition.label,
    //           newConditionId: newConditionId,
    //           source: condition.source,
    //         };
    //         newConditions.push(temp);
    //       }
    //     );

    //     let variableAttrs = {
    //       // ...states.variables[variableId],
    //       id: newVariableId,
    //       _id: newVariableId,
    //       taskId: newTaskId,
    //       conditions: newConditions,
    //       createdAt: Date.now(),
    //       updatedAt: Date.now(),
    //       color: "",
    //     };
    //     dispatch(createVariable(newTaskId, variableAttrs, true));

    //     Object.values(conditions).map((condition) => {
    //       // let attrs = {
    //       //   ...states.charts[condition.chartId],
    //       //   id: condition.newConditionId,
    //       //   _id: condition.newConditionId,
    //       //   taskId: newTaskId,
    //       //   chartId: condition.newConditionId,
    //       //   label: condition.label,
    //       //   // source: condition.source,
    //       //   createdAt: Date.now(),
    //       //   updatedAt: Date.now(),
    //       // };
    //       dispatch(
    //         updateChart(condition.newConditionId, { source: condition.source })
    //       );
    //     });
    //   });
    //   console.log(taskId, sourceExperimentId);
    // }

    const surveyIds = Object.values(states.surveys)
      .filter((survey) =>
        [taskId, sourceExperimentId].includes(survey.parentId)
      )
      .map((s) => s.id);
    if (surveyIds.length) {
      surveyIds.map((surveyId) => {
        let newSurveyId = uniqueId("survey_");

        // questions start
        const questionIds = Object.values(states.questions)
          .filter((question) => question.surveyId === surveyId)
          .map((q) => q.id);
        // console.log(questionIds);
        questionIds.map((questionId) => {
          let newQuestionId = uniqueId("question_");

          /// options start
          const optionIds = Object.values(states.options)
            .filter((option) => option.questionId === questionId)
            .map((o) => o.id);
          // console.log(optionIds);
          optionIds.map((optionId) => {
            let newOptionId = uniqueId("option_");
            let optionAttrs = {
              ...states.options[optionId],
              id: newOptionId,
              _id: newOptionId,
              questionId: newQuestionId,
              createdAt: Date.now(),
              updatedAt: Date.now(),
            };
            // fetchFunc({ type: CREATE_OPTION, newOptionId, optionAttrs });
            dispatch(
              createOption(newQuestionId, null, optionAttrs, false, true)
            );
          });
          // options end

          let questionAttrs = {
            ...states.questions[questionId],
            id: newQuestionId,
            _id: newQuestionId,
            surveyId: newSurveyId,
            createdAt: Date.now(),
            updatedAt: Date.now(),
          };
          // fetchFunc({ type: CREATE_QUESTION, newQuestionId, questionAttrs });
          dispatch(
            createQuestion(newSurveyId, questionAttrs, false, null, true)
          );
        });
        // questions end
        let newParentId =
          states.surveys[surveyId].type === "perceptionSurvey"
            ? newTaskId
            : newExperimentId;
        let surveyAttrs = {
          ...states.surveys[surveyId],
          id: newSurveyId,
          _id: newSurveyId,
          parentId: newParentId,
          createdAt: Date.now(),
          updatedAt: Date.now(),
        };
        // fetchFunc({ type: CREATE_SURVEY, newSurveyId, surveyAttrs });
        dispatch(createSurvey(newParentId, surveyAttrs, true));
      });
    }
    history.push(`/experiment/${newExperimentId}/edit`);
  };
  // variable;
  // responses;
};

export const requestExperimentsPublic = () => {
  return async (dispatch) => {
    let action = { type: REQUEST_EXPERIMENTS_PUBLIC };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(receiveExperiments(resp.data));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const requestExperimentsRemixes = (experimentId) => {
  return async (dispatch) => {
    let action = { type: REQUEST_EXPERIMENTS_REMIXES, experimentId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(receiveExperiments(resp.data));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const requestExperiments = (username) => {
  return async (dispatch) => {
    let action = { type: REQUEST_EXPERIMENTS, username };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(receiveExperiments(resp.data));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const receiveExperiments = (experiments) => {
  return { type: RECEIVE_EXPERIMENTS, experiments };
};

export const requestExperimentContent = (
  username,
  experimentId,
  viewMode = false
) => {
  return async (dispatch) => {
    let action = {
      type: REQUEST_EXPERIMENT_CONTENT,
      username,
      experimentId,
      viewMode,
    };
    // console.log(action);
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(receiveExperimentContent(resp.data));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
    // const json = await response.json();
    // await dispatch(receiveExperimentContent(json));
  };
};

export const receiveExperimentContent = (data) => {
  // console.log(data);
  return { type: RECEIVE_EXPERIMENT_CONTENT, ...data };
};

//selectors
export const makeGetExperimentsByUser = () =>
  createSelector(
    (state) => state.auth.user.uid,
    (state) => state.experiments,
    (username, experiments) =>
      username
        ? Object.values(experiments)
            .filter((experiment) => experiment.username === username)
            .sort(dateDescending)
        : [] // return annotations for the panel
  );

export const makeGetPublicExperiments = () =>
  createSelector(
    (state) => state.experiments,
    (experiments) =>
      Object.values(experiments).filter(
        (experiment) =>
          experiment.isFeatured === true || experiment.shareState !== "private"
      )
  );

export const makeGetRemixes = () =>
  createSelector(
    (state, ownProps) => ownProps.experimentId,
    (state) => state.experiments,
    (experimentId, experiments) =>
      Object.values(experiments).filter(
        (experiment) => experiment.parentRemix === experimentId
      )
  );

export const makeGetQuestionnaire = () => {
  return createSelector(
    (state, ownProps) => ownProps.surveyId,
    (state) => state.questions,
    (state) => state.options,
    (surveyId, questions, options) => {
      questions = Object.values(questions).sort(createdDateAscending);
      options = Object.values(options).sort(idxAscending);
      return [
        ...questions
          .filter((q) => q.surveyId === surveyId)
          .map((q) => {
            return {
              ...q,
              options: options.filter((o) => o.questionId === q.id), //
            };
          }),
      ];
    }
  );
};
