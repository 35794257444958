import {
  SELECT_EXPERIMENT,
  OPEN_EXPERIMENT,
  CREATE_EXPERIMENT,
  DELETE_EXPERIMENT,
  SELECT_QUESTION,
  SELECT_OPTION,
  SELECT_CHART,
  SELECT_CONSENT,
  SELECT_TUTORIAL,
  // SELECT_DEMOGRAPHIC_QUESTION,
  // SELECT_DEMOGRAPHIC_OPTION,
  // SELECT_POST_QUESTION,
  // SELECT_POST_OPTION,
  SELECT_SURVEY,
  SELECT_VARIABLE,
  // SELECT_BUBBLEVIEW_PARAMS,
  // SELECT_MEMORABILITY_PARAMS,
  SELECT_TASK,
  REQUEST_EXPERIMENT_CONTENT,
  REQUEST_EXPERIMENTS,
  RECEIVE_EXPERIMENT_CONTENT,
  RECEIVE_EXPERIMENTS,
  ALERT_SERVER_ERROR,
  RELEASE_SERVER_ERROR,
} from "actions";

let initState = {
  selectedExperiment: null,
  selectedQuestion: null,
  selectedOption: null,
  selectedChart: null,
  selectedConsent: null,
  selectedTutorial: null,
  selectedSurvey: null,
  selectedVariable: null,
  selectedTask: null,
  isFetching: null,
  serverError: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SELECT_EXPERIMENT:
      return {
        ...state,
        selectedExperiment: action.experimentId,
      };
    case OPEN_EXPERIMENT:
    case CREATE_EXPERIMENT:
      return {
        ...state,
        selectedExperiment: action.experimentId,
        selectedQuestion: null,
        selectedOption: null,
        selectedChart: null,
        selectedConsent: null,
        selectedTutorial: null,
        selectedSurvey: null,
        selectedVariable: null,
        selectedTask: null,
      };
    case DELETE_EXPERIMENT:
      return {
        ...state,
        selectedExperiment:
          action.experimentId === state.selectedExperiment
            ? null
            : state.selectedExperiment,
      };
    case SELECT_QUESTION:
      return {
        ...state,
        selectedQuestion: action.questionId,
        selectedOption: !action.questionId ? null : state.selectedOption,
      };
    case SELECT_OPTION:
      return {
        ...state,
        selectedOption: action.optionId,
      };
    case SELECT_CHART:
      return {
        ...state,
        selectedChart: action.chartId,
      };
    case SELECT_CONSENT:
      return {
        ...state,
        selectedConsent: action.consentId,
      };
    case SELECT_TUTORIAL:
      return {
        ...state,
        selectedTutorial: action.tutorialId,
      };
    case SELECT_SURVEY:
      return {
        ...state,
        selectedSurvey: action.surveyId,
      };
    case SELECT_VARIABLE:
      return {
        ...state,
        selectedVariable: action.variableId,
      };
    case SELECT_TASK:
      return {
        ...state,
        selectedTask: action.taskId,
      };
    case REQUEST_EXPERIMENT_CONTENT:
    case REQUEST_EXPERIMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_EXPERIMENT_CONTENT:
    case RECEIVE_EXPERIMENTS:
      return {
        ...state,
        isFetching: false,
      };
    // case NOTIFY_AUTH_FAILURE:
    // 	return {
    // 		...state,
    // 		serverError: action.status
    // 	};
    case ALERT_SERVER_ERROR:
      return {
        ...state,
        serverError: action.message.details
          ? action.message.details
          : "There was a communication error with the server. Please try refresh the page",
      };
    case RELEASE_SERVER_ERROR:
      return {
        ...state,
        serverError: null,
      };
    default:
      return state;
  }
};
