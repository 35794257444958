import _ from "underscore";

export function standardDeviation(values) {
  // console.log(values);
  var avg = average(values);

  var squareDiffs = values.map(function (value) {
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  });

  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev.toFixed(2);
}

export function average(data) {
  // console.log(data);
  var sum = data.reduce(function (sum, value) {
    return sum + value;
  }, 0);

  var avg = sum / data.length;
  if (avg === 100 || avg === 0) {
    return avg;
  } else {
    return avg.toFixed(2);
  }
}

export const median = (arr) => {
  // console.log(arr);
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  const res =
    arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;

  try {
    return res.toFixed(2);
  } catch (TypeError) {
    return "No Answer";
  }
};

export const max = (arr) => {
  // console.log(arr);
  const max = Math.max(...arr);
  try {
    return max;
  } catch (TypeError) {
    return "No Answer";
  }
};

export const min = (arr) => {
  // console.log(arr);
  const min = Math.min(...arr);

  try {
    return min;
  } catch (TypeError) {
    return "No Answer";
  }
};

export function answerRate(data) {
  // console.log(data);
  var sum = data.reduce(function (sum, value) {
    return sum + value;
  }, 0);

  var rate = sum / data.length;
  return rate.toFixed(2);
}

export function rank(data, myData, order) {
  // console.log(data, myData);
  // Insert num into arr, creating a new array.
  let newArray = data.concat(myData);
  //             [40, 60].concat(50)
  //             [40, 60, 50]

  // Sort the new array from least to greatest.
  if (order === "ascending") {
    newArray.sort((a, b) => a - b);
  } else {
    newArray.sort((a, b) => b - a);
  }
  // [40, 60, 50].sort((a, b) => a - b)
  // [40, 50, 60]

  // Return the index of num which is now
  // in the correct place in the new array.
  return newArray.indexOf(myData) + 1;
  // return [40, 50, 60].indexOf(50)
  // 1
}

export function sum(data) {
  return data.reduce(function (sum, value) {
    return sum + value;
  }, 0);
}
