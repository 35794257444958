import React, { useEffect } from "react";

import { Box, Typography, Divider } from "@material-ui/core";
export default function Startline(props) {
  const { setIsValidSubmit } = props;
  useEffect(() => {
    setIsValidSubmit(true);
  }, []);
  return (
    <>
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        // style={{ marginTop: "20px" }}
      >
        Main Trial
      </Typography>
      <Divider variant="fullWidth" />
      <Box mt={5}>
        <Typography variant="subtitle1">
          Click Next to begin the main trial.
        </Typography>
      </Box>
    </>
  );
}
