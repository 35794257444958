import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { signUp } from "actions/auth";

// import style from "components/Auth/SignupPage.module.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Paper,
  Box,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  container: {
    // display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    marginTop: "50px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  errorText: {
    color: "#f50057",
    marginBottom: "5",
    textAlign: "center",
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    background: "black",
  },
  infoText: {
    color: "green",
    marginBottom: "5",
    textAlign: "center",
  },
}));

function SignupPage(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    passwordOne: "",
    passwordTwo: "",
  });
  const [isValid, setIsValid] = useState(false);

  const { isAuthenticated, signupError } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    signupError: state.auth.signupError,
  }));

  const handelInputChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signUp(state.email, state.passwordOne));
  };

  useEffect(() => {
    setIsValid(
      state.passwordOne !== "" &&
        state.email !== "" &&
        state.passwordOne === state.passwordTwo
    );
  }, [state]);
  console.log(
    state,
    isValid,
    state.passwordOne !== "",
    state.email !== "",
    state.passwordOne === state.passwordTwo
  );

  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/experiment" />
      ) : (
        <>
          <Box className={classes.container}>
            <Paper className={classes.paper} elevation={0}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handelInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordOne"
                label="Password"
                type="password"
                id="password"
                onChange={handelInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordTwo"
                label="Confirm Password"
                type="password"
                id="password"
                onChange={handelInputChange}
              />
              {EXPERIMENTAL_FEATURE && signupError && (
                <Typography component="p" className={classes.infoText}>
                  We have sent a link to your email. Please verify your email
                  address by clicking the link.
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid}
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}

export const SignUpLink = () => (
  <Link to="/signup" component={RouterLink} variant="button">
    Create an account
  </Link>
);

export default SignupPage;
