import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ChartStimuli from "components/ChartStimuli";

import { makeGetTasks } from "actions/tasks";
import { makeGetVariables } from "actions/variables";
import { makeGetSurveys } from "actions/surveys";
import { makeGetQuestionnaire } from "actions/experiments";
import { makeGetCharts } from "actions";
import { getExperimentCharts } from "utils/variables";

import {
  Button,
  Grid,
  FormControlLabel,
  Radio,
  Checkbox,
  Snackbar,
  MobileStepper,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import _ from "underscore";

let startTime, endTime;

function Live(props) {
  const {
    handleAnswers,
    handleNextStep,
    setIsValidSubmit,
    isValidSubmit,
    handleSubmit,
  } = props;

  // handle answers : Chart level
  const [responseCharts, setResponseCharts] = useState({
    charts: {},
  });

  const [response, setResponse] = useState({
    answer: {},
  });

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  // const getVariables = makeGetVariables();
  // const { variables } = useSelector((state) => ({
  //   variables: getVariables(state, {
  //     taskId: tasks[0].id,
  //   }),
  // }));
  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  const getSurveys = makeGetSurveys();
  const { surveys } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: tasks[0].id,
      type: "perceptionSurvey",
    }),
  }));

  const getQuestions = makeGetQuestionnaire();
  const { _questions } = useSelector((state) => ({
    _questions: getQuestions(state, {
      surveyId: surveys[0].id,
    }),
  }));
  const questions = _questions.filter((q) => q.text !== "");

  const [experimentCharts, setExperimentCharts] = useState([]);

  const [selectedExperimentChart, setSelectedExperimentChart] = useState();

  // ui
  /// Question level
  let maxSteps = questions.length;
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // Question navigation handlers
  const [activeStep, setActiveStep] = useState(0);
  const [isValidNextChart, setIsValidNextChart] = useState(false);
  const [isTimeRecorded, setIsTimeRecorded] = useState(false);
  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [isValidNextQuestion, setIsValidNextQuestion] = useState(false);

  useEffect(() => {
    setIsValidNextQuestion(false);
  }, [selectedQuestion]);

  useEffect(() => {
    setIsValidSubmit(false);
    // setIsValidNextStep(false);
    // setIsValidNextChart(false);

    // const res = getExperimentCharts(variables);
    // console.log(res);
    // console.log(charts);
    const res = _.sample(charts, Math.floor(charts.length / 2));
    // console.log(res);
    // const between = [...charts[Math.floor(Math.random() * charts.length)]];
    // console.log(between);
    // const res = between.sort(function () {
    //   return 0.5 - Math.random();
    // });
    setExperimentCharts(res);
  }, []);

  useEffect(() => {
    if (experimentCharts.length > 0) {
      setSelectedExperimentChart(experimentCharts[0].id);
    }
  }, [experimentCharts]);

  // when next stimulus
  useEffect(() => {
    if (questions.length > 0) {
      setSelectedQuestion(questions[0].id);
      setActiveStep(0);
      maxSteps = questions.length;
      setIsValidNextChart(false);
      setResponse({ answer: {} });
    }
  }, [selectedExperimentChart]);

  // when questions are loaded
  useEffect(() => {
    if (questions.length > 0 && selectedQuestion === null) {
      setSelectedQuestion(questions[0].id);
      setActiveStep(0);
      maxSteps = questions.length;
    }
  }, [questions.length]);

  // when next question
  useEffect(() => {
    if (selectedQuestion) {
      startTime = new Date();
      setIsTimeRecorded(false);
    }
  }, [selectedQuestion, selectedExperimentChart]);

  useEffect(() => {
    if (
      // experimentCharts.every((item) => responseCharts.charts[item.key]) &&
      // responseCharts.charts !== {}
      experimentCharts.length > 0 &&
      experimentCharts.every((item) => responseCharts.charts[item.id])
    ) {
      // console.log(experimentCharts, responseCharts);
      setIsValidNextStep(true);
      handleAnswers(responseCharts);
      // isValidSubmitChart가 true가 되고 isValidSubmit이 true가 되는데, isValidSubmitChart가 먼저 true가 되서 차트가 바뀌게 된다.
      // response -> responseChart로 보냄 & setIsValidSubmitChart(true) -> Next Chart button
      // next chart button 누르지 않았는데 차트 그림이 바뀌는 것이 문제
      // chart그림은 selectedExperimentChart가 바뀌면서 QuestionAndVis가 rerender 되어 바뀌게 됨

      // window.localStorage.setItem('myResponse', JSON.stringify(response));
    } else {
      setIsValidNextStep(false);
    }
  }, [responseCharts]);

  // for submit questionnaires
  useEffect(() => {
    if (
      questions.every((item) => response.answer[item.id]) &&
      selectedExperimentChart !== null &&
      isTimeRecorded
    ) {
      handleAnswersCharts(selectedExperimentChart, response);
      setIsValidNextChart(true);
    } else {
      setIsValidNextChart(false);
    }
  }, [response]);

  const handleAnswersCharts = (chartId, ans) => {
    setResponseCharts({
      ...responseCharts,
      charts: {
        ...responseCharts.charts,
        [chartId]: {
          answer: { ...ans.answer },
          time: { ...ans.time },
        },
      },
    });
  };

  const handleNextChart = () => {
    let index = experimentCharts.findIndex(
      (q) => q.id === selectedExperimentChart
    );
    index = index + 1;
    // console.log(index, selectedExperimentChart);

    if (index < experimentCharts.length) {
      setSelectedExperimentChart(experimentCharts[index].id);
    }
  };

  const handleEndTime = () => {
    let question = getQuestion();
    endTime = new Date();
    // console.log(startTime, endTime);
    const timeDiff = endTime - startTime;

    // let div = document.createElement("div");
    // div.innerHTML = (timeDiff / 1000).toFixed(3);
    // document.body.appendChild(div);

    setResponse({
      ...response,
      time: {
        ...response.time,
        [question.id]: (timeDiff / 1000).toFixed(3),
      },
    });
    setIsTimeRecorded(true);
    // isTimeRecorded = true;
  };

  const handlePrevQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index -= 1;
    if (index >= 0) {
      setSelectedQuestion(questions[index].id);
    } else {
      setSelectedQuestion(null);
    }
    setActiveStep(index);
  };

  const handleNextQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index += 1;
    if (index < questions.length) {
      setSelectedQuestion(questions[index].id);
      setActiveStep(index);
    } else {
    }
    // setActiveStep(index);
  };

  // problem: when click the answer, chart is changed immediately.
  // response state changes, setIsValidNextChart true
  const handleRadioChange = (event) => {
    let question = getQuestion();
    // endTime = new Date();
    // const timeDiff = endTime - startTime;
    // console.log(timeDiff + ' ms');
    setResponse({
      ...response,
      answer: {
        ...response.answer,
        [question.id]: event.target.value,
      },
      // time: {
      //   ...response.time,
      //   [question.id]: (timeDiff / 1000).toFixed(3),
      // },
    });
    setIsValidNextQuestion(true);
  };

  const handleCheckboxChange = (optionId, event) => {
    let question = getQuestion();
    // endTime = new Date();
    // const timeDiff = endTime - startTime;
    // console.log(endTime, startTime);
    // console.log(timeDiff + ' ms');
    setResponse({
      ...response,
      answer: {
        ...response.answer,
        [question.id]: {
          ...(response.answer[question.id] || {}),
          [optionId]: event.target.checked,
        },
      },
      // time: {
      //   ...response.time,
      //   [question.id]: (timeDiff / 1000).toFixed(3),
      // },
    });
    setIsValidNextQuestion(true);
  };

  const handleTextAnswers = (e) => {
    let question = getQuestion();
    // endTime = new Date();
    // const timeDiff = endTime - startTime;
    setResponse({
      ...response,
      answer: {
        ...response.answer,
        [question.id]: e.target.value,
      },
      // time: {
      //   ...response.time,
      //   [question.id]: (timeDiff / 1000).toFixed(3),
      // },
    });
    setIsValidNextQuestion(true);
  };

  const getQuestion = () => {
    // console.log(questions, selectedQuestion);
    return questions.find((q) => q.id === selectedQuestion);
  };

  // console.log(isValidNextChart, isValidNextStep, isValidSubmit);

  const nextButtonText = () => {
    if (isValidSubmit && isValidNextStep && isTimeRecorded) {
      return "Submit";
    } else if (isValidNextStep && isTimeRecorded) {
      return "Go to Next Page";
    } else if (isValidNextChart && isTimeRecorded) {
      return "Go to Next Chart";
    } else {
      return "Next question";
    }
  };

  const nextButtonAction = () => {
    if (isValidSubmit && isValidNextStep && isTimeRecorded) {
      handleSubmit();
    } else if (isValidNextStep && isTimeRecorded) {
      handleNextStep();
    } else if (isValidNextChart && isTimeRecorded) {
      handleNextChart();
    } else {
      handleEndTime();
      handleNextQuestion();
    }
  };

  const render = () => {
    let curQuestion = getQuestion();
    // console.log(selectedExperimentChart);

    return (
      <div>
        {curQuestion && (
          <>
            {selectedQuestion !== null && (
              <>
                {/* <Grid container align="left">
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      display="block"
                      gutterBottom
                      style={{ marginTop: "20px" }}
                    >
                      Main Task
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    align="center"
                    spacing={2}
                  >
                    <Grid item xs>
                      <ChartStimuli
                        type="live"
                        chartId={selectedExperimentChart}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item align="left">
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                        style={{ marginTop: "20px" }}
                      >
                        {curQuestion.text === " "
                          ? "Question"
                          : curQuestion.text}
                      </Typography>
                      {curQuestion.type === "paragraph" && (
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={5}
                          onChange={(e) => handleTextAnswers(e)}
                          value={
                            response.answer[curQuestion.id] === undefined
                              ? ""
                              : response.answer[curQuestion.id]
                          }
                        />
                      )}
                      {curQuestion.type === "short-answer" && (
                        <TextField
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleTextAnswers(e)}
                          value={
                            response.answer[curQuestion.id] === undefined
                              ? ""
                              : response.answer[curQuestion.id]
                          }
                          type="number"
                          label="Number only"
                        />
                      )}
                      {curQuestion.type === "checkboxes" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {curQuestion.options.map((option) => (
                            <FormControlLabel
                              key={option.id}
                              label={
                                option.text === "" ? "Option" : option.text
                              }
                              value={option.id}
                              data-id={option.id}
                              control={
                                <Checkbox
                                  style={{ margin: "5px" }}
                                  checked={
                                    response.answer[curQuestion.id]
                                      ? response.answer[curQuestion.id][
                                          option.id
                                        ]
                                      : false
                                  }
                                />
                              }
                              onChange={(e) =>
                                handleCheckboxChange(option.id, e)
                              }
                            />
                          ))}
                        </div>
                      )}
                      {curQuestion.type === "choice" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {curQuestion.options.map((option) => (
                            <FormControlLabel
                              label={
                                option.text === "" ? "Option" : option.text
                              }
                              value={option.id}
                              key={option.id}
                              data-id={option.id}
                              control={
                                <Radio
                                  style={{ margin: "5px" }}
                                  checked={
                                    response.answer[curQuestion.id] ===
                                    option.id
                                  }
                                />
                              }
                              onChange={(e) => handleRadioChange(e)}
                            />
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      variant={
                        isValidNextStep || isValidNextChart ? "contained" : null
                      }
                      disableElevation
                      color={
                        isValidNextStep || isValidNextChart
                          ? "primary"
                          : "default"
                      }
                      size="small"
                      onPointerUp={() => {
                        nextButtonAction();
                      }}
                      disabled={!isValidNextQuestion}
                      // disabled={activeStep === maxSteps - 1}
                    >
                      {nextButtonText()}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onPointerUp={handlePrevQuestion}
                      // disabled={activeStep === 0}
                      disabled={true}
                    >
                      Previous Question
                    </Button>
                  }
                />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return render();
}

export default Live;
