export function dateDescending(a, b) {
  var dateA = new Date(a["updatedAt"]).getTime();
  var dateB = new Date(b["updatedAt"]).getTime();
  return dateA < dateB ? 1 : -1;
}

export function dateAscending(a, b) {
  var dateA = new Date(a["updatedAt"]).getTime();
  var dateB = new Date(b["updatedAt"]).getTime();
  return dateA > dateB ? 1 : -1;
}

export function createdDateAscending(a, b) {
  var dateA = new Date(a["createdAt"]).getTime();
  var dateB = new Date(b["createdAt"]).getTime();
  return dateA > dateB ? 1 : -1;
}

export function createdDateDescending(a, b) {
  var dateA = new Date(a["createdAt"]).getTime();
  var dateB = new Date(b["createdAt"]).getTime();
  return dateA < dateB ? 1 : -1;
}

export function titleDescending(a, b) {
  return a["title"] < b["title"] ? 1 : -1;
}

export function titleAscending(a, b) {
  return a["title"] > b["title"] ? 1 : -1;
}

export function idxAscending(a, b) {
  return a["idx"] > b["idx"] ? 1 : -1;
}

export function templateAscending(a, b) {
    return a["type"] > b["type"] ? 1 : -1;
}

// return time elapsed
export function calcTimeElapsed(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = Math.floor(seconds / 31556926);

  if (interval >= 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
