export * from "./auth";
export * from "./experiments";
export * from "./questions";
export * from "./options";
export * from "./ui";
export * from "./charts";
export * from "./responses";
export * from "./consent";
export * from "./variables";
export * from "./tasks";
export * from "./surveys";
export * from "./tutorials";

export const url =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:8080"
    : window.location.protocol + "//" + window.location.host; //"https://vislab-test-gbcrb2cilq-uc.a.run.app"
console.log(url, process.env.REACT_APP_ENV);
export const requestInfo = {
  method: "POST",
  mode: "cors",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Content-Type": "application/json",
  },
};
