import React, { useState } from "react";
import { useDispatch } from "react-redux";
import vegaEmbed from "vega-embed";

import { updateChart } from "actions/charts";

function FileLoader(props) {
  const dispatch = useDispatch();
  const { chartId } = props;

  // const [errorMsg, setErrorMsg] = useState(null);
  const [dragging, setDragging] = useState(false);
  let dragTargetSaved = null;

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (props.onDrop) {
      props.onDrop(e);
    }
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    dragTargetSaved = e.target;
    setDragging(true);
    if (props.onDragEnter) {
      props.onDragEnter(e);
    }
  }

  function handleDragOver(e) {
    if (e.dataTransfer.types.includes("Files") == false) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (props.onDragOver) {
      props.onDragOver(e);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (props.onDragLeave && dragTargetSaved == e.target) {
      // if (props.onDragLeave) {
      props.onDragLeave(e);
    }
  }

  let child = React.Children.only(props.children);
  return React.cloneElement(child, {
    onDragEnter: handleDragEnter,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
    dragging: dragging,
    // errorMsg: errorMsg,
  });
}

export default FileLoader;
