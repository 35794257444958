import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import vegaEmbed from "vega-embed";

import FileLoader from "components/FileLoader";
import ChartStimuli from "components/ChartStimuli";
import UploadProgress from "components/Memorability/UploadProgress";

import { updateTask, makeGetTasks } from "actions/tasks";
import {
  createChart,
  updateChart,
  deleteChart,
  makeGetCharts,
} from "actions/charts";
import { selectTask, selectChart } from "actions/ui";
import { setUploadFile } from "actions/charts";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { EXPERIMENTAL_FEATURE } from "index";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
function Design(props) {
  const { experimentId, readOnly } = props;
  const dispatch = useDispatch();

  const [pauseTime, setPauseTime] = useState("1.5");
  const [displayTime, setDisplayTime] = useState("1.0");
  const [fillerUpload, setFillerUpload] = useState("default");

  const getTasks = makeGetTasks();
  const { tasks, selectedTask } = useSelector((state) => ({
    tasks: getTasks(state, props),
    selectedTask: state.ui.selectedTask,
  }));

  // console.log("tasks, selectedTask", tasks, selectedTask);

  const getCharts = makeGetCharts();
  const { charts, selectedChart } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: selectedTask,
    }),
    selectedChart: state.ui.selectedChart,
  }));

  useEffect(() => {
    if (tasks.length > 0) {
      dispatch(selectTask(tasks[0].id));
    }
  }, [tasks.length]);

  useEffect(() => {
    if (charts.length > 0 && selectedChart === null) {
      dispatch(selectChart(charts[0].id));
    }
  }, [charts.length]);

  useEffect(() => {
    if (selectedTask !== null) {
      let index = tasks.findIndex((q) => q.id === selectedTask);

      dispatch(
        updateTask(selectedTask, {
          params: {
            ...tasks[index].params,
            displayTime: displayTime,
            pauseTime: pauseTime,
            fillerUpload: fillerUpload,
          },
        })
      );
    }
  }, [displayTime, pauseTime, fillerUpload]);

  useEffect(() => {
    let curTaskParams;

    if (tasks.length > 0 && selectedTask) {
      let index = tasks.findIndex((task) => task.id === selectedTask);
      curTaskParams = tasks[index].params;

      setDisplayTime(curTaskParams.displayTime);
      setPauseTime(curTaskParams.pauseTime);
      setFillerUpload(curTaskParams.fillerUpload);
    }
  }, [selectedTask]);

  const handleAddChart = () => {
    let index = tasks.findIndex((task) => task.id === selectedTask);
    dispatch(createChart(tasks[index].id, {}));
  };

  const handleDeleteChart = () => {
    if (selectedChart) {
      let deleted = selectedChart;
      let index = charts.findIndex((b) => b.id === selectedChart);
      index -= 1;

      if (index >= 0) {
        dispatch(selectChart(charts[index].id));
      } else {
        dispatch(selectChart(null));
      }
      dispatch(deleteChart(deleted));
    }
  };

  const handleDisplayTimeChange = (event) => {
    setDisplayTime(event.target.value);
  };

  const handlePauseTimeChange = (event) => {
    setPauseTime(event.target.value);
  };

  const handleAttachFile = (e) => {
    // could do some validation for the attached file here
    dispatch(setUploadFile(e.target.files));
    e.target.value = ""; // to clear the current file
  };

  const handleFillerUploadChange = (event) => {
    setFillerUpload(event.target.value);
  };

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleReleaseError = () => {
    setShowError(false);
  };

  function handleDrop(e, chartId) {
    let file = e.dataTransfer.files[0];
    if (!file) {
      return;
    }

    if (file.type.match("application/json")) {
      // vega-lite
      const reader = new FileReader();

      // TODO: check
      reader.onload = async (e) => {
        const spec = JSON.parse(e.target.result);

        let div = document.createElement("div");
        div.style.width = "512px";
        div.style.height = "100%";
        document.body.appendChild(div);
        let result = await vegaEmbed(div, spec, {
          actions: false,
        });
        // console.log(result);
        result.view
          .toImageURL("png")
          .then(function (url) {
            // console.log("png:       ", url);
            dispatch(
              updateChart(chartId, {
                spec: JSON.stringify(spec),
                source: url === "data:," ? null : url,
                type: "vega",
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(div);
        document.body.removeChild(div);
      };

      reader.readAsText(file);
    } else if (file.type.match(/image.*/)) {
      if (file.size > 1000000) {
        setShowError(true);
        setErrorMsg("Size too big (>1MB)!");
        return;
      } else {
        let reader = new FileReader();
        reader.onloadend = (e) => {
          dispatch(
            updateChart(chartId, { type: "image", source: e.target.result })
          );
        };
        reader.readAsDataURL(file);
      }
    } else {
      setShowError(true);
      setErrorMsg("Wrong format!");
    }
  }

  return (
    <>
      <Box mt={2}>
        Provide your visualization design alternatives as experiment stimuli.
        Participants will be asked to recognize the visualizations on repeated
        occurrences during a sequence of random filler images. The recognition
        hit rate will be recorded for evaluating the memorability of the
        visualizations.
      </Box>
      {tasks.length > 0 && selectedTask !== null ? (
        <>
          <Box display="flex" alignItems="center" mt={2} mb={2}>
            <Typography variant="h6" display="block">
              Visualizations
            </Typography>
            &nbsp;
            <Tooltip title="Target visualizations will appear two times and participants are asked to press the space bar on the repeated appearance.">
              <InfoOutlinedIcon />
            </Tooltip>
          </Box>
          <Grid container spacing={3}>
            {Object.values(charts).map((chart) => (
              <Grid item key={chart.id}>
                <FileLoader
                  chartId={chart.id}
                  onDrop={!readOnly && ((e) => handleDrop(e, chart.id))}
                >
                  <ChartStimuli type="small" chartId={chart.id} />
                </FileLoader>
              </Grid>
            ))}
          </Grid>
          {!readOnly && (
            <div style={{ marginTop: "20px" }}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                onPointerUp={() => handleAddChart()}
                disabled={charts.length > 9 ? true : false}
              >
                Add Visualization
              </Button>
              {charts.length > 0 && (
                <Button onPointerUp={() => handleDeleteChart()}>
                  <DeleteOutlinedIcon />
                </Button>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            // style={{ marginTop: "20px" }}
          >
            Visualizations
          </Typography>
          {!readOnly && (
            <div style={{ marginTop: "10px" }}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                onPointerUp={() => handleAddChart()}
              >
                Add Visualization
              </Button>
            </div>
          )}
        </>
      )}

      {selectedTask !== null && (
        <Grid container spacing={2}>
          {selectedChart && (
            <Grid item xs>
              <Typography
                variant="h6"
                display="block"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Visualization Preview
              </Typography>
              <ChartStimuli type="live" chartId={selectedChart} />
            </Grid>
          )}

          <Grid item xs>
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              style={{ marginTop: "20px" }}
            >
              Parameters
            </Typography>
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Box display="flex" alignItems="center">
                    Display Time&nbsp;
                    <Tooltip title="Display Time determines how long an image will be displayed each time.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  // name="time"
                  value={displayTime}
                  onChange={handleDisplayTimeChange}
                >
                  <FormControlLabel
                    value={"0.5"}
                    control={<Radio />}
                    label={"0.5s"}
                  />
                  <FormControlLabel
                    value={"1.0"}
                    control={<Radio />}
                    label={"1.0s"}
                  />
                  <FormControlLabel
                    value={"1.5"}
                    control={<Radio />}
                    label={"1.5s"}
                  />
                  <FormControlLabel
                    value={"2.0"}
                    control={<Radio />}
                    label={"2.0s"}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Box display="flex" alignItems="center">
                    Pause Time&nbsp;
                    <Tooltip title="Pause Time determines the time gap between two consecutive images.">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  // name="time"
                  value={pauseTime}
                  onChange={handlePauseTimeChange}
                >
                  <FormControlLabel
                    value={"0.5"}
                    control={<Radio />}
                    label={"0.5s"}
                  />
                  <FormControlLabel
                    value={"1.0"}
                    control={<Radio />}
                    label={"1.0s"}
                  />
                  <FormControlLabel
                    value={"1.5"}
                    control={<Radio />}
                    label={"1.5s"}
                  />
                  <FormControlLabel
                    value={"2.0"}
                    control={<Radio />}
                    label={"2.0s"}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {EXPERIMENTAL_FEATURE ? (
              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Box display="flex" alignItems="center">
                      Filler Images&nbsp;
                      <Tooltip title="Filler images are random visualization images inserted among target images. They appear once.">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                  </FormLabel>

                  <RadioGroup
                    row
                    // name="time"
                    value={fillerUpload}
                    onChange={handleFillerUploadChange}
                  >
                    <FormControlLabel
                      value={"default"}
                      control={<Radio />}
                      label={"Default"}
                    />
                    <FormControlLabel
                      value={"customize"}
                      control={<Radio />}
                      label={"Customize"}
                    />
                  </RadioGroup>
                  {fillerUpload === "customize" ? (
                    <>
                      <input
                        id="upload-filler"
                        type="file"
                        multiple
                        onChange={handleAttachFile}
                        style={{ marginTop: "10px" }}
                      />

                      <div>
                        <UploadProgress experimentId={experimentId} />
                      </div>
                    </>
                  ) : (
                    <Typography variant="body2" style={{ marginTop: "10px" }}>
                      {120 - 30 - charts.length * 2} filler images will be
                      included.
                    </Typography>
                  )}
                </FormControl>
              </Box>
            ) : (
              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Box display="flex" alignItems="center">
                      Filler Images&nbsp;
                      <Tooltip title="Filler images are random visualization images inserted among target images. They appear once.">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                  </FormLabel>
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    {120 - 30 - charts.length * 2} filler images will be
                    included.
                  </Typography>
                </FormControl>
              </Box>
            )}
          </Grid>
        </Grid>
      )}

      {showError && (
        <Snackbar open={errorMsg !== null} autoHideDuration={500}>
          <Alert
            onClose={() => handleReleaseError()}
            variant="filled"
            severity="error"
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Design;
