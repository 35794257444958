import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Result from "components/Perception/Result";

import { updateResponse } from "actions/responses";

import {
  parsePerceptionData,
  parsePerceptionDataForExport,
} from "utils/dataProcessing";

import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Collapse,
  IconButton,
  Typography,
  Box,
  Button,
} from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import GetAppIcon from "@material-ui/icons/GetApp";

import _ from "underscore";
// import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  columns: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    width: "100%",
    margin: "0",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  header: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

export default function IndividualDashboard(props) {
  const { experimentId, responseId, responses } = props;
  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);

  const { questions, options, charts } = useSelector((state) => ({
    questions: state.questions,
    options: state.options,
    charts: state.charts,
  }));

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parsePerceptionData(
          responses,
          questions,
          options,
          true // forIndividualDashboard
        );
        // console.log(result);
        setData(result);
        // const newResult = result.map((r) => {
        //   if (r.accuracy < 0) {
        //     return {
        //       ...r,
        //       accuracy: 0,
        //     };
        //   } else {
        //     return r;
        //   }
        // });

        // setData(newResult);
      }
    }
    fetch();
  }, [responses.length]);

  function CollapseRow(props) {
    const { row } = props;
    console.log(row);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const { omitState } = useSelector((state) => ({
      omitState: state.responses[row.id].omit,
    }));
    const handleOmitCheck = (event) => {
      dispatch(updateResponse(row.id, { omit: event.currentTarget.checked }));
    };

    return (
      <>
        <Fragment key={row.id}>
          <TableRow>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">{row.ip}</TableCell>
            <TableCell align="right">{row.accuracy}</TableCell>
            <TableCell align="right">{row.taskTime}</TableCell>
            <TableCell align="right" padding="checkbox">
              <Checkbox checked={omitState} onChange={handleOmitCheck} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "none",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto">
                {open && (
                  <Box p={3} bgcolor="#f5f5f5">
                    <Result
                      experimentId={experimentId}
                      responseId={row.id}
                      responses={responses}
                      isResult={false}
                    />
                  </Box>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      </>
    );
  }

  function ExpandableRow(props) {
    const { rows } = props;

    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Identifier</TableCell>
              <TableCell align="right">Accuracy</TableCell>
              <TableCell align="right">Task Completion Time</TableCell>
              <TableCell align="right">Omit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <CollapseRow key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  async function fetchDataforExport() {
    if (responses.length > 0) {
      const result = await parsePerceptionDataForExport(
        responses,
        questions,
        options
      );
      setDataForExport(result);
    }
  }

  useEffect(() => {
    if (dataForExport.length > 0) {
      // console.log(dataForExport);
      let csvContent =
        "data:text/csv;charset=utf-8," +
        dataForExport.map((e) => Object.values(e).join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", experimentId + "_result.csv");
      // console.log(link);
      document.body.appendChild(link);
      link.click();

      // image download
      const chartIds = [];
      dataForExport.map((d) => {
        if (!chartIds.includes(d.chartId) && d.chartId) {
          chartIds.push(d.chartId);
        }
      });
      // console.log(chartIds);
      // console.log(charts);
      chartIds.map((chartId) => {
        var img = document.createElement("a");
        img.setAttribute("href", charts[chartId].source);
        img.setAttribute("download", chartId + ".png");
        document.body.appendChild(img);
        img.click();
      });
    }
  }, [dataForExport]);

  const handleExport = () => {
    fetchDataforExport();
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <ExpandableRow rows={data} />
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
            style={{ marginTop: "25px" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onPointerUp={handleExport}
              // style={{ marginBotton: "20px" }}
            >
              Export
            </Button>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid item alignItems="flex-start" justifyContent="center">
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
