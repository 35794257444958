import React from "react";
import { useSelector } from "react-redux";

import { makeGetResponses } from "actions/responses";
import { calcTimeElapsed } from "utils/date";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
  },
  content: {
    marginTop: "25px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function ExperimentStatus(props) {
  const classes = useStyles();
  const { experimentId } = props;
  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getResponses = makeGetResponses();
  const { responses } = useSelector((state) => ({
    responses: getResponses(state, {
      experimentId: experimentId,
    }),
  }));
  const numOfResponses = Object.keys(responses).length;

  const numOfExcluded = () => {
    try {
      return responses.filter((response) => response.omit === true).length;
    } catch {
      return 0;
    }
  };
  // console.log(numOfExcluded());

  const rows = experiment
    ? [
        {
          key: "Last Updated",
          value: new Date(experiment.updatedAt).toLocaleString(),
        },
        {
          key: "Time elapsed",
          value: calcTimeElapsed(experiment.createdAt),
        }, // TODO after published
        {
          key: "Number of participants omitted",
          value: numOfExcluded(),
        },
        // { key: "Average time per participation", value: "2 minutes 30 secs" },
        // { key: "Share Public", value: experiment.public ? "Yes" : "No" },
        // {
        //   key: "Tutorial",
        //   value: experiment.showTutorial ? "Yes" : "No",
        // },
        // {
        //   key: "Practice Trial",
        //   value: experiment.showPractice ? "Yes" : "No",
        // },
        // {
        //   key: "Color Blinedness Screening",
        //   value: experiment.screening.show
        //     ? experiment.screening.show.isInclusion
        //       ? "Yes (Inclusion)"
        //       : "Yes (Exclusion)"
        //     : "No",
        // },
        // {
        //   key: "Demographic Survey",
        //   value: experiment.showDemographicSurvey ? "Yes" : "No",
        // },
        // { key: "Post Survey", value: experiment.showPostSurvey ? "Yes" : "No" },
      ]
    : null;

  return (
    <Grid container direction="column">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          Number of participants: {numOfResponses - numOfExcluded()}
        </Typography>
        <TableContainer component="div">
          <Table
            className={classes.table}
            aria-label="simple table"
            size="small"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ borderBottom: "none" }}
                  >
                    {row.key}
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
