import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { passwordChange, getEmailFromPasswordResetCode } from "actions/auth";

import { Typography, Paper, Box, Button, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  container: {
    // display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    marginTop: "50px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  errorText: {
    color: "#f50057",
    marginBottom: "5",
    textAlign: "center",
  },
}));

function PasswordChangePage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  // const [error, setError] = useState(null);
  const { accountEmail } = useSelector((state) => ({
    accountEmail: state.auth.user.accountEmail,
  }));
  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  const actionCode = props.location.actionCode;

  useEffect(() => {
    dispatch(getEmailFromPasswordResetCode(actionCode));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(passwordChange(history, actionCode, passwordOne));
  };

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        {accountEmail ? (
          <Typography component="h6" variant="h5">
            {accountEmail}
          </Typography>
        ) : (
          <Typography component="p" className={classes.errorText}>
            The password reset code is invalid. Please try password reset again.{" "}
          </Typography>
        )}

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="passwordOne"
          label="New Password"
          type="password"
          id="password"
          disabled={!accountEmail}
          onChange={(event) => {
            setPasswordOne(event.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="passwordTwo"
          label="Confirm New Password"
          type="password"
          id="password"
          disabled={!accountEmail}
          onChange={(event) => {
            setPasswordTwo(event.target.value);
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isInvalid}
          onClick={handleSubmit}
        >
          Reset My Password
        </Button>
      </Paper>
    </Box>
  );
}

export default PasswordChangePage;
