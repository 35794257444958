import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import React, { useState, useEffect, createContext } from "react";

const firebaseconfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const myFirebase = initializeApp(firebaseconfig);
export const auth = getAuth(myFirebase);
// const analytics = getAnalytics(myFirebase);
// console.log(myFirebase);
// console.log(myFirebase.storage);
// const baseDb = myFirebase.firestore();
// export const db = baseDb;
