import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeGetRemixes, requestExperimentsRemixes } from "actions/experiments";

import {
  Typography,
  Grid,
  makeStyles,
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  Link,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  card: {
    width: "100%",
    height: "200px",
  },
  cardDesc: {
    height: "80px",
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  customBoxDesc: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-all",
    overflow: "hidden",
  },
}));

const RemixesView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { experimentId } = props;
  const getRemixes = makeGetRemixes();
  const { remixes } = useSelector((state) => ({
    remixes: getRemixes(state, { experimentId: experimentId }),
  }));

  useEffect(() => {
    dispatch(requestExperimentsRemixes(experimentId));
  }, []);

  const remixesFiltered = remixes.filter(
    (rmx) => rmx.parentRemix === experimentId
  );

  return (
    <div className={classes.content}>
      <Grid container spacing={4}>
        {remixesFiltered.length > 0 ? (
          <Grid container item>
            <Grid item xs={12}>
              {/* <Typography variant="h6" gutterBottom>
              Remixes:
            </Typography> */}
              <Grid container spacing={2}>
                {remixesFiltered.map((exp) => (
                  <Grid key={exp.id} item xs={3}>
                    <Card className={classes.card} variant="outlined">
                      <CardContent className={classes.content}>
                        <Box
                          component="div"
                          classes={{ root: classes.customBox }}
                        >
                          <Typography
                            variant="button"
                            gutterBottom
                            display="block"
                          >
                            {exp.title}
                          </Typography>
                        </Box>
                        <Box
                          component="div"
                          classes={{ root: classes.customBoxDesc }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className={classes.cardDesc}
                            classes={{ root: classes.customBoxDesc }}
                          >
                            {exp.desc}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Box
                          display="flex"
                          width="100%"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            size="small"
                            component={Link}
                            to={`/experiment/${exp.id}/edit-readonly`}
                            target="_blank"
                            endIcon={<ArrowForwardIcon />}
                          >
                            See Inside
                          </Button>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container item>
            <Typography variant="h6">No Remixed Experiments</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default RemixesView;
