/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";

import { PieChart } from "utils/Charts";
import { default as PerceptionDashboard } from "components/Perception/Dashboard";
import { default as BubbleViewDashboard } from "components/BubbleView/Dashboard";
import { default as MemorabilityDashboard } from "components/Memorability/Dashboard";
import PaginatedTable from "components/PaginatedTable";

import { makeGetQuestionnaire } from "actions/experiments";
import { makeGetSurveys } from "actions/surveys";
import { makeGetResponses } from "actions/responses";
import { parseDemographicData, parsePostData } from "utils/dataProcessing";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function ExperimentDashboard(props) {
  const { experimentId } = props;

  const [demographicData, setDemographicData] = useState({});
  const [postData, setPostData] = useState({});

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getSurveys = makeGetSurveys();
  const { demographicSurvey, postSurvey } = useSelector((state) => ({
    demographicSurvey: getSurveys(state, {
      parentId: experimentId,
      type: "demographicSurvey",
    }),
    postSurvey: getSurveys(state, {
      parentId: experimentId,
      type: "postSurvey",
    }),
  }));
  const getQuestions = makeGetQuestionnaire();
  const { showDemographicSurvey, demographicQuestions, demographicOptions } =
    useSelector((state) => ({
      showDemographicSurvey:
        state.experiments[experimentId].showDemographicSurvey,
      demographicQuestions:
        demographicSurvey.length > 0
          ? getQuestions(state, {
              surveyId: demographicSurvey[0].id,
            })
          : [],
      demographicOptions: state.options,
    }));

  const { showPostSurvey, postQuestions, postOptions } = useSelector(
    (state) => ({
      showPostSurvey: state.experiments[experimentId].showPostSurvey,
      postQuestions:
        postSurvey.length > 0
          ? getQuestions(state, { surveyId: postSurvey[0].id })
          : null,
      postOptions: state.options,
    })
  );

  const getResponses = makeGetResponses();
  const { responses } = useSelector((state) => ({
    responses: getResponses(state, {
      experimentId: experimentId,
      type: experiment.type,
    }),
  }));

  useEffect(() => {
    if (showDemographicSurvey && demographicQuestions) {
      const result = parseDemographicData(
        responses,
        demographicQuestions,
        demographicOptions
      );
      setDemographicData(result);
    }
  }, []);

  useEffect(() => {
    if (showPostSurvey && postQuestions) {
      const result = parsePostData(responses, postQuestions, postOptions);
      setPostData(result);
    }
  }, []);

  const renderTemplateData = () => {
    if (experiment.type === "Graphical Perception") {
      return (
        <PerceptionDashboard
          experimentId={experimentId}
          responses={responses}
          demographicData={demographicData}
          demographicQuestions={demographicQuestions}
        />
      );
    } else if (experiment.type === "Attention Tracking") {
      return (
        <BubbleViewDashboard
          experimentId={experimentId}
          responses={responses}
          demographicData={demographicData}
          demographicQuestions={demographicQuestions}
        />
      );
    } else if (experiment.type === "Memorability") {
      return (
        <MemorabilityDashboard
          experimentId={experimentId}
          responses={responses}
          demographicData={demographicData}
          demographicQuestions={demographicQuestions}
        />
      );
    }
  };

  return (
    <>
      <Box margin={1}>
        <Grid container spacing={5} direction="column">
          <Grid item>{renderTemplateData(experiment.type, experimentId)}</Grid>

          {showDemographicSurvey && demographicData.length > 0 && (
            <>
              <Grid item>
                <Typography
                  variant="h5"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  Demographic Report
                </Typography>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {demographicData.map((d) => (
                      <Fragment key={d.questionId}>
                        <Grid container item xs={4} direction="column">
                          <Grid item xs>
                            <Typography variant="subtitle1" gutterBottom>
                              {
                                demographicQuestions.find(
                                  (q) => q.id === d.questionId
                                ).text
                              }
                            </Typography>
                          </Grid>

                          <Grid item xs>
                            <PieChart data={d.binnedData} />
                          </Grid>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </>
          )}

          {showPostSurvey && postData.length > 0 && (
            <>
              <Grid item>
                <Typography
                  variant="h5"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  Post Survey Report
                </Typography>
                <Box mt={2}>
                  {postQuestions.map((d, i) => (
                    <Fragment key={d.id}>
                      <Typography variant="subtitle1">
                        Q{i + 1}. {d.text}
                      </Typography>

                      {/* {d.type === "choice" || d.type === "checkboxes" ? (
                            <PieChart
                              data={
                                postData.filter((p) => p.questionId === d.id)
                                  .data
                              }
                            />
                          ) : ( */}
                      {d.type === "paragraph" && (
                        <PaginatedTable
                          rows={postData
                            .filter((p) => p.questionId === d.id)
                            .map((p) => p.data)
                            .flat(1)}
                          header={false}
                          pagination={
                            postData
                              .filter((p) => p.questionId === d.id)
                              .map((p) => p.data)
                              .flat(1).length > 5
                              ? true
                              : false
                          }
                        />
                      )}
                    </Fragment>
                  ))}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default ExperimentDashboard;
