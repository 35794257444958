import uniqueId from "utils/uniqueId";
import { url, requestInfo } from "actions/index";
import { selectConsent, alertServerError } from "actions/ui";
import { createSelector } from "reselect";

export const CREATE_CONSENT = "CREATE_CONSENT";
// export const DELETE_CONSENT = 'DELETE_CONSENT';
export const UPDATE_CONSENT = "UPDATE_CONSENT";

const path_dict = {
  CREATE_CONSENT: "create_consent",
  UPDATE_CONSENT: "update_consent",
  // DELETE_CONSENT: 'delete_consent'
};

export const createConsent = (experimentId, attrs = {}) => {
  return async (dispatch) => {
    let consentId = uniqueId("consent_");
    let createdAt = Date.now();
    attrs = {
      ...attrs,
      experimentId,
      id: consentId,
      createdAt: createdAt,
      updatedAt: createdAt,
    };
    let action = { type: CREATE_CONSENT, consentId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(selectConsent(consentId));
        }
        // console.log(resp);
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateConsent = (consentId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_CONSENT, consentId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const makeGetConsent = () => {
  return createSelector(
    (state, props) => props.experimentId,
    (state) => state.consent,
    (experimentId, consent) =>
      Object.values(consent).filter((c) => c.experimentId === experimentId)
  );
};
