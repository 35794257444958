import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

// import Variables from "components/Perception/Variables";
// import Stimuli from "components/Perception/Stimuli";
import Survey from "components/Survey";
import ChartStimuli from "components/ChartStimuli";
import FileLoader from "components/FileLoader";

import { makeGetVariables } from "actions/variables";
import { makeGetSurveys } from "actions/surveys";
import {
  createChart,
  deleteChart,
  makeGetCharts,
  updateChart,
} from "actions/charts";
import { selectChart, selectTask, selectVariable } from "actions/ui";
import { createVariable, updateVariable } from "actions/variables";
import { makeGetTasks } from "actions/tasks";

import { getVariableCombination } from "utils/variables";
import uniqueId from "utils/uniqueId";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { EXPERIMENTAL_FEATURE } from "index";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import vegaEmbed from "vega-embed";

const useStyles = makeStyles((theme) => ({
  list: {
    marginLeft: 0,
  },
}));

function Design(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { readOnly } = props;

  const getTasks = makeGetTasks();
  const { tasks, selectedTask } = useSelector((state) => ({
    tasks: getTasks(state, props),
    selectedTask: state.ui.selectedTask,
  }));
  // console.log(tasks[0].id, selectedTask);
  const getSurveys = makeGetSurveys();
  const { surveys } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: selectedTask,
      type: "perceptionSurvey",
    }),
  }));

  const getVariables = makeGetVariables();
  const { variables, selectedVariable } = useSelector((state) => ({
    variables: getVariables(state, {
      taskId: selectedTask,
    }),
    selectedVariable: state.ui.selectedVariable,
  }));

  const getCharts = makeGetCharts();
  const { charts, selectedChart } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: selectedTask,
    }),
    selectedChart: state.ui.selectedChart,
  }));

  useEffect(() => {
    if (tasks.length > 0) {
      dispatch(selectTask(tasks[0].id));
    }
  }, [tasks.length]);

  useEffect(() => {
    if (charts.length > 0 && selectedChart === null) {
      // console.log(charts[0]);
      dispatch(selectChart(charts[0].id));
    }
  }, [charts.length]);

  // const [conditions, setConditions] = useState();
  // useEffect(() => {
  //   if (variables.length > 0) {
  //     //} && selectedVariable === null) {
  //     dispatch(selectVariable(variables[0].id));
  //     setConditions(variables[0].conditions);
  //   }
  // }, [variables.length]);

  // const handleDragAndDropBoxes = () => {
  //   console.log(variables);
  //   const variableCombination = getVariableCombination(variables);
  //   // compare
  //   // console.log(variableCombination);
  //   const flatBoxes = variableCombination.flat(Infinity);
  //   console.log(flatBoxes);
  //   console.log(charts);

  //   // let index = tasks.findIndex((b) => b.id === selectedTask);
  //   if (charts.length === 0) {
  //     flatBoxes.map(
  //       (f) =>
  //         dispatch(createChart(tasks[0].id, { chartId: f.key, label: f.label }))
  //       // console.log(tasks[0].id, f.key)
  //     );
  //   } else {
  //     const news = flatBoxes.filter((item) => {
  //       return !charts.some((chart) => item.key === chart.id);
  //     });
  //     const removes = charts.filter((chart) => {
  //       return !flatBoxes.some((item) => chart.id === item.key);
  //     });
  //     console.log(news, removes);

  //     if (news.length > 0) {
  //       news.map((n) =>
  //         dispatch(
  //           createChart(tasks[0].id, {
  //             chartId: n.key,
  //             label: n.label,
  //           })
  //         )
  //       );
  //     }
  //     if (removes.length > 0) {
  //       // removes.map((r) => dispatch(deleteChart(r.chartId)));
  //       console.log("delete");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   console.log("vvvvvvvvv----", variables);
  //   handleDragAndDropBoxes();
  // }, [variables]);

  // const handleAddVariable = () => {
  //   dispatch(createVariable(tasks[0].id, { color: variables.length }));
  // };

  // const [showVariables, setShowVariables] = useState(false);

  // const variable = useSelector((state) => state.variables[variables[0].id]);
  // console.log(variables);
  // const variable = useSelector((state) => ({
  //   variable: state.variables[selectedVariable],
  // }));

  // useEffect(() => {
  //   if (selectedVariable && conditions) {
  //     dispatch(updateVariable(selectedVariable, { conditions: conditions }));
  //   }
  // }, [conditions]);

  const handleAddChart = () => {
    // let conditionId = uniqueId("condition_");
    // setConditions([
    //   ...conditions,
    //   {
    //     // key: `${variable.id}_${conditions.length}`,
    //     // // id: variable.id,
    //     // id: `${variable.id}_${conditions.length}`,
    //     key: conditionId,
    //     id: conditionId,
    //     label: "condition" + (conditions.length + 1),
    //     design: "between",
    //     variableId: variable.id,
    //     combLabel: [
    //       {
    //         variableId: variable.id,
    //         label: "condition" + (conditions.length + 1),
    //       },
    //     ],
    //   },
    // ]);
    let index = tasks.findIndex((task) => task.id === selectedTask);
    dispatch(createChart(tasks[index].id, {}));
  };

  const handleDeleteChart = () => {
    if (selectedChart) {
      // let conditionToDelete = selectedChart;
      // console.log(conditions);

      // let index = conditions.findIndex((b) => b.id === conditionToDelete);
      // index -= 1;

      // if (index >= 0) {
      //   dispatch(selectChart(conditions[index].id));
      // } else {
      //   dispatch(selectChart(null));
      // }
      // // dispatch(deleteChart(deleted));

      // setConditions((conditions) =>
      //   conditions.filter((condition) => condition.id !== conditionToDelete)
      // );
      let deleted = selectedChart;
      let index = charts.findIndex((b) => b.id === selectedChart);
      index -= 1;

      if (index >= 0) {
        dispatch(selectChart(charts[index].id));
      } else {
        dispatch(selectChart(null));
      }
      dispatch(deleteChart(deleted));
    }
  };

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleReleaseError = () => {
    setShowError(false);
  };

  function handleDrop(e, chartId) {
    let file = e.dataTransfer.files[0];
    if (!file) {
      return;
    }

    if (file.type.match("application/json")) {
      // vega-lite
      const reader = new FileReader();

      // TODO: check
      reader.onload = async (e) => {
        const spec = JSON.parse(e.target.result);

        let div = document.createElement("div");
        div.style.width = "512px";
        div.style.height = "100%";
        document.body.appendChild(div);
        let result = await vegaEmbed(div, spec, {
          actions: false,
        });
        // console.log(result);
        result.view
          .toImageURL("png")
          .then(function (url) {
            // console.log("png:       ", url);
            dispatch(
              updateChart(chartId, {
                spec: JSON.stringify(spec),
                source: url === "data:," ? null : url,
                type: "vega",
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(div);
        document.body.removeChild(div);
      };

      reader.readAsText(file);
    } else if (file.type.match(/image.*/)) {
      if (file.size > 1000000) {
        setShowError(true);
        setErrorMsg("Size too big (>1MB)!");
        return;
      } else {
        let reader = new FileReader();
        reader.onloadend = (e) => {
          dispatch(
            updateChart(chartId, { type: "image", source: e.target.result })
          );
        };
        reader.readAsDataURL(file);
      }
    } else {
      setShowError(true);
      setErrorMsg("Wrong format!");
    }
  }

  return (
    <>
      <Box mb={2}>
        Provide your visualization design alternatives as experiment stimuli and
        add task questions capturing what potential readers should focus on.
        Participants will answer the questions, while the accuracy and time of
        the responses will be recorded for evaluating the perceptual
        effectiveness of each visualization.
      </Box>
      {/* {tasks.length > 0 && selectedTask !== null && variables.length > 0 && ( */}
      {tasks.length > 0 && selectedTask !== null && (
        <>
          {/* {EXPERIMENTAL_FEATURE && (
            <>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowVariables(!showVariables);
                }}
              >
                {showVariables ? "Hide Variables" : "Show Variables"}
              </Button>
              {showVariables && (
                <div>
                  <Typography variant="h6" display="block" gutterBottom>
                    Variables
                  </Typography>
                  <List className={classes.list}>
                    {variables.map((variable, i) => (
                      <Fragment key={variable.id}>
                        <ListItem disableGutters={true}>
                          <Variables variableId={variable.id} />
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                  <Button
                    disableElevation
                    variant="outlined"
                    color="primary"
                    onPointerUp={() => handleAddVariable()}
                    disabled={variables.length > 2 ? true : false}
                  >
                    Add Variable
                  </Button>
                </div>
              )}
            </>
          )} */}

          {/* <div> */}
          {/* {EXPERIMENTAL_FEATURE && (
              <List className={classes.list}>
                {variables.map((variable, i) => (
                  <Fragment key={variable.id}>
                    <ListItem disableGutters={true}>
                      <Variables variableId={variable.id} />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            )} */}
          <Typography variant="h6" display="block">
            Visualizations
          </Typography>
          {!readOnly && (
            <Typography variant="body2" display="block" gutterBottom>
              Upload images or{" "}
              <Link
                color="secondary"
                href="https://vega.github.io/vega-lite/examples/"
                target="_blank"
              >
                Vega-lite
              </Link>{" "}
              specs.
            </Typography>
          )}

          {/* <Stimuli
              variables={variables}
              getVariableCombination={getVariableCombination}
              charts={charts}
              readOnly={readOnly}
            /> */}
          <Grid container spacing={3}>
            {Object.values(charts).map((chart) => (
              <Grid item key={chart.id}>
                <FileLoader
                  chartId={chart.id}
                  onDrop={!readOnly && ((e) => handleDrop(e, chart.id))}
                >
                  <ChartStimuli type="small" chartId={chart.id} />
                </FileLoader>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" alignItems="center" style={{ marginTop: "20px" }}>
            {!readOnly && (
              <>
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onPointerUp={() => handleAddChart()}
                  disabled={charts.length > 2 ? true : false}
                >
                  Add Visualization
                </Button>
                {/* &nbsp;
              <Tooltip title="It is not currently supported.">
                <InfoOutlinedIcon />
              </Tooltip> */}
                <Button onPointerUp={handleDeleteChart}>
                  <DeleteOutlinedIcon />
                </Button>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <InfoOutlinedIcon />
            <Typography
              variant="body2"
              display="block"
              style={{ marginLeft: "5px", paddingTop: "2px" }}
            >
              Each participant will see a randomly selected visualization among
              all visualizations.
            </Typography>
          </Box>
          {/* </div> */}

          <Box mt={2}>
            <Grid container spacing={2}>
              {selectedChart && (
                <Grid item xs>
                  <Typography
                    variant="h6"
                    display="block"
                    gutterBottom
                    style={{ marginTop: "20px" }}
                  >
                    Visualization Preview
                  </Typography>
                  <ChartStimuli type="live" chartId={selectedChart} />
                </Grid>
              )}
              <Grid item xs>
                <Typography
                  variant="h6"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  Task Questionnaire
                </Typography>

                {surveys.length > 0 && (
                  <Survey
                    surveyId={surveys[0].id}
                    type="perceptionSurvey"
                    readOnly={readOnly}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default Design;
