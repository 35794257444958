import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateExperiment } from "actions/experiments";
import { makeGetTutorial, updateTutorial, selectTutorial } from "actions";
import { DEFAULT_TUTORIAL_CONTENTS } from "utils/textResources";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Grid from "@material-ui/core/Grid";

import { FormControlLabel, Switch, Typography, Box } from "@material-ui/core";
// import { EXPERIMENTAL_FEATURE } from "index";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  quill: {
    "& .ql-container": {
      fontSize: "16px",
    },
  },
}));

// const Size = Quill.import("attributors/style/size");
// console.log("size", Size, ReactQuill.register);
// Size.whitelist = ["14px", "16px", "18px"];
// Quill.register(Size, true);

// const Font = Quill.import("formats/font");
// Font.whitelist = ["Ubuntu", "Raleway", "Roboto"];
// Quill.register(Font, true);

// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline", "strike"], // toggled buttons
//     ["blockquote", "code-block"],

//     [{ header: 1 }, { header: 2 }], // custom button values
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ script: "sub" }, { script: "super" }], // superscript/subscript
//     [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
//     [{ direction: "rtl" }], // text direction

//     [{ size: [] }], // custom dropdown

//     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//     [{ font: [] }],
//     [{ align: [] }],

//     ["clean"], // remove formatting button
//   ],
// };
function TutorialPractice(props) {
  const classes = useStyles();
  const { experimentId, type, previewMode, setIsValidSubmit, readOnly } = props;
  const dispatch = useDispatch();
  const { showTutorial, showPractice } = useSelector((state) => ({
    showTutorial: state.experiments[experimentId].showTutorial,
    showPractice: state.experiments[experimentId].showPractice,
  }));

  useEffect(() => {
    if (setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    if (previewMode) {
      setIsValidSubmit(true);
    }
  }, [previewMode]);

  const handleTutorialButtonChange = (e) => {
    dispatch(
      updateExperiment(experimentId, { showTutorial: e.target.checked })
    );
  };

  const handlePracticeButtonChange = (e) => {
    dispatch(
      updateExperiment(experimentId, { showPractice: e.target.checked })
    );
  };

  const getTutorial = makeGetTutorial();
  const { tutorial, selectedTutorial } = useSelector((state) => ({
    tutorial: getTutorial(state, props),
    selectedTutorial: state.ui.selectedTutorial,
  }));

  const defaultContent = DEFAULT_TUTORIAL_CONTENTS[type];

  const [value, setValue] = useState(
    tutorial.length === 0 || tutorial[0].text === undefined
      ? defaultContent
      : tutorial[0].text
  );

  useEffect(() => {
    if (tutorial.length !== 0) {
      dispatch(selectTutorial(tutorial[0].id));
    }
  }, []);

  useEffect(() => {
    if (showTutorial && selectedTutorial !== null) {
      dispatch(
        updateTutorial(selectedTutorial, {
          text: value,
        })
      );
    }
  }, [value]);

  return (
    <>
      <Box mb={2}>
        Provide a concise and clear instruction and consider adding a practice
        trial in order to familiarize participants with the experiment.
      </Box>

      {!previewMode && (
        <>
          <Grid container align="left">
            <Grid item xs>
              <FormControlLabel
                control={
                  <Switch
                    checked={showTutorial || false}
                    onChange={(e) => handleTutorialButtonChange(e)}
                    disabled={readOnly}
                  />
                }
                label="Add Instruction"
              />

              {/* {!showTutorial && (
                <Typography variant="subtitle1" display="block" gutterBottom>
                  In the instruction, you can explain what participants are expected to do in the main task. We provide a template instruction. Please feel free to reuse or customize it.                  
                </Typography>
              )} */}
              <Typography variant="subtitle1" display="block" gutterBottom>
                In the instruction, you can explain what participants are
                expected to do in the main task. We provide a template
                instruction. Please feel free to reuse or customize it.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {showTutorial && (
        <>
          {previewMode ? (
            <ReactQuill
              className={classes.quill}
              theme={null}
              value={value}
              readOnly={true}
              modules={{ toolbar: false }}
            />
          ) : (
            <ReactQuill
              className={classes.quill}
              theme={"snow"}
              value={value !== "" ? value : ""}
              onChange={setValue}
              // modules={modules}
              readOnly={readOnly}
            />
          )}
        </>
      )}
      {/* {EXPERIMENTAL_FEATURE && ( */}
      <>
        {!previewMode && (
          <>
            <Grid container align="left">
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPractice || false}
                      onChange={(e) => handlePracticeButtonChange(e)}
                      disabled={readOnly}
                    />
                  }
                  label="Add Practice Trial"
                  style={{ marginTop: "20px" }}
                />

                <Typography
                  variant="subtitle1"
                  // color={!showPractice ? "text.disabled" : "black"}
                  // display="block"
                  // gutterBottom

                  // style={{ marginTop: "20px" }}
                >
                  {" "}
                  In the practice trial, participants will have a chance to try
                  a sample task and prepare themselves for the main task. You
                  can check this practice trial in the Preview mode.
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>
      {/* )} */}
    </>
  );
}

export default TutorialPractice;
