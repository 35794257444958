import uniqueId from "utils/uniqueId";
import { createSelector } from "reselect";
import { alertServerError, selectSurvey } from "actions/ui";
import { createQuestion } from "actions/questions";
import { url, requestInfo } from "actions/index";
import { createdDateAscending } from "utils/date";
import {
  DEFAULT_DEMOGRAPHIC_QUESTIONS_AND_OPTIONS,
  DEFAULT_POST_QUESTIONS_AND_OPTIONS,
} from "utils/textResources";

// action types
export const CREATE_SURVEY = "CREATE_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const UPDATE_SURVEY = "UPDATE_SURVEY";

// actions
const path_dict = {
  CREATE_SURVEY: "create_survey",
  UPDATE_SURVEY: "update_survey",
  DELETE_SURVEY: "delete_survey",
};

const defaultDemographicQuestions = DEFAULT_DEMOGRAPHIC_QUESTIONS_AND_OPTIONS;
const defaultPostQuestions = DEFAULT_POST_QUESTIONS_AND_OPTIONS;

export const createSurvey = (parentId, attrs = {}, duplicate = false) => {
  return async (dispatch) => {
    let surveyId = uniqueId("survey_");
    let createdAt = Date.now();
    if (!duplicate) {
      attrs = {
        ...attrs,
        parentId,
        id: surveyId,
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    } else {
      surveyId = attrs.id;
    }
    let action = { type: CREATE_SURVEY, surveyId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        // dispatch(selectQuestion(questionId));
        if (resp.status) {
          dispatch(action);
          dispatch(selectSurvey(surveyId));
          if (!duplicate) {
            if (attrs.type === "demographicSurvey") {
              defaultDemographicQuestions.map((defaultDemographicQuestion) =>
                dispatch(
                  createQuestion(
                    surveyId,
                    {
                      text: defaultDemographicQuestion.question,
                      // allowMultipleAnswers:
                      // defaultDemographicQuestion.allowMultipleAnswers,
                      type: defaultDemographicQuestion.type,
                    },
                    true, // fromJson
                    defaultDemographicQuestion.options //demographicOptions
                  ) //id, attrs, fromJson, optionsAttr
                )
              );
            } else if (attrs.type === "postSurvey") {
              defaultPostQuestions.map((defaultPostQuestion) =>
                dispatch(
                  createQuestion(
                    surveyId,
                    {
                      text: defaultPostQuestion.question,
                      type: defaultPostQuestion.type,
                    },
                    true,
                    defaultPostQuestion.options
                  ) //id, attrs, fromJson, optionsAttr
                )
              );
            } else if (attrs.type === "perceptionSurvey") {
              // dispatch createQuestion
              dispatch(
                createQuestion(surveyId, { text: " ", type: "choice" }, true, [
                  {
                    text: "",
                    idx: 0,
                  },
                ])
              );
            }
          }
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateSurvey = (surveyId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_SURVEY, surveyId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteSurvey = (surveyId) => {
  return async (dispatch) => {
    let action = { type: DELETE_SURVEY, surveyId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

//selectors
export const makeGetSurveys = () => {
  return createSelector(
    (state, props) => props.parentId,
    (state, props) => props.type,
    (state) => state.surveys,
    (parentId, type, surveys) =>
      Object.values(surveys).filter(
        (survey) => survey.parentId === parentId && survey.type === type
      )
    // .sort(createdDateAscending)
  ); // return annotations for the panel
};
