import uniqueId from "utils/uniqueId";
import { url, requestInfo } from "actions/index";
import { alertServerError, selectVariable } from "actions/ui";
import { createSelector } from "reselect";

export const CREATE_VARIABLE = "CREATE_VARIABLE";
export const DELETE_VARIABLE = "DELETE_VARIABLE";
export const UPDATE_VARIABLE = "UPDATE_VARIABLE";

const path_dict = {
  CREATE_VARIABLE: "create_variable",
  UPDATE_VARIABLE: "update_variable",
  DELETE_VARIABLE: "delete_variable",
};

const colors = ["", "#f86624", "#ea3546", "#662e9b", "#43bccd"];

export const createVariable = (taskId, attrs = {}, duplicate = false) => {
  return async (dispatch) => {
    let variableId = uniqueId("variable_");
    if (!duplicate) {
      let condition1_Id = uniqueId("condition_");
      let condition2_Id = uniqueId("condition_");
      let condition3_Id = uniqueId("condition_");
      let createdAt = Date.now();

      attrs = {
        ...attrs, //override the above if specified
        taskId,
        id: variableId,
        conditions: [
          // { key: `${variableId}_0`, id: variableId, label: 'condition1', design: 'within' },
          // { key: `${variableId}_1`, id: variableId, label: 'condition2', design: 'within' },
          // { key: `${variableId}_0`, id: `${variableId}_0`, label: 'condition1', design: 'within' },
          // { key: `${variableId}_1`, id: `${variableId}_1`, label: 'condition2', design: 'within' },
          {
            key: condition1_Id,
            id: condition1_Id,
            label: "condition1",
            design: "between",
            variableId: variableId,
            combLabel: [{ variableId: variableId, label: "condition1" }],
          },
          {
            key: condition2_Id,
            id: condition2_Id,
            label: "condition2",
            design: "between",
            variableId: variableId,
            combLabel: [{ variableId: variableId, label: "condition2" }],
          },
          {
            key: condition3_Id,
            id: condition3_Id,
            label: "condition3",
            design: "between",
            variableId: variableId,
            combLabel: [{ variableId: variableId, label: "condition3" }],
          },
        ],
        color: colors[attrs.color % colors.length] || "",
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    } else {
      variableId = attrs.id;
    }
    let action = { type: CREATE_VARIABLE, variableId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        // dispatch(selectVariable(variableId));
        if (resp.status) {
          dispatch(action);
          dispatch(selectVariable(variableId));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateVariable = (variableId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_VARIABLE, variableId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteVariable = (variableId) => {
  return async (dispatch) => {
    let action = { type: DELETE_VARIABLE, variableId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};
export const makeGetVariables = () => {
  return createSelector(
    (state, props) => props.taskId,
    (state) => state.variables,
    (taskId, variables) => {
      return Object.values(variables).filter(
        (variable) => variable.taskId === taskId
      );
    }
  );
};
