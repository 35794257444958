import uniqueId from "utils/uniqueId";
import { url, requestInfo } from "actions/index";
import { selectTutorial, alertServerError } from "actions/ui";
import { createSelector } from "reselect";

export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
// export const DELETE_TUTORIAL = 'DELETE_TUTORIAL';
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";

const path_dict = {
  CREATE_TUTORIAL: "create_tutorial",
  UPDATE_TUTORIAL: "update_tutorial",
  // DELETE_TUTORIAL: 'delete_tutorial'
};

export const createTutorial = (experimentId, attrs = {}, duplicate = false) => {
  return async (dispatch) => {
    let tutorialId = uniqueId("tutorial_");
    let createdAt = Date.now();
    if (!duplicate) {
      attrs = {
        ...attrs,
        experimentId,
        id: tutorialId,
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    }
    let action = { type: CREATE_TUTORIAL, tutorialId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          dispatch(selectTutorial(tutorialId));
        }
        // console.log(resp);
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateTutorial = (tutorialId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_TUTORIAL, tutorialId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const makeGetTutorial = () => {
  return createSelector(
    (state, props) => props.experimentId,
    (state) => state.tutorials,
    (experimentId, tutorials) =>
      Object.values(tutorials).filter(
        (tutorial) => tutorial.experimentId === experimentId
      )
  );
};
