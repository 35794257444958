import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles((theme) => ({}));
function TitleIntroductionLive(props) {
  const classes = useStyles();
  const { experimentId, setIsValidSubmit, handleAnswers } = props;
  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  // const handleResponseNameChange = (event) => {
  //   handleAnswers({
  //     name: event.target.value,
  //   });
  //   setIsValidSubmit(true);
  // };
  useEffect(() => {
    setIsValidSubmit(true);
  }, []);

  return (
    <div>
      <Typography variant="h5" display="block" gutterBottom>
        {experiment.title}
      </Typography>

      <Divider variant="fullWidth" />
      <Box m={5}>
        <Typography
          variant="subtitle1"
          display="block"
          gutterBottom
          align="justify"
        >
          {experiment.desc}
        </Typography>
        <Typography
          variant="subtitle1"
          display="block"
          gutterBottom
          style={{ marginTop: "32px" }}
        >
          If you want to participate in this experiment, please click the Next
          button below.
        </Typography>
      </Box>

      {/* <TextField
        placeholder="Write a your name."
        style={{ width: 300, marginTop: "10px" }}
        onChange={(e) => handleResponseNameChange(e)}
        // variant="filled"
        size="small"
      /> */}
    </div>
  );
}

export default TitleIntroductionLive;
