export const SELECT_EXPERIMENT = 'SELECT_EXPERIMENT';
export const OPEN_EXPERIMENT = 'OPEN_EXPERIMENT';
export const SELECT_OPTION = 'SELECT_OPTION';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const SELECT_CHART = 'SELECT_CHART';
export const SELECT_CONSENT = 'SELECT_CONSENT';
export const SELECT_TUTORIAL = 'SELECT_TUTORIAL'
export const SELECT_SURVEY = 'SELECT_SURVEY'
export const SELECT_VARIABLE = 'SELECT_VARIABLE';
export const SELECT_TASK = 'SELECT_TASK'
export const ALERT_SERVER_ERROR = 'ALERT_SERVER_ERROR';
export const RELEASE_SERVER_ERROR = 'RELEASE_SERVER_ERROR';

export const selectExperiment = (experimentId) => {
  return {
    type: SELECT_EXPERIMENT,
    experimentId,
  };
};

export const openExperiment = (experimentId) => {
  return {
    type: OPEN_EXPERIMENT,
    experimentId,
  };
};

export const selectOption = (optionId) => {
  return {
    type: SELECT_OPTION,
    optionId,
  };
};

export const selectQuestion = (questionId) => {
  return {
    type: SELECT_QUESTION,
    questionId,
  };
};

export const selectChart = (chartId) => {
  return {
    type: SELECT_CHART,
    chartId,
  };
};

export const selectConsent = (consentId) => {
  return {
    type: SELECT_CONSENT,
    consentId,
  };
};

export const selectTutorial = (tutorialId) => {
  return {
    type: SELECT_TUTORIAL,
    tutorialId,
  };
};


export const selectSurvey = (surveyId) => {
  return {
    type: SELECT_SURVEY,
    surveyId,
  }
}

export const selectVariable = (variableId) => {
  return {
    type: SELECT_VARIABLE,
    variableId,
  };
};


export const selectTask = (taskId) => {
  return {
    type: SELECT_TASK,
    taskId,
  }
}

export const alertServerError = (message) => {
  return {
    type: ALERT_SERVER_ERROR,
    message,
  };
};

export const releaseServerError = () => {
  return {
    type: RELEASE_SERVER_ERROR,
  };
};
