import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
// import classes from "components/Auth/PasswordForgetPage.module.css";

import { passwordReset } from "actions/auth";

import { Typography, Paper, Button, TextField, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  container: {
    // display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    marginTop: "50px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  errorText: {
    color: "#f50057",
    marginBottom: "5",
    textAlign: "center",
  },
}));

function PasswordForgetPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  // const [error, setError] = useState(null);

  // const isInvalid = email === '';
  const [isInvalid, setIsInvalid] = useState(email === "");
  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    dispatch(passwordReset(email));
    setIsInvalid(true);
    setSubmit(true);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    setIsInvalid(false);
  };

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          onChange={handleChange}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isInvalid}
          className={classes.submit}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        {submit && (
          <Typography component="p" className={classes.errorText}>
            Please check your email account to reset your password.
          </Typography>
        )}
      </Paper>
    </Box>
  );
}

export const PasswordForgetLink = () => (
  <Link to="/password-forget" component={RouterLink} variant="button">
    Forgot Password?
  </Link>
);

export default PasswordForgetPage;
