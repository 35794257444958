import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  DEFAULT_TEMPLATE_CONSENT_TEXT,
  DEFAULT_CONSENT_CONTENT,
} from "utils/textResources";
import { createConsent, updateConsent, makeGetConsent } from "actions/consent";
import { selectConsent } from "actions/ui";
import { updateExperiment } from "actions/experiments";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

function Consent(props) {
  const { experimentId, type } = props;
  const consent4template = DEFAULT_TEMPLATE_CONSENT_TEXT;
  const defaultContent = DEFAULT_CONSENT_CONTENT(consent4template, type);

  const dispatch = useDispatch();

  const getConsent = makeGetConsent();

  const { showConsent, consent, selectedConsent } = useSelector((state) => ({
    showConsent: state.experiments[experimentId].showConsent,
    consent: getConsent(state, props),
    selectedConsent: state.ui.selectedConsent,
  }));

  const [value, setValue] = useState(
    consent.length === 0 || consent[0].text[type] === undefined
      ? defaultContent
      : consent[0].text[type]
  );

  useEffect(() => {
    if (consent.length !== 0) {
      dispatch(selectConsent(consent[0].id));
    }
  }, []);

  useEffect(() => {
    if (showConsent && selectedConsent !== null) {
      dispatch(
        updateConsent(selectedConsent, {
          text: { ...consent[0].text, [type]: value },
        })
      );
    }
  }, [value]);

  const handleConsentButtonChange = (e) => {
    if (consent.length === 0 && showConsent === false) {
      dispatch(
        createConsent(experimentId, { text: { [type]: defaultContent } })
      );
    }
    dispatch(updateExperiment(experimentId, { showConsent: e.target.checked }));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={showConsent || false}
            onChange={(e) => handleConsentButtonChange(e)}
          />
        }
        label="Add Consent"
      />
      {showConsent && consent && (
        <div>
          <ReactQuill
            theme="snow"
            value={value !== "" ? value : ""}
            onChange={setValue}
          />
        </div>
      )}
    </>
  );
}

export default Consent;
