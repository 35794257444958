import uniqueId from "utils/uniqueId";
import { createSelector } from "reselect";
import { selectOption, alertServerError } from "actions/ui";
import { url, requestInfo } from "actions/index";
import { idxAscending } from "utils/date";

// action types
export const CREATE_OPTION = "CREATE_OPTION";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";

// actions
const path_dict = {
  CREATE_OPTION: "create_option",
  UPDATE_OPTION: "update_option",
  DELETE_OPTION: "delete_option",
};

export const createOption = (
  questionId,
  options = null,
  attrs = {},
  fromJson = false,
  duplicate = false
) => {
  return (dispatch) => {
    let optionId = uniqueId("option_");
    let createdAt = Date.now();
    if (!duplicate) {
      if (fromJson) {
        attrs = {
          ...attrs,
          questionId,
          id: optionId,
          createdAt: createdAt,
          updatedAt: createdAt,
        };
      } else {
        let ops = Object.values(options).filter(
          (o) => o.questionId === questionId
        );
        const nOptions = ops.length;

        attrs = {
          ...attrs,
          text: "",
          questionId,
          id: optionId,
          idx: nOptions,
          createdAt: createdAt,
          updatedAt: createdAt,
        };
      }
    } else {
      optionId = attrs.id;
    }
    let action = { type: CREATE_OPTION, optionId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
          dispatch(selectOption(optionId));
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateOption = (optionId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_OPTION, optionId, attrs };
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteOption = (optionId) => {
  return async (dispatch) => {
    let action = { type: DELETE_OPTION, optionId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

//selectors

export const makeGetOptions = () => {
  return createSelector(
    (state, props) => props.questionId,
    (state) => state.options,
    (questionId, options) =>
      Object.values(options)
        .filter((option) => option.questionId === questionId)
        .sort(idxAscending)
  ); // return annotations for the panel
};
