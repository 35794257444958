import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Paper } from "@material-ui/core/";
import { myFirebase } from "components/firebase.js";
import { url } from "actions/index";
import axios from "axios";

function VerifyEmail(props) {
  const [email, setEmail] = useState();
  const [res, setRes] = useState();

  const actionCode = props.location.actionCode;

  useEffect(() => {
    async function setEmailVerified() {
      const result = await axios({
        baseURL: url,
        url: "/set_email_verified",
        method: "post",
        data: {
          email: email,
        },
      });
      setRes(result);
    }
    if (email) {
      setEmailVerified();
    }
  }, [email]);

  useEffect(() => {
    myFirebase
      .auth()
      .checkActionCode(actionCode)
      .then(function (info) {
        setEmail(info.data.email);
      });
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ alignItems: "center", margin: "50px 5px" }}
    >
      <Paper
        style={{
          marginTop: "100",
          display: "flex",
          padding: "20",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" display="block" gutterBottom>
          Welcome to VisLab!
        </Typography>
        <Typography variant="h6" display="block" gutterBottom>
          Your email is verified.
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          size="large"
        >
          Sign In
        </Button>
      </Paper>
    </Container>
  );
}

export default VerifyEmail;
