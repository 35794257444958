import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_VARIABLE,
  UPDATE_VARIABLE,
  DELETE_VARIABLE,
} from 'actions';

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable.id]: variable,
        };
      }, state);
    case CREATE_VARIABLE:
    case UPDATE_VARIABLE:
      return {
        ...state,
        [action.variableId]: {
          ...(state[action.variableId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_VARIABLE: {
      let newState = {
        ...state,
      };
      delete newState[action.variableId];
      return newState;
    }
    default:
      return state;
  }
};
