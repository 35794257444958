/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { default as PerceptionIndividualDashboard } from "components/Perception/IndividualDashboard";
import { default as MemorabilityIndividualDashboard } from "components/Memorability/IndividualDashboard";
import { default as BubbleViewIndividualDashboard } from "components/BubbleView/IndividualDashboard";

import { makeGetResponses } from "actions/responses";

import Box from "@material-ui/core/Box";

function ExperimentIndividualDashboard(props) {
  const { experimentId } = props;

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getResponses = makeGetResponses();
  const { responses } = useSelector((state) => ({
    responses: getResponses(state, {
      experimentId: experimentId,
      type: experiment.type,
    }),
  }));

  useEffect(() => {
    renderTemplateData(experiment.type, experimentId);
  }, [responses]);

  const renderTemplateData = () => {
    if (experiment.type === "Graphical Perception") {
      return (
        <PerceptionIndividualDashboard
          experimentId={experimentId}
          responses={responses}
        />
      );
    } else if (experiment.type === "Attention Tracking") {
      return (
        <BubbleViewIndividualDashboard
          experimentId={experimentId}
          responses={responses}
        />
      );
    } else if (experiment.type === "Memorability") {
      return (
        <MemorabilityIndividualDashboard
          experimentId={experimentId}
          responses={responses}
        />
      );
    }
  };

  return (
    <>
      <Box margin={1}>{renderTemplateData(experiment.type, experimentId)}</Box>
    </>
  );
}

export default ExperimentIndividualDashboard;
