import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// import { verifyAuth } from "./actions";
import createRootReducer from "./reducers";
import logger from "redux-logger";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

// let username = localStorage.getItem("username");
// if (!username) {
//   username = sessionStorage.getItem("username");
// }

let initData = undefined;
// if (username) {
//   initData = {
//     auth: { user: { uid: username } },
//   };
// }

function configureStore(persistedState) {
  const store = createStore(
    createRootReducer(history),
    // newRootReducer,
    // newRootReducer,
    persistedState,
    compose(
      applyMiddleware(routerMiddleware(history), thunkMiddleware, logger),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
    ),
    initData
  );
  // store.dispatch(verifyAuth());
  return store;
}

export default configureStore;
