import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
} from "actions";

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.tasks.reduce((acc, task) => {
        return {
          ...acc,
          [task.id]: task,
        };
      }, state);
    case CREATE_TASK:
    case UPDATE_TASK:
      return {
        ...state,
        [action.taskId]: {
          ...(state[action.taskId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_TASK: {
      let newState = {
        ...state,
      };
      delete newState[action.taskId];
      return newState;
    }
    default:
      return state;
  }
};
