import { url, requestInfo } from "actions/index";
import { alertServerError, selectChart } from "actions/ui";
import { createSelector } from "reselect";
import axios from "axios";
import uniqueId from "utils/uniqueId";
import { createdDateAscending } from "utils/date";
import { size } from "lodash";

export const CREATE_CHART = "CREATE_CHART";
export const DELETE_CHART = "DELETE_CHART";
export const UPDATE_CHART = "UPDATE_CHART";
export const REQUEST_MEMORABILITY_FILLER = "REQUEST_MEMORABILITY_FILLER";
export const RECEIVE_MEMORABILITY_FILLER = "RECEIVE_MEMORABILITY_FILLER";
export const DELETE_MEMORABILITY_FILLER = "DELETE_MEMORABILITY_FILLER";
export const REQUEST_MEMORABILITY_IMAGES = "REQUEST_MEMORABILITY_IMAGES";

const path_dict = {
  CREATE_CHART: "create_chart",
  UPDATE_CHART: "update_chart",
  DELETE_CHART: "delete_chart",
  REQUEST_MEMORABILITY_FILLER: "request_memorability_filler",
  RECEIVE_MEMORABILITY_FILLER: "receive_memorability_filler",
  DELETE_MEMORABILITY_FILLER: "delete_memorability_filler",
};

export const createChart = (taskId, attrs = {}, duplicate = false) => {
  return async (dispatch) => {
    // console.log(attrs);
    let chartId = uniqueId("chart_");
    // let chartId =
    // attrs.chartId !== undefined ? attrs.chartId : uniqueId("chart_");
    // let label = attrs.label !== undefined ? attrs.label : null;

    let createdAt = Date.now();
    if (!duplicate) {
      attrs = {
        ...attrs, //override the above if specified
        taskId,
        id: chartId,
        source: null,
        // label: label,
        createdAt: createdAt,
        updatedAt: createdAt,
      };
    } else {
      chartId = attrs.chartId;
    }
    let action = { type: CREATE_CHART, chartId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          // dispatch(selectChart(chartId));
        } // console.log(resp);
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateChart = (chartId, attrs) => {
  return async (dispatch) => {
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_CHART, chartId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteChart = (chartId) => {
  return async (dispatch) => {
    let action = { type: DELETE_CHART, chartId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};
export const makeGetCharts = () => {
  return createSelector(
    (state, props) => props.taskId,
    (state) => state.charts,
    (taskId, charts) =>
      Object.values(charts)
        .filter((chart) => chart.taskId === taskId)
        .sort(createdDateAscending)
  );
};

export const requestMemorabilityFiller = (
  numberOfImages
  // fillerUpload,
  // experimentId
) => {
  return async (dispatch) => {
    // console.log(numberOfImages);
    // let action = { type: REQUEST_MEMORABILITY_FILLER, name };
    // dispatch(action);
    // let path = path_dict[action.type];
    // const response = await fetch(`${url}/${path}`, {
    //   ...requestInfo,
    //   body: JSON.stringify(action),
    // });
    // const json = await response.json();
    // await dispatch(receiveMemorabilityFiller(json));
    // if (fillerUpload === "default") {
      const response = await fetch(
        process.env.PUBLIC_URL + "/fillers/fillers.txt"
      );
      const json = await response.text();
      // const shuffled = json.split("\n").sort(() => 0.5 - Math.random());
      // let selected = shuffled.slice(0, numberOfImages);
      // console.log(selected);
      let randomSelected = json
        .split("\n")
        .sort(() => 0.5 - Math.random())
        .slice(0, numberOfImages);
      await dispatch(receiveMemorabilityFiller({ images: randomSelected }));
    // } else {
    //   let action = {
    //     type: REQUEST_MEMORABILITY_IMAGES,
    //     experimentId: experimentId,
    //   };
    //   return await fetch(`${url}/${"request_memorability_images"}`, {
    //     ...requestInfo,
    //     body: JSON.stringify(action),
    //   })
    //     .then((resp) => resp.json())
    //     .then((resp) => {
    //       if (resp.status) {
    //         dispatch(action);
    //         dispatch(receiveMemorabilityFiller({ images: resp.data }));
    //       }
    //     })
    //     .catch((error) => dispatch(alertServerError(error.message)));
    // }

    // console.log(randomSelected, numberOfImages);
    // await dispatch(receiveMemorabilityFiller({ images: randomSelected }));
  };
};

export const receiveMemorabilityFiller = (data) => {
  // console.log(data);
  return { type: RECEIVE_MEMORABILITY_FILLER, ...data };
};

export const deleteMemorabilityFiller = () => {
  return { type: DELETE_MEMORABILITY_FILLER };
};

export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";

export const setUploadFile = (data) => ({
  type: SET_UPLOAD_FILE,
  attrs: data,
});

export const setUploadProgress = (id, progress) => ({
  type: SET_UPLOAD_PROGRESS,
  attrs: {
    id,
    progress,
  },
});

export const successUploadFile = (id, source) => ({
  type: SUCCESS_UPLOAD_FILE,
  attrs: { id, source },
});

export const failureUploadFile = (id) => ({
  type: FAILURE_UPLOAD_FILE,
  attrs: id,
});

export const uploadFile =
  (files, attrs = {}) =>
  (dispatch) => {
    if (files.length) {
      files.forEach(async (file) => {
        let imageId = uniqueId("image_");
        if (file.file.size > 1000000) {
          // larger than 1 MB
          return;
        }
        if (file.file.type.match(/image.*/)) {
          let reader = new FileReader();
          // console.log('image', file.file);
          reader.onloadend = (e) => {
            console.log(e.target);
            try {
              axios({
                baseURL: url,
                url: "/upsert_memorability_image",
                method: "post",
                data: {
                  imageId: imageId,
                  attrs: {
                    timestamp: Date.now(),
                    id: imageId,
                    source: e.target.result,
                    experimentId: attrs.experimentId,
                    type: "image",
                  },
                },
                onUploadProgress: (progress) => {
                  const { loaded, total } = progress;
                  const percentageProgress = Math.floor((loaded / total) * 100);
                  dispatch(setUploadProgress(file.id, percentageProgress));
                },
              });
              console.log(file, file.id, file.source);
              dispatch(successUploadFile(file.id, file.source));
            } catch (error) {
              dispatch(failureUploadFile(file.id));
            }
          };
          reader.readAsDataURL(file.file);
        }
        //   console.log(attrs);
      });
    }
  };

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {};

  for (let i = 0; i < files.length; i++) {
    const id = size(existingFiles) + i + 1;
    // const CancelToken = axios.CancelToken  --> can be used for cancelling upload progress
    // const source = CancelToken.source()

    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
        // cancelSource: source,
      },
    };
  }

  return fileToUpload;
};
