/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Heatmap from "components/BubbleView/Heatmap";

import { parseBubbleViewData } from "utils/dataProcessing";

import { url } from "actions/index";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import {
  Grid,
  Typography,
  Paper,
  Divider,
  TextField,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";

function Dashboard(props) {
  const { experimentId, responses, demographicData, demographicQuestions } =
    props;

  const numOfResponses = Object.keys(responses).length;

  const [data, setData] = useState([]);

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    // charts: state.charts,
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  // const imgOriginalEl = useRef(null);

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseBubbleViewData(responses);
        setData(result);
      }
    }
    fetch();
  }, [responses.length]);

  const [demographicColumn, setDemographicColumn] = useState({});
  const handleDemographicFilter = (e, value) => {
    if (value === null) value = {};
    setDemographicColumn(value);
  };

  const initialColumnNames = charts.map((c, i) => "Visualization" + (i + 1));
  const [columnNames, setColumnNames] = useState(initialColumnNames);

  useEffect(() => {
    if (Object.keys(demographicColumn).length > 0) {
      // add demographic questions into TableHead after 'Condition'
      let cNames = [
        // ...initialColumnNames.slice(0, 1),
        demographicColumn.text,
        ...initialColumnNames.slice(0),
      ];
      setColumnNames(cNames);
    }
  }, [demographicColumn]);

  const renderTable = (
    columnNames,
    chartIds,
    demographicColumn,
    dependentVar
  ) => {
    return (
      <>
        {Object.keys(demographicColumn).length > 0 && (
          <>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columnNames.map((el, i) => (
                      <TableCell align="left" key={i}>
                        {el}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demographicColumn.options.map((o) => (
                    <Fragment key={o.id}>
                      <TableRow>
                        <TableCell align="left">{o.text}</TableCell>

                        {charts.map((chart) => (
                          <TableCell key={chart.id + o.id}>
                            <Heatmap
                              width={200}
                              height={200}
                              imgUrl={chart.source}
                              data={data
                                .filter(
                                  (d) => d.demographicSurvey !== undefined
                                )
                                .filter(
                                  (d) =>
                                    d.demographicSurvey[
                                      demographicColumn.id
                                    ] === o.id
                                )
                                .filter((d) => d.chartId === chart.id)}
                              // .reduce((a, b) => a.clicks.concat(b.clicks))}
                              bubble={tasks[0].params.bubble}
                              numOfResponses={numOfResponses}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  const [ccScore, setCCScore] = useState(0);
  let ccScoreList = [];
  async function fetchCCScore(data1, data2) {
    const result = await axios({
      baseURL: url,
      url: "/cc_score",
      method: "post",
      data: {
        data1: data1,
        data2: data2,
      },
    });
    setCCScore(result.data.result);
  }

  useEffect(() => {
    ccScoreList.push(ccScore);
  }, [ccScore]);

  return (
    <>
      {data.length > 0 && charts.length > 0 ? (
        <>
          <Box mt={1}>
            <Alert severity="warning" style={{ marginTop: "10px" }}>
              This result is not a scientific fact but could be used as a design
              feedback.
            </Alert>
          </Box>
          <Box mt={3}>
            <Typography variant="h5" display="block" gutterBottom>
              Overview
            </Typography>
          </Box>

          <Grid item container spacing={2} direction="row">
            {charts.map((chart) => (
              <Fragment key={chart.id}>
                <Grid item xs={6}>
                  <Heatmap
                    imgUrl={chart.source}
                    data={data.filter((d) => d.chartId === chart.id)}
                    // .reduce((a, b) => a.clicks.concat(b.clicks))}
                    bubble={tasks[0].params.bubble}
                    numOfResponses={numOfResponses}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Box mt={6}>
            <Typography variant="h5" display="block" gutterBottom>
              Breakdown Report
            </Typography>
            <Typography variant="subtitle1" display="block" gutterBottom>
              Choose a demographic factor to analyze reading patterns.
            </Typography>
          </Box>

          <Box mt={3}>
            <Autocomplete
              disabled={Object.keys(demographicData).length === 0}
              // multiple
              freeSolo
              options={demographicQuestions ? demographicQuestions : []}
              getOptionLabel={(option) => option.text}
              filterSelectedOptions
              onChange={handleDemographicFilter}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Demographic factor"
                  size="small"
                />
              )}
            />
          </Box>
          <Box mt={2}>
            {Object.keys(demographicData).length > 0 &&
              renderTable(columnNames, charts, demographicColumn)}
          </Box>
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
