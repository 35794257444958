import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateExperiment } from "actions/experiments";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { titleAscending } from "utils/date";
import { DEFAULT_TEMPLATE_TITLE_DESCRIPTION } from "utils/textResources";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

function TitleIntroduction(props) {
  const { experimentId, readOnly } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const [defaultTitle, defaultDesc] = Object.values(
    DEFAULT_TEMPLATE_TITLE_DESCRIPTION[experiment.type]
  );
  const [title, setTitle] = useState(experiment.title);
  const [desc, setDesc] = useState(experiment.desc);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleUpdate = (event) => {
    dispatch(updateExperiment(experimentId, { title: title }));
  };

  const handleDescChange = (event) => {
    setDesc(event.target.value);
  };

  const handleDescUpdate = (event) => {
    dispatch(updateExperiment(experimentId, { desc: desc }));
  };
  // console.log(defaultTitle, defaultDesc);
  return (
    // <div className={classes.root}>
    //   <div>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        Describe your experiment with a compelling title and description to engage curious participants.
      </Grid>
      <Grid item xs={8}>
        <TextField
          label="Title"
          fullWidth
          multiline
          // style={{ width: 400 }}
          placeholder={title === defaultTitle ? title : undefined}
          value={title === defaultTitle ? undefined : title}
          onChange={handleTitleChange}
          onBlur={handleTitleUpdate}
          variant="filled"
          size="small"
          InputLabelProps={{ shrink: true }}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          // style={{ width: 400 }}
          multiline
          rows={4}
          label="Description"
          placeholder={desc === defaultDesc ? desc : undefined}
          value={desc === defaultDesc ? null : desc}
          onChange={handleDescChange}
          onBlur={handleDescUpdate}
          variant="filled"
          size="small"
          InputLabelProps={{ shrink: true }}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
    //   </div>
    // </div>
  );
}

export default TitleIntroduction;
