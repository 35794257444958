import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Result from "components/BubbleView/Result";

import { updateResponse } from "actions/responses";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";
import {
  parseBubbleViewData,
  parseBubbleViewDataForExport,
} from "utils/dataProcessing";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
// import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import _ from "underscore";
import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  columns: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    width: "100%",
    margin: "0",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  header: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

export default function IndividualDashboard(props) {
  const { experimentId, responseId, responses } = props;
  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, props),
  }));

  const getCharts = makeGetCharts();
  const { charts } = useSelector((state) => ({
    charts: getCharts(state, {
      taskId: tasks[0].id,
    }),
  }));

  useEffect(() => {
    async function fetch() {
      if (responses.length > 0) {
        const result = await parseBubbleViewData(responses, true);

        setData(result);
      }
    }
    fetch();
  }, [responses.length]);

  function CollapseRow(props) {
    const { data } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const { omitState } = useSelector((state) => ({
      omitState: state.responses[data.id].omit,
    }));
    const handleOmitCheck = (event) => {
      dispatch(updateResponse(data.id, { omit: event.currentTarget.checked }));
    };

    return (
      <>
        {/* {Object.keys(data).length > 0 ? ( */}
        <Fragment key={data.id}>
          <TableRow>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {/* <TableCell align="inherit" component="th" scope="row"> */}
            <TableCell align="left">{data.ip}</TableCell>
            {/* <TableCell align="center">{data.screening}</TableCell> */}
            {charts.map((chart, index) => (
              <Fragment key={index}>
                <TableCell align="right">
                  {data[chart.id + "_clicks"]}
                </TableCell>
                <TableCell align="right">
                  {parseInt(data[chart.id + "_time"]).toFixed(2)} sec
                </TableCell>
              </Fragment>
            ))}

            <TableCell align="right" padding="checkbox">
              <Checkbox checked={omitState} onChange={handleOmitCheck} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "none",
              }}
              colSpan={12}
            >
              <Collapse in={open} timeout="auto">
                {open && (
                  <Box p={3} bgcolor="#f5f5f5">
                    <Result
                      key={data.id}
                      experimentId={experimentId}
                      responseId={data.id}
                      responses={responses}
                      isResult={false}
                    />
                  </Box>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
        {/* ) )} */}
      </>
    );
  }

  function ExpandableRow(props) {
    const { rows } = props;
    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Identifier</TableCell>
              {/* <TableCell align="inherit">Screening</TableCell> */}
              {charts.map((chart, index) => (
                <Fragment key={index}>
                  <TableCell align="right">
                    Vis{index + 1} Click Count
                  </TableCell>
                  <TableCell align="right">
                    Vis{index + 1} Elapsed Time (sec)
                  </TableCell>
                </Fragment>
              ))}
              <TableCell align="right">Omit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <CollapseRow key={i} data={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  async function fetchDataforExport() {
    if (responses.length > 0) {
      const result = await parseBubbleViewDataForExport(responses);
      setDataForExport(result);
    }
  }

  useEffect(() => {
    if (dataForExport.length > 0) {
      console.log(dataForExport);
      let csvContent =
        "data:text/csv;charset=utf-8," +
        dataForExport.map((e) => Object.values(e).join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", experimentId + "_result.csv");
      // console.log(link);
      document.body.appendChild(link);
      link.click();

      charts.map((chart) => {
        var img = document.createElement("a");
        img.setAttribute("href", chart.source);
        img.setAttribute("download", chart.id + ".png");
        document.body.appendChild(img);
        img.click();
      });
    }
  }, [dataForExport]);

  const handleExport = () => {
    fetchDataforExport();
  };

  return (
    <>
      {/* {Object.keys(data).length > 0 && ( */}
      {data.length > 0 ? (
        <>
          <ExpandableRow rows={data} />
          {/* {EXPERIMENTAL_FEATURE && ( */}
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
            style={{ marginTop: "25px" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onPointerUp={handleExport}
            >
              Export
            </Button>
          </Grid>
          {/* )} */}
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item>
            <Typography variant="h6">No Responses</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
