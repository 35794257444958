import uniqueId from "utils/uniqueId";
import { createSelector } from "reselect";
import { alertServerError } from "actions/ui";
import { url, requestInfo } from "actions/index";
// import { push } from 'react-router-redux';

// action types
export const CREATE_RESPONSE = "CREATE_RESPONSE";
export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const DELETE_RESPONSE = "DELETE_RESPONSE";
export const CHECK_DUPLICATED_RESPONSE = "CHECK_DUPLICATED_RESPONSE";
// export const RECEIVE_FORM_CONTENT = 'RECEIVE_FORM_CONTENT';
// export const REQUEST_RESPONSE_CONTENT = 'REQUEST_RESPONSE_CONTENT';
// export const RECEIVE_RESPONSE_CONTENT = 'RECEIVE_RESPONSE_CONTENT';

const path_dict = {
  CREATE_RESPONSE: "create_response",
  UPDATE_RESPONSE: "update_response",
  DELETE_RESPONSE: "delete_response",
  // REQUEST_RESPONSE_CONTENT: 'request_response_content'
};

// actions
export const createResponse = (experimentId, history, liveMode, attrs = {}) => {
  return async (dispatch) => {
    let responseId = uniqueId("response_");
    let createdAt = Date.now();
    if (attrs.name === null) {
      attrs.name = responseId.replace("response_", "");
    }
    attrs = {
      ...attrs,
      experimentId,
      id: responseId,
      omit: false,
      createdAt: createdAt,
      updatedAt: createdAt,
    };
    let action = { type: CREATE_RESPONSE, responseId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];

    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
          // if (liveMode) {
          history.push(
            `/experiment/${experimentId}/response/${responseId.replace(
              "response_",
              ""
            )}`
          );
          // } else {
          //   console.log(responseId);
          //   localStorage.removeItem("responseId");
          //   localStorage.setItem("responseId", responseId);
          // }
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const updateResponse = (responseId, attrs) => {
  return async (dispatch) => {
    // console.log(attrs);
    attrs = {
      ...attrs,
      updatedAt: Date.now(),
    };
    let action = { type: UPDATE_RESPONSE, responseId, attrs };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify(action),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const deleteResponse = (responseId) => {
  return async (dispatch) => {
    let action = { type: DELETE_RESPONSE, responseId };
    // dispatch(action);
    let path = path_dict[action.type];
    return await fetch(`${url}/${path}`, {
      ...requestInfo,
      body: JSON.stringify({ responseId: responseId }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp);
        // dispatch(receiveExperiments(resp.data));
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

// 9/19
export const checkDuplicatedResponse = (responseIP) => {
  return async (dispatch) => {
    let action = { type: CHECK_DUPLICATED_RESPONSE, responseIP };
    return await fetch(`${url}/${"check_duplicated_response"}`, {
      ...requestInfo,
      body: JSON.stringify({ responseIP: responseIP }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          dispatch(action);
        }
      })
      .catch((error) => dispatch(alertServerError(error.message)));
  };
};

export const makeGetResponses = () => {
  return createSelector(
    (state, props) => props.experimentId,
    (state) => state.responses,
    (experimentId, responses) =>
      Object.values(responses).filter(
        (response) => response.experimentId === experimentId
      )
  );
};
