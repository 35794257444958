import { myFirebase, auth } from "components/firebase.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { EXPERIMENTAL_FEATURE } from "index";

export const SAVE_USER = "SAVE_USER";
// export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
// export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
// export const LOGIN_VERIFIED = "LOGIN_VERIFIED";
// export const VERIFY_REQUEST = "VERIFY_REQUEST";
// export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
// export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const SET_ACCOUNT_EMAIL = "SET_ACCOUNT_EMAIL";

export const saveUser = (user) => {
  return {
    type: SAVE_USER,
    user: { ...user },
  };
};

// const requestLogin = () => {
//   return {
//     type: LOGIN_REQUEST,
//   };
// };

const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user: { ...user },
  };
};

// const verifyLogin = (user) => {
//   return {
//     type: LOGIN_VERIFIED,
//     user: { ...user },
//   };
// };

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

// const requestLogout = () => {
//   return {
//     type: LOGOUT_REQUEST,
//   };
// };

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

// const verifyRequest = () => {
//   return {
//     type: VERIFY_REQUEST,
//   };
// };

// const verifySuccess = () => {
//   return {
//     type: VERIFY_SUCCESS,
//   };
// };

// const requestSignup = () => {
//   return {
//     type: SIGNUP_REQUEST,
//   };
// };

const signupSuccess = (user) => {
  // console.log(user);
  return {
    type: SIGNUP_SUCCESS,
    user,
    user: { ...user },
  };
};

const signupError = (error) => {
  return {
    type: SIGNUP_FAILURE,
    error,
  };
};

const passwordResetSuccess = () => {
  return {
    type: PASSWORD_RESET_SUCCESS,
  };
};

const passwordResetError = () => {
  return {
    type: PASSWORD_RESET_FAILURE,
  };
};

const setAccountEmail = (email) => {
  return {
    type: SET_ACCOUNT_EMAIL,
    email,
  };
};
const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

export const signUp = (email, password) => {
  return async (dispatch) => {
    // dispatch(requestSignup());

    // if (EXPERIMENTAL_FEATURE) {
    //   myFirebase
    //     .auth()
    //     .createUserWithEmailAndPassword(email, password)
    //     .then((dataBeforeEmail) => {
    //       myFirebase.auth().onAuthStateChanged(function (user) {
    //         user.sendEmailVerification();
    //       });
    //     })
    //     .then((dataAfterEmail) => {
    //       myFirebase.auth().onAuthStateChanged(function (user) {
    //         if (user.emailVerified) {
    //           sessionStorage.setItem("username", user.user.uid);
    //           localStorage.setItem("username", user.user.uid);
    //           dispatch(signupSuccess(user));
    //         } else {
    //           dispatch(
    //             signupError(
    //               "Something went wrong, we couldn't create your account. Please try again."
    //             )
    //           );
    //         }
    //       });
    //     });
    // } else {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // sessionStorage.setItem("username", user.user.uid);
        // localStorage.setItem("username", user.user.uid);
        const user = userCredential.user;
        console.log(user);
        dispatch(signupSuccess(user));
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        dispatch(signupError(error));
      });
    // }
  };
};
export const signIn = (email, password) => {
  return async (dispatch) => {
    // dispatch(requestLogin());
    // myFirebase
    // .auth()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (EXPERIMENTAL_FEATURE) {
          if (userCredential.user.emailVerified) {
            // sessionStorage.setItem("username", user.user.uid);
            // localStorage.setItem("username", user.user.uid);
            dispatch(loginSuccess(userCredential.user));
          }
        } else {
          // sessionStorage.setItem("username", user.user.uid);
          // localStorage.setItem("username", user.user.uid);
          console.log(userCredential);
          dispatch(loginSuccess(userCredential.user));
        }
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
};

export const signOut = (history) => {
  return async (dispatch) => {
    // dispatch(requestLogout());
    // myFirebase
    // .auth()
    auth
      .signOut()
      .then(() => {
        // sessionStorage.removeItem("username");t
        // localStorage.removeItem("username");
        dispatch(logoutSuccess());
        history.push("/login")
      })
      .catch((error) => {
        dispatch(logoutError());
      });
  };
};

// export const verifyAuth = () => {
//   return async (dispatch) => {
//     // dispatch(verifyRequest());
//     myFirebase.auth().onAuthStateChanged((user) => {
//       console.log(user);
//       if (user != null) {
//         if (EXPERIMENTAL_FEATURE) {
//           if (user.emailVerified) {
//             dispatch(verifyLogin(user));
//           }
//         } else {
//           dispatch(verifyLogin(user));
//         }
//         // dispatch(verifySuccess());
//       }
//       dispatch(verifySuccess());
//     });
//   };
// };

export const passwordReset = (email) => {
  return async (dispatch) => {
    // dispatch(requestPasswordReset());
    // myFirebase
    // .auth()
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // dispatch(receivePasswordReset());
        dispatch(passwordResetSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(passwordResetError());
      });
  };
};

export const passwordChange = (history, actionCode, passwordOne) => {
  return async (dispatch) => {
    // myFirebase
    //   .auth()
    //   .currentUser.updatePassword(passwordOne)
    //   .then(() => {
    //     history.push("/login");
    //   });
    confirmPasswordReset(auth, actionCode, passwordOne)
      .then(() => {
        history.push("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getEmailFromPasswordResetCode = (actionCode) => {
  return async (dispatch) => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        // console.log(email);
        // return email;
        dispatch(setAccountEmail(email));
      })
      .catch((error) => {
        console.log(error.code);
      });
  };
};
