import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  colorBlindnessTestintruc: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    fontSize: "12pt",
    cursor: "default",
    display: "none",
  },

  colorBlindnessTest: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    fontSize: "12pt",
    cursor: "default",
    display: "none",
  },

  colorBlindnessTestText: {
    position: "relative",
    fontSize: "14pt",
  },

  response: {
    width: "60px",
    align: "center",
    fontSize: "15px",
  },

  enterNumberError: {
    position: "relative",
    fontSize: "8pt",
    color: "#ff0000",
  },

  loading: {
    fontSize: "14pt",
    fontStyle: "italic",
  },
});

const answers = [
  {
    plate: 1,
    answer: 12,
  },
  {
    plate: 2,
    answer: 8,
  },
  {
    plate: 4,
    answer: 29,
  },
  {
    plate: 6,
    answer: 5,
  },
  {
    plate: 8,
    answer: 15,
  },
  {
    plate: 9,
    answer: 74,
  },
  {
    plate: 11,
    answer: 6,
  },
  {
    plate: 13,
    answer: 45,
  },
  {
    plate: 14,
    answer: 5,
  },
  {
    plate: 15,
    answer: 15,
  },
  {
    plate: 16,
    answer: 16,
  },
  {
    plate: 17,
    answer: 73,
  },
  {
    plate: 18,
    answer: 0,
  },
  {
    plate: 20,
    answer: 0,
  },
];

function ColorBlindnessTest(props) {
  const classes = useStyles();
  const { setIsValidSubmit, handleAnswers, isInclusion } = props;
  var testIndex = 0;
  var testNumbers = [1, 2, 4, 6, 8, 9, 11, 13, 14, 15, 16, 17, 18, 20];
  var testResponses = new Array();
  const [testText, setTestText] = useState(null);
  const [start, setStart] = useState(false);
  const [title, setTitle] = useState("Color Blindness Test");
  const imgEl = useRef(null);
  let responseState = useRef(null);
  let response = "";

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleReleaseError = () => {
    setShowError(false);
  };

  function ShowColorBlindnessTestInstruc() {
    setTestText(
      "The following is a test for color blindness. \n - You will be asked to view some images of number and enter the number that you see.\n - Some of the images will have no discernible number visible in this case you should enter the number 0. \n\n Before taking the test \n - Remove all glasses with colored lenses: The test is designed to be taken with the naked eye or with a prescription if you wear one. It will not work with colored lenses of any type and will lead to inaccurate results. \n - Turn brightness on screen to high: Light affects color and the ability to perceive differentiation. \n\nPress the Enter key to continue."
    );

    function pressEnter(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        document.removeEventListener("keydown", pressEnter);
        setStart(true);
        ShowColorBlindnessTest(e.keyCode);
      }
    }
    document.addEventListener("keydown", pressEnter);
  }

  useEffect(() => {
    ShowColorBlindnessTestInstruc();
  }, []);

  function ShowColorBlindnessTest(keyCode) {
    if (keyCode === 13) {
      // console.log("re enter");
      setTestText(
        "Please enter the number that you see in the picture. \n If you do not see a number in the picture type the number 0."
      );
      imgEl.current.src =
        process.env.PUBLIC_URL +
        "/img/IshiharaPlates/IshiharaPlate" +
        testNumbers[testIndex] +
        ".png";
      EnterResponse();
    }
  }

  function EnterResponse() {
    function pressKey(e) {
      if (e.keyCode === 13) {
        try {
          response = e.target.value;
          responseState.current.value = e.target.value;

          e.preventDefault();
          if (CheckNumber(response)) {
            if (response !== "") {
              testResponses.push(response);
              testIndex++;
              // setResponseState("");
              responseState.current.value = "";

              response = "";
              document.removeEventListener("keydown", pressKey);
              if (testIndex >= testNumbers.length) {
                // to main experiment
                ShowResult(testResponses);
                // setDone(true);
                return;
              }
              ShowColorBlindnessTest(e.keyCode);
            }
          }
        } catch (err) {
          console.log("no value", err.message);
        }
      }
    }
    document.addEventListener("keydown", pressKey);
  }

  function CheckNumber(response) {
    if (response.length == 0) {
      return false;
    }
    if (!isNaN(response)) {
      if (/\s/.test(response)) {
        setShowError(true);
        setErrorMsg(
          "Please remove all non-numbers from your response (including spaces!)"
        );
        // setResponseState("");
        responseState.current.value = "";

        response = "";

        return false;
      }
      if (!/\s/.test(response)) {
        // setResponseState("");
        responseState.current.value = "";

        response = "";
        return true;
      } else {
        setShowError(true);
        setErrorMsg("The value you have entered is invalid. Please try again.");
        // setResponseState("");
        responseState.current.value = "";

        response = "";
        return false;
      }
    } else {
      setShowError(true);
      setErrorMsg("The value you have entered is invalid. Please try again.");
      //   setResponseState("");
      responseState.current.value = "";
      response = "";
      return false;
    }
  }

  function ShowResult(testResponses) {
    // console.log(testResponses);
    imgEl.current.src = "";
    setStart(false);

    let score = 0;
    testResponses.map(function (res, index) {
      if (parseInt(res) === answers[index].answer) score += 1;
    });
    // console.log(score, isInclusion);

    /// Normal - only inclusion: excluded.
    /// normal - exclusion: included
    /// color blinded - only inclusion: included
    /// color blinded - exclusion: excluded
    let text = "";
    setTitle("Color Blindness Test Result");
    if (score > 11) {
      if (isInclusion === "Inclusion") {
        text = "Sorry! you can't participate in this experiment.";

        handleAnswers({ screening: { result: "normal", eligibility: false } });
        setIsValidSubmit(false);
      } else {
        text = "You can participate in this experiment.";

        handleAnswers({ screening: { result: "normal", eligibility: true } });
        setIsValidSubmit(true);
      }
      setTestText("You have normal color vision. " + text);
    } else {
      if (isInclusion === "Inclusion") {
        text = "You can participate in this experiment.";

        handleAnswers({
          screening: { result: "color-blinded", eligibility: true },
        });
        setIsValidSubmit(true);
      } else {
        text = "Sorry! you can't participate in this experiment.";

        handleAnswers({
          screening: { result: "color-blinded", eligibility: false },
        });
        setIsValidSubmit(false);
      }
      setTestText("You have red-green deficiency. " + text);
    }
  }

  return (
    <>
      <Grid container align="left">
        <Grid item xs>
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        align={start ? "center" : "left"}
        direction="column"
        spacing={2}
      >
        <Grid item xs>
          <Typography
            variant="subtitle1"
            style={{ whiteSpace: "pre-line", marginTop: "20px" }}
          >
            {testText}
          </Typography>
        </Grid>
      </Grid>
      <Grid container align="center" style={{ marginTop: "20px" }}>
        <Grid item xs>
          <img ref={imgEl} width={400}></img>
        </Grid>
        <Grid item xs>
          {start && (
            <>
              <TextField
                autoFocus
                variant="outlined"
                label="Press 'Enter' to continue"
                inputRef={responseState}
                size="small"
                style={{ width: 400 }}
              />
            </>
          )}
        </Grid>
      </Grid>

      {showError && (
        <Snackbar open={errorMsg !== null} autoHideDuration={1000}>
          <Alert
            onClose={() => handleReleaseError()}
            variant="filled"
            severity="error"
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ColorBlindnessTest;
