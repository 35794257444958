import React from "react";
import { Box, Tooltip } from "@material-ui/core/";
import { Vega } from "react-vega";

export default function ErrorBar1D(props) {
  const { name, data, myData, text, size, dependentVar, domainMin, domainMax } =
    props;
  // console.log(text, data, myData);
  return (
    <>
      {/* <Paper elevation={0} style={{ marginBotton: "0px" }}> */}
      <Tooltip
        title="This plot shows bootstrapped 95% confidence interval of the mean field value."
        placement="top"
      >
        <Box>
          <Vega
            actions={false}
            spec={
              text
                ? {
                    $schema: "https://vega.github.io/schema/vega/v5.json",
                    width: size,
                    padding: 5,
                    config: {
                      axisBand: {
                        bandPosition: 1,
                        tickExtra: true,
                        tickOffset: 0,
                      },
                    },
                    signals: [
                      {
                        name: "plotWidth",
                        value: 60,
                      },
                      {
                        name: "height",
                        update: "(plotWidth - 10)",
                      },
                    ],
                    data: [
                      {
                        name: "my",
                        values: myData,
                      },
                      {
                        name: "myStats_raw",
                        source: "my",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["responseId", "chartId"],
                            fields: [dependentVar],
                            ops: ["mean"],
                            as: ["mean"],
                          },
                        ],
                      },
                      {
                        name: "myStats",
                        source: "myStats_raw",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["chartId"],
                            fields: ["mean"],
                            ops: ["mean"],
                            as: ["mean"],
                          },
                        ],
                      },
                      {
                        name: "raw",
                        values: data,
                      },
                      {
                        name: "stats_raw",
                        source: "raw",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["responseId"],
                            fields: [dependentVar],
                            ops: ["mean"],
                            as: ["mean"],
                          },
                        ],
                      },
                      {
                        name: "stats",
                        source: "stats_raw",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["chartId"],
                            fields: ["mean", "mean", "mean", "mean", "mean"],
                            ops: ["ci0", "mean", "ci1", "count", "median"],
                            as: ["ci0", "mean", "ci1", "count", "median"],
                          },
                        ],
                      },
                    ],
                    scales: [
                      {
                        name: "layout",
                        type: "band",
                        range: "height",
                        domain: {
                          data: "raw",
                          field: "chartId",
                        },
                      },
                      {
                        name: "xscale",
                        type: "linear",
                        range: "width",
                        round: true,
                        domain: {
                          data: "raw",
                          field: dependentVar,
                        },
                        domainMin: domainMin,
                        domainMax: domainMax,
                        zero: false,
                        nice: true,
                      },
                      {
                        name: "hscale",
                        type: "linear",
                        range: [
                          0,
                          {
                            signal: "plotWidth",
                          },
                        ],
                      },
                    ],
                    axes: [
                      {
                        orient: "bottom",
                        scale: "xscale",
                        zindex: 1,
                        tickCount: 5,
                      },
                    ],
                    marks: [
                      {
                        type: "group",
                        data: [
                          {
                            name: "summary",
                            source: "stats",
                          },
                        ],
                        marks: [
                          {
                            type: "rect",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "steelblue",
                                },
                                height: {
                                  value: 4,
                                },
                                tooltip: {
                                  signal:
                                    // dependentVar === "time"
                                    //   ? "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' took between ' + format(datum.ci0, '0.1f') + ' and ' + format(datum.ci1, '0.1f') + ' seconds.'}"
                                    //   : "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' scored between ' + format(datum.ci0, '0.1%') + ' and ' + format(datum.ci1, '0.1%') + '.'}",
                                    dependentVar === "time"
                                      ? "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' took between ' + format(datum.ci0, '0.1f') + ' and ' + format(datum.ci1, '0.1f') + ' seconds.'}"
                                      : dependentVar === "accuracy"
                                      ? "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' scored between ' + format(datum.ci0/100, '0.1%') + ' and ' + format(datum.ci1/100, '0.1%') + '.'}"
                                      : "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' scored between ' + format(datum.ci0, '0.1f') + ' and ' + format(datum.ci1, '0.1f') + '.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "ci0",
                                },
                                x2: {
                                  scale: "xscale",
                                  field: "ci1",
                                },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "symbol",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "black",
                                },
                                // tooltip: {
                                //   signal:
                                //     dependentVar === "correct"
                                //       ? "{'':'Half of the participants scored lower than ' + format(datum.mean, '0.1%') + ' and half of the participants scored higher than ' + format(datum.mean, '0.1%') + '.'}"
                                //       : "{'':'Half of the participants took less than ' + format(datum.mean, '0.1f') + ' seconds and half of the participants took more than ' + format(datum.mean, '0.1f') + ' seconds.'}",
                                // },
                                tooltip: {
                                  signal:
                                    dependentVar === "accuracy"
                                      ? "{'':'The average accuracy of participants was ' + format(datum.mean, '0.1f') + '%. Half of the participants scored lower than ' + format(datum.median, '0.1f') + '% and half of the participants scored higher than ' + format(datum.median, '0.1f') + '%.'}"
                                      : dependentVar === "score"
                                      ? "{'':'The average score of participants was ' + format(datum.mean, '0.2f') + '. Half of the participants scored lower than ' + format(datum.median, '0.2f') + ' and half of the participants scored higher than ' + format(datum.median, '0.2f') + '.'}"
                                      : "{'':'The participants took an average of ' + format(datum.mean, '0.1f') + ' seconds. Half of the participants took less than ' + format(datum.median, '0.1f') + ' seconds and half of the participants took more than ' + format(datum.median, '0.1f') + ' seconds.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                size: { value: 150 },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "text",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                yc: {
                                  signal: "plotWidth / 2 + 15",
                                },
                                align: {
                                  value: "center",
                                },
                                baseline: {
                                  value: "bottom",
                                },
                                text: {
                                  value: "Mean",
                                },
                                fontSize: {
                                  value: 10,
                                },
                              },
                            },
                          },
                          {
                            type: "symbol",
                            from: {
                              data: "myStats",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "#ff7961",
                                },
                                tooltip: {
                                  signal:
                                    dependentVar === "accuracy"
                                      ? "{'':'Accuracy was ' + format(datum.mean, '0.1f') + '%.'}"
                                      : dependentVar === "score"
                                      ? "{'':'Score was ' + format(datum.mean, '0.2f') + '.'}"
                                      : "{'':'Completion time was ' + format(datum.mean, '0.1f') + ' seconds.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                size: { value: 150 },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "symbol",
                            from: {
                              data: "myStats",
                            },
                            encode: {
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                yc: {
                                  signal: "plotWidth / 6 - 5",
                                },
                                shape: {
                                  value: "arrow",
                                },
                                angle: {
                                  value: -180,
                                },
                                size: {
                                  value: 300,
                                },
                                strokeWidth: {
                                  value: 3,
                                },
                                stroke: {
                                  value: "#ff7961",
                                },
                                fill: {
                                  value: "#ff7961",
                                },
                              },
                            },
                          },
                          {
                            type: "text",
                            from: {
                              data: "myStats",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "#ff7961",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                yc: {
                                  signal: "-plotWidth + 50",
                                },
                                align: {
                                  value: "center",
                                },
                                baseline: {
                                  value: "bottom",
                                },
                                text: {
                                  value: name,
                                },
                                fontWeight: {
                                  value: "bold",
                                },
                                fontSize: {
                                  value: 13,
                                },
                              },
                            },
                          },
                        ],
                      },
                    ],
                  }
                : {
                    $schema: "https://vega.github.io/schema/vega/v5.json",
                    width: 200,
                    padding: 5,
                    config: {
                      axisBand: {
                        bandPosition: 1,
                        tickExtra: true,
                        tickOffset: 0,
                      },
                    },
                    signals: [
                      {
                        name: "plotWidth",
                        value: 60,
                      },
                      {
                        name: "height",
                        update: "(plotWidth - 10)",
                      },
                    ],
                    data: [
                      {
                        name: "my",
                        values: myData,
                      },
                      {
                        name: "raw",
                        values: data,
                      },
                      {
                        name: "stats_raw",
                        source: "raw",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["responseId"],
                            fields: [dependentVar],
                            ops: ["mean"],
                            as: ["mean"],
                          },
                        ],
                      },
                      {
                        name: "stats",
                        source: "stats_raw",
                        transform: [
                          {
                            type: "aggregate",
                            groupby: ["chartId"],
                            fields: ["mean", "mean", "mean", "mean", "mean"],
                            ops: ["ci0", "mean", "ci1", "count", "median"],
                            as: ["ci0", "mean", "ci1", "count", "median"],
                          },
                        ],
                      },
                    ],
                    scales: [
                      {
                        name: "layout",
                        type: "band",
                        range: "height",
                        domain: {
                          data: "raw",
                          field: "chartId",
                        },
                      },
                      {
                        name: "xscale",
                        type: "linear",
                        range: "width",
                        round: true,
                        domain: {
                          data: "raw",
                          field: dependentVar,
                        },
                        domainMin: domainMin,
                        domainMax: domainMax,
                        zero: false,
                        nice: true,
                      },
                      {
                        name: "hscale",
                        type: "linear",
                        range: [
                          0,
                          {
                            signal: "plotWidth",
                          },
                        ],
                        // domain: {
                        //   data: "density",
                        //   field: "density",
                        // },
                      },
                      {
                        name: "color",
                        type: "ordinal",
                        domain: {
                          data: "raw",
                          field: "chartId",
                        },
                        range: "category",
                      },
                    ],
                    axes: [
                      {
                        orient: "bottom",
                        scale: "xscale",
                        zindex: 1,
                        tickCount: 5,
                      },
                    ],
                    marks: [
                      {
                        type: "group",
                        data: [
                          {
                            name: "summary",
                            source: "stats",
                          },
                        ],
                        marks: [
                          {
                            type: "rect",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "steelblue",
                                },
                                height: {
                                  value: 4,
                                },
                                tooltip: {
                                  signal:
                                    dependentVar === "accuracy"
                                      ? "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' scored between ' + format(datum.ci0/100, '0.1%') + ' and ' + format(datum.ci1/100, '0.1%') + '.'}"
                                      : "{'':'Around ' + format(0.95*datum.count, '.1d') + ' out of ' + datum.count + ' took between ' + format(datum.ci0, '0.1f') + ' and ' + format(datum.ci1, '0.1f') + ' seconds.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "ci0",
                                },
                                x2: {
                                  scale: "xscale",
                                  field: "ci1",
                                },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "symbol",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "black",
                                },
                                tooltip: {
                                  signal:
                                    dependentVar === "time"
                                      ? "{'':'The participants took an average of ' + format(datum.mean, '0.1f') + ' seconds. Half of the participants took less than ' + format(datum.median, '0.1f') + ' seconds and half of the participants took more than ' + format(datum.median, '0.1f') + ' seconds.'}"
                                      : "{'':'The average accuracy of participants was ' + format(datum.mean, '0.1f') + '%. Half of the participants scored lower than ' + format(datum.median, '0.1f') + '% and half of the participants scored higher than ' + format(datum.median, '0.1f') + '%.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                size: { value: 150 },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "text",
                            from: {
                              data: "summary",
                            },
                            encode: {
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: "mean",
                                },
                                yc: {
                                  signal: "plotWidth / 2 + 15",
                                },
                                align: {
                                  value: "center",
                                },
                                baseline: {
                                  value: "bottom",
                                },
                                text: {
                                  value: "Mean",
                                },
                                fontSize: {
                                  value: 10,
                                },
                              },
                            },
                          },
                          {
                            type: "symbol",
                            from: {
                              data: "my",
                              // data: "myStats",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "#ff7961",
                                },
                                tooltip: {
                                  signal:
                                    dependentVar === "accuracy"
                                      ? "{'':'Accuracy was ' + format(datum.accuracy, '0.1f') + '%.'}"
                                      : dependentVar === "score"
                                      ? "{'':'Score was ' + format(datum.score, '0.2f') + '.'}"
                                      : "{'':'Completion time was ' + format(datum.time, '0.2f') + ' seconds.'}",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: dependentVar,
                                },
                                size: { value: 150 },
                                yc: {
                                  signal: "plotWidth / 2 - 5",
                                },
                              },
                            },
                          },
                          {
                            type: "text",
                            from: {
                              data: "my",
                            },
                            encode: {
                              enter: {
                                fill: {
                                  value: "#ff7961",
                                },
                              },
                              update: {
                                x: {
                                  scale: "xscale",
                                  field: dependentVar,
                                },
                                yc: {
                                  signal: "plotWidth - 45",
                                },
                                align: {
                                  value: "center",
                                },
                                baseline: {
                                  value: "bottom",
                                },
                                text: {
                                  value: name,
                                },
                                fontWeight: {
                                  value: "bold",
                                },
                                fontSize: {
                                  value: 13,
                                },
                              },
                            },
                          },
                        ],
                      },
                    ],
                  }
            }
          />
        </Box>
      </Tooltip>
    </>
  );
}
