import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ExperimentThumbnail from "components/ExperimentThumbnail";

import { createExperiment } from "actions/experiments";
import { selectExperiment } from "actions/ui";
import {
  requestExperiments,
  makeGetExperimentsByUser,
} from "actions/experiments";
import {
  createdDateDescending,
  createdDateAscending,
  titleDescending,
  titleAscending,
  templateAscending,
} from "utils/date";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
const useStyles = makeStyles((theme) => ({
  experimentList: {
    display: "flex",
    flexFlow: "row wrap",
    marginTop: "20px",
    width: "100%",
  },
  addButton: {
    fontSize: "25px",
    position: "relative",
    margin: "0px 15px 15px 0px",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    boxSizing: "border-box",
    border: "2px dashed #212121",
    userSelect: "none",
    overflow: "visible",
    transition: "all 0.2s",
    cursor: "pointer",
    width: "200px",
    height: "200px",
  },
  card: {
    width: "100%",
    cursor: "pointer",
    height: "200px",
  },
  cardDesc: {
    height: "80px",
  },
  sortBy: {
    marginTop: theme.spacing(3),
  },
}));

function ExperimentsPage() {
  let history = useHistory();

  const [sortFunc, setSortFunc] = useState("dateDescending");

  const getExperiments = makeGetExperimentsByUser();
  const { username, experiments } = useSelector((state) => ({
    username: state.auth.user.uid,
    experiments: getExperiments(state),
  }));

  let uname = username;
  if (!username) {
    uname = localStorage.getItem("username");
    if (!uname) {
      uname = sessionStorage.getItem("username");
    }
  }
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleDeselect = () => {
    dispatch(selectExperiment(null));
  };
  const handleCreateGraphicalPerception = (e) => {
    e.stopPropagation();
    dispatch(createExperiment(uname, history, "Graphical Perception"));
  };
  const handleCreateMemorability = (e) => {
    e.stopPropagation();
    dispatch(createExperiment(uname, history, "Memorability"));
  };
  const handleCreateAttentionTracking = (e) => {
    e.stopPropagation();
    dispatch(createExperiment(uname, history, "Attention Tracking"));
  };
  useEffect(() => {
    // componentDidMount
    window.addEventListener("pointerup", handleDeselect);
    dispatch(requestExperiments(uname));

    // componentWillUnmount
    return () => {
      window.removeEventListener("pointerup", handleDeselect);
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [sortAnchorEl, setSortAnchorEl] = React.useState(null);

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
    setOpenSortMenu(false);
  };

  const handleSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
    setOpenSortMenu(true);
  };

  const [openSortMenu, setOpenSortMenu] = useState(false);

  const getSortedList = (_experiments, _sortFunc) => {
    if (sortFunc === "dateDescending") {
      _experiments.sort(createdDateDescending);
    } else if (sortFunc === "dateAscending") {
      _experiments.sort(createdDateAscending);
    } else if (sortFunc === "titleAtoZ") {
      _experiments.sort(titleAscending);
    } else if (sortFunc === "titleZtoA") {
      _experiments.sort(titleDescending);
    } else if (sortFunc === "template") {
      _experiments.sort(templateAscending);
    }

    return _experiments;
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-end"
          className={classes.sortBy}
        >
          <Button
            aria-controls="sort-menu"
            aria-haspopup="true"
            onClick={handleSortMenu}
            endIcon={
              openSortMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
          >
            Sort By
          </Button>
          <Menu
            anchorEl={sortAnchorEl}
            // keepMounted
            open={Boolean(sortAnchorEl)}
            onClose={handleSortMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleSortMenuClose}>
              <ListItemText
                primary="Date Created: Newest to Oldest"
                onClick={() => setSortFunc("dateDescending")}
              />
            </MenuItem>
            <MenuItem onClick={handleSortMenuClose}>
              <ListItemText
                primary="Date Created: Oldest to Newest"
                onClick={() => setSortFunc("dateAscending")}
              />
            </MenuItem>
            <MenuItem onClick={handleSortMenuClose}>
              <ListItemText
                primary="Title: A to Z"
                onClick={() => setSortFunc("titleAtoZ")}
              />
            </MenuItem>
            <MenuItem onClick={handleSortMenuClose}>
              <ListItemText
                primary="Title: Z to A"
                onClick={() => setSortFunc("titleZtoA")}
              />
            </MenuItem>
            <MenuItem onClick={handleSortMenuClose}>
              <ListItemText
                primary="Template"
                onClick={() => setSortFunc("template")}
              />
            </MenuItem>
          </Menu>
        </Grid>

        <Box className={classes.experimentList} onPointerUp={handleDeselect}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card
                className={classes.card}
                variant="outlined"
                onClick={handleAddClick}
              >
                <CardContent>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    pt={3}
                  >
                    <AddIcon style={{ fontSize: 64 }} />
                  </Box>

                  <Typography display="block" variant="button" align="center">
                    Create a new experiment
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {getSortedList(experiments, sortFunc).map((experiment) => (
              <Grid key={experiment.id} item xs={3}>
                <ExperimentThumbnail experimentId={experiment.id} />
              </Grid>
            ))}
          </Grid>
          <Menu
            anchorEl={anchorEl}
            // keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuList>
              <MenuItem onClick={handleClose}>
                <ListItemText
                  primary="Graphical Perception"
                  value="perception"
                  onPointerUp={handleCreateGraphicalPerception}
                />
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemText
                  primary="Memorability"
                  value="memorability"
                  onPointerUp={handleCreateMemorability}
                />
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemText
                  primary="Attention Tracking"
                  value="attention"
                  onPointerUp={handleCreateAttentionTracking}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Container>
    </>
  );
}

export default ExperimentsPage;
