import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DesignView from "components/DesignView";
import LiveView from "components/LiveView";
import DeployView from "components/DeployView";
import DashboardView from "components/DashboardView";
import RemixesView from "components/RemixesView";

import { requestExperimentContent } from "actions/experiments";
import { selectExperiment } from "actions/ui";
import { makeGetTasks } from "actions/tasks";

import { makeStyles, Tabs, Tab, Grid, Typography } from "@material-ui/core";
import { EXPERIMENTAL_FEATURE } from "index";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  menu: {
    display: "flex",
  },
  content: {
    width: "100%",
    margin: "5px",
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Experiment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { experimentId } = useParams();

  const { experiment, username } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
    username: state.auth.user.uid,
  }));

  const getTasks = makeGetTasks();
  const { tasks } = useSelector((state) => ({
    tasks: getTasks(state, {
      experimentId: experimentId,
    }),
  }));

  const [value, setValue] = useState(0);

  const [isLoading, setIsLoading] = useState(!tasks.length);

  useEffect(() => {
    async function fetchData() {
      if (isLoading) {
        await dispatch(requestExperimentContent(username, experimentId, true)); //retrieve form content from server
        await dispatch(selectExperiment(experimentId)); // when acceced directly from the url
      }
      if (tasks.length === 0) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const changeMode = (e, newValue) => {
    e.preventDefault();
    setValue(newValue);
  };

  const renderMode = () => {
    if (value === 0) {
      return <DesignView experimentId={experimentId} changeMode={changeMode} />;
    } else if (value === 2) {
      return (
        <DeployView
          experimentId={experimentId}
          isLaunched={experiment.isLaunched}
          experimentShareState={experiment.shareState}
          tags={experiment.tags}
          prolificCode={experiment.prolificCode}
        />
      );
    } else if (value === 3) {
      return (
        <DashboardView experimentId={experimentId} changeMode={changeMode} />
      );
    } else if (value === 1) {
      return (
        <LiveView
          experimentId={experimentId}
          username={username}
          previewMode={true}
          liveMode={false}
          changeMode={changeMode}
        />
      );
    } else if (value === 4) {
      return <RemixesView experimentId={experimentId} />;
    }
  };

  return (
    <>
      {tasks.length > 0 && experiment && (
        <>
          {value !== "Preview" && (
            <>
              <Grid
                container
                className={classes.root}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12}>
                  <Typography variant="h5" display="block" gutterBottom>
                    {experiment.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={changeMode}
                      centered
                    >
                      <Tab label="Design" id={0} style={{ minWidth: "18%" }} />
                      <Tab label="Preview" id={1} style={{ minWidth: "18%" }} />
                      <Tab label="Deploy" id={2} style={{ minWidth: "18%" }} />
                      <Tab label="Result" id={3} style={{ minWidth: "18%" }} />
                      {EXPERIMENTAL_FEATURE && (
                        <Tab
                          label="Remixes"
                          id={4}
                          style={{ minWidth: "18%" }}
                        />
                      )}
                    </Tabs>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <div className={classes.content}>
            {tasks.length > 0 && renderMode()}
          </div>
        </>
      )}
    </>
  );
};

export default Experiment;
