import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { signIn } from "actions";

import { PasswordForgetLink } from "components/Auth/PasswordForgetPage";
import { SignUpLink } from "components/Auth/SignupPage";
import { makeStyles } from "@material-ui/core/styles";

import {
  Avatar,
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Box,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  container: {
    // display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    marginTop: "50px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  errorText: {
    color: "#f50057",
    marginBottom: "5",
    textAlign: "center",
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    background: "black",
  },
}));

function LoginPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loginError, isAuthenticated } = useSelector((state) => ({
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
  }));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleSubmit = () => {
    dispatch(signIn(email, password));
  };
  // console.log(props.location);

  return (
    <>
      {isAuthenticated ? (
        <>
          <Redirect to="/experiment" />
        </>
      ) : (
        <>
          <Box className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Log In
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleEmailChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handlePasswordChange}
              />
              {loginError && (
                <Typography component="p" className={classes.errorText}>
                  Incorrect email or password.
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
              <Box m={3}>
                <PasswordForgetLink /> · <SignUpLink />
              </Box>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}

export default LoginPage;
