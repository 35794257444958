import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import ColorBlindnessTest from "components/Screening/ColorBlindnessTest";

import { updateExperiment } from "actions/experiments";
import { DEFAULT_SCREENING_DESCRIPTION } from "utils/textResources";
import {
  FormControlLabel,
  Switch,
  Radio,
  FormGroup,
  FormControl,
  RadioGroup,
  Grid,
  Typography,
} from "@material-ui/core";

function Screening(props) {
  const { experimentId, live, previewMode, setIsValidSubmit, handleAnswers } =
    props;
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);

  const { screening, showScreening, isInclusionState } = useSelector(
    (state) => ({
      screening: state.experiments[experimentId].screening,
      showScreening: state.experiments[experimentId].screening.show,
      isInclusionState: state.experiments[experimentId].screening.isInclusion,
    })
  );

  const [isInclusion, setIsInclusion] = useState(isInclusionState);

  useEffect(() => {
    if (setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    if (live) {
      if (done || previewMode) {
        setIsValidSubmit(true);
      }
    }
  }, [done, previewMode]);

  useEffect(() => {
    if (isInclusionState) {
      setIsInclusion(isInclusionState);
    }
  }, [isInclusionState]);

  useEffect(() => {
    dispatch(
      updateExperiment(experimentId, {
        screening: { ...screening, isInclusion: isInclusion },
      })
    );
  }, [isInclusion]);

  const handleButtonChange = (e) => {
    dispatch(
      updateExperiment(experimentId, {
        screening: { ...screening, show: e.target.checked },
      })
    );
  };

  const handleInclusionChange = (e) => {
    setIsInclusion(e.target.value);
    // dispatch(
    //   updateExperiment(experimentId, {
    //     screening: { ...screening, isInclusion: e.target.checked },
    //   })
    // );
  };

  return (
    <>
      {!live && (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showScreening || false}
                onChange={(e) => handleButtonChange(e)}
              />
            }
            label="Add Color-Blindness Test"
          />
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={isInclusion}
              onChange={handleInclusionChange}
            >
              <FormControlLabel
                value={"Inclusion"}
                control={<Radio />}
                label={
                  "Only the color blind can participate this experiment. The normal color vision cannot participate this experiment."
                }
                disabled={!showScreening}
              />
              <FormControlLabel
                value={"Exclusion"}
                control={<Radio />}
                label={
                  "Only the normal color vision can participate this experiment. The color blind cannot participate this experiment."
                }
                disabled={!showScreening}
              />
            </RadioGroup>
          </FormControl>
        </FormGroup>
      )}
      {live ? (
        <ColorBlindnessTest
          setIsValidSubmit={setIsValidSubmit}
          handleAnswers={handleAnswers}
          isInclusion={isInclusion}
        />
      ) : (
        <>
          <Grid container spacing={2} direction="row">
            <Grid item xs={4}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/img/IshiharaPlates/IshiharaPlate9.png"
                }
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" style={{ whiteSpace: "pre-line" }}>
                {DEFAULT_SCREENING_DESCRIPTION}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Screening;
