import {
  RECEIVE_EXPERIMENT_CONTENT,
  CREATE_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
} from "actions";

// reducers
export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EXPERIMENT_CONTENT:
      return action.surveys.reduce((acc, survey) => {
        return {
          ...acc,
          [survey.id]: survey,
        };
      }, state);
    case CREATE_SURVEY:
    case UPDATE_SURVEY:
      return {
        ...state,
        [action.surveyId]: {
          ...(state[action.surveyId] || {}),
          ...action.attrs,
        },
      };
    case DELETE_SURVEY: {
      let newState = {
        ...state,
      };
      delete newState[action.surveyId];
      return newState;
    }
    default:
      return state;
  }
};
