import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TitleIntroduction from "components/TitleIntroduction";
import Consent from "components/Consent";
import Demographic from "components/Demographic";
import PostSurvey from "components/PostSurvey";
import TutorialPractice from "components/TutorialPractice";
import Screening from "components/Screening/Screening";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { EXPERIMENTAL_FEATURE } from "index";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  stepper: {
    padding: 0,
  },
  stepButton: {
    display: "flex",
    alignItems: "center",
  },
}));

const ExperimentTemplate = (props) => {
  const { experimentId, type, readOnly } = props;

  const ExperimentComponent = props.experimentComponent;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(2);

  // const { experiment } = useSelector((state) => ({
  //   experiment: state.experiments[experimentId],
  // }));

  // for control next button's visibility and submit the response
  // const [isValidSubmit, setIsValidSubmit] = useState(false);
  const experimentDescText = {
    "Graphical Perception":
      "At this stage, you can design your experiment specifically. Add visualizations you want to compare. Add questions according to your purpose of the experiment.",
    "Attention Tracking":
      "At this stage, you can design your experiment specifically. Please upload visualizations that participants can look at. You can set parameters such as task type, mouse mode, blur and bubble size.",
    Memorability:
      "At this stage, you can design your experiment specifically. Please upload multiple images including the target images that you want participants to remember. You can set parameters such as Display time and Pause time.",
  };

  const getSteps = () => {
    return {
      Introduction: {
        required: false,
        index: 0,
        // description:
        //   "Enter the title of your experiment and add a brief description of the experiment below.",
      },
      // "Consent Form": 1,
      // ...(EXPERIMENTAL_FEATURE
      //   ? {
      Tutorial: {
        index: 1,
        required: false,
        // description:
        //   "You can add Instruction & Practice Trial for this experiment so that participants can easily understand the experiment.",
      },
      //   }
      // : {
      //     Instruction: {
      //       index: 1,
      //       description:
      //         "You can add Instruction for this experiment so that participants can easily understand the experiment.",
      //     },
      //   }),
      ...(EXPERIMENTAL_FEATURE
        ? {
            "Screening Test": {
              index: 2,
              required: false,
              // description:
              //   "You can set the inclusion and exclusion criteria for participants you want to recruit for your experiment at this stage (e.g., color blindness)",
            },
          }
        : {}),
      Task: {
        index: 3,
        required: true,
        description: experimentDescText[type],
      },
      "Demographic Survey": {
        required: false,
        index: 4,
        // description:
        //   "You can collect demographic information of participants. You can use some template questions that we’ve prepared in advance.",
      },
      "Post Survey": {
        index: 5,
        required: false,
        // description:
        //   "You can also ask participants for feedback and comments by adding some additional questions at the end of the experiment.",
      },
    };
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TitleIntroduction experimentId={experimentId} readOnly={readOnly} />
        );
      // case 1:
      //   return <Consent experimentId={experimentId} type={type} />;
      case 1:
        return (
          <TutorialPractice
            experimentId={experimentId}
            type={type}
            readOnly={readOnly}
          />
        );
      case 2:
        return (
          <Screening
            experimentId={experimentId}
            live={false}
            readOnly={readOnly}
          />
        );
      case 3:
        return (
          <ExperimentComponent
            experimentId={experimentId}
            readOnly={readOnly}
          />
        );
      case 4:
        return <Demographic experimentId={experimentId} readOnly={readOnly} />;
      case 5:
        return <PostSurvey experimentId={experimentId} readOnly={readOnly} />;
      default:
        return "Unknown step";
    }
  };
  const steps = getSteps();

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // useEffect(() => {
  //   if (activeStep === Object.keys(steps).length - 1) {
  //     // console.log(activeStep, Object.keys(steps).length - 1);
  //     setIsValidSubmit(true);
  //   }
  // }, [activeStep, steps]);

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.stepper}
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
      >
        {Object.entries(steps).map(([label, value], index) => (
          <Step key={label}>
            <StepButton
              onClick={handleStep(index)}
              className={classes.stepButton}
            >
              <Typography variant="h6">
                {label}{" "}
                {value.required && (
                  <Chip
                    label="Required"
                    size="small"
                    className={classes.chip}
                  />
                )}
              </Typography>

              {/* <Typography variant="subtitle1" display="block" gutterBottom>
                {value.description}
              </Typography> */}
            </StepButton>
            <StepContent>
              <div className={classes.actionsContainer}>
                {getStepContent(value.index)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default ExperimentTemplate;
