import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import NavBar from "components/NavBar";
import LandingPage from "components/LandingPage";

import LoginPage from "components/Auth/LoginPage";
import SignupPage from "components/Auth/SignupPage";
import PasswordForgetPage from "components/Auth/PasswordForgetPage";
import PasswordChangePage from "components/Auth/PasswordChangePage";
import VerifyEmail from "components/Auth/VerifyEmail";
// import Manage from "components/Memorability/Manage";

import ExperimentsPage from "components/ExperimentsPage";
import Experiment from "components/Experiment";
import ExperimentReadOnly from "components/ExperimentReadOnly";
import LiveView from "components/LiveView";
import ResponseView from "components/ResponseView";
import ExplorePage from "components/ExplorePage";
import ProtectedRoute from "components/Auth/ProtectedRoute";

import { releaseServerError } from "actions/ui";

import {
  Container,
  Button,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { auth } from "components/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { saveUser } from "actions/auth";

// import { history } from "configureStore";

const useStyles = makeStyles((theme) => ({
  snackbar: `
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #F44336;
    padding-left:10px;
    margin:5px;
  `,
  loadPageStyle: `
    position: absolute;
    left:0;
    width: 100vw;
    height: 100vh;
    line-height: 100vh;
    text-align:center;
    font-family: 'Roboto', sans-serif;
    font-size:42px;
    z-index: 1;
    background-color:white;
  `,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isFetching, serverError } = useSelector((state) => ({
    isFetching: state.ui.isFetching,
    serverError: state.ui.serverError,
  }));

  // let username = localStorage.getItem("username");
  // if (!username) {
  //   username = sessionStorage.getItem("username");
  // }

  // let username =
  //   localStorage.getItem("username") === sessionStorage.getItem("username")
  //     ? localStorage.getItem("username")
  //     : null;

  // console.log("username---------:", username);
  useEffect(() => {
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        // TODO: Implement getParameterByName()
        function getParameterByName(name) {
          // console.log(window.location.href);
          let params = window.location.href.split("?")[1];
          let result = {};
          if (params !== undefined) {
            params.split("&").map((param) => {
              let kv = param.split("=");
              result[kv[0]] = kv[1];
            });
            // console.log(result);
            return result[name];
          }
        }

        // Get the action to complete.
        var mode = getParameterByName("mode");
        // Get the one-time code from the query parameter.
        var actionCode = getParameterByName("oobCode");
        // (Optional) Get the continue URL from the query parameter if available.
        // var continueUrl = getParameterByName("continueUrl");
        //   // (Optional) Get the language code if available.
        // var lang = getParameterByName("lang") || "en";

        //   // Handle the user management action.
        switch (mode) {
          case "resetPassword":
            // Display reset password handler and UI.
            // handleResetPassword(actionCode, continueUrl, lang);
            history.push({
              pathname: "/password-change",
              actionCode: actionCode,
            });
            break;
          case "recoverEmail":
            // Display email recovery handler and UI.
            // handleRecoverEmail(actionCode, lang);
            break;
          case "verifyEmail":
            // Display email verification handler and UI.
            // handleVerifyEmail(actionCode, continueUrl, lang);
            // dispatch(verifyEmail())
            history.push({
              pathname: "/verify-email",
              actionCode: actionCode,
            });
            break;
          default:
          // Error: invalid mode.
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(saveUser(user.auth.currentUser));
        console.log(user);
      }
    });
  }, [auth, dispatch]);

  return (
    // <Router>
    <>
      <Route exact path="/" component={NavBar} />
      <Route exact path="/login" component={NavBar} />
      <Route exact path="/signup/" component={NavBar} />
      <Route exact path="/password-forget" component={NavBar} />
      <Route exact path="/password-change" component={NavBar} />
      <Route exact path="/experiment" component={NavBar} />
      <Route path="/experiment/:experimentId/edit" component={NavBar} />
      <Route
        path="/experiment/:experimentId/edit-readonly"
        component={NavBar}
      />
      <Route exact path="/explore" component={NavBar} />

      <Container maxWidth="md">
        {serverError && (
          <div className={classes.snackbar}>
            <div>
              Error: {serverError} - Probably something went wrong in the
              server. You may try refresh the page.
            </div>
            <Button onPointerUp={dispatch(releaseServerError())}>Close</Button>
          </div>
        )}
        {isFetching && (
          <Backdrop className={classes.backdrop} open={isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/password-forget" component={PasswordForgetPage} />
          <Route path="/password-change" component={PasswordChangePage} />
          <Route path="/verify-email" component={VerifyEmail} />
          {/* <Route
          path="/mode=resetPassword&oobCode=:code&apiKey=:key&lang=en"
          component={PasswordChangePage}
        /> */}
          <Route
            path="/experiment/:experimentId/response/:responseId"
            component={ResponseView}
          />
          <Route path="/experiment/:experimentId/share" component={LiveView} />

          {/* <Route path="/resetPassword">
          <Redirect to="/password-change" />
        </Route> */}
          {/* {mode === "resetPassword" && (
          <PasswordChangePage actionCode={actionCode} />
        )} */}

          <ProtectedRoute
            exact
            path="/experiment"
            component={ExperimentsPage}
          />
          <ProtectedRoute
            path="/experiment/:experimentId/edit"
            component={Experiment}
          />
          <ProtectedRoute
            path="/experiment/:experimentId/edit-readonly"
            component={ExperimentReadOnly}
          />
          <ProtectedRoute exact path="/explore" component={ExplorePage} />
        </Switch>
      </Container>
      {/* </Router> */}
    </>
  );
}

export default App;
