const StackBlur = window.StackBlur;
// const JsDiff = window.JsDiff;

var _bubbleR = 24;
var _blurR = 40;
// var VIEW_TIME = 10;
var userTask = null;
var image = null;
var canvas = null;

export const imageWidth = 500;
export const imageHeight = 500;
// export const imageWidth = 800; //1024;
// export const imageHeight = 800; //1024;

export function calcNewImageSize(
  imgWidth,
  imgHeight,
  canvasWidth,
  canvasHeight
) {
  var ratio = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight); //Math.min(, 1.0);
  if (ratio > 1.0) {
    ratio = 1.0;
  }
  return {
    width: imgWidth * ratio,
    height: imgHeight * ratio,
  };
}

function blurImage(img, radius, blurAlphaChannel) {
  var w = img.naturalWidth;
  var h = img.naturalHeight;

  const temp = document.createElement("canvas");
  temp.width = img.naturalWidth;
  temp.height = img.naturalHeight;
  temp.style.width = w + "px";
  temp.style.height = h + "px";

  const context = temp.getContext("2d");
  context.clearRect(0, 0, w, h);
  context.drawImage(img, 0, 0, w, h);

  if (isNaN(radius) || radius < 1) return;

  if (blurAlphaChannel) StackBlur.canvasRGBA(temp, 0, 0, w, h, radius);
  else StackBlur.canvasRGB(temp, 0, 0, w, h, radius);

  return temp;
}

function onClickDrawMask(e) {
  var ctx = canvas.getContext("2d");

  ctx.save();

  var rect = canvas.getBoundingClientRect();

  var x = e.clientX - rect.left;
  var y = e.clientY - rect.top;

  //reset previous cicle
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  var newSize = calcNewImageSize(
    image.naturalWidth,
    image.naturalHeight,
    canvas.width,
    canvas.height
  );
  // console.log(
  //   image.naturalWidth,
  //   image.naturalHeight,
  //   canvas.width,
  //   canvas.height
  // );
  // console.log(newSize);
  var blurred = blurImage(image, _blurR);
  ctx.drawImage(blurred, 0, 0, newSize.width, newSize.height);

  //draw the circle
  ctx.beginPath();
  ctx.arc(x, y, _bubbleR, 0, 6.28, false);
  ctx.clip();
  ctx.drawImage(image, 0, 0, newSize.width, newSize.height);

  ctx.arc(x, y, _bubbleR, 0, 2 * Math.PI, false);
  ctx.lineWidth = 2;
  ctx.strokeStyle = "#ff0000";
  ctx.stroke();
  ctx.restore();

  ctx.restore();
  if (userTask) {
    userTask.call(e, {
      image: image,
      timestamp: new Date().getTime(),
      cx: x,
      cy: y,
      radius: _bubbleR,
      blur: _blurR,
    });
  }
}

var MOUSE_MOVE_THRESHOLD = 25,
  lastMouseMoveTime = -1;
function mouseMoveCallback(ev) {
  var now = +new Date();
  if (now - lastMouseMoveTime < MOUSE_MOVE_THRESHOLD) return;
  lastMouseMoveTime = now;
  onClickDrawMask(ev);
}
export const setup = (
  imgSrc,
  canvasEl,
  bubbleR,
  blurR,
  mouse,
  task,
  pauseEvent
) => {
  userTask = task;
  canvas = canvasEl.current;
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  // canvas = null;
  // console.log(canvas.width, canvas.height);
  // console.log(pauseEvent);
  image = new Image();
  bubbleR = parseInt(bubble2value[bubbleR]);
  if (isNaN(bubbleR) || bubbleR <= 0) {
    return;
  }
  _bubbleR = bubbleR;
  blurR = parseInt(blur2value[blurR]);
  if (isNaN(blurR) || blurR <= 0) {
    return;
  }
  _blurR = blurR;
  // console.log(bubbleR, blurR);
  // console.log(mouse);
  image.onload = () => {
    // console.log(pauseEvent);
    if (!pauseEvent) {
      if (mouse === "click") {
        // console.log("hereererere");

        canvas.removeEventListener("mousemove", mouseMoveCallback);
        canvas.addEventListener("click", onClickDrawMask);
      } else {
        canvas.removeEventListener("click", onClickDrawMask);
        canvas.addEventListener("mousemove", mouseMoveCallback);
      }
    } else {
      if (mouse === "click") {
        // console.log("hereererere2");
        canvas.removeEventListener("click", onClickDrawMask);
      } else {
        canvas.removeEventListener("mousemove", mouseMoveCallback);
      }
    }
    // console.log(
    //   image.naturalWidth,
    //   image.naturalHeight,
    //   canvas.width,
    //   canvas.height
    // );

    // var ctx = canvas.getContext("2d");
    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    var newSize = calcNewImageSize(
      image.naturalWidth,
      image.naturalHeight,
      canvas.width,
      canvas.height
    );

    canvas.width = newSize.width;
    canvas.height = newSize.height;
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var blurred = blurImage(image, _blurR);
    ctx.drawImage(blurred, 0, 0, newSize.width, newSize.height);
    // console.log(newSize);
  };
  // console.log(canvas.width, canvas.height);
  image.src = imgSrc;
  // console.log(image);
};

export const blur2value = {
  small: 30,
  medium: 40,
  large: 50,
};

export const bubble2value = {
  small: 16,
  medium: 24,
  large: 32,
};

export function resizeCanvas(canvasID, containerID) {
  var container = containerID.current;
  // console.log(container.width, container.height);
  var newWidth = container.width;
  if (newWidth > 500) {
    newWidth = 500;
  }
  var canvas = canvasID.current;
  // console.log(canvas.width, canvas.height);
  canvas.width = parseInt(newWidth);
  canvas.height = parseInt(newWidth);
}
