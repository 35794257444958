import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ path, component: Component }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const location = useLocation();

  return (
    <Route
      path={path}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={location.pathname} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
