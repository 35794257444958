import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { requestExperimentContent } from "actions/experiments";
import { makeGetResponses } from "actions/responses";

import { default as PerceptionResult } from "components/Perception/Result";
import { default as BubbleViewResult } from "components/BubbleView/Result";
import { default as MemorabilityResult } from "components/Memorability/Result";

import {
  Button,
  Typography,
  makeStyles,
  Grid,
  Breadcrumbs,
  Link,
  Divider,
  Box,
  Fab,
  Snackbar,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "12.5%",
    marginRight: "12.5%",
  },
  content: {
    marginTop: theme.spacing(6),
  },
  table: {
    minWidth: 700,
  },
  columns: {
    width: "100%",
    display: "flex",
  },
  navBar: {
    width: "100%",
    margin: "0",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  header: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
}));

function ResponseView(props) {
  // console.log(props);
  const dispatch = useDispatch();
  let history = useHistory();

  const { liveMode } = props;
  const { username, loggedInUsername, experimentId, responseId } = useSelector(
    (state) => ({
      username: state.auth.user.uid,
      loggedInUsername: state.auth.user.uid,
      experimentId: props.match.params.experimentId,
      responseId: props.match.params.responseId,
    })
  );

  const { experiment } = useSelector((state) => ({
    experiment: state.experiments[experimentId],
  }));

  const getResponses = makeGetResponses();
  const { responses } = useSelector((state) => ({
    responses: getResponses(state, {
      experimentId: experimentId,
      // type: experiment ? experiment.type : "",
    }),
  }));
  const [isLoading, setIsLoading] = useState(!experiment);

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      let loggedInUsername = "";
      if (isLoading) {
        await dispatch(
          requestExperimentContent(loggedInUsername, experimentId, true)
        ); //retrieve form content from server
        // await dispatch(selectExperiment(experimentId));// when acceced directly from the url
      }
      if (experiment != null) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const renderResult = () => {
    if (experiment.type === "Graphical Perception") {
      return (
        <PerceptionResult
          // username={username}
          experimentId={experimentId}
          responseId={"response_" + responseId}
          responses={responses}
          isResult={true}
        />
      );
    } else if (experiment.type === "Attention Tracking") {
      return (
        <BubbleViewResult
          // username={username}
          experimentId={experimentId}
          responseId={"response_" + responseId}
          responses={responses}
          isResult={true}
        />
      );
    } else if (experiment.type === "Memorability") {
      return (
        <MemorabilityResult
          // username={username}
          experimentId={experimentId}
          responseId={"response_" + responseId}
          responses={responses}
          isResult={true}
        />
      );
    }
  };

  const handleFab = () => {
    history.push(`/experiment/${experimentId}/edit`);
  };
  return (
    <>
      {/* {liveMode && (
        <>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Breadcrumbs aria-label="breadcrumb">
                  <Button component={Link} to="/" size="large">
                    VisLab
                  </Button>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Grid>
        </>
      )} */}
      {username && username === loggedInUsername && (
        <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
          <Fab
            variant="extended"
            color="secondary"
            size="medium"
            aria-label="edit"
            // component={Link}
            // to={`/experiment/${experimentId}/edit`}
            onPointerUp={handleFab}
          >
            <EditIcon className={classes.editIcon} /> Edit
          </Fab>
        </Box>
      )}

      {experiment && (
        <>
          <div className={classes.content}>
            <Grid container align="center">
              <Grid item xs>
                <Typography variant="h5" display="block" gutterBottom>
                  Thanks for completing the experiment!
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider variant="fullWidth" />
          <Alert severity="warning" style={{ marginTop: "5px" }}>
            This result is not a scientific fact but could be used as a design
            feedback.
          </Alert>
        </>
      )}

      {experiment && <div className={classes.root}>{renderResult()}</div>}
    </>
  );
}

export default ResponseView;
