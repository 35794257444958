import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Survey from "components/Survey";

import { createSurvey, makeGetSurveys } from "actions/surveys";
import { updateExperiment } from "actions/experiments";

import { FormControlLabel, Switch, Grid, Box } from "@material-ui/core";

function PostSurvey(props) {
  const dispatch = useDispatch();
  const { experimentId, readOnly } = props;

  const getSurveys = makeGetSurveys();
  const { surveys, showPostSurvey } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: experimentId,
      type: "postSurvey",
    }),
    showPostSurvey: state.experiments[experimentId].showPostSurvey,
  }));

  const handlePostChange = (e) => {
    if (surveys.length === 0 && showPostSurvey === false) {
      dispatch(createSurvey(experimentId, { type: "postSurvey" }));
    }
    dispatch(
      updateExperiment(experimentId, { showPostSurvey: e.target.checked })
    );
  };

  return (
    <>
      <Box mb={2}>
        Feel free to add any additional questions to seek general feedback from
        participants or subjective preferences among the visualization design
        alternatives.
      </Box>

      <Grid container direction="column">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showPostSurvey || false}
                onChange={(e) => handlePostChange(e)}
                disabled={readOnly}
              />
            }
            label="Add Post Survey"
          />

          {/* {!showPostSurvey && (
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
            >
              If you add a post survey, participants are asked to leave opinions
              or comments. You can edit the default question or add another.
            </Typography>
          )} */}
        </Grid>
        <Grid item xs={8}>
          {showPostSurvey && (
            <>
              {surveys.length > 0 && (
                <Survey
                  surveyId={surveys[0].id}
                  type="postSurvey"
                  readOnly={readOnly}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PostSurvey;
