/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";

import {
  requestMemorabilityFiller,
  deleteMemorabilityFiller,
} from "actions/charts";
import { makeGetTasks } from "actions/tasks";
import { makeGetCharts } from "actions/charts";

import { calcNewImageSize } from "utils/bubbleView";
import { randomizeImageSequence } from "utils/memorability";

import {
  Typography,
  CircularProgress,
  Backdrop,
  MobileStepper,
  Button,
  makeStyles,
  withStyles,
  Grid,
  Box,
  Paper,
} from "@material-ui/core";
import { DEFAULT_MEMORABILITY_PARAMS } from "utils/textResources";
// const fillerName = 'chi2020';

const useStyles = makeStyles((theme) => ({
  overlayLarge: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  fit: {
    width: "100%",
    height: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StyledMobileStepper = withStyles({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    backgroundColor: "white",
  },
  progress: {
    width: "100%",
  },
})(MobileStepper);

let image, canvas;
var imgCount = 24;
var combinedImgSet = null;
var correctImgSet = [];
var incorrectImgSet = [];
let falseAlarmImgSet = [];
let fillers = [];
let vigilances = [];
let targets = [];
var firstRound = [];
let isFirst = false;
let ccc = 0;
let answerStatus = "neutral"; // "correct", "incorrect", "neutral"

function Practice(props) {
  // var combinedImgSet = null;

  // var RECOG_INTERVAL = 1.0;
  // var FIXATE_TIME = 1.5;
  var imgIndex = -1;
  // var countDown = RECOG_INTERVAL;
  var countdown = 5;
  const [countDown, setCountDown] = useState(5);

  var elapsed = 0.0;

  const {
    experimentId,
    handleAnswers,
    handleNextStep,
    isValidSubmit,
    handleSubmit,
    setIsValidSubmit,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCharts = makeGetCharts();
  const targetImages = [
    {
      id: "t0",
      source: process.env.PUBLIC_URL + "/img/Practices/bar.png",
    },
    {
      id: "t1",
      source: process.env.PUBLIC_URL + "/img/Practices/pie.png",
    },
  ];
  // const { targetImages, fillerImages } = useSelector((state) => ({
  //   targetImages: getCharts(state, {
  //     taskId: tasks[0].id,
  //   }),
  // }));
  const { fillerImages } = useSelector((state) => ({
    fillerImages: state.charts.fillers,
  }));

  // const [isLoading, setIsLoading] = useState(!fillerImages);
  const [isLoading, setIsLoading] = useState(true);

  const [displayTime] = useState(
    parseFloat(DEFAULT_MEMORABILITY_PARAMS.displayTime)
  );
  const [pauseTime] = useState(
    parseFloat(DEFAULT_MEMORABILITY_PARAMS.pauseTime)
  );

  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [isStart, setIsStart] = useState(false);

  const [response, setResponse] = useState(null);

  const canvasEl = useRef(null);
  const canvasContainerEl = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState();

  var recogTimer;
  // for stepper

  // const numOfTargetsAndVigilance = 6;
  const NUMBER_OF_FILLER_IMAGES = imgCount;
  // 8 + numOfTargetsAndVigilance - targetImages.length;
  const NUMBER_OF_VIGILANCE = 4;

  // onboarding
  const onBoardingSteps = [
    {
      content: <h2>Let's get to know the interface!</h2>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: "center",
      target: "body",
      disableOverlayClose: true,
    },
    // {
    //   target: ".practice-startButton",
    //   content: "To begin the experiment, click the Start button.",
    //   disableBeacon: true,
    //   disableOverlayClose: true,
    //   hideCloseButton: false,
    //   hideFooter: false,
    //   placement: "bottom",
    //   spotlightClicks: true,
    //   title: "Start",
    // },
    {
      target: ".practice-countdown",
      content: "After counting 5, the image sequence will begin.",
      title: "Countdown",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: "bottom",
      spotlightClicks: true,
    },
    {
      target: ".practice-image",
      title: "Visualization Sequence",
      content: (
        <div>
          Each chart will appear for {displayTime}s and disappears for{" "}
          {pauseTime}s. When a chart appears for the second time, please press
          the space bar.
          <br />
          If you answer correctly, you will see '
          <span style={{ font: "bold 30px Arial", color: "green" }}>+</span>' ,
          otherwise '<span style={{ font: "30px Arial" }}>x</span>'. If you
          don't response, you'll see '
          <span style={{ font: "30px Arial" }}>.</span>'.
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      spotlightClicks: true,
    },
    {
      target: ".practice-progressBar",
      title: "Progress Bar",
      content: "You can see the progress of the image sequence here.",
      placement: "bottom",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      spotlightClicks: true,
    },
    {
      target: "body",
      content: "Let's try some examples. You will see a sequence of 20 images.",
      title: "Now it's your turn.",
      disableOverlayClose: true,
      placement: "center",
    },
    // {
    //   content: <div>Our rate is off the charts!</div>,
    //   placement: 'bottom',
    //   spotlightClicks: true,
    //   target: this.growth!,
    //   title: 'Our Growth',
    // },
  ];
  const [onBoardingRun, setOnBoardingRun] = useState(true);
  const [onBoardingStepIndex, setOnBoardingStepIndex] = useState(0);

  async function fetchData() {
    if (isLoading) {
      await dispatch(requestMemorabilityFiller(NUMBER_OF_FILLER_IMAGES));
    }
    if (fillerImages !== undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (setIsValidSubmit) {
      setIsValidSubmit(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      dispatch(deleteMemorabilityFiller());
      // window.removeEventListener("keydown", pressSpaceBar);
      clearTimeout(recogTimer);
    };
  }, []);

  function pressSpaceBar(e) {
    // console.log(e);
    if (e.keyCode === 32) e.preventDefault(); //disable spacebar scrolling
    OnKeyPress(e.keyCode);
  }

  // useEventListener("keydown", pressSpaceBar);
  // const sequenceParams = {
  //   target: [3, 10, 6, 9], // min: 0, max: 28, min2: 91, max2: 109
  //   vigilance: [1, 14, 1, 5], //{ min: 1, max: 112, min2: 1, max2: 7 },
  // };

  useEffect(() => {
    if (!isLoading) {
      // let fillerImagesFilename = Object.values(fillerImages).map((v) => v);
      // let splitted = randomSplit(
      //   fillerImagesFilename,
      //   targetImages.length,
      //   numOfTargetsAndVigilance
      // );

      // fillers = splitted[0].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      // vigilances = splitted[1].map((fn) => ({
      //   id: fn.split(".")[0],
      //   source: process.env.PUBLIC_URL + "/fillers/" + fn,
      // }));
      targets = Object.values(targetImages).map((target) => {
        return {
          type: "t",
          id: target.id,
          source: target.source,
        };
      });

      [combinedImgSet, vigilances, fillers] = randomizeImageSequence(
        [6, 9],
        [1, 5],
        _.shuffle(targets),
        _.shuffle(fillerImages),
        imgCount,
        NUMBER_OF_VIGILANCE
      );
      // console.log(vigilances, fillers);

      console.log(vigilances.length, targets.length, fillers.length);
      // imgCount = fillers.length + vigilances.length * 2 + targets.length * 2;
      // console.log(imgCount);
      if (
        imgCount !==
        fillers.length + vigilances.length * 2 + targets.length * 2
      ) {
        throw "imgCount is not matched with fillers.length + vigilances.length * 2 + targets.length * 2.";
      }
      setTotalSteps(imgCount + 1);
      document.addEventListener("keydown", pressSpaceBar);
    }

    // combinedImgSet = [];
    // for (var i = 0; i < imgCount; i++) {
    //   combinedImgSet[i] = "";
    // }

    //   const sequenceParams = {
    //     target: { min: 3, max: 10, min2: 6, max2: 9 },
    //     vigilance: { min: 1, max: 14, min2: 1, max2: 5 },
    //   };
    //   // console.log(combinedImgSet);
    //   randomImageSequence(
    //     sequenceParams,
    //     combinedImgSet,
    //     _.shuffle(targets),
    //     "target"
    //   );
    //   // console.log(combinedImgSet);
    //   randomImageSequence(
    //     sequenceParams,
    //     combinedImgSet,
    //     _.shuffle(vigilances),
    //     "vigilance"
    //   );
    //   // console.log(combinedImgSet);
    //   /* All the repeat images appeared 91-109 images apart. Vigilance images Repeated twice with a spacing of 1-7 images */
    //   var j = 0;
    //   fillers = _.shuffle(fillers);
    //   for (var i = 0; i < imgCount; i++) {
    //     if (combinedImgSet[i] === "") {
    //       combinedImgSet[i] = fillers[j];
    //       j++;
    //     }
    //   }

    //   document.addEventListener("keydown", pressSpaceBar);
    // } else {
    //   if (fillerImages) {
    //     if (Object.keys(fillerImages).length > 0) {
    //       setIsLoading(false);
    //     }
    //   }
    // }

    return () => {
      document.removeEventListener("keydown", pressSpaceBar);
    };
  }, [isLoading]);

  const recordResponse = (ans) => {
    // console.log(ans);
    setResponse({
      target: ans.target,
      filler: ans.filler,
      vigilance: ans.vigilance,
      correct: ans.correct,
      incorrect: ans.incorrect,
      falseAlarm: ans.falseAlarm,
      firstRound: ans.firstRound,
    });
  };

  const showResult = () => {
    if (response) {
      const ntarget = response.target.length;
      const nfiller = response.filler.length;
      const nvigilance = response.vigilance.length;
      const ncorrect = response.correct.length;
      const nfalseAlarm = response.falseAlarm.length;
      // console.log(ncorrect, nfalseAlarm);
      const hitRate = ncorrect / (nvigilance + ntarget);
      const falseAlarmRate = nfalseAlarm / (nvigilance + ntarget + nfiller);

      // const score = ltqnorm(hitRate) - ltqnorm(falseAlarmRate);

      return (
        <Box m={2}>
          <Typography variant="body1" display="block" gutterBottom>
            You remembered{" "}
            <strong>
              {ncorrect} / {ntarget + nvigilance}
            </strong>{" "}
            repeated images. You also falsely recognized {nfalseAlarm} /
            {nfiller + nvigilance + ntarget} first-presented images as repeated.
          </Typography>
        </Box>
      );
    }
  };
  // const handleStart = () => {
  //   recognition();
  //   setIsStart(true);

  //   setOnBoardingStepIndex(2);
  // };
  useEffect(() => {
    if (!onBoardingRun) {
      // console.log(timer);
      recognition();
      setIsStart(true);
    }
  }, [onBoardingRun]);

  function OnKeyPress(keyCode) {
    if (keyCode === 32) {
      // user has pressed space
      try {
        const imgEl = canvasEl.current;
        const id = imgEl.dataset.id;

        if (id !== "") {
          var isRepeatable = _.isUndefined(
            _.find(fillers, function (obj) {
              return obj._id === id;
            })
          );
          if (isFirst) {
            falseAlarmImgSet.push(id);
            answerStatus = "incorrect";
          } else if (!isFirst && isRepeatable) {
            correctImgSet.push(id);
            answerStatus = "correct";
          }
        }

        return;
      } catch (error) {
        clearTimeout(recogTimer);
      }
    }
  }

  const [timer, setTimer] = useState(null);
  function recognition() {
    //update countdown timer
    recogTimer = setInterval(function () {
      elapsed += 0.5;
      if (imgIndex === -1) {
        if (elapsed % 1 === 0) {
          countdown -= 1;
          setCountDown((prev) => prev - 1);
          if (countdown === -1) {
            imgIndex = 0;
            elapsed = 0.0;

            if (onBoardingRun) {
              setOnBoardingStepIndex(2);
            }

            setIsValidSubmit(true);
          }
        }
      } else {
        if (elapsed === displayTime) {
          imageRender(canvasEl, "", "", "clear");
          // console.log(imgCount);
          if (imgCount === 0) {
            //move on to next stage
            clearTimeout(recogTimer);
            setIsValidNextStep(true);
            recordResponse({
              target: targets.map((target) => target.id),
              filler: fillers.map((filler) => filler.id),
              vigilance: vigilances.map((vigilance) => vigilance.id),
              correct: _.uniq(correctImgSet),
              incorrect: _.uniq(incorrectImgSet),
              falseAlarm: _.uniq(falseAlarmImgSet),
              firstRound: _.uniq(firstRound),
            });

            // window.removeEventListener("keydown", pressSpaceBar);
            return;
          }
        }

        //change image
        if (elapsed === pauseTime + displayTime) {
          let first = _.isUndefined(
            _.find(firstRound, function (obj) {
              return obj === combinedImgSet[imgIndex].id;
            })
          );
          if (first) {
            firstRound.push(combinedImgSet[imgIndex].id);
            isFirst = true;
          } else {
            isFirst = false;
            ccc++;
          }

          imageRender(
            canvasEl,
            combinedImgSet[imgIndex].source,
            combinedImgSet[imgIndex].id,
            "draw"
          );

          imgCount -= 1;
          imgIndex += 1;
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          elapsed = 0.0;
          if (imgIndex === combinedImgSet.length) {
            imgIndex = 0;
          }
        }
      }
    }, 500);
  }

  const imageRender = (canvasEl, imgSrc, imgId, mode) => {
    canvas = canvasEl.current;
    image = new Image();
    try {
      const ctx = canvas.getContext("2d");

      if (mode === "clear") {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (answerStatus === "neutral") {
          ctx.font = "80px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText(".", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "incorrect") {
          ctx.font = "50px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText("x", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "false alarm") {
          ctx.font = "50px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText("x", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        } else if (answerStatus === "correct") {
          ctx.font = "bold 50px Arial";
          ctx.fillStyle = "green";
          ctx.textAlign = "center";
          ctx.fillText("+", canvas.width / 2, canvas.height / 2);
          answerStatus = "neutral";
        }
      } else if (mode === "draw") {
        image.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const newSize = calcNewImageSize(
            image.naturalWidth,
            image.naturalHeight,
            canvas.width,
            canvas.height
          );
          ctx.drawImage(image, 0, 0, newSize.width, newSize.height);
        };
        image.src = imgSrc;
        canvas.dataset.id = imgId;
      } else {
      }
    } catch (error) {
      // console.log(error, 'imageRender');
      clearTimeout(recogTimer);
      // window.removeEventListener("keydown", pressSpaceBar);
    }
  };

  // console.log(onBoardingStepIndex);

  const handleJoyrideCallback = (CallBackProps) => {
    const { action, index, status, type } = CallBackProps;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setOnBoardingRun(false);
      setOnBoardingStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
        // recognition();
        setIsStart(true);
      } else if (index === 1) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 2) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 3) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);
      } else if (index === 4) {
        setOnBoardingRun(true);
        setOnBoardingStepIndex(stepIndex);

        // canvasEl.current.dataset.id = null;
        // const ctx = canvasEl.current.getContext("2d");
        // ctx.clearRect(0, 0, canvasEl.current.width, canvasEl.current.height);

        // clearInterval(timer);
        // clearTimeout(timer);
        // imgIndex = -1;
        // countdown = 5;
        // elapsed = 0.0;
        // setTotalSteps(
        //   fillers.length + vigilances.length * 2 + targets.length * 2
        // );
        // setActiveStep(0);
        // setCountDown(5);
        setIsStart(false);
        // setIsValidNextStep(false);
        // console.log(targetImages, fillerImages);
      } else {
        setOnBoardingStepIndex(stepIndex);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="practice-wrapper">
            <Joyride
              callback={handleJoyrideCallback}
              continuous={true}
              // getHelpers={getHelpers}
              run={onBoardingRun}
              steps={onBoardingSteps}
              stepIndex={onBoardingStepIndex}
              scrollToFirstStep={true}
              showProgress={true}
              showSkipButton={true}
              styles={{
                tooltipContainer: {
                  textAlign: "left",
                },
                tooltipContent: {
                  padding: "20px 0px",
                },
                options: {
                  zIndex: 10000,
                },
              }}
              floaterProps={{ disableAnimation: true }}
            />
            {/* {!isStart ? (
              <Grid container align="left">
                <Grid item xs>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    onPointerUp={handleStart}
                    className="practice-startButton"
                  >
                    Start
                  </Button>
                </Grid>
              </Grid>
            ) : null} */}
            <Typography variant="body1" display="block" gutterBottom>
              {isValidNextStep
                ? "Done!"
                : "Each chart appears for " +
                  displayTime +
                  "s and disappears for " +
                  pauseTime +
                  "s. When a chart appears for the second time, press space bar."}
            </Typography>
            <Grid container align="center" direction="column">
              {isStart && !isValidNextStep && (
                <Grid item xs>
                  <Box mt={2} mb={2} className="practice-progressBar">
                    <StyledMobileStepper
                      variant="progress"
                      steps={totalSteps}
                      position="static"
                      activeStep={activeStep}
                      color="secondary"
                    />
                  </Box>
                </Grid>
              )}
              {isValidNextStep && showResult()}
              {isStart && countDown > -1 && (
                <Grid item xs>
                  <Paper elevation={0} className="practice-countdown">
                    <Typography
                      variant="h6"
                      display="block"
                      color="secondary"
                      gutterBottom
                    >
                      Countdown: {countDown}
                    </Typography>
                  </Paper>
                </Grid>
              )}
              <Grid item xs>
                <Box width="512px" height="512px">
                  <Box
                    ref={canvasContainerEl}
                    mt={6}
                    className="practice-image"
                  >
                    <canvas ref={canvasEl} width={512} height={512} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default Practice;
