import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: "0px",
    padding: "0px",
  },
  // media: {
  //   height: 0,
  //   paddingTop: "56.25%", // 16:9
  // },
  content: {
    padding: "5px",
    margin: "5px",
  },
  actions: {
    position: "absolute",
    bottom: "0px",
  },
  chip: {
    // display: "flex",
    // justifyContent: "center",
    // flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  customBoxDesc: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-all",
    overflow: "hidden",
  },
}));

export default function ExperimentCard(props) {
  const classes = useStyles();
  const { title, desc, image, type, updatedAt, numOfResponses } = props;

  return (
    <Card className={classes.root}>
      {/* <CardMedia className={classes.media} image={image} title={title} height={40} /> */}
      <CardContent className={classes.content}>
        <Box
          fontSize="h6.fontSize"
          component="div"
          classes={{ root: classes.customBox }}
        >
          {title}
        </Box>
        <Box
          fontSize="body2.fontSize"
          component="div"
          classes={{ root: classes.customBoxDesc }}
          style={{ marginTop: "5px" }}
        >
          {desc}
        </Box>
      </CardContent>
      <CardActions className={classes.actions}>
        <Grid container direction="column">
          <Grid item spacing={2}>
            <Chip label={type} size="small" className={classes.chip} />
            {/* <Chip
              label={numOfResponses}
              size="small"
              className={classes.chip}
            /> */}
          </Grid>
          <Grid>
            <Typography variant="body2" color="textSecondary" component="p">
              {new Date(updatedAt).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
