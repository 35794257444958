import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Question from "components/Question";

// import { DEFALUT_QUESTION_LIST } from "utils/textResources";

import {
  createQuestion,
  deleteQuestion,
  makeGetQuestions,
} from "actions/questions";
import { selectQuestion } from "actions/ui";

import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "@material-ui/core/MobileStepper";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ShortTextIcon from "@material-ui/icons/ShortText";
import SubjectIcon from "@material-ui/icons/Subject";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  "& .MuiMobileStepper-root": {
    maxWidth: "100%",
  },
}));

function Survey(props) {
  // props
  // requireAnswer, type,
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const getOptions = makeGetOptions();
  const { surveyId, type, readOnly } = props;

  // const { selectedSurvey } = useSelector((state) => ({
  //   selectedSurvey: state.ui.selectedSurvey,
  // }));

  // useEffect(() => {
  //   dispatch(selectSurvey(surveyId));
  // }, [surveyId]);

  //   const { experimentId, question, options, selectedOption } = useSelector(
  //     (state) => ({
  //       experimentId: state.ui.selectedExperiment,
  //       question: state.questions[questionId],
  //       options: getOptions(state, props),
  //       selectedOption: state.ui.selectedOption,
  //     })
  //   );
  const getQuestions = makeGetQuestions();
  const { questions, selectedQuestion } = useSelector((state) => ({
    questions: getQuestions(state, props),
    selectedQuestion: state.ui.selectedQuestion,
  }));
  // console.log(surveyId, questions, selectedQuestion);
  //   console.log(surveyId, selectedQuestion, questions);

  useEffect(() => {
    function selectQuestionSanityCheck() {
      if (questions.length > 0) {
        if (questions.find((q) => q.surveyId === surveyId)) {
          dispatch(selectQuestion(questions[0].id));
        } else {
          dispatch(selectQuestion(null));
        }
      } else {
        dispatch(selectQuestion(null));
      }
    }
    selectQuestionSanityCheck();
  }, []);

  let maxSteps = questions.length;

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (questions.length > 0 && selectedQuestion === null) {
      dispatch(selectQuestion(questions[0].id));
      setActiveStep(0);
      maxSteps = questions.length;
    }
  }, [questions.length]);

  const handleCreateQuestion = () => {
    dispatch(
      createQuestion(surveyId, { text: " ", type: "choice" }, true, [
        {
          text: "",
          idx: 0,
        },
      ])
    ); //id, attrs, fromJson, optionsAttr
    setActiveStep(questions.length);
  };

  const handleCreateParagraph = () => {
    dispatch(
      createQuestion(surveyId, { text: " ", type: "paragraph" }, true, [
        { text: "", idx: 0 },
      ])
    ); //id, attrs, fromJson, optionAttr
    setActiveStep(questions.length);
  };

  const handleCreateShortAnswer = () => {
    dispatch(
      createQuestion(surveyId, { text: " ", type: "short-answer" }, true, [
        { text: "", idx: 0 },
      ])
    ); //id, attrs, fromJson, optionAttr
    setActiveStep(questions.length);
  };

  // const handleCreateMultipleChoice = () => {
  //   // dispatch(createQuestion(surveyId, { type: "choice" }, false, null)); //id, attrs, fromJson, optionAttr
  //   dispatch(
  //     createQuestion(surveyId, { text: "", type: "choice" }, true, [
  //       {
  //         text: "option1",
  //         idx: 0,
  //       },
  //     ])
  //   ); //id, attrs, fromJson, optionAttr
  //   setActiveStep(questions.length);
  // };

  const handleCreateCheckboxes = () => {
    // dispatch(createQuestion(surveyId, { type: "checkboxes" }, false, null)); //id, attrs, fromJson, optionAttr
    dispatch(
      createQuestion(surveyId, { text: " ", type: "checkboxes" }, true, [
        {
          text: "",
          idx: 0,
        },
      ])
    ); //id, attrs, fromJson, optionAttr
    setActiveStep(questions.length);
  };

  const handlePrevQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index -= 1;
    if (index >= 0) {
      dispatch(selectQuestion(questions[index].id));
    } else {
      dispatch(selectQuestion(null));
    }
    setActiveStep(index);
  };

  const handleNextQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index += 1;
    if (index < questions.length) {
      dispatch(selectQuestion(questions[index].id));
    }
    setActiveStep(index);
  };

  const handleDeleteQuestion = () => {
    if (selectedQuestion) {
      let deleted = selectedQuestion;
      handlePrevQuestion();
      dispatch(deleteQuestion(deleted));

      let index = questions.findIndex((q) => q.id === selectedQuestion);
      if (index - 1 > 0) {
        setActiveStep(index - 1);
      } else {
        setActiveStep(0);
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    // console.log(event.currentTarger);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {selectedQuestion !== null && (
        <>
          {type === "perceptionSurvey" && (
            <Typography variant="body2">
              Add analytical questions for the experiment. A good question
              captures representative reading or analytical tasks that your
              target audience will perform.
            </Typography>
          )}
          {/* {type==="demographicSurvey" &&<Typography variant="body2">
            Add demographic questions. You will be able to analyze the experiment results based on the participant demographics.
          </Typography>}
          {type==="postSurvey" &&<Typography variant="body2">
            Add any additional questions such as subjective preferences among visualization design alternatives.
          </Typography>} */}
          {!readOnly && (
            <>
              <Box mt={2}>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  disableElevation
                >
                  Add Question
                </Button>
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClose}
                  onPointerUp={() => handleCreateQuestion()}
                >
                  <ListItemIcon>
                    <RadioButtonCheckedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Multiple Choice"
                    // onPointerUp={() => handleCreateQuestion()}
                  />
                </MenuItem>

                <MenuItem
                  onClick={handleClose}
                  onPointerUp={() => handleCreateCheckboxes()}
                >
                  <ListItemIcon>
                    <CheckBoxIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Checkboxes"
                    // onPointerUp={() => handleCreateCheckboxes()}
                  />
                </MenuItem>

                {type !== "demographicSurvey" && (
                  <MenuItem
                    onClick={handleClose}
                    onPointerUp={() => handleCreateShortAnswer()}
                  >
                    <ListItemIcon>
                      <ShortTextIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Short Answer"
                      // onPointerUp={() => handleCreateShortAnswer()}
                    />
                  </MenuItem>
                )}

                {type === "postSurvey" && (
                  <MenuItem
                    onClick={handleClose}
                    onPointerUp={() => handleCreateParagraph()}
                  >
                    <ListItemIcon>
                      <SubjectIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Paragraph"
                      // onPointerUp={() => handleCreateParagraph()}
                    />
                  </MenuItem>
                )}
              </Menu>
            </>
          )}

          <Grid
            container
            mt={2}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="button" display="block">
                Question {activeStep + 1}.
              </Typography>
            </Grid>
            {!readOnly && (
              <Grid item>
                <Button onPointerUp={() => handleDeleteQuestion()}>
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            )}
          </Grid>
          {/* <Box display="flex" justifyContent="space-between">
           
            <Button variant="outlined" onPointerUp={() => handleDeleteQuestion()}>
            Delete Question
          </Button>
         
          </Box> */}

          <Question
            questionId={selectedQuestion}
            type={type}
            readOnly={readOnly}
          />
          <div style={{ marginTop: "30px" }}>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNextQuestion}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next Question
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handlePrevQuestion}
                  disabled={activeStep === 0}
                >
                  Previous Question
                </Button>
              }
            />
          </div>
        </>
      )}
      {/* <div className={classes.content}> */}
      {/* {type === "demographicSurvey" ? (
          <Button
            variant="outlined"
            color="primary"
            onPointerUp={() => handleCreateQuestion()}
          >
            Add Question
          </Button>
        ) : (
          <> */}

      {/* </div> */}
    </>
  );
}

export default Survey;
