import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { makeGetQuestionnaire } from "actions/experiments";
import { makeGetSurveys } from "actions/surveys";

import {
  FormControlLabel,
  Button,
  Grid,
  Radio,
  Checkbox,
  Snackbar,
  MobileStepper,
  Typography,
  TextField,
  Divider,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function PostSurveyLive(props) {
  const {
    experimentId,
    handleNextStep,
    handleAnswers,
    setIsValidSubmit,
    isValidSubmit,
    handleSubmit,
  } = props;

  const [response, setResponse] = useState({
    postSurvey: {},
  });
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const getSurveys = makeGetSurveys();
  const { surveys, showPostSurvey } = useSelector((state) => ({
    surveys: getSurveys(state, {
      parentId: experimentId,
      type: "postSurvey",
    }),
    showPostSurvey: state.experiments[experimentId].showPostSurvey,
  }));

  const getQuestions = makeGetQuestionnaire();
  const { questions } = useSelector((state) => ({
    questions: getQuestions(state, { surveyId: surveys[0].id }),
  }));

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // ui
  const [isValidNextStep, setIsValidNextStep] = useState(false);
  const [isValidNextQuestion, setIsValidNextQuestion] = useState(false);

  let maxSteps = questions.length;
  // Question navigation handlers
  const [activeStep, setActiveStep] = useState(0);

  // state changes

  useEffect(() => {
    setIsValidNextQuestion(false);
  }, [selectedQuestion]);

  useEffect(() => {
    setIsValidSubmit(false);
  }, []);

  useEffect(() => {
    if (questions.length > 0 && selectedQuestion === null) {
      setSelectedQuestion(questions[0].id);
      setActiveStep(0);
      setIsValidSubmit(false);
      maxSteps = questions.length;
    }
  }, [questions.length]);

  useEffect(() => {
    if (questions.every((item) => response.postSurvey[item.id])) {
      setIsValidNextStep(true);
      handleAnswers(response);
    } else {
      setIsValidNextStep(false);
    }
  }, [response]);

  // functions
  const handlePrevQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index -= 1;
    if (index >= 0) {
      setSelectedQuestion(questions[index].id);
    } else {
      setSelectedQuestion(null);
    }
    setActiveStep(index);
  };

  const handleNextQuestion = () => {
    let index = questions.findIndex((q) => q.id === selectedQuestion);
    index += 1;
    if (index < questions.length) {
      setSelectedQuestion(questions[index].id);
    }
    setActiveStep(index);
  };

  const getQuestion = () => {
    return questions.find((q) => q.id === selectedQuestion);
  };

  // for submit questionnaires
  const handleRadioChange = (event) => {
    let question = getQuestion();
    setResponse({
      ...response,
      postSurvey: {
        ...response.postSurvey,
        [question.id]: event.target.value,
      },
    });
    setIsValidNextQuestion(true);
  };

  const handleCheckboxChange = (optionId, event) => {
    let question = getQuestion();
    setResponse({
      ...response,
      postSurvey: {
        ...response.postSurvey,
        [question.id]: {
          ...(response.postSurvey[question.id] || {}),
          [optionId]: event.target.checked,
        },
      },
    });
    setIsValidNextQuestion(true);
  };

  const handleReleaseError = () => {
    setShowError(false);
    setErrorMsg("");
  };

  const handleTextAnswers = (e) => {
    let question = getQuestion();
    setResponse({
      ...response,
      postSurvey: {
        ...response.postSurvey,
        [question.id]: e.target.value,
      },
    });
    setIsValidNextQuestion(true);
  };

  const nextButtonText = () => {
    if (isValidSubmit && isValidNextStep) {
      return "Submit";
    } else if (isValidNextStep) {
      return "Go to Next Page";
    } else {
      return "Next Question";
    }
  };

  const nextButtonAction = () => {
    if (isValidSubmit && isValidNextStep) {
      handleSubmit();
    } else if (isValidNextStep) {
      handleNextStep();
    } else {
      handleNextQuestion();
    }
  };

  const render = () => {
    const curQuestion = getQuestion();
    // console.log(curQuestion);

    return (
      <>
        {showPostSurvey && (
          <>
            {selectedQuestion !== null && (
              <>
                {/* <Grid container align="left" spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      display="block"
                      gutterBottom
                      style={{ marginTop: "20px" }}
                    >
                      Post Survey
                    </Typography>
                  </Grid>
                </Grid> */}
                <Typography
                  variant="h5"
                  display="block"
                  gutterBottom
                  // style={{ marginTop: "20px" }}
                >
                  Post Survey
                </Typography>
                <Divider variant="fullWidth" />

                <Grid container align="left" spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                      style={{ marginTop: "20px" }}
                      // fullWidth
                    >
                      {curQuestion.text}
                    </Typography>

                    {curQuestion.type === "paragraph" && (
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={5}
                        onChange={handleTextAnswers}
                        value={response.postSurvey[curQuestion.id]}
                        style={{ marginTop: "20px" }}
                      />
                    )}
                    {curQuestion.type === "short-answer" && (
                      <TextField
                        variant="outlined"
                        fullWidth
                        onChange={handleTextAnswers}
                        value={response.postSurvey[curQuestion.id]}
                        style={{ marginTop: "20px" }}
                        // type="number"
                        // label="Number only"
                      />
                    )}
                    {curQuestion.type === "choice" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {curQuestion.options.map((option) => (
                          <FormControlLabel
                            label={option.text}
                            value={option.id}
                            key={option.id}
                            data-id={option.id}
                            control={
                              <Radio
                                style={{ margin: "5px" }}
                                checked={
                                  response.postSurvey[curQuestion.id] ===
                                  option.id
                                }
                              />
                            }
                            onChange={(e) => handleRadioChange(e)}
                          />
                        ))}
                      </div>
                    )}
                    {curQuestion.type === "checkboxes" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {curQuestion.options.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            label={option.text}
                            value={option.id}
                            data-id={option.id}
                            control={
                              <Checkbox
                                style={{ margin: "5px" }}
                                checked={
                                  response.postSurvey[curQuestion.id]
                                    ? response.postSurvey[curQuestion.id][
                                        option.id
                                      ]
                                    : false
                                }
                              />
                            }
                            onChange={(e) => handleCheckboxChange(option.id, e)}
                          />
                        ))}
                      </div>
                    )}
                  </Grid>
                  {/* <Grid item xs></Grid> */}
                </Grid>

                {showError && (
                  <Snackbar open={errorMsg !== null} autoHideDuration={1000}>
                    <Alert
                      onClose={() => handleReleaseError()}
                      variant="filled"
                      severity="error"
                    >
                      {errorMsg}
                    </Alert>
                  </Snackbar>
                )}
                <Box mt={2}>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        variant={isValidNextStep ? "contained" : null}
                        disableElevation
                        color={isValidNextStep ? "primary" : "default"}
                        size="small"
                        disabled={!isValidNextQuestion}
                        onPointerUp={() => {
                          nextButtonAction();
                        }}
                      >
                        {nextButtonText()}
                      </Button>
                    }
                    backButton={
                      <Button
                        disableElevation
                        size="small"
                        onClick={handlePrevQuestion}
                        // disabled={activeStep === 0}
                        disabled={true}
                      >
                        Previous Question
                      </Button>
                    }
                  />
                </Box>
              </>
            )}
          </>
        )}
      </>
    );
  };
  return render();
}

export default PostSurveyLive;
